<cal-add-currency-adjustment-condition (saved)="onSaved()"></cal-add-currency-adjustment-condition>
<div class="panel" style="margin-top: 5px;">
   <div class="panel-body">
      <div class="quantity-filter">
         <mat-checkbox (change)="onShowOnlyValidConditions($event.checked)" [checked]="showOnlyValidConditions | async" #myCheckbox>
             {{resources.ShowValidConditions}}
         </mat-checkbox>
      </div>
      <cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="dataList | async" (sortTriggered)="onSort($event)"
         (filterTriggered)="onFilter($event)" (clearFilterAndSortingTriggered)="onClearFilterAndSorting()"
         [isLoading]="isLoading | async" [filterExpression]="filterExpression | async"
         [dataTotalCount]="(dataList | async).length">
      </cal-calsiumlist-listing>
   </div>
</div>