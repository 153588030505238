import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import {
    LoadMaterialsAction,
    ChangePageAction,
    ChangeResultsTotalCountAction,
    ChangeSortExpressionAction,
    ChangeFilterExpressionAction,
    LoadMaterialAction,
    LoadMaterialPriceTypesAction,
    AddMaterialPriceAction,
    ChangeMaterialPricesSortExpressionAction,
    ChangeMaterialPricesFilterExpressionAction
} from './actions';

import { CalsiumDateFormatterPipe } from '../../app/shared/calsium-date.pipe';
import { MaterialApiService } from '../../api/services/material-api.service';
import { AppState } from '../app.state';
import { Paging, Material, MaterialPriceType, MaterialPrice } from '..';
import { Loadable } from '../common/loadable';
import { MaterialAddPrice } from '../material/state-models/material-add-price';

@Injectable()
export class MaterialStateService {
    constructor(private appStore: Store<AppState>, private apiService: MaterialApiService, private datePipe: CalsiumDateFormatterPipe) { }

    @Loadable()
    async loadMaterials(paging: Paging, sortExpression: string, filterExpression: string): Promise<void> {
        const payload = {
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression
        };
        const material = await this.apiService.getAllMaterials(payload);
        this.appStore.dispatch(new LoadMaterialsAction(material.list));
        this.appStore.dispatch(new ChangeResultsTotalCountAction(material.totalCount));
    }

    @Loadable()
    async loadMaterialById(id: number, sortExpression?: string, filterExpression?: string): Promise<void> {
        const payload = {
            order: sortExpression,
            filter: filterExpression
        };
        const response = await this.apiService.getMaterialById(id, payload);
        if (response && response.length > 0) {
            let material = response[0];
            const materialPrices = [];
            material.materialPrices.forEach((price) => {
                const newPrice = { ...price };
                newPrice.modifiedOn = newPrice.updatedOn || newPrice.createdOn;
                newPrice.modifiedBy = newPrice.updatedBy || newPrice.createdBy;
                materialPrices.push(newPrice);
            });
            material = { ...material, materialPrices: [...materialPrices] };
            this.appStore.dispatch(new LoadMaterialAction(material));
        }
    }

    @Loadable()
    async loadMaterialPriceTypes(): Promise<void> {
        const response = await this.apiService.getMaterialPriceTypes();
        if (response && response.materialPriceType) {
            this.appStore.dispatch(new LoadMaterialPriceTypesAction(response.materialPriceType));
        }
    }

    getMaterials(): Observable<Material[]> {
        return this.appStore
            .select(state => state.material.Materials).pipe(map(item => _.values(item)));
    }

    getSelectedMaterial(): Observable<Material> {
        return this.appStore.select(state => state.material.SelectedMaterial);
    }

    getMaterialPriceTypes(): Observable<MaterialPriceType[]> {
        return this.appStore
            .select(state => state.material.MaterialPriceTypes).pipe(map(item => _.values(item)));
    }

    getPaging(): Observable<Paging> {
        return this.appStore.select(state => state.material.Paging);
    }

    goToPage(page: number) {
        this.appStore.dispatch(new ChangePageAction(page));
    }

    getTotalCount(): Observable<number> {
        return this.appStore.select(state => state.material.TotalCount);
    }

    getSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.material.SortExpression);
    }

    sort(sortExpression: string) {
        this.appStore.dispatch(new ChangeSortExpressionAction(sortExpression));
    }

    getFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.material.FilterExpression);
    }

    filter(filterExpression: string) {
        this.appStore.dispatch(new ChangeFilterExpressionAction(filterExpression));
    }

    getMaterialPricesSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.material.MaterialPricesSortExpression);
    }

    sortMaterialPrices(sortExpression: string) {
        this.appStore.dispatch(new ChangeMaterialPricesSortExpressionAction(sortExpression));
    }

    getMaterialPricesFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.material.MaterialPricesFilterExpression);
    }

    filterMaterialPrices(filterExpression: string) {
        this.appStore.dispatch(new ChangeMaterialPricesFilterExpressionAction(filterExpression));
    }

    clearFilterAndSorting() {
        [
            new ChangeFilterExpressionAction(null),
            new ChangeSortExpressionAction(null)
        ].forEach(action => this.appStore.dispatch(action));
    }

    clearMaterialPricesFilterAndSorting() {
        [
            new ChangeMaterialPricesFilterExpressionAction(null),
            new ChangeMaterialPricesSortExpressionAction(null)
        ].forEach(action => this.appStore.dispatch(action));
    }

    async addMaterialPrice(materialAddPrice: MaterialAddPrice): Promise<any> {
        let materialPrices: MaterialPrice[];
        const response = await this.apiService.addMaterialPrice(materialAddPrice);
        if (response.errors) {
            const errors = response.errors.map((err: { message: any; }) => err.message);
            return errors;
        }
        materialPrices = response.data.materialPricesMutation.updateMaterialPrice;
        if (materialPrices != null) {
            materialPrices.forEach((price) => {
                price.modifiedOn = price.updatedOn || price.createdOn;
                price.modifiedBy = price.updatedBy || price.createdBy;
            });
            this.appStore.dispatch(new AddMaterialPriceAction(materialPrices));
        }
    }
}
