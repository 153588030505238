import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { ComponentIndividual } from '../../../../../state/flow/state-models/component-individual';
import { OrchestratorStateService } from 'src/state/orchestrator/service';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { ConfirmationDialogService } from 'src/app/common/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'cal-comp-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css']
})
export class DetailsComponent {

    @Input() componentIndividual: ComponentIndividual;
    @Input() resources: any;

    constructor(private orchestratorService: OrchestratorStateService,
        private confirmationDialog: ConfirmationDialogService,
        private errHandler: ErrorHandlerService) {
    }

    toAttributesArray(extraAttrs: string) {
        if (extraAttrs) {
            const collection = JSON.parse(extraAttrs);
            return _.chunk(
                _.map(collection, (value, key) => ({ value, key })),
                4
            );
        }
    }

    InputReady() {
        return this.componentIndividual !== null && typeof (this.componentIndividual) !== 'undefined';
    }

    async recalculateComponent() {
        const result = await this.confirmationDialog.show(
            this.resources.RecalculateConfirmation.formatUnicorn(1, this.resources.Item_s),
            this.resources.Recalculate.formatUnicorn(this.resources.component_s));
        if (!result) {
            return;
        }
        await this.orchestratorService.recalculateComponents([this.componentIndividual.id])
            .then((data) => {
                this.errHandler.emitError(this.resources.RecalculationStarted.formatUnicorn(data || 0, this.resources.component_s), this.resources.Success, ErrorTypeEnum.Success);
            }, (error) => {
                this.errHandler.emitError(this.resources.RecalculationFailed, this.resources.Error, ErrorTypeEnum.Error);
            });
    }
}
