import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Paging } from '../../../state/common/state-models/paging';
import * as _ from 'lodash';

@Component({
    selector: 'cal-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {
    @Input()
    set paging(value: Paging) {
        if (value) {
            this.offset = value.offset;
            this.currentPage = value.page;
            this.doPaging();
        }
    }
    @Input()
    set dataTotalCount(value: number) {
        if (value >= 0) {
            this.totalCount = value;
            this.doPaging();
        }
    }

    @Output() pageChange = new EventEmitter<number>();
    pagesCount: number;
    displayPages: number[] = [];
    showFirstPage: boolean;
    showLastPage: boolean;
    showPrevious: boolean;
    showNext: boolean;
    showFirstGoToPage: boolean = false;
    showLastGoToPage: boolean = false;
    currentPage: number;
    offset: number;
    totalCount: number;
    constructor() { }

    doPaging() {
        if (this.totalCount < 1 || this.offset < 1 || this.currentPage < 1) {
            this.pagesCount = 0;
            return;
        }
        const numberOfPagesToDisplay = 4;
        if (this.totalCount === undefined) {
            this.pagesCount = 0;
        } else {
            this.pagesCount = Math.ceil(this.totalCount / this.offset);
        }
        this.displayPages = [];
        const prevPages: number[] = [];
        const nextPages: number[] = [];
        for (let i = -1 * (numberOfPagesToDisplay / 2); i < 0; i++) {
            if (this.currentPage + i > 0) {
                prevPages.push(this.currentPage + i);
            }
        }
        for (let i = 1; i < 1 + (numberOfPagesToDisplay / 2); i++) {
            if (this.currentPage + i <= this.pagesCount) {
                nextPages.push(this.currentPage + i);
            }
        }
        for (let i = prevPages.length; i < numberOfPagesToDisplay / 2; i++) {
            if (nextPages.length > 0 && nextPages[nextPages.length - 1] + 1 < this.pagesCount) {
                nextPages.push(nextPages[nextPages.length - 1] + 1);
            }
        }
        for (let i = nextPages.length; i < numberOfPagesToDisplay / 2; i++) {
            if (prevPages.length > 0 && _.sortBy(prevPages)[0] - 1 > 0) {
                prevPages.push(_.sortBy(prevPages)[0] - 1);
            }
        }
        if (_.sortBy(prevPages)[0] === 2) {
            prevPages.push(1);
        }

        if (_.sortBy(nextPages)[nextPages.length - 1] === this.pagesCount - 1) {
            prevPages.push(this.pagesCount);
        }

        this.displayPages = this.displayPages.concat(prevPages);
        this.displayPages.push(this.currentPage);
        this.displayPages = this.displayPages.concat(nextPages);
        this.displayPages = _.sortBy(this.displayPages);
        this.showFirstPage = this.displayPages.findIndex(x => x === 1) === -1;
        this.showLastPage = this.displayPages.findIndex(x => x === this.pagesCount) === -1;
        this.showPrevious = this.currentPage > 1;
        this.showNext = this.currentPage < this.pagesCount;
    }

    onPageChanging(newPage) {
        this.showFirstGoToPage = false;
        this.showLastGoToPage = false;
        this.pageChange.emit(newPage);
        window.scroll(0, 0);
    }

    goToPage(event) {
        const value = +event.target.value;
        if (value >= 1 && value <= this.pagesCount) {
            this.onPageChanging(+value);
        }
    }
    showGoToPageClicked(first, element) {
        if (first) {
            this.showFirstGoToPage = true;
        } else {
            this.showLastGoToPage = true;
        }
        window.setTimeout(() => element.focus(), 200);
        element.value = '';
    }
}
