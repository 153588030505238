<div class="card">
   <h3 class="card-header">{{resources.AddCurrencyAdjustmentCode}}</h3>
   <div class="card-body">
      <div class="container-fluid">
         <div class="row">
            <div class="form-group col-md-2 ">
               <label>{{resources.Code}}</label>
                <div class="input-group">
                    <mat-form-field style="width: 100%;" appearance="outline">
                        <input matInput [formControl]="CodeInput" type="text"  placeholder="{{resources.Code}}" maxlength="25" />
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group col-md-2 ">
                <label >{{ resources.From }}</label>
                <div class="input-group">
                    <mat-form-field style="width: 100%;" appearance="outline">
                    <input matInput [formControl]="validFrom"  type="date" placeholder="{{resources.validFrom}}" />
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group  col-md-2 ">
                <label>{{ resources.To }}</label>
                <div class="input-group">
                    <mat-form-field style="width: 100%;" appearance="outline">
                    <input matInput [formControl]="validTo"  type="date" placeholder="{{ resources.validTo }}" />
                </mat-form-field>
                </div>
            </div>
            <div class="form-group col-md-2">
            <label>&nbsp;</label>
               <button mat-raised-button type="button" class="btn btn-sm btn-primary form-control" id="saveDeliveryRoute"
                  (click)="onSave()">
                  <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<br />
