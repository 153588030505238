import { Directive, ElementRef, HostListener, Input, OnInit, PipeTransform } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Directive({
    selector: '[appNumberOnly]'
})
export class NumberOnlyDirective implements OnInit {
    @Input() maxNumberOfIntegers = 6;
    @Input() maxNumberOfDecimals = 0;
    @Input() useCommaDecimalSeperator = true;
    @Input() pipe: PipeTransform = null;
    @Input() form: UntypedFormGroup;
    @Input() formControl: UntypedFormControl;


    // use https://regexr.com/ to understand regexp formula
    private decimals_validate_regexp: RegExp;
    private integers_validate_regexp: RegExp = new RegExp(/^[+-]?[0-9]{1,3}(?:\ ?[0-9]{3})*$/g);
    private comma_decimal_seperator = ',';
    private dot_decimal_seperator = '.';
    private decimal_seperator = '';
    private decimals_allowed_chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' '];
    private integers_allowed_chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' '];
    private specialKeys = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Delete', 'Insert'];

    private initialValue: string;
    private previousValue: string[] = [];
    private _maxDecimals: number;
    private _maxIntegers: number;
    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.decimal_seperator = this.useCommaDecimalSeperator ? this.comma_decimal_seperator : this.dot_decimal_seperator;
        this.decimals_allowed_chars.push(this.decimal_seperator);
        this.decimals_validate_regexp = this.useCommaDecimalSeperator ?
            new RegExp(/^[+-]?[0-9]{1,3}(?:\ ?[0-9]{3})*(?:\,[0-9]{1,5})?$/g) :
            new RegExp(/^[+-]?[0-9]{1,3}(?:\ ?[0-9]{3})*(?:\.[0-9]{1,5})?$/g);
        if (this.el.nativeElement.value !== '' && this.el.nativeElement.value.length > 0 && this.pipe !== null) {
            this.changeValue(this.pipe.transform(this.el.nativeElement.value));
        }
        this._maxIntegers = this.getMaxNumber(this.maxNumberOfIntegers);
        this._maxDecimals = this.getMaxNumber(this.maxNumberOfDecimals);
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const allowChars = this.maxNumberOfDecimals > 0 ? this.decimals_allowed_chars : this.integers_allowed_chars;
        if (allowChars.indexOf(event.key) !== -1) {
            this.previousValue.push(this.el.nativeElement.value);
            return;
        }
        event.preventDefault();
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        if (!this.verifyMaxNumber(this.el.nativeElement.value)) {
            this.changeValue(this.getValidPreviousValue());
        }
        return;
    }

    @HostListener('focus', ['$event.target'])
    onfocus(event: any) {
        this.initialValue = this.el.nativeElement.value;
    }

    @HostListener('change', ['$event'])
    onChange(event: KeyboardEvent) {
        const current: string = this.el.nativeElement.value;
        const regexp = this.maxNumberOfDecimals > 0 ? this.decimals_validate_regexp : this.integers_validate_regexp;
        if ((current !== '' && !String(current).match(regexp)) || !this.verifyMaxNumber(this.el.nativeElement.value)) {
            this.changeValue(this.initialValue);
        }
    }

    changeValue(newValue: string) {
        if (this.form) {
            this.form.controls[this.el.nativeElement.name].setValue(newValue);
        } else if (this.formControl) {
            this.formControl.setValue(newValue);
        } else {
            this.el.nativeElement.value = newValue;
        }
    }

    verifyMaxNumber(value): boolean {
        let current = value;
        current = current.split(' ').join('');
        if (current.split(this.decimal_seperator).length < 3 && !Number.isNaN(+current)) {
            const integer = +current.split(this.decimal_seperator)[0];
            if (integer > this._maxIntegers) {
                return false;
            }
            if (current.split(this.decimal_seperator).length === 2) {
                const decimal = +current.split(this.decimal_seperator)[1];
                if (decimal > this._maxDecimals) {
                    return false;
                }
            }
        }
        return true;
    }
    getMaxNumber(numberOfIntegers: number): number {
        let str = '';
        for (let i = 0; i < numberOfIntegers; i++) {
            str += '9';
        }
        return +str;
    }
    getValidPreviousValue() {
        let value;
        do {
            value = this.previousValue.pop();
        }
        while (value != undefined && !this.verifyMaxNumber(value))
        return value;
    }
}
