import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription, combineLatest, of } from 'rxjs';

import {
  Paging,
  CommonStateService,
} from '../../../../state';
import { CalsiumListConfig, ColumnDataType } from '../../../calsiumlist/calsiumlist.component.model';
import { switchMap, debounceTime, map } from 'rxjs/operators';
import { SimulationStateService } from 'src/state/simulation/service';
import { Simulation } from 'src/state/simulation/state-models/simulation-data';
import { CalsiumNumberSeperatorPipe } from 'src/app/shared/calsium-number-seperator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CalculationDetailsComponent } from 'src/app/allocation/pages/calculation-details/calculation-details.component';
import { CalsiumDateFormatterPipe } from 'src/app/shared/calsium-date.pipe';

@Component({
  selector: 'cal-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.css']
})
export class SimulationComponent implements OnInit, OnDestroy {
  resources: any = this.commonService.getResources();
  listConfig: CalsiumListConfig;
  dataList: Observable<Simulation[]>;
  isLoading: Observable<boolean>;
  paging: Observable<Paging>;
  totalCount: Observable<number>;
  errorCount: Observable<number>;
  subscriptions = new Subscription();
  sortingExpression: Observable<string>;
  filterExpression: Observable<string>;
  @Input() RowData: any;

  constructor(
    private simulationService: SimulationStateService,
    private commonService: CommonStateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isLoading = this.commonService.getLoading();
    this.sortingExpression = this.simulationService.getSortingExpression('simulation');
    this.filterExpression = this.simulationService.getFilterExpression('simulation');
    this.totalCount = this.simulationService.getTotalCount('simulation');
    this.errorCount = this.simulationService.getErrorCount('simulation');
    this.paging = this.simulationService.getPaging('simulation');
    this.dataList = this.simulationService.getList('simulation');

    this.subscriptions.add(
      combineLatest(this.paging, this.sortingExpression, this.filterExpression).pipe(
        debounceTime(0),
        switchMap(([paging, sortingExpression, filterExpression]) => {
          return this.simulationService.loadSimulationList(
            paging,
            sortingExpression,
            filterExpression,
            true
          );
        })
      ).subscribe()
    );
    this.buildList();
  }

  ngOnDestroy() { this.subscriptions.unsubscribe(); }

  onPaging(page: number) {
    this.simulationService.goToPage(page);
  }

  onSort(sortExpression: string) {
    this.simulationService.sort(sortExpression);
  }

  onFilter(filterExpression: string) {
    const newFilterExpression = this.simulationService.convertStatusCode(filterExpression);
    if (newFilterExpression) filterExpression = newFilterExpression;
    this.simulationService.filter(filterExpression);
  }

  onClearFilter() {
    this.simulationService.clearFilterAndSorting();
  }

  buildList() {
    this.listConfig = new CalsiumListConfig({
      ShowListCaption: true,
      ShowExportToExcel: false,
      ListCaption: this.resources.FinishedSimulationProductsTitle,
      ShowFilterRow: true,
      ShowSumRow: false,
      ParentComponent: this
    });

    let column;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'simProdNumber', this.resources.SimProdNumber);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'partPeriod', this.resources.PartPeriod);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'structMarketCOReleaseTime', this.resources.StructMarketCOReleaseTime);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'userId', this.resources.UserId);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'status', this.resources.Status);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'baseCost', this.resources.BaseCost);
    column.AllowFilter = true;
    column.Sortable = true;
    column.DisplayPipe = new CalsiumNumberSeperatorPipe();

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'createdOn', this.resources.CreatedOn);
    column.AllowFilter = true;
    column.Sortable = true;
    column.DisplayPipe = new CalsiumDateFormatterPipe();

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'updatedOn', this.resources.UpdatedOn);
    column.AllowFilter = true;
    column.Sortable = true;
    column.DisplayPipe = new CalsiumDateFormatterPipe();

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'errorMessage', this.resources.ErrorMessage);
    column.CssClass = 'nowrap';
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'sportMessage', this.resources.SportMessage);
    column.CssClass = 'nowrap';
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.details, "", "");
    column.AllowFilter = false;
    column.Sortable = false;
    column.IsClickable = (_) => true;
    column.OnClick = this.viewCalculationDetails;
    column.GetAnchorLink = (RowData) =>
      `/calculation-details/${RowData.productIndividualId}/0`;
  }

  viewCalculationDetails(RowData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      productIndividualId: RowData.productIndividualId,
    };
    dialogConfig.height = "80%";
    dialogConfig.width = "80%";

    this.dialog.open(CalculationDetailsComponent, dialogConfig);
  }
}