import { Component, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Subscription, Observable, combineLatest, of } from 'rxjs';
import { Validators, UntypedFormControl } from '@angular/forms';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
const moment = _rollupMoment || _moment;

import { environment } from 'src/environments/environment';
import { ComponentAllocationStateService } from 'src/state/component-allocation/service';
import { CommonStateService, AllocationSummary, Paging } from 'src/state';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ComponentAllocationSearchForm } from 'src/state/component-allocation/state-models/component-allocation-search-form';
import { now } from 'lodash';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CalsiumWidgetConfig } from 'src/app/calsiumlist/calsiumlist.component.model';


@Component({
    selector: 'cal-allocation-component',
    templateUrl: './component.component.html',
    styleUrls: ['./component.component.css']
})
export class ComponentComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    selectedScaniaUnitId: number = 0;
    selectedPeriod: Moment;
    componentSearchForm: Observable<ComponentAllocationSearchForm>;
    subscriptions = new Subscription();
    widgetValue = {
        produced: null as Observable<AllocationSummary>,
        allocated: null as Observable<AllocationSummary>,
        stock: null as Observable<AllocationSummary>
    };
    allocationBucketUrl = environment.allocationBucketUrl;
    searchExpression: string;
    producedFrom: UntypedFormControl;
    producedTo: UntypedFormControl;
    minDate: string;
    maxDate: string;
    widgetConfig: CalsiumWidgetConfig;
    selectedTab: string = "";

    constructor(
        private stateService: ComponentAllocationStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) { }

    ngOnInit() {
        this.buildWidget();
        this.componentSearchForm = this.stateService.getComponentSearchForm();
        this.widgetValue.produced = this.stateService.getComponentsSummary('produced') || of({totalValue:0, totalAmount:0});
        this.widgetValue.allocated = this.stateService.getComponentsSummary('allocated') || of({totalValue:0, totalAmount:0});
        this.widgetValue.stock = this.stateService.getComponentsSummary('stock') || of({totalValue:0, totalAmount:0});
        this.producedFrom=new UntypedFormControl (moment().date(1).format('YYYY-MM-DD')),[Validators.required];
        this.producedTo=new UntypedFormControl (moment().add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD'),[Validators.required]);
        this.minDate=moment().date(1).format('YYYY-MM-DD');
        this.maxDate=moment().add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD');


        this.subscriptions.add(
            combineLatest(this.componentSearchForm).pipe(
                debounceTime(0),
                switchMap(([componentSearchForm]) => {
                    if (componentSearchForm != null) {
                        this.fillSearchForm(componentSearchForm);
                        return this.stateService.loadComponentAllocationSummary(
                            componentSearchForm.selectedScaniaUnitId,
                            componentSearchForm.fromDate,
                            componentSearchForm.toDate,
                            true
                        );
                    }
                    return new Promise<void>(null);
                })
            ).subscribe()
        );

    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    onScaniaUnitSelected(scaniaUnitId: any) {
        this.selectedScaniaUnitId = scaniaUnitId;
        this.resetToDefaults();
    }

    onPeriodSelected(period: any) {
        this.selectedPeriod = period;
        this.producedFrom.setValue(period.date(1).format('YYYY-MM-DD'));
        this.producedTo.setValue(period.add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD'));
        this.minDate = period.date(1).format('YYYY-MM-DD');
        this.maxDate = period.add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD');
        this.resetToDefaults();
    }

    onMonthSearchClick() {
        const period = this.selectedPeriod || moment();
        const selectedScaniaUnitId = this.selectedScaniaUnitId;
        this.producedFrom.setValue(period.date(1).format('YYYY-MM-DD'));
        this.producedTo.setValue(period.add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD'));

        if (selectedScaniaUnitId === 0) return;

        const fromDate = period.date(1).format('YYYY-MM-DD');
        const toDate = period.add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD');
        const param: ComponentAllocationSearchForm = {
            fromDate,
            toDate,
            selectedScaniaUnitId
        };
        this.stateService.search(param);
        this.searchExpression = `${fromDate}${toDate}${selectedScaniaUnitId}`;
    }
    onSearchClick() {
        if (this.isValidForm()) {
            const period = this.selectedPeriod || moment();
            const selectedScaniaUnitId = this.selectedScaniaUnitId;

            if (selectedScaniaUnitId === 0) return;

            const fromDate = this.producedFrom.value;
            const toDate = this.producedTo.value;
            const param: ComponentAllocationSearchForm = {
                fromDate,
                toDate,
                selectedScaniaUnitId
            };
            this.stateService.search(param);
            this.searchExpression = `${fromDate}${toDate}${selectedScaniaUnitId}`;
        }
    }

    fillSearchForm(form: ComponentAllocationSearchForm) {
        this.selectedScaniaUnitId = form.selectedScaniaUnitId;
        this.selectedPeriod = moment(form.fromDate, 'YYYY-MM-DD');

    }


    isValidForm(): boolean {
        let valid = true;

        if (this.producedFrom.errors != null) {
            if (this.producedFrom.errors.required) {
                this.errHandler.emitError(this.resources.producedFromRequired, this.resources.Error, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.producedTo.errors != null) {
            if (this.producedTo.errors.required) {
                this.errHandler.emitError(this.resources.producedToRequired, this.resources.Error, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.producedTo.errors == null && this.producedFrom.errors == null) {
            if (this.producedFrom.value > this.producedTo.value) {
                this.errHandler.emitError(this.resources.producedFromToCorrect, this.resources.Error, ErrorTypeEnum.Error);
                valid = false;
            }
        }

        return valid;
    }

    onExport(allocationStatus: string) {
        this.stateService.exportAllocationData(allocationStatus);
    }

    buildWidget() {
        this.widgetConfig = new CalsiumWidgetConfig();
        this.widgetConfig.ShowExportToExcel = true;
    }


    setSelectedTab(selectedTab: string) {
        this.selectedTab = selectedTab;
        this.onSearchClick();
    }

    resetToDefaults() {
        this.selectedTab = "";
        this.stateService.resetToDefaults();
        let el = this.elementRef.nativeElement.querySelector('.nav-link.active');
        if (el)
            this.renderer.removeClass(el, 'active');
    }
}


