import { Injectable } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Loadable } from '../common/loadable';
import { Paging } from '../common/state-models/paging';
import {
    ChangeMissingSIMMaterialListFilterExpressionAction,
    ChangeMissingSIMMaterialListPageAction,
    ChangeMissingSIMMaterialListSearchForm,
    ChangeMissingSIMMaterialListSortExpressionAction,
    ChangeMissingSIMMaterialListTotalCountAction,
    LoadMissingSIMMaterialListPageAction
} from './action';
import { Observable } from 'rxjs';
import { ChangeAllocatedComponentListSortExpressionAction } from '../component-allocation/actions';
import { SimulationApiService } from 'src/api/services/simulation-api.service';
import { MissingSIMMaterial } from './state-models/missing-sim-material';
import { MissingSIMMaterialListState } from './state';
import { MaterialSearchForm } from './state-models/missing-material-search-form';
import { CalsiumDateFormatterPipe } from 'src/app/shared/calsium-date.pipe';
@Injectable()
export class MissingSIMMaterialListStateService {

    constructor(
        private missingSIMMaterialApiService: SimulationApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>,
        private datePipe: CalsiumDateFormatterPipe
    ) { }

    @Loadable()
    async loadMissingSIMMaterialList(searchForm: MaterialSearchForm,
        paging: Paging,
        sortExpression: string,
        filterExpression: string,
        forceRefetch: boolean = false) {
        const payload = {
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression
        };
        if (searchForm != null && searchForm.fromDate != null) {
            payload['from'] = this.datePipe.transform(searchForm.fromDate);
        }
        if (searchForm != null && searchForm.toDate != null) {
            payload['to'] = this.datePipe.transform(searchForm.toDate);
        }
        const missingSIMMaterialList = await this.missingSIMMaterialApiService.getMissingSIMMaterialList('missing_SIM_material', payload, forceRefetch);
        this.appStore.dispatch(new LoadMissingSIMMaterialListPageAction(missingSIMMaterialList.list));
        this.appStore.dispatch(new ChangeMissingSIMMaterialListTotalCountAction(missingSIMMaterialList.totalCount));
    }

    // async refetchList(from: string, to: string): Promise<void> {
    //     const state = <MissingSIMMaterialListState>this.appState.getValue().missingSIMMaterials;
    //     await this.loadMissingSIMMaterialList(from, to, state.missingSIMMaterialListPaging, state.missingSIMMaterialListSortExpression, state.missingSIMMaterialListFilterExpression, true);
    // }

    getList(): Observable<MissingSIMMaterial[]> {
        return this.appStore.select(state => state.missingSIMMaterials.missingSIMMaterialList);
    }


    getSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.missingSIMMaterials.missingSIMMaterialListSortExpression);
    }

    sort(sortExpression: string) {
        this.appStore.dispatch(new ChangeMissingSIMMaterialListSortExpressionAction(sortExpression));
    }

    getMaterialSearchForm(): Observable<MaterialSearchForm> {
        return this.appStore.select(state => state.missingSIMMaterials.missingSIMMaterialListSearchForm);
    }

    changeMaterialSearchForm(searchForm: MaterialSearchForm) {
        this.appStore.dispatch(new ChangeMissingSIMMaterialListSearchForm(searchForm));
    }

    getFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.missingSIMMaterials.missingSIMMaterialListFilterExpression);
    }

    filter(filterExpression: string) {
        this.appStore.dispatch(new ChangeMissingSIMMaterialListFilterExpressionAction(filterExpression));
    }

    getPaging(): Observable<Paging> {
        return this.appStore.select(state => state.missingSIMMaterials.missingSIMMaterialListPaging);
    }

    goToPage(page: number) {
        this.appStore.dispatch(new ChangeMissingSIMMaterialListPageAction(page));
    }

    getTotalCount(): Observable<number> {
        return this.appStore.select(state => state.missingSIMMaterials.missingSIMMaterialListTotalCount);
    }

    clearFilterAndSorting() {
        [
            new ChangeMissingSIMMaterialListSortExpressionAction(null),
            new ChangeMissingSIMMaterialListFilterExpressionAction(null)
        ].forEach(action => this.appStore.dispatch(action));
    }

    exportMissingMaterialExcel(fileName: string, variables?: any): Promise<any> {
        return this.missingSIMMaterialApiService.exportMissingMaterialExcel(fileName, variables);
    }
}
