import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';
import { DeliveryRoute } from 'src/state/administraion/delivery-route/state-models/delivery-route';
import { DeliveryRouteAllocationUnit } from 'src/state/administraion/delivery-route-allocation-unit/state-models/delivery-route-allocation-unit';
import { ScaniaUnitAllocation, ScaniaUnitConsolidation, EdgeResult } from 'src/state';


@Injectable({
  providedIn: 'root'
})
export class DeliveryRouteAllocationUnitApiService {
  constructor(private apollo: Apollo) { }

  getDeliveryRouteAllocationUnitList(variables?): Promise<EdgeResult<DeliveryRouteAllocationUnit>> {
    const result$ = this.apollo
      .query<DeliveryRouteAllocationUnit[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
        query deliveryRouteAllocationUnitQuery($order: String, $filter: String, $paging: Paging) {
          admin {
            deliveryRouteAllocationUnit(
              order: $order
              filter: $filter
              paging: $paging
            ) {
              list {
                id
                propertyCode
                deliveryRoute {
                  id
                  code
                }
                scaniaUnitAllocation {
                  allocationScaniaUnit {
                    name
                    id
                  }
                  scaniaUnitComponentType {
                    componentType {
                      name
                    }
                    scaniaUnit{
                      name
                    }
                  }
                }
                scaniaUnitConsolidation {
                  productClass {
                    variantOption {
                      description
                    }
                  }
                  scaniaUnit {
                    name
                  }
                  consolidatingScaniaUnit{
                    name
                  }
                }        
              }
              totalCount
            }
          }
        }
        `
      })
      .pipe<EdgeResult<DeliveryRouteAllocationUnit>>(
        
        map((result: any) => {
          return result?.data?.admin?.deliveryRouteAllocationUnit;
          })
      )
     
      return lastValueFrom(result$);
  }


  async updateDeliveryRouteAllocationUnit(payload: DeliveryRouteAllocationUnit): Promise<any> {
    const mutation = `
    mutation updateDeliveryRouteAllocationUnit($payload: DeliveryRouteAllocationUnitInput!) {
      deliveryRoute {
        updateDeliveryRouteAllocationUnit(payload: $payload)
      }
    }    
    `;
    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    })
      .toPromise();
  }

  getScaniaUnitAllocation(variables?): Promise<ScaniaUnitAllocation[]> {
    const result$ = this.apollo
      .query<ScaniaUnitAllocation[]>({
        variables,
        query: gql`
        query scaniaUnitAllocationQuery($order: String, $filter: String, $paging: Paging) {
          admin {
            scaniaUnitAllocation(order: $order, filter: $filter, paging: $paging) {
              list {
                id
                allocationScaniaUnit {
                  name
                  id
                }
                scaniaUnitComponentType {
                  componentType {
                    name
                  }
                  scaniaUnit {
                    name
                  }
                }
              }
            }
          }
        }        
      `
      })
      .pipe<ScaniaUnitAllocation[]>(
      
        map((result: any) => {
          return result?.data?.admin?.scaniaUnitAllocation?.list;
          })
      )
     
      return lastValueFrom(result$);
  }

  
  getScaniaUnitConsolidation(variables?): Promise<ScaniaUnitConsolidation[]> {
    const result$ = this.apollo
      .query<ScaniaUnitConsolidation[]>({
        variables,
        query: gql`
        query scaniaUnitConsolidationQuery($order: String, $filter: String, $paging: Paging) {
          admin {
            scaniaUnitConsolidation(order: $order, filter: $filter, paging: $paging) {
              list {
                id
                productClass {
                  variantOption {
                    description
                  }
                }
                scaniaUnit {
                  name
                }
                consolidatingScaniaUnit {
                  name
                }
              }
            }
          }
        }               
      `
      })
      .pipe<ScaniaUnitConsolidation[]>(
      
        map((result: any) => {
          return result?.data?.admin?.scaniaUnitConsolidation?.list;
          })
      )
     
      return lastValueFrom(result$);
  }
}
