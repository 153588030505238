
<div class="alert-wrapper">
    <div class="broadcast-banner-message" [ngClass]="{
        'info':systemNotification.notificationType === 1,
        'danger':systemNotification.notificationType === 2
      }"
       *ngFor="let systemNotification of systemNotificationsList | async">
       <i class="fas" [ngClass]="{
           'fa-exclamation-triangle':systemNotification.notificationType === 2,
            'fa-bullhorn':systemNotification.notificationType === 1
       }"></i>
       <strong>{{systemNotification.message}}</strong>
    </div>
</div>

