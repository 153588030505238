module.exports = [
    {
        CPAUnits: ["MSB", "MZB", "MX"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            engineVehicle
            axle
            batteryVehicle
            gearboxVehicle
            electricPropVehicle
            chassisBus: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            busType
            responsibilityCode
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        CPAUnits: ["BRBU"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            engineVehicle
            axle
            batteryVehicle
            gearboxVehicle
            chassisBus: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            busType
            responsibilityCode
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        CPAUnits: ["MZ","MZK", "MA", "MS1", "MS2"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            engineVehicle
            batteryVehicle
            axle
            gearboxVehicle
            cab
            painted
            electricPropVehicle
            chassisTruck: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        CPAUnits: ["BRCH"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            engineVehicle
            batteryVehicle
            gosParts
            axle
            gearboxVehicle
            cab
            painted
            chassisTruck: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        CPAUnits: ["IM", "BRIM"],
        Fields: `
            serialNumber
            productIndividualNumber
            countryCode
            engineIM: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        CPAUnits: ["DGE"],
        Fields: `
            serialNumber
            productIndividualNumber
            countryCode
            gearboxExternal: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        CPAUnits: ["MS"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            engineVehicle
            batteryVehicle
            axle
            gearboxVehicle
            cab
            painted
            electricPropVehicle
            chassisTruck: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
            produced
        `
    },
    {
        CPAUnits: null,
        Fields: `
            chassisNumber
            serialNumber
            productIndividualNumber
            countryCode
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    }
];