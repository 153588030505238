import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NotificationsService } from 'src/state';
import { SystemNotification } from 'src/state/notifications/state-models/system-notifications';
import { AuthService } from 'src/app/authentication';

@Component({
    selector: 'cal-system-notifications',
    templateUrl: './system-notifications.component.html',
    styleUrls: ['./system-notifications.component.css']
})
export class SystemNotificationsComponent implements OnInit, OnDestroy {
    systemNotificationsList: Observable<SystemNotification[]>;
    subscriptions = new Subscription();
    constructor(
        private authService: AuthService,
        private notificationsService: NotificationsService
    ) { }

    ngOnInit() {
        this.subscriptions.add(
            this.authService.userInfo.subscribe((user) => {
                if (!!user && !user.expired) {
                    this.notificationsService.loadSystemNotifications();
                }
            })
        );
        this.systemNotificationsList = this.notificationsService.getSystemNotifications();
    }
    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    getNotificationClass(notificationType: number) {

        let className = '';
        switch (notificationType) {
            case 1:
                className = 'info';
                break;
            case 2:
                className = 'danger';
                break;
            default:
                className = 'info';
        }
        return className;
    }
}
