import { MaterialState } from './state';
import { MaterialAction, MaterialActions } from './actions';

const initialState = require('./initial-data.json') as MaterialState;
export function materialReducer(state: MaterialState = initialState, action: MaterialAction): MaterialState {
    switch (action.type) {
        case MaterialActions.loadMaterials:
            return {
                ...state,
                Materials: action.payload,
                MaterialPricesFilterExpression:null,
                MaterialPricesSortExpression: null
            };
        case MaterialActions.loadMaterial:
            return {
                ...state,
                SelectedMaterial: action.payload
            };
        case MaterialActions.loadMaterialPriceType:
            return {
                ...state,
                MaterialPriceTypes: action.payload
            };
        case MaterialActions.changeResultsTotalCountAction:
            return {
                ...state,
                TotalCount: action.payload
            };
        case MaterialActions.updatPrice:
            const updateNdx = state.Materials.findIndex(x => x.id === action.payload.id);
            return {
                ...state,
                Materials: [...state.Materials.slice(0, updateNdx),
                    action.payload,
                    ...state.Materials.slice(updateNdx + 1)]
            };
        case MaterialActions.changePageAction:
            return {
                ...state,
                Paging: {
                    ...state.Paging,
                    page: action.payload
                }
            };
        case MaterialActions.changeSortExpressionAction:
            return {
                ...state,
                Paging: { ...state.Paging, page: 1 },
                SortExpression: action.payload
            };
        case MaterialActions.changeFilterExpressionAction:
            return {
                ...state,
                Paging: { ...state.Paging, page: 1 },
                FilterExpression: action.payload
            };
        case MaterialActions.addMaterialPriceAction:
            return {
                ...state,
                SelectedMaterial: {
                    ...state.SelectedMaterial,
                    materialPrices: action.payload
                }
            };
        case MaterialActions.changeMaterialPricesFilterExpressionAction:
            return {
                ...state,
                MaterialPricesFilterExpression: action.payload
            };
        case MaterialActions.changeMaterialPricesSortExpressionAction:
            return {
                ...state,
                MaterialPricesSortExpression: action.payload
            };
        default:
            return state;
    }
}
