import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, lastValueFrom } from 'rxjs';
import * as _ from 'lodash';
import { map, catchError } from 'rxjs/operators';

import { AppState } from '../app.state';
import {
    LoadStepFaultAction,
    ChangeStepFaultPageAction,
    ChangeStepFaultSortExpressionAction,
    ChangeStepFaultFilterExpressionAction,
    ChangeStepFaultSearchForm,
    ChangeStepFaultResultsTotalCountAction,
    LoadProductIndividualStepFault,
    ChangeProdIndStepFaultTotalCountAction
} from './actions';

import { CalsiumDateFormatterPipe } from '../../app/shared/calsium-date.pipe';
import { OrchestratorsApiService } from '../../api/services/orchestrator-api.service';
import { Paging, ComponentAllocationInput } from '..';
import { StepFaultSearchForm } from './state-models/step-fault-search-form';
import { Loadable } from '../common/loadable';
import { StepFaultView } from './state-models/step-fault-view';

@Injectable()
export class OrchestratorStateService {
    constructor(
        private appStore: Store<AppState>,
        private apiService: OrchestratorsApiService,
        private datePipe: CalsiumDateFormatterPipe
    ) {}

    getStepFault(): Observable<StepFaultView[]> {
        return this.appStore.select((state) => state.orchestrator.stepFault);
    }
    getProductIndividualStepFault(): Observable<StepFaultView[]> {
        return this.appStore.select(
            (state) => state.orchestrator.productIndividualStepFault
        );
    }
    // =============================================
    @Loadable()
    async loadStepFaultData(
        searchForm: StepFaultSearchForm,
        paging: Paging,
        sortExpression: string,
        filterExpression: string
    ): Promise<void> {
        const payload = {
            paging: {
                page: paging.page,
                offset: paging.offset,
            },
            order: sortExpression,
            filter: filterExpression,
        };
        if (searchForm != null && searchForm.fromDate != null) {
            payload["from"] = this.datePipe.transform(searchForm.fromDate);
        }
        if (searchForm != null && searchForm.toDate != null) {
            payload["to"] = this.datePipe.transform(searchForm.toDate);
        }
        if (searchForm != null && searchForm.selectedScaniaUnit != null) {
            payload["scaniaUnitId"] = searchForm.selectedScaniaUnit;
        }
        const response = await this.apiService.getStepFault(payload);
        const action = new LoadStepFaultAction(response.list);
        this.appStore.dispatch(action);

        const totalCountAction = new ChangeStepFaultResultsTotalCountAction(
            response.totalCount
        );
        this.appStore.dispatch(totalCountAction);
    }

    async loadProductIndividualStepFault(
        productIndividualId: number,
        allocationScaniaUnitId: number
    ): Promise<void> {
        this.appStore.dispatch(new LoadProductIndividualStepFault([]));
        this.appStore.dispatch(new ChangeProdIndStepFaultTotalCountAction(0));

        const payload = {
            productIndividualId,
            allocationScaniaUnitId,
        };

        const response = await this.apiService.getProductIndividualStepFault(
            payload
        );
        const action = new LoadProductIndividualStepFault(response);
        this.appStore.dispatch(action);
        this.appStore.dispatch(
            new ChangeProdIndStepFaultTotalCountAction(response.length)
        );
    }

    @Loadable()
    recalculateComponents(componentIdList: number[]): Promise<number> {
        const result$ = this.apiService
            .recalculateComponents(componentIdList)
            .pipe(
                catchError((error) => {
                    console.error("Unable to recalculate component", error);
                    return null;
                }),
                map((result: number) => {
                    return result;
                })
            )
            
          return lastValueFrom(result$);
    }

    @Loadable()
    recalculateProductsByComponentTypes(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: ComponentAllocationInput[],
        excludedItems: ComponentAllocationInput[]
    ): Promise<number> {
        const result$ = this.apiService
            .recalculateProductsByComponentTypes(
                allocationScaniaUnitId,
                allocationStatus,
                from,
                to,
                filterExpression,
                selectAll,
                includedItems,
                excludedItems
            )
            .pipe(
                catchError((error) => {
                    console.error("Unable to recalculate components", error);
                    return null;
                }),
                map((result: number) => {
                    return result;
                })
            )
            
            return lastValueFrom(result$);
    }

    @Loadable()
    recalculateProducts(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Promise<number> {
        const result$ =  this.apiService
            .recalculateProducts(
                allocationScaniaUnitId,
                allocationStatus,
                from,
                to,
                filterExpression,
                selectAll,
                includedItems,
                excludedItems,
                requestSource
            )
            .pipe(
                catchError((error) => {
                    console.error("Unable to recalculate products", error);
                    return null;
                }),
                map((result: number) => {
                    return result;
                })
            )
          return lastValueFrom(result$);
    }

    @Loadable()
    recalculateCurrencies(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Promise<number> {
        const result$ = this.apiService
            .recalculateCurrencies(
                allocationScaniaUnitId,
                allocationStatus,
                from,
                to,
                filterExpression,
                selectAll,
                includedItems,
                excludedItems,
                requestSource
            )
            .pipe(
                catchError((error) => {
                    console.error("Unable to recalculate products", error);
                    return null;
                }),
                map((result: number) => {
                    return result;
                })
            )
            
          return lastValueFrom(result$);
    }

    @Loadable()
    recalculateMarkups(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Promise<number> {
        const result$ = this.apiService
            .recalculateMarkups(
                allocationScaniaUnitId,
                allocationStatus,
                from,
                to,
                filterExpression,
                selectAll,
                includedItems,
                excludedItems,
                requestSource
            )
            .pipe(
                catchError((error) => {
                    console.error("Unable to recalculate products", error);
                    return null;
                }),
                map((result: number) => {
                    return result;
                })
            )
            
          return lastValueFrom(result$);
    }
    @Loadable()
    deallocateComponents(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: ComponentAllocationInput[],
        excludedItems: ComponentAllocationInput[]
      
    ): Promise<number> {
        const result$ =  this.apiService
            .deallocateByComponents(
                allocationScaniaUnitId,
                allocationStatus,
                from,
                to,
                filterExpression,
                selectAll,
                includedItems,
                excludedItems,
                )
            .pipe(
                catchError((error) => {
                    console.error("Unable to deallocate component", error);
                    return null;
                }),
                map((result: number) => {
                    return result;
                })
            )
            
          return lastValueFrom(result$);
    }

    @Loadable()
    deallocateProducts(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Promise<number> {
        const result$ = this.apiService
            .deallocateProducts(
                allocationScaniaUnitId,
                allocationStatus,
                from,
                to,
                filterExpression,
                selectAll,
                includedItems,
                excludedItems,
                requestSource
            )
            .pipe(
                catchError((error) => {
                    console.error("Unable to recalculate products", error);
                    return null;
                }),
                map((result: number) => {
                    return result;
                })
            )
            return lastValueFrom(result$);
    }

    getStepFaultPaging(): Observable<Paging> {
        return this.appStore.select(
            (state) => state.orchestrator.stepFaultPaging
        );
    }

    goToStepFaultPage(page: number) {
        this.appStore.dispatch(new ChangeStepFaultPageAction(page));
    }

    getStepFaultTotalCount(): Observable<number> {
        return this.appStore.select(
            (state) => state.orchestrator.stepFaultTotalCount
        );
    }

    getProductIndividualStepFaultTotalCount(): Observable<number> {
        return this.appStore.select(
            (state) => state.orchestrator.prodIndStepFaultTotalCount
        );
    }

    getStepFaultSortingExpression(): Observable<string> {
        return this.appStore.select(
            (state) => state.orchestrator.stepFaultSortExpression
        );
    }

    sortStepFault(sortExpression: string) {
        this.appStore.dispatch(
            new ChangeStepFaultSortExpressionAction(sortExpression)
        );
    }

    getStepFaultFilterExpression(): Observable<string> {
        return this.appStore.select(
            (state) => state.orchestrator.stepFaultFilterExpression
        );
    }

    filterStepFault(filterExpression: string) {
        this.appStore.dispatch(
            new ChangeStepFaultFilterExpressionAction(filterExpression)
        );
    }

    clearFilterAndSorting() {
        [
            new ChangeStepFaultFilterExpressionAction(null),
            new ChangeStepFaultSortExpressionAction(null),
        ].forEach((action) => this.appStore.dispatch(action));
    }

    getStepFaultSearchForm(): Observable<StepFaultSearchForm> {
        return this.appStore.select(
            (state) => state.orchestrator.stepFaultSearchForm
        );
    }

    changeStepFaultSearchForm(searchForm: StepFaultSearchForm) {
        this.appStore.dispatch(new ChangeStepFaultSearchForm(searchForm));
    }
}
