<div [ngClass]="{loading: isLoading | async}">
    <div class="row">
        <div class="col-sm-4">
            <mat-form-field class="form">
                <mat-label>{{ resources.ConsumingPlanningArea }}</mat-label>
                <mat-select (selectionChange)="onChange($event)">
                    <mat-option *ngFor="let cpa of cpaList | async" [value]="cpa.to">
                        {{cpa.to}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-4">
            <button class="btn btn-primary" (click)="onExport()">
                {{ resources.ExportToExcel }}
            </button>
        </div>
    </div>
</div>
