import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';
import { ProductionPriceList } from '../../state';
import { CommonApiService } from '../common.service';

@Injectable({
    providedIn: 'root'
})
export class ProductionPriceListApiService {
    constructor(private apollo: Apollo,
        private commonService: CommonApiService) { }

    private getProductionPriceListQuery(productionPriceListId?: number, includeDetails: boolean = false): any {
        const priceListDetails = `
            productionPriceCondition {
                description
                productionPriceConditionVariantOption {
                    variantOption {
                        value
                        variantFamily {
                            value
                            variantClass {
                                value
                            }
                        }
                    }
                }
                productionPriceConditionVariantFamily {
                    variantFamily {
                        value
                        variantClass {
                            value
                        }
                    }
                }
                productionPriceConditionPrice {
                    price
                    productionPriceType {
                        name
                        description
                    }
                }
            }`;

        return gql`
        query priceListQuery${!!productionPriceListId ? '($id: Int)' : ''} {
          productionPriceLists {
            priceList${!!productionPriceListId ? '(id: $id)' : ''} {
              list {
                id
                description
                validFrom
                validTo
                componentType {
                    name
                }
                market {
                    name
                }
                ${includeDetails ? priceListDetails : ''}
              }
            }
          }
        }`;
    }

    exportSelectedProductionPriceList(productionPriceListId?: number,fileName?:string)
    {
        const query = gql`
        query priceListQuery${!!productionPriceListId ? '($id: Int)' : ''} 
	    {
            productionPriceLists 
            {
                search${!!productionPriceListId ? '(id: $id)' : ''} 
                {                                                            
                    priceListType
                    componentType
                    condition
                    conditionDescription
                    variantClass                    
                    variantFamily
                    variantOption
                    familyDescription
                    optionDescription
                    cost
                    priceType
                    validFrom
                    validTo
                    market                                   
                }
            }
        }`;

        return this.commonService.exportQuery(query,{id:productionPriceListId},fileName,true,"ProdcutionPriceList");
    }

    getAllPriceLists(variables?): Promise<ProductionPriceList[]> {
        const result$ = this.apollo
          .query<ProductionPriceList[]>({
              variables,
              query: this.getProductionPriceListQuery(null, false)
          })
          .pipe<ProductionPriceList[]>(
                       map((result: any) => {
                           return result?.data?.productionPriceLists?.priceList?.list;
                })
          )
return lastValueFrom(result$);

    }

    getPriceList(id: number, variables?): Promise<ProductionPriceList> {
        const result$ = this.apollo
          .query<ProductionPriceList>({
              variables: { ...variables, id },
              query: this.getProductionPriceListQuery(id, true)
          })
          .pipe<ProductionPriceList>(
           
            map((result: any) => {
                return result?.data?.productionPriceLists?.priceList?.list;
                })
          )

          return lastValueFrom(result$);
    }
}
