import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'src/state/app.state';
import { Loadable } from 'src/state/common/loadable';
import { DeliveryRouteAllocationUnitApiService } from 'src/api/services/delivery-route-allocation-unit-api.service';
import {LoadListAction, ChangeFilterExpressionAction, ChangePageAction, ChangeResultsTotalCountAction, ChangeSortExpressionAction, LoadDeliveryRouteListAction , LoadScaniaUnitAllocationListAction , LoadScaniaUnitConsolidationListAction } from './actions';
import { Paging } from 'src/state';
import { DeliveryRouteAllocationUnit } from './state-models/delivery-route-allocation-unit';
import { DeliveryRouteAllocationUnitState } from './state';
import { DeliveryRouteApiService } from 'src/api/services/delivery-route-api.service';
import { DeliveryRoute } from '../delivery-route/state-models/delivery-route';
import { ScaniaUnitAllocation } from './state-models/scania-unit-allocation';
import { ScaniaUnitConsolidation } from './state-models/scania-unit-consolidation';

@Injectable()
export class DeliveryRouteAllocationUnitStateService {
    constructor(
        private apiService: DeliveryRouteAllocationUnitApiService,
        private deliveryRouteService: DeliveryRouteApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>
    ) { }

    @Loadable()
    async loadList(sortExpression: string, filterExpression: string, paging: Paging): Promise<void> {
        const payload = {
            order: sortExpression,
            filter: filterExpression,
            paging: {
                page: paging.page,
                offset: paging.offset
            },
        };
        const deliveryRoutelist = await this.apiService.getDeliveryRouteAllocationUnitList(payload);
        this.appStore.dispatch(new LoadListAction(deliveryRoutelist.list));
        this.appStore.dispatch(new ChangeResultsTotalCountAction(deliveryRoutelist.totalCount));
    }

    @Loadable()
    async update(deliveryRoute: DeliveryRouteAllocationUnit): Promise<any> {
        const response = await this.apiService.updateDeliveryRouteAllocationUnit(deliveryRoute);
        if (response.errors) {
            const errors = response.errors.map((err: { message: any; }) => err.message);
            return errors;
        }
        const state = <DeliveryRouteAllocationUnitState>this.appState.getValue().admin.deliveryRouteAllocationUnit;
        await this.loadList(state.sortExpression, state.filterExpression, state.paging);
    }

    @Loadable()
    async loadDeliveryRouteList(): Promise<void> {
        const payload = {
            order: null,
            filter: null
        };
        const list = await this.deliveryRouteService.getDeliveryRouteList(payload);
        this.appStore.dispatch(new LoadDeliveryRouteListAction(list));
    }

    @Loadable()
    async loadScaniaUnitAllocationList(): Promise<void> {
        const payload = {
            order: null,
            filter: null
        };
        const list = await this.apiService.getScaniaUnitAllocation(payload);
        this.appStore.dispatch(new LoadScaniaUnitAllocationListAction(list));
    }

    @Loadable()
    async loadScaniaUnitConsolidationList(): Promise<void> {
        const payload = {
            order: null,
            filter: null
        };
        const list = await this.apiService.getScaniaUnitConsolidation(payload);
        this.appStore.dispatch(new LoadScaniaUnitConsolidationListAction(list));
    }

    getDeliveryRouteList(): Observable<DeliveryRoute[]> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.deliveryRouteList);
    }

    
    getScaniaUnitAllocationList(): Observable<ScaniaUnitAllocation[]> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.scaniaUnitAllocationList);
    }

    getScaniaUnitConsolidationList(): Observable<ScaniaUnitConsolidation[]> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.scaniaUnitConsolidationList);
    }

    getList(): Observable<DeliveryRouteAllocationUnit[]> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.list);
    }

    getSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.sortExpression);
    }

    getFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.filterExpression);
    }


    sort(sortExpression: string) {
        this.appStore.dispatch(new ChangeSortExpressionAction(sortExpression));
    }

    filter(filterExpression: string) {
        this.appStore.dispatch(new ChangeFilterExpressionAction(filterExpression));
    }

    clearFilterAndSorting() {
        this.sort(null);
        this.filter(null);
    }

    getPaging(): Observable<Paging> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.paging);
    }

    goToPage(page: number) {
        this.appStore.dispatch(new ChangePageAction(page));
    }

    getTotalCount(): Observable<number> {
        return this.appStore.select(state => state.admin.deliveryRouteAllocationUnit.totalCount);
    }

}