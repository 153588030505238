import { ComponentTypeProductionValue } from './state-models/component-type-production-value';
import { ProductIndividual } from './state-models/product-individual';
import { ComponentTypeManualProductionValue } from './state-models/component-type-manual-production-value';

export interface ProductIndividualState {
    productIndividual: ProductIndividual;
    componentTypeProductionValues: ComponentTypeProductionValue[];
    componentTypeManualProductionValues: ComponentTypeManualProductionValue[];

}
