import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { take, map } from 'rxjs/operators';

@Injectable()
export class AuthGuardService  {

    constructor(private authService: AuthService,
        private router: Router) { }

    canActivate(): Observable<boolean> {
        return this.authService.isUserLoggedIn.pipe(
            take(1),
            map((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    window.localStorage.setItem('last-url', window.location.pathname);
                    this.authService.startAuthentication();
                } else if (!this.authService.isUserAuthorized()) {
                    this.router.navigate(['/unauthorized']);
                    return false;
                }
                return isLoggedIn;
            })
        );
    }
}
