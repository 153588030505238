import { Action } from '@ngrx/store';
import { CurrencyAdjustmentCode, CurrencyAdjustmentCodeAdd } from './state-models/currency-adjustment-code';
import { CurrencyAdjustmentPercentage } from './state-models/currency-adjustment-percentage';
import { CurrencyAdjustmentCondition, CurrencyAdjustmentConditionUpdate } from './state-models/currency-adjustment-condition';
import { CurrencyAdjustmentConditionVariantOption } from './state-models/currency-adjustment-condition-variant-option';
import { VariantFamily } from 'src/state/';
import { VariantOption } from 'src/state';
import { ProductClass } from 'src/state';
import { Market } from 'src/state';

export enum CurrencyAdjustmentActions {
    LoadCodeListAction = "[ Admin ] load adjustment code list",
    //LoadValidCodeListAction = "[ Admin ] load valid adjustment code list",
    AddCodeAction = "[ Admin ] Add adjustment code",
    ChangeCodeListSortExpressionAction = "[ Admin ] Change adjustment code Sort Expression",
    ChangeCodeListFilterExpressionAction = "[ Admin ] Change adjustment code Filter Expression",
    DeleteCodeAction = "[ Admin ] Delete code",
    UpdateCodeAction = "[ Admin ] Update adjustment code",
    UpdateConditionsAction ="[ Admin ] Update adjustment condition",

    LoadPercentagesAction = "[ Admin ] load adjustment percentage list",
    AddPercentageAction = "[ Admin ] Add adjustment percentage",
    ChangePercentagesSortExpressionAction = "[ Admin ] Change percentage Sort Expression",
    ChangePercentagesFilterExpressionAction = "[ Admin ] Change percentage Filter Expression",

    LoadConditionsAction = "[ Admin ] load adjustment condition list",
    ChangeConditionsSortExpressionAction = "[ Admin ] Change condition Sort Expression",
    ChangeConditionsFilterExpressionAction = "[ Admin ] Change condition Filter Expression",
    AddConditionAction = "[ Admin ] Add condition",
    AddConditionVariantAction = "[ Admin ] Add condition variant",
    RemoveConditionVariantAction = "[ Admin ] Remove Condition Variant",
    SaveConditionAction = "[ Admin ] Save condition",

    LoadVariantFamilyAction = "[ Admin ] load adjustment condition list variant families",
    LoadVariantOptionsAction = "[ Admin ] load adjustment condition list variant options",
    LoadProductClassAction = "[ Admin ] load adjustment condition Product class list",
    LoadMarketAction = "[ Admin ] load adjustment condition market list",

    ChangeCodeShowOnlyValidCodesAction = "[ Admin ] change show valid codes only",
    ChangeConditionsShowOnlyValidConditionsAction = "[ Admin ] change show valid Conditions only"

}
export class LoadCodeListAction implements Action {
  readonly type = CurrencyAdjustmentActions.LoadCodeListAction;
  constructor(public payload: CurrencyAdjustmentCode[]) { }
}

export class AddCodeAction implements Action {
  readonly type = CurrencyAdjustmentActions.AddCodeAction;
  constructor(public payload: CurrencyAdjustmentCode) { }
}

export class ChangeCodeListSortExpressionAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangeCodeListSortExpressionAction;
  constructor(public payload: string) { }
}

export class ChangeCodeListFilterExpressionAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangeCodeListFilterExpressionAction;
  constructor(public payload: string) { }
}

export class UpdateCodeAction implements Action {
    readonly type = CurrencyAdjustmentActions.UpdateCodeAction;
    constructor(public payload: CurrencyAdjustmentCode) {}
}

export class UpdateConditionsAction implements Action {
  readonly type = CurrencyAdjustmentActions.UpdateConditionsAction;
  constructor(public payload: CurrencyAdjustmentCondition) {}
}



export class DeleteCodeAction implements Action {
  readonly type = CurrencyAdjustmentActions.DeleteCodeAction;
  constructor(public payload: number) { }
}

export class LoadPercentagesAction implements Action {
  readonly type = CurrencyAdjustmentActions.LoadPercentagesAction;
  constructor(public payload: CurrencyAdjustmentPercentage[]) { }
}

export class AddPercentageAction implements Action {
  readonly type = CurrencyAdjustmentActions.AddPercentageAction;
  constructor(public payload: CurrencyAdjustmentPercentage) { }
}

export class ChangePercentagesSortExpressionAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangePercentagesSortExpressionAction;
  constructor(public payload: string) { }
}

export class ChangePercentagesFilterExpressionAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangePercentagesFilterExpressionAction;
  constructor(public payload: string) { }
}

export class LoadConditionsAction implements Action {
  readonly type = CurrencyAdjustmentActions.LoadConditionsAction;
  constructor(public payload: CurrencyAdjustmentCondition[]) { }
}

export class ChangeConditionsSortExpressionAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangeConditionsSortExpressionAction;
  constructor(public payload: string) { }
}

export class ChangeConditionsFilterExpressionAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangeConditionsFilterExpressionAction;
  constructor(public payload: string) { }
}

export class LoadVariantFamilyAction implements Action {
  readonly type = CurrencyAdjustmentActions.LoadVariantFamilyAction;
  constructor(public payload: VariantFamily[]) { }
}

export class LoadVariantOptionAction implements Action {
  readonly type = CurrencyAdjustmentActions.LoadVariantOptionsAction;
  constructor(public payload: VariantOption[]) { }
}

export class AddConditionVariantAction implements Action {
  readonly type = CurrencyAdjustmentActions.AddConditionVariantAction;
  constructor(public payload: CurrencyAdjustmentConditionVariantOption) { }
}

export class AddConditionAction implements Action {
  readonly type = CurrencyAdjustmentActions.AddConditionAction;
  constructor(public payload: CurrencyAdjustmentCondition) { }
}

export class RemoveConditionVariantAction implements Action {
  readonly type = CurrencyAdjustmentActions.RemoveConditionVariantAction;
  payload: { conditionId: number, conditionVariantId: number };
  constructor(conditionId: number, conditionVariantId: number) {
    this.payload = { conditionId, conditionVariantId };
  }
}

export class LoadProductClassAction implements Action {
  readonly type = CurrencyAdjustmentActions.LoadProductClassAction;
  constructor(public payload: ProductClass[]) { }
}

export class LoadMarketAction implements Action {
  readonly type = CurrencyAdjustmentActions.LoadMarketAction;
  constructor(public payload: Market[]) { }
}

export class SaveConditionAction implements Action {
  readonly type = CurrencyAdjustmentActions.SaveConditionAction;
  constructor(public payload: CurrencyAdjustmentCondition[]) { }
}

export class ChangeCodeShowOnlyValidCodesAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangeCodeShowOnlyValidCodesAction;
  constructor(public payload: boolean) { }
}

export class ChangeConditionsShowOnlyValidConditionsAction implements Action {
  readonly type = CurrencyAdjustmentActions.ChangeConditionsShowOnlyValidConditionsAction;
  constructor(public payload: boolean) { }
}

export type CurrencyAdjustmentAction =
  | LoadCodeListAction

  | ChangeCodeListSortExpressionAction
  | ChangeCodeListFilterExpressionAction
  | AddCodeAction
  | DeleteCodeAction
  | UpdateCodeAction
  | UpdateConditionsAction
  | AddPercentageAction
  | LoadPercentagesAction
  | ChangePercentagesSortExpressionAction
  | ChangePercentagesFilterExpressionAction

  | LoadConditionsAction
  | ChangeConditionsSortExpressionAction
  | ChangeConditionsFilterExpressionAction
  | LoadVariantFamilyAction
  | LoadVariantOptionAction
  | AddConditionVariantAction
  | AddConditionAction
  | RemoveConditionVariantAction
  | SaveConditionAction

  | ChangeCodeShowOnlyValidCodesAction
  | ChangeConditionsShowOnlyValidConditionsAction

  | LoadProductClassAction
  | LoadMarketAction
  ;
