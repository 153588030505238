import { Action } from '@ngrx/store';
import * as _ from 'lodash';

import { ComponentTypeProductionValue } from './state-models/component-type-production-value';
import { ProductIndividual } from '..';
import { ComponentTypeManualProductionValue } from './state-models/component-type-manual-production-value';

export enum ProductIndividualActions {
    loadProductIndividual = '[ Product Individual ] Load production individual',
    loadProductProductionValue = '[ Product Individual ] Load production values',
    loadProductManualProductionValue = '[ Product Individual ] Load manual production values'
}

export class LoadProductIndividual implements Action {
    readonly type = ProductIndividualActions.loadProductIndividual;
    constructor(public payload: ProductIndividual) { }
}

export class LoadProductProductionValueAction implements Action {
    readonly type = ProductIndividualActions.loadProductProductionValue;
    constructor(public payload: ComponentTypeProductionValue[]) { }
}

export class LoadProductManualProductionValueAction implements Action {
    readonly type = ProductIndividualActions.loadProductManualProductionValue;
    constructor(public payload: ComponentTypeManualProductionValue[]) { }
}

export type ProductIndividualAction =
    | LoadProductIndividual
    | LoadProductProductionValueAction
    | LoadProductManualProductionValueAction;
