<div class="card">
  <div class="card-body">
    <form [formGroup]="searchComponentsForm" (ngSubmit)="onSearch()" class="row">
      <div class="form-group col-lg-3 col-md-4 col-xs-12">
          <label>{{resources.AllocationScaniaUnit}}:</label>            
          <div class="input-group">
              <select formControlName="selectedScaniaUnit" class="form-control">
              <option value="" selected disabled="disabled"></option>
              <option *ngFor="let scaniaUnit of scaniaUnitList | async" value={{scaniaUnit.id}}>
                  {{scaniaUnit.name}} - {{scaniaUnit.description}}
              </option>
              </select>
          </div>
      </div>
      <div class="form-group col-lg-2 col-md-3 col-xs-12">
        <label>{{resources.From}}:</label>
        <div class="input-group">
          <input formControlName="startDate" type="date" placeholder="yyyy-mm-dd" class="form-control" />
        </div>
      </div>
      <div class="form-group col-lg-2 col-md-3 col-xs-12">
        <label>{{resources.To}}:</label>
        <div class="input-group">
          <input formControlName="endDate" type="date" class="form-control" placeholder="yyyy-mm-dd" />
        </div>
      </div>
      <div class="form-group col-md-2 col-xs-12">
          <label>&nbsp;</label>          
          <button class="btn btn-primary form-control" type="submit">
              {{resources.Search}}
          </button>
      </div>
    </form>
  </div>
</div>

<div class="panel" style="margin-top: 5px;">
  <div class="panel-body">
      <cal-calsiumlist-listing [config]="listConfig"
          [ListBodyData]="dataList | async" (sortTriggered)="onSort($event)" (filterTriggered)="onFilter($event)"
          (clearFilterAndSortingTriggered)="onClearFilterAndSorting()" [isLoading]="isLoading | async"
          [dataTotalCount]="totalCount | async" [filterExpression]="filterExpression | async"          
          (exportToExcelTriggered)="onExportToExcel()">
      </cal-calsiumlist-listing>
      <cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async">
      </cal-pagination>
  </div>
</div>
