import { Action } from "@ngrx/store";
import { MarkupCondition } from "./state-models/markup-condition";
import { MarkupConditionMaterial } from "./state-models/markup-condition-material";

export enum MarkupConditionMaterialActionType {
    AddMarkupConditionMaterialAction = "[ Admin ] Add markup condition material ",

    LoadMarkupConditionListAction = "[ Admin ] load markup condition code list",
    LoadMarkupConditionMaterialListAction = "[ Admin ] load markup condition material list",
    ChangeMarkupConditionMaterialSortExpressionAction = "[ Admin ] Change markup condition material Sort Expression",
    ChangeMarkupConditionMaterialFilterExpressionAction = "[ Admin ] Change markup condition material Filter Expression",
    UpdateMarkupConditionMaterialAction = "[ Admin ] Update markup condition material",

    ChangeMarkupConditionMaterialShowOnlyValidMarkupConditionMaterialsAction = "[ Admin ] change show valid makrup condition materials only",
}

export class AddMarkupConditionMaterialAction implements Action {
    readonly type =
        MarkupConditionMaterialActionType.AddMarkupConditionMaterialAction;
    constructor(public payload: MarkupConditionMaterial) {}
}

export class LoadMarkupConditionMaterialAction implements Action {
    readonly type =
        MarkupConditionMaterialActionType.LoadMarkupConditionMaterialListAction;
    constructor(public payload: MarkupConditionMaterial[]) {}
}

export class LoadMarkupConditionListAction implements Action {
    readonly type =
        MarkupConditionMaterialActionType.LoadMarkupConditionListAction;
    constructor(public payload: MarkupCondition[]) {}
}

export class LoadMarkupConditionAction implements Action {
    readonly type =
        MarkupConditionMaterialActionType.LoadMarkupConditionListAction;
    constructor(public payload: MarkupCondition[]) {}
}

export class ChangeMarkupConditionMaterialSortExpressionAction
    implements Action
{
    readonly type =
        MarkupConditionMaterialActionType.ChangeMarkupConditionMaterialSortExpressionAction;
    constructor(public payload: string) {}
}

export class ChangeMarkupConditionMaterialFilterExpressionAction
    implements Action
{
    readonly type =
        MarkupConditionMaterialActionType.ChangeMarkupConditionMaterialFilterExpressionAction;
    constructor(public payload: string) {}
}

export class ChangeMarkupConditionMaterialsAction implements Action {
    readonly type =
        MarkupConditionMaterialActionType.ChangeMarkupConditionMaterialShowOnlyValidMarkupConditionMaterialsAction;
    constructor(public payload: boolean) {}
}

export class UpdateMarkupConditionMaterialAction implements Action {
    readonly type =
        MarkupConditionMaterialActionType.UpdateMarkupConditionMaterialAction;
    constructor(public payload: MarkupConditionMaterial) {}
}

export type MarkupConditionMaterialAction =
    | AddMarkupConditionMaterialAction
    | UpdateMarkupConditionMaterialAction
    | LoadMarkupConditionAction
    | LoadMarkupConditionMaterialAction
    | ChangeMarkupConditionMaterialSortExpressionAction
    | ChangeMarkupConditionMaterialFilterExpressionAction
    | ChangeMarkupConditionMaterialsAction
;
