import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { AllocationSearchForm } from './state-models/allocation-search-form';
import { ConsolidationUnitAllocation } from './state-models/consolidation-unit-allocation';
import { AllocationSummary } from './state-models/allocation-summary';

export enum ConsolidationUnitAllocationActions {
    ChangeComponentSearchFormAction = '[ Consolidation Unit Allocation ] Change Search Form',
    ChangeProducedComponentListSortExpressionAction = '[ Consolidation Unit Allocation ] Change Produced Sort Expression',
    ChangeProducedComponentListFilterExpressionAction = '[ Consolidation Unit Allocation ] Change Produced Filter Expression',
    ChangeProducedComponentListPageAction = '[ Consolidation Unit Allocation ] Change Produced Page',
    ChangeProducedComponentListTotalCountAction = '[ Consolidation Unit Allocation ] Change Produced Total Count',
    LoadProducedComponentListAction = '[ Consolidation Unit Allocation ] Load produced components',
    ChangeAllocatedComponentListSortExpressionAction = '[ Consolidation Unit Allocation ] Change Allocated Sort Expression',
    ChangeAllocatedComponentListFilterExpressionAction = '[ Consolidation Unit Allocation ] Change Allocated Filter Expression',
    ChangeAllocatedComponentListPageAction = '[ Consolidation Unit Allocation ] Change Allocated Page',
    ChangeAllocatedComponentListTotalCountAction = '[ Consolidation Unit Allocation ] Change allocated Total Count',
    LoadAllocatedComponentListAction = '[ Consolidation Unit Allocation ] Load allocated components',
    LoadInStockComponentListAction = '[ Consolidation Unit Allocation ] Load stock components',
    LoadProducedWidgetAction = '[ Consolidation Unit Allocation ] load produced widget',
    LoadAllocatedWidgetAction = '[ Consolidation Unit Allocation ] load allocated widget',
    LoadInStockWidgetAction = '[ Consolidation Unit Allocation ] load stock widget',
    AllocateComponents = '[ Consolidation Unit Allocation ] allocate components',
    ChangeCurrentUnitColumns = '[ Consolidation Unit Allocation ] Change Current Allocation Scania Unit Columns',
    ChangeInStockComponentListSortExpressionAction = '[ Consolidation Unit Allocation ] Change Stock Sort Expression',
    ChangeInStockComponentListFilterExpressionAction = '[ Consolidation Unit Allocation ] Change Stock Filter Expression',
    ChangeInStockComponentListPageAction = '[ Consolidation Unit Allocation ] Change Stock Page',
    ChangeInStockComponentListTotalCountAction = '[ Consolidation Unit Allocation ] Change Stock Total Count',
}

export class ChangeComponentSearchFormAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeComponentSearchFormAction;
    constructor(public payload: AllocationSearchForm) { }
}

export class ChangeProducedComponentListSortExpressionAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeProducedComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeProducedComponentListFilterExpressionAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeProducedComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeProducedComponentListTotalCountAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeProducedComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class ChangeProducedComponentListPageAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeProducedComponentListPageAction;
    constructor(public payload: number) { }
}

export class LoadProducedComponentListAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.LoadProducedComponentListAction;
    constructor(public payload: ConsolidationUnitAllocation[]) { }
}

export class ChangeAllocatedComponentListTotalCountAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeAllocatedComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}
export class ChangeInStockComponentListTotalCountAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeInStockComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class LoadAllocatedComponentListAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.LoadAllocatedComponentListAction;
    constructor(public payload: ConsolidationUnitAllocation[]) { }
}

export class LoadProducedWidgetAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.LoadProducedWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class LoadAllocatedWidgetAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.LoadAllocatedWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class LoadInStockWidgetAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.LoadInStockWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class LoadInStockComponentListAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.LoadInStockComponentListAction;
    constructor(public payload: ConsolidationUnitAllocation[]) { }
}

export class ChangeAllocatedComponentListSortExpressionAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeAllocatedComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeAllocatedComponentListFilterExpressionAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeAllocatedComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeAllocatedComponentListPageAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeAllocatedComponentListPageAction;
    constructor(public payload: number) { }
}


export class ChangeInStockComponentListSortExpressionAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeInStockComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeInStockComponentListFilterExpressionAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeInStockComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeInStockComponentListPageAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeInStockComponentListPageAction;
    constructor(public payload: number) { }
}


export class AllocateComponentsAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.AllocateComponents;
    constructor(public payload: ConsolidationUnitAllocation[]) { }
}

export class ChangeCurrentUnitColumnsAction implements Action {
    readonly type = ConsolidationUnitAllocationActions.ChangeCurrentUnitColumns;
    constructor(public payload: string[]) { }
}
export type ConsolidationUnitAllocationAction =
    | LoadProducedComponentListAction
    | ChangeComponentSearchFormAction
    | ChangeProducedComponentListSortExpressionAction
    | ChangeProducedComponentListFilterExpressionAction
    | ChangeProducedComponentListTotalCountAction
    | ChangeProducedComponentListPageAction

    | ChangeAllocatedComponentListTotalCountAction
    | LoadAllocatedComponentListAction
    | ChangeAllocatedComponentListSortExpressionAction
    | ChangeAllocatedComponentListFilterExpressionAction
    | ChangeAllocatedComponentListPageAction

    | LoadProducedWidgetAction
    | LoadAllocatedWidgetAction
    | AllocateComponentsAction
    | ChangeCurrentUnitColumnsAction
    | LoadInStockWidgetAction

    | ChangeInStockComponentListTotalCountAction
    | LoadInStockComponentListAction
    | ChangeInStockComponentListSortExpressionAction
    | ChangeInStockComponentListFilterExpressionAction
    | ChangeInStockComponentListPageAction
    ;
