import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';

import { MaterialAddPrice } from '../../../../../state/material/state-models/material-add-price';
import { MaterialPriceType, Material, MaterialStateService, MaterialPrice } from '../../../../../state';
import { environment } from '../../../../../environments/environment';
import { ErrorHandlerService } from '../../../../errorhandler/error-handler.service';
import { ErrorTypeEnum } from '../../../../shared/enums.model';
import { materialReducer } from '../../../../../state/material/reducer';

@Component({
    selector: 'cal-material-add-price',
    templateUrl: './material-add-price.component.html',
    styleUrls: ['./material-add-price.component.css']
})

export class MaterialAddPriceComponent implements OnInit, OnDestroy {
    @Input() resources: any;
    @Input() materialPriceTypes: Observable<MaterialPriceType[]>;
    @Input() material$: Observable<Material>;
    material: Material;
    materialPriceTypeList: MaterialPriceType[];
    subscription = new Subscription();
    addPriceForm: UntypedFormGroup;
    isManualSelected = false;

    constructor(private materialService: MaterialStateService,
        private errHandler: ErrorHandlerService,
        private formBuilder: UntypedFormBuilder
    ) { }

    toggleValidTo(event: number) {
        if (event === environment.manualPriceId) {
            this.addPriceForm.controls['validTo'].disable();
        } else {
            this.addPriceForm.controls['validTo'].enable();
        }
    }

    onSave() {
        if (this.isValidForm()) {
            const payload: MaterialAddPrice = {
                id: 0,
                materialId: this.material.id,
                materialPriceTypeId: this.addPriceForm.value.materialPriceTypeId,
                validFrom: this.addPriceForm.value.validFrom || new Date(new Date().getFullYear() - 1, 0, 1),
                validTo: this.addPriceForm.value.validTo,
                modifierComments: this.addPriceForm.value.comments,
                price: this.addPriceForm.value.price
            };
            this.materialService.addMaterialPrice(payload).then((errors) => {
                if (errors && errors.length) {
                    let errorMessage = '';
                    for (const error of errors) {
                        const errorType = error.split('\n')[0].replace('GraphQL.ExecutionError:', '').trim();
                        switch (errorType) {
                            case 'VALIDITY_OVERLAPS':
                                errorMessage += this.resources.OverlappingDates;
                                break;
                            case 'NO_PAST_MANUAL_PRICES':
                                errorMessage += this.resources.ChangePriceOrDate;
                                break;
                            case 'NEGATIVE_PRICES':
                                errorMessage += this.resources.NegativePrice;
                                break;
                        }
                    }
                    this.errHandler.emitError(this.resources.OverlappingDates, this.resources.Error, ErrorTypeEnum.Error);
                } else {
                    this.errHandler.emitError(this.resources.PriceAddedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
                    this.addPriceForm.reset();
                }
            });
        }
    }

    ngOnInit() {
        this.buildForm();

        this.subscription.add(
            this.materialPriceTypes
                .pipe(combineLatest(this.material$))
                .subscribe(([materialPrices, material]) => {
                    this.material = material;
                    if (this.canAddManual(material)) {
                        this.materialPriceTypeList = materialPrices.filter(price => price.id !== environment.standardPriceId);
                    } else {
                        this.materialPriceTypeList = materialPrices.filter(price => price.id !== environment.standardPriceId && price.id !== environment.manualPriceId);
                    }
                    this.isManualSelected = this.materialPriceTypeList.filter((type) => type.id === 3).length > 0;
                    this.addPriceForm.controls["materialPriceTypeId"].setValue(this.isManualSelected ? "3" : "2");
                })
        );
    }

    buildForm() {
        this.addPriceForm = this.formBuilder.group({
            materialPriceTypeId: ['', <any>Validators.required],
            price: ['', [<any>Validators.required, Validators.max(999999999)]],
            validFrom: [<Date>null, <any>Validators.required],
            validTo: [<Date>null, []],
            comments: ['', <any>Validators.required],
        });
    }

    getStartDateIfNoPreviousPrices(event) {
        if (this.material.materialPrices.length === 0) {
            const today = new Date();
            const startOfYear = new Date(today.getFullYear() - 1, 0, 2).toISOString();
            this.addPriceForm.controls['validFrom'].setValue(startOfYear.substring(0, startOfYear.indexOf('T')));
            this.addPriceForm.controls['validFrom'].disable();
        } else {
            this.addPriceForm.controls['validFrom'].enable();
        }
    }

    canAddManual(material: Material) {
        const validPrices = material.materialPrices.filter(price =>
            ((price.materialPriceType.id === (environment.standardPriceId || environment.strategicPriceId)) && price.validTo == null));
        return validPrices.length === 0;
    }

    isValidForm(): boolean {
        let valid = true;
        if (!this.addPriceForm.valid) {
            if (this.addPriceForm.get('materialPriceTypeId').errors != null) {
                if (this.addPriceForm.get('materialPriceTypeId').errors.required) {
                    this.errHandler.emitError(this.resources.SelectPriceType, this.resources.InvalidPriceType, ErrorTypeEnum.Error);
                    valid = false;
                }
            }
            if (this.addPriceForm.get('price').errors != null) {
                if (this.addPriceForm.get('price').errors.required) {
                    this.errHandler.emitError(this.resources.SetPrice, this.resources.InvalidPrice, ErrorTypeEnum.Error);
                    valid = false;
                }
                if (this.addPriceForm.get('price').errors.max) {
                    this.errHandler.emitError(this.resources.OutOfRangePrice, this.resources.InvalidPrice, ErrorTypeEnum.Error);
                    valid = false;
                }
            }
            if (this.addPriceForm.get('validFrom').errors != null) {
                if (this.addPriceForm.get('validFrom').errors.required) {
                    this.errHandler.emitError(this.resources.SetFromDate, this.resources.InvalidDates, ErrorTypeEnum.Error);
                    valid = false;
                }
            }
            if (this.addPriceForm.get('comments').errors != null) {
                if (this.addPriceForm.get('comments').errors.required) {
                    this.errHandler.emitError(this.resources.SetComment, this.resources.InvalidComment, ErrorTypeEnum.Error);
                    valid = false;
                }
            }
        }
        if (parseInt(this.addPriceForm.value.materialPriceTypeId, 10) === environment.manualPriceId && this.addPriceForm.value.validFrom <= new Date().getDate()
            && this.material.materialPrices != null) {
            this.errHandler.emitError(this.resources.ChangePriceOrDate, this.resources.NoPastManualPrice, ErrorTypeEnum.Error);
            valid = false;
        }
        if (this.addPriceForm.value.validTo < this.addPriceForm.value.validFrom) {
            this.errHandler.emitError(this.resources.ToDateGreaterThanFromDate, this.resources.InvalidDates, ErrorTypeEnum.Error);
            valid = false;
        }

        if (this.addPriceForm.value.price < 0) {
            this.errHandler.emitError(this.resources.NegativePrice, this.resources.InvalidPrice, ErrorTypeEnum.Error);
            valid = false;
        }

        return valid;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
