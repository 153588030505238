import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DeliveryRouteApiService } from 'src/api/services/delivery-route-api.service';
import { AppState } from 'src/state/app.state';
import { Loadable } from 'src/state/common/loadable';
import { LoadListAction, ChangeFilterExpressionAction , ChangeSortExpressionAction } from './actions';
import { DeliveryRoute } from './state-models/delivery-route';
import { DeliveryRouteState } from './state';

@Injectable()
export class DeliveryRouteStateService {
    constructor(
        private apiService: DeliveryRouteApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>
    ) { }

    @Loadable()
    async loadDeliveryRoute(sortExpression: string, filterExpression: string, ): Promise<void> {
        const payload = {
            order: sortExpression,
            filter: filterExpression,
        };
        const deliveryRoutelist = await this.apiService.getDeliveryRouteList(payload);
        this.appStore.dispatch(new LoadListAction(deliveryRoutelist));
    }

    @Loadable()
    async updateDeliveryRoute(deliveryRoute: DeliveryRoute): Promise<any> {
        const response = await this.apiService.updateDeliveryRoute(deliveryRoute);
        if (response.errors) {
            const errors = response.errors.map((err: { message: any; }) => err.message);
            return errors;
        }

        const state = <DeliveryRouteState>this.appState.getValue().admin.deliveryRoute;
        await this.loadDeliveryRoute(state.sortExpression, state.filterExpression);
    }

    getDeliveryRouteList(): Observable<DeliveryRoute[]> {
        return this.appStore.select(state => state.admin.deliveryRoute.list);
    }

    getSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.admin.deliveryRoute.sortExpression);
    }

    getFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.admin.deliveryRoute.filterExpression);
    }


    sort(sortExpression: string) {
        this.appStore.dispatch(new ChangeSortExpressionAction(sortExpression));
    }

    filter(filterExpression: string) {
        this.appStore.dispatch(new ChangeFilterExpressionAction(filterExpression));
    }

    clearFilterAndSorting() {
        this.sort(null);
        this.filter(null);
    }
}