import { Injectable } from '@angular/core';
import { map,lastValueFrom } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CommonApiService } from '../common.service';
import { FinalAssemblyAllocation } from 'src/state/final-assembly-allocation/state-models/final-assembly-allocation';
import { AllocationSummary } from 'src/state/final-assembly-allocation/state-models/allocation-summary';

import * as finalAssemblyAllocationConfig from'../../assets/config/final-assembly-allocation.config.js';

@Injectable({
    providedIn: 'root'
})
export class FinalAssemblyAllocationApiService {
    constructor(
    private apollo: Apollo,
    private commonService: CommonApiService
  ) { }

  // The "from" parameter is ignored in the API in case of in-stock tab
    private finalAssemblyAllocationSearchQuery = `
      search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to, paging: $paging, order: $order, filter: $filter) {
        list {
          id
          productIndividualId
          productIndividualNumber
          allocationScaniaUnitId
          allocationStatusId
          chassisNumber
          serialNumber
          chassisType
          countryCode
          fullyAllocated
          invoiceDate
          productionDate
          finalAssemblyComponentDeliveryValue
          finalAssemblyComponentTypeName
          spare
          axle
          batteryVehicle
          gosParts
          cab
          engineVehicle
          gearboxVehicle
          painted
          electricPropVehicle
          totalValue
        }
        totalCount
        errorsCount:extraHeaderInfo1
      }`;

    private finalAssemblyAllocationSummaryQuery = `
      summary (allocationScaniaUnit: $allocationScaniaUnit, from: $from, to: $to) {
        totalValue
        totalAmount
      }`;

    getFinalAssemblyComponentsList(queryType: string, variables?, forceRefetch: boolean = false): Promise<any> {
        const result$ = this.apollo
      .query<FinalAssemblyAllocation[]>({
          variables,
          fetchPolicy: forceRefetch ? 'no-cache' : null,
          query: gql`
                query finalAssemblyAllocationListQuery($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $paging: Paging, $order:String, $filter:String) {
                  finalAssemblyFactoryAllocation {
                    ${queryType} {
                      ${this.finalAssemblyAllocationSearchQuery}
                    }
                  }
                }`
      })
      .pipe<FinalAssemblyAllocation[]>(
       map((result: any) => {
        return result?.data?.finalAssemblyFactoryAllocation?.[queryType]?.search;
        })
      )
      return lastValueFrom(result$);
    }

    getFinalAssemblyComponentsSummary(queryType: string, variables?, forceRefetch: boolean = false): Promise<AllocationSummary> {
      const result$ = this.apollo
      .query<AllocationSummary>({
          variables,
          fetchPolicy: forceRefetch ? 'no-cache' : null,
          query: gql`
                query finalAssemblyAllocationListQuery($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime) {
                  finalAssemblyFactoryAllocation {
                    ${queryType} {
                      ${this.finalAssemblyAllocationSummaryQuery}
                    }
                  }
                }`
      })
      .pipe<AllocationSummary>(
       map((result: any) => {
           return result?.data?.finalAssemblyFactoryAllocation?.[queryType]?.summary;
        })
      )

      return lastValueFrom(result$);
    }

    exportFinalAssemblyAllocationList(queryType: string, scaniaUnitName: string, variables?, fileName: string = null): Promise<any> {
        const query = gql`
      query finalAssemblyAllocation($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime) {
        finalAssemblyFactoryAllocation {
          export_${queryType} {
            search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to) {
              ${this.queryProperties(scaniaUnitName)}
            }
          }
        }
      }`;
        return this.commonService.exportQuery(query, variables, fileName, true, queryType);
    }

    queryProperties(scaniaUnitName: string): string {
        let config = finalAssemblyAllocationConfig.find(config =>
          config.AllocationUnits !== null && config.AllocationUnits.indexOf(scaniaUnitName) >= 0
        );

        if (!config) {
            config = finalAssemblyAllocationConfig.find(config => config.AllocationUnits === null);
        }

        return config.Fields;
    }

    allocate(variables?): Promise<any> {
        return this.apollo
          .mutate({
              variables,
              mutation: gql`
              mutation ($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $selectAll: Boolean, $includedItems: [Int], $excludedItems: [Int], $filter:String) {
                finalAssemblyAllocation {
                  allocate(allocationScaniaUnit: $allocationScaniaUnit, from: $from, to: $to, selectAll: $selectAll, includedItems: $includedItems, excludedItems: $excludedItems, filter: $filter)
                }
              }`
          })
          .toPromise();
    }
}
