import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { result } from 'lodash';
import { map,lastValueFrom } from 'rxjs';
import { ProductClass } from 'src/state';


@Injectable({
  providedIn: 'root'
})
export class ProductClassApiService {
  constructor(private apollo: Apollo) { }

  getProductClass(variables?): Promise<ProductClass[]> {
    const result$ = this.apollo
      .query<ProductClass[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
        query productClassQuery($order: String, $filter: String) {
          admin {
            productClass(order: $order, filter: $filter) {
              id
              variantOption {
                id
                description
                value
              }
            }
          }
        }
        `
      })
      .pipe<ProductClass[]>(
       
        map((result: any) => {
          return result?.data?.admin?.productClass;
          })
      )
      return lastValueFrom(result$);
  }
}
