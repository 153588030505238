import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { CommonStateService } from 'src/state';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationDialogService {
    resources: any = this.commonService.getResources();

    constructor(public dialog: MatDialog,
        private commonService: CommonStateService) { }

    show(message: string, title?: string, positiveButtonText?: string, negativeButtonText?: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '400px',
                maxWidth: '50vw',
                data: {
                    message,
                    title: title || this.resources.Confirm,
                    positiveButtonText: positiveButtonText || this.resources.OK,
                    negativeButtonText: negativeButtonText || this.resources.Cancel
                }
            });
            dialogRef.afterClosed().subscribe(result => resolve(result));
        });
    }
}
