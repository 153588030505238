import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccessControlApiService {
    constructor(private http: HttpClient) { }

    private getUserAccessRights(): Observable<string[]> {
        const url = `${environment.adminUrl}/accessControl/checkAccessRights`;
        return this.http.get<string[]>(url);
    }

    checkUserAccessRights(): Promise<string[]> {
        return new Promise<string[]>((resolve) => {
            this.getUserAccessRights().subscribe(
                data => resolve(data),
                error  => resolve([])
            );
        });
    }
}
