<div class="card">
   <h3 class="card-header">{{resources.AddCurrencyAdjustmentConditionVariant}}</h3>
   <div class="card-body">
      <div class="container-fluid">
         <div class="row">
            <div class="form-group col-md-2">
               <label>{{resources.VariantFamily}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <input type="text" aria-label="Number" matInput [formControl]="variantFamilyInput"
                        [matAutocomplete]="auto">
                     <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="onVariantFamilyChange($event)">
                        <mat-option *ngFor="let item of filteredVariantFamilyList | async" [value]="item">
                           {{item.value}} - {{item.description}}
                        </mat-option>
                     </mat-autocomplete>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.VariantOption}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="variantOptionInput">
                        <mat-option *ngFor="let item of variantOptionList | async" [value]="item.id">
                           {{item.value}} - {{item.description}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>&nbsp;</label>
               <button type="button" class="btn btn-sm btn-primary form-control" (click)="onAdd()">
                  <i class="fas fa-lg fa-plus-square"></i>&nbsp; {{resources.Add}}
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<br />