<div class="card">
  <div class="card-body">
    <form [formGroup]="searchForm" (ngSubmit)="onSearch()" class="row">
      <div class="form-group col-lg-3 col-md-4 col-xs-12">
        <label>{{resources.AllocationScaniaUnit}}:</label>
        <div class="input-group">
          <select formControlName="selectedScaniaUnit" class="form-control">
            <option value="" selected disabled="disabled"></option>
            <option value="-1">*{{resources.All}}*</option>
            <option *ngFor="let scaniaUnit of scaniaUnitList | async" value={{scaniaUnit.id}}>
              {{scaniaUnit.name}} - {{scaniaUnit.description}}
            </option>
          </select>
        </div>
      </div>
        <div class="form-group col-lg-2 col-md-3 col-xs-12">
          <label>{{resources.From}}:</label>
          <div class="input-group">
            <input formControlName="startDate" placeholder="yyyy-mm-dd" type="date" class="form-control" />
            <!-- <div class="input-group-btn">
              <button class="btn btn-outline-secondary" (click)="fromDate.toggle()" type="button">
                <i class="scania-icon-date xs"></i>
              </button>
            </div> -->
          </div>
        </div>
        <div class="form-group col-lg-2 col-md-3 col-xs-12">
          <label>{{resources.To}}:</label>
          <div class="input-group">
            <input formControlName="endDate" placeholder="yyyy-mm-dd" type="date" class="form-control" />
            <!-- <div class="input-group-btn">
              <button class="btn btn-outline-secondary" (click)="toDate.toggle()" type="button">
                <i class="scania-icon-date xs"></i>
              </button>
            </div> -->
          </div>
        </div>
        <div class="form-group col-md-1 col-xs-12">
          <label>&nbsp;</label>
          <button class="btn btn-primary form-control" type="submit">
            {{resources.Search}}
          </button>
        </div>
    </form>
  </div>
</div>
<p></p>
<cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="dataList | async" (sortTriggered)="onSort($event)"
  (filterTriggered)="onFilter($event)" [filterExpression]="filterExpression | async" (clearFilterAndSortingTriggered)="onClearFilterAndSorting()" [isLoading]="isLoading | async"
  [dataTotalCount]="totalCount | async">
</cal-calsiumlist-listing>
<cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async">
</cal-pagination>