import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

// Calsium Modules
import { CommonModule } from './common';
import { NotificationsModule } from './notifications';
import { FlowModule, flowRoutes } from './flow';
import { MaterialModule, materialRoutes } from './material';
import { AllocationModule, allocationRoutes } from './allocation';
import { AuthenticationModule, authRoutes, AuthService } from './authentication';
import { ProductionPriceListModule, productionPriceListRoutes } from './production-price-list';
import { reportsRoutes, ReportsModule } from './reports';
import { pvaManualAdjustmentRoutes, PvaManualAdjustmentModule } from './pva-manual-adjustment';
// shared modules in root registry
import { StateModule } from '../state';

import { ErrorHandlerService } from './errorhandler/error-handler.service';

// Components
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HighLightEditableCell } from './shared/directives/highlighteditablecell.directive';
import { CalsiumDateFormatterPipe } from './shared/calsium-date.pipe';
import { CalsiumDateFormatter } from './shared/calsium-date-formatter';
import { ToastrHelperService } from './shared/toastr-helper.service';
import { adminRoutes, AdminModule } from './administration';
import { AddMarkupConditionMaterialComponent } from './administration/pages/markup-condition-material/add-markup-condition-material/add-markup-condition-material.component';
import { MarkupConditionMaterialListComponent } from './administration/pages/markup-condition-material/markup-condition-material-list/markup-condition-material-list.component';

export function waitForUserFactory(authService: AuthService) {
    return () => authService.userInfo.subscribe();
}

// ============================================================================
const routes: Routes = [
    ...authRoutes,
    ...flowRoutes,
    ...materialRoutes,
    ...productionPriceListRoutes,
    ...allocationRoutes,
    ...reportsRoutes,
    ...pvaManualAdjustmentRoutes,
    ...adminRoutes
];
@NgModule({
    declarations: [
        AppComponent,
        HighLightEditableCell
    ],
    imports: [
        ToastrModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        NgbModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {}),
        CommonModule,
        AuthenticationModule,
        FlowModule,
        AllocationModule,
        StateModule,
        MaterialModule,
        ProductionPriceListModule,
        ReportsModule,
        PvaManualAdjustmentModule,
        AdminModule,
        NotificationsModule
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: waitForUserFactory, deps: [AuthService], multi: true },
        ToastrHelperService,
        ErrorHandlerService,
        CalsiumDateFormatterPipe,
        { provide: NgbDateParserFormatter, useClass: CalsiumDateFormatter }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
