import {
    MarkupPercentageAction,
    MarkupPercentageActionType,
} from "./action";
import { MarkupPercentageState } from "./state";

export const MarkupPercentageInitialState =
    require("./initial-data.json") as MarkupPercentageState;

export function MarkupPercentageReducer(
    state: MarkupPercentageState = MarkupPercentageInitialState,
    action: MarkupPercentageAction
): MarkupPercentageState {
    switch (action.type) {
        case MarkupPercentageActionType.LoadMarkupPercentageListAction:
            return {
                ...state,
                markupPercentageList: action.payload,
            };        
        case MarkupPercentageActionType.AddMarkupPercentageAction:
            return {
                ...state,
                markupPercentageList: [
                    ...state.markupPercentageList,
                    action.payload,
                ],
            };
        case MarkupPercentageActionType.ChangeMarkupPercentageFilterExpressionAction:
            return {
                ...state,
                markupPercentageListFilterExpression: action.payload,
            };
        case MarkupPercentageActionType.ChangeMarkupPercentageSortExpressionAction:
            return {
                ...state,
                markupPercentageListSortExpression: action.payload,
            };
        case MarkupPercentageActionType.ChangeMarkupPercentageListPageAction:            
            return {
                ...state,
                markupPercentageList: null,
                markupPercentageTotalCount: 0,
                markupPercentagePaging: { ...state.markupPercentagePaging, page: action.payload },
            };
        case MarkupPercentageActionType.ChangeMarkupPercentageListTotalCountAction:            
            return {
                ...state,
                markupPercentageTotalCount: action.payload.totalCount,                
            };
        case MarkupPercentageActionType.ChangeMarkupPercentageShowOnlyValidPercentagesAction:
            return{
                ...state,
                showOnlyValidMarkupPercentages: action.payload
            }

        default:
            return state;
    }
}
