import { DeliveryRouteAllocationUnitState } from './state';
import { DeliveryRouteAllocationUnitsAction, DeliveryRouteAllocationUnitActions } from './actions';
import { DeliveryRouteActions } from '../delivery-route/actions';

export const DeliveryRouteAllocationUnitInitialState = require('./initial-data.json') as DeliveryRouteAllocationUnitState;

export function DeliveryRouteAllocationUnitReducer(
    state: DeliveryRouteAllocationUnitState = DeliveryRouteAllocationUnitInitialState,
    action: DeliveryRouteAllocationUnitsAction
): DeliveryRouteAllocationUnitState {
    switch (action.type) {
        case DeliveryRouteAllocationUnitActions.LoadListAction:
            return {
                ...state,
                list: action.payload
            };
        case DeliveryRouteAllocationUnitActions.ChangeFilterExpressionAction:
            return {
                ...state,
                filterExpression: action.payload,
                paging: { ...state.paging, page: 1 }
            };
        case DeliveryRouteAllocationUnitActions.ChangeSortExpressionAction:
            return {
                ...state,
                sortExpression: action.payload,
                paging: { ...state.paging, page: 1 }
            };
        case DeliveryRouteAllocationUnitActions.ChangeResultsTotalCountAction:
            return {
                ...state,
                totalCount: action.payload
            };
        case DeliveryRouteAllocationUnitActions.ChangePageAction:
            return {
                ...state,
                paging: { ...state.paging, page: action.payload }

            };
        case DeliveryRouteAllocationUnitActions.LoadDeliveryRouteListAction:
            return {
                ...state,
                deliveryRouteList: action.payload
            }
        case DeliveryRouteAllocationUnitActions.LoadScaniaUnitConsolidationListAction:
            return {
                ...state,
                scaniaUnitConsolidationList: action.payload
            }
        case DeliveryRouteAllocationUnitActions.LoadScaniaUnitAllocationListAction:
            return {
                ...state,
                scaniaUnitAllocationList: action.payload
            }
        default:
            return state;
    }
}
