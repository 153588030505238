import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { CommonStateService } from 'src/state';
import { CPAReportStateService } from 'src/state/cpa-report/service';
import { CPAItem } from 'src/state/cpa-report/state-models/cpa-item';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';

@Component({
    selector: 'cal-cpa-report-component',
    templateUrl: './cpa-report.component.html',
    styleUrls: ['./cpa-report.component.css']
})
export class CPAReportComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    cpaList: Observable<CPAItem[]>;
    selected: string;
    isLoading: Observable<boolean>;

    constructor(
        private commonService: CommonStateService,
        private service: CPAReportStateService,
        private errHandler: ErrorHandlerService

    ) {

    }

    ngOnInit() {
        this.isLoading = this.commonService.getLoading();
        this.service.loadCPAList();
        this.cpaList = this.service.getList();
    }

    onExport() {
        if (!this.selected) {
            this.errHandler.emitError(this.resources.NoConsumingPlanningAreaSelected, this.resources.Error, ErrorTypeEnum.Warning);
            return;
        }
        this.service.exportCPAData(this.selected);
    }

    onChange(e) {
        this.selected = e.value;
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
