import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CommonStateService, Paging } from 'src/state';
import { MarkupPercentageStateServiceService } from 'src/state/administraion/markup-percentage/service';
import { MarkupPercentage } from 'src/state/administraion/markup-percentage/state-models/markup-percentage';

@Component({
  selector: 'cal-markup-percentage-list',
  templateUrl: './markup-percentage-list.component.html',
  styleUrls: ['./markup-percentage-list.component.css']
})
export class MarkupPercentageListComponent implements OnInit {  

    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    totalCount: Observable<number>;
    markupPercentageList: Observable<MarkupPercentage[]>;
    isLoading: Observable<boolean>;
    showOnlyValidMarkupPercentages: Observable<boolean>;
    listConfig: CalsiumListConfig;    
    paging: Observable<Paging>;

    constructor(
        private markupPercentageService: MarkupPercentageStateServiceService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) {}    

    ngOnInit(): void {
        
        this.isLoading = this.commonService.getLoading();
        this.sortingExpression = this.markupPercentageService.getMarkupPercentageListSortExpression();
        this.filterExpression = this.markupPercentageService.getMarkupPercentageListFilterExpression();
        this.markupPercentageList = this.markupPercentageService.getMarkupPercentageList();
        this.showOnlyValidMarkupPercentages = this.markupPercentageService.getShowOnlyValidMarkupPercentages();
        this.paging = this.markupPercentageService.getPaging();           
        this.totalCount = this.markupPercentageService.getTotalCount();

        this.subscriptions.add(
            combineLatest(
                this.paging,
                this.sortingExpression,
                this.filterExpression,
                this.showOnlyValidMarkupPercentages
            )
            .pipe(
                debounceTime(0),
                switchMap(
                    ([
                        paging,
                        sortingExpression,
                        filterExpression,
                        showOnlyValidMarkupPercentages
                    ]) => {
                        return this.markupPercentageService.loadMarkupPercentages(
                            paging,
                            sortingExpression,
                            filterExpression,
                            showOnlyValidMarkupPercentages                              
                        );
                    }
                )
            )
            .subscribe()
        );

        this.buildList();
    }
    
    buildList() 
    {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.MarkupPercentageList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;        
        this.listConfig.ShowExportToExcel = false;

        let column;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "market.name",
            this.resources.Market
        );
        column.AllowFilter = true;
        column.Sortable = true;
        
        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "productClass.variantOption.description",
            this.resources.ProductClass
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "markupCondition.markupConditionCode",
            this.resources.MarkupCondition
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "percentage",
            this.resources.Percentage
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validFrom",
            this.resources.From
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validTo",
            this.resources.To
        );
        column.AllowFilter = true;
        column.Sortable = true;        
    }

    ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    }

    onSort(sortExpression: string) {
    this.markupPercentageService.sortMarkupPercentage(
        sortExpression
    );
    }

    onFilter(filterExpression: string) {
        this.markupPercentageService.filterMarkupPercentage(
            filterExpression
        );
    }
    onPaging(page: number) {
        this.markupPercentageService.goToPage(page);
      }

    onClearFilterAndSorting() {
        this.markupPercentageService.clearMarkupPercentageFilterAndSorting();
    }

    onShowOnlyValidMarkupPercentage(showOnlyValidMarkupPercentage: boolean) {
        this.markupPercentageService.showShowOnlyValidMarkupPercentages(
            showOnlyValidMarkupPercentage
        );
    }
}
