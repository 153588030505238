import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CalsiumListConfig, ColumnDataType } from '../../../calsiumlist/calsiumlist.component.model';
import { CalsiumDateFormatterPipe } from '../../../shared/calsium-date.pipe';

import { ProductionPriceList, CommonStateService, ProductionPriceListStateService } from '../../../../state';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'cal-production-price-list',
    templateUrl: './production-price-list.component.html',
    styleUrls: ['./production-price-list.component.css']
})
export class ProductionPriceListComponent implements OnInit {

    resources: any = this.commonService.getResources();
    productionPriceLists: Observable<ProductionPriceList[]>;
    selectedPriceList: Observable<ProductionPriceList>;
    productionPriceListConfig: CalsiumListConfig;
    priceListForm: UntypedFormGroup;
    isPriceListsLoading: boolean = true;
    isResultsLoading: boolean = false;
    isExcelExportVisible=false;
    idOfPriceListToBeExported=0;
    nameOfPriceListToBeExported="";

    constructor(private productionPriceListService: ProductionPriceListStateService,
        private commonService: CommonStateService) { }

    ngOnInit() {
        this.productionPriceLists = this.productionPriceListService.getProductionPriceList();
        this.selectedPriceList = this.productionPriceListService.getSelectedProductionPriceList();
        this.productionPriceListService.loadPriceLists().then(
            () => { this.isPriceListsLoading = false; }
        );

        this.priceListForm = new UntypedFormGroup({
            selectedPriceList: new UntypedFormControl()
        });

        this.buildList();
    }

    buildList() {
        this.productionPriceListConfig = new CalsiumListConfig({
            ShowFilterRow: false,
            ShowFooter: false,
            ShowSumRow: false,
            ShowClearFilterSortingButton:false,
            ListCaption: 'Production price lists',
            ParentComponent: this
        });

        let column = this.productionPriceListConfig.AddListColumn(ColumnDataType.radio, '', '');
        column.OnChange = (row: ProductionPriceList) => this.selectPriceList(row.id,row.description);
        column.CssClass = 'select-column';
        // column.IsAnchor = () => true;
        // column.GetAnchorLink = (row: ProductionPriceList) => `/production-price-list/${row.id}`;

        column = this.productionPriceListConfig.AddListColumn(ColumnDataType.string, 'description', this.resources.Description);

        column = this.productionPriceListConfig.AddListColumn(ColumnDataType.date, 'validFrom', this.resources.ValidFrom);
        column.DisplayPipe = new CalsiumDateFormatterPipe();

        column = this.productionPriceListConfig.AddListColumn(ColumnDataType.date, 'validTo', this.resources.ValidTo);
        column.DisplayPipe = new CalsiumDateFormatterPipe();
    }

    selectPriceList(id: number,description:string) {
        this.isResultsLoading = true;
        this.productionPriceListService.loadPriceList(id).then(
            () => { this.isResultsLoading = false; }
        );
        this.isExcelExportVisible=true;
        this.productionPriceListConfig.ShowExportToExcel=true;
        this.idOfPriceListToBeExported=id;
        this.nameOfPriceListToBeExported=description;
    }

    onExport(){               
        this.productionPriceListService.exportSelectedPriceList(this.idOfPriceListToBeExported,this.nameOfPriceListToBeExported);
    }
}
