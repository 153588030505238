import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
const moment = _rollupMoment || _moment;
import { CommonStateService } from 'src/state';
import { Validators, UntypedFormControl } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CurrencyAdjustmentStateService } from 'src/state/administraion/currency-adjustment/service';
import { CurrencyAdjustmentCode } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-code';
import { CurrencyAdjustmentPercentage, CurrencyAdjustmentPercentageAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-percentage';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'cal-add-currency-adjustment-percentage',
    templateUrl: './add-currency-adjustment-percentage.component.html'
})
export class AddCurrencyAdjustmentPercentageComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    codeInput: UntypedFormControl;
    percentageInput: UntypedFormControl;
    currencyAdjustmentCodeId: number;
    selectedPeriod: Moment = moment();
    codeList: Observable<CurrencyAdjustmentCode[]>;
    isSaving: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private currencyAdjustmentService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) { }

    ngOnInit(): void {
        this.buildForm();
        this.codeList = this.currencyAdjustmentService.getCodeList();
        this.route.params.subscribe(async (params) => {
            this.currencyAdjustmentCodeId = params.id;
            this.codeInput.setValue(params.id);
        });
    }
    buildForm() {
        this.percentageInput = new UntypedFormControl('', [Validators.required]);
        this.codeInput = new UntypedFormControl('', [Validators.required]);
    }

    onSave() {
        if (this.isValidForm()) {
            const fromDate = this.selectedPeriod.date(1).format('YYYY-MM-DD');
            const payload: CurrencyAdjustmentPercentageAdd = {
                currencyAdjustmentCodeId: this.currencyAdjustmentCodeId ? this.currencyAdjustmentCodeId : this.codeInput.value,
                percentage: this.percentageInput.value,
                validFrom: new Date(fromDate),
                validTo: null
            };

            this.currencyAdjustmentService.addPercentage(payload).then((errors) => {
                this.isSaving = false;
                if (errors && errors.length) {
                    let errorMessage = '';
                    for (const error of errors) {
                        const errorType = error.split('\n')[0].replace('GraphQL.ExecutionError:', '').trim();
                        switch (errorType) {
                            case 'ALREADY_EXIST':
                                errorMessage += this.resources.CurrencyAdjustmentPercentageExist;
                                break;
                            default:
                                errorMessage += this.resources.ErrorOccured;
                                break;
                        }
                    }
                    this.errHandler.emitError(errorMessage, this.resources.Error, ErrorTypeEnum.Error);
                } else {
                    this.errHandler.emitError(this.resources.SavedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
                    this.reset();
                }
            });
        }
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.percentageInput.errors != null) {
            if (this.percentageInput.errors.required) {
                this.errHandler.emitError(this.resources.RequiredFieldError, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.codeInput.errors != null) {
            if (this.codeInput.errors.required) {
                this.errHandler.emitError(this.resources.RequiredFieldError, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        return valid;
    }
    onPeriodSelected(period: any) {
        this.selectedPeriod = period;
    }

    reset() {
        if (this.percentageInput) {
            this.percentageInput.reset();
            if (!this.currencyAdjustmentCodeId) {
                this.codeInput.reset();
            }
            this.selectedPeriod = moment();
        }
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
