<ng-container [formGroup]="Form" *ngIf="!(IsAddMode && Column.DisplayOnNewItemCreation === false)">
    <ng-container *ngIf="Column.DataType === 'date'">
        <div>
            <input placeholder="yyyy-mm-dd" name="dp" id="{{Column.ColumnName}}" type="date" class="form-control"
                maxlength="{{Column.Maxlength}}" size="{{Column.Size}}" name="{{Column.ColumnName}}" formControlName="{{Column.ColumnName}}"
                (dateSelect)="onChange(this,$event)" max="9999-12-31">
        </div>
    </ng-container>
    <ng-container *ngIf="Column.DataType === 'string' || Column.DataType === 'anchor'">
        <input size="{{Column.Size}}" id="{{Column.ColumnName}}" maxlength="{{Column.Maxlength}}" type="text" name="{{Column.ColumnName}}"
            formControlName="{{Column.ColumnName}}" (change)="onChange(this,$event)"> {{CurrencySymbol}}
    </ng-container>
    <ng-container *ngIf="Column.DataType === 'number'">
        {{CurrencySymbol}}
        <input size="{{Column.Size}}" [form]="Form" [maxNumberOfIntegers]='Column.MaxNumberOfIntegrsAllowed'
            [maxNumberOfDecimals]='Column.MaxNumberOfDecimalsAllowed' id="{{Column.ColumnName}}" maxlength="{{Column.Maxlength}}"
            type="text" appNumberOnly name="{{Column.ColumnName}}" formControlName="{{Column.ColumnName}}" (change)="onChange(this,$event)">
    </ng-container>
    <ng-container *ngIf="Column.DataType === 'list'">
        <select id="{{Column.ColumnName}}" name="{{Column.ColumnName}}" formControlName="{{Column.ColumnName}}" [value]="Value"
            (change)="onChange(this,$event)">
            <option value="null">
                {{resources.None}}
            </option>
            <ng-container *ngIf="IsAddMode">
                <option value="{{selectItem.Value}}" *ngFor="let selectItem of Column.NewItemListItems | async">
                    {{selectItem.Text}}
                </option>
            </ng-container>
            <ng-container *ngIf="IsEditMode">
                <option value="{{selectItem.Value}}" *ngFor="let selectItem of Column.EditItemListItems | async">
                    {{selectItem.Text}}
                </option>
            </ng-container>
        </select>
    </ng-container>
</ng-container>