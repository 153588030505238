import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ManualInvoicing } from 'src/app/administration/pages/manual-invoicing/manual-invoicing-model';
@Injectable({
  providedIn: 'root'
})
export class ManualInvoicingApiService {
  constructor(private apollo: Apollo) { }

  verify(payload: ManualInvoicing[]): Promise<any> {
    const mutation = `
        mutation ($payload: [ManualInvoicingInput]!) {
            manualInvoicing {
              verify(payload: $payload){
                chassisNumber
                serialNumber
                countryCode
                invoiceAmount
                invoiceDate
                responsibilityCode
              }
          }
        }`;

    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    }).toPromise();
  }

  async confirm(payload: ManualInvoicing[]): Promise<any> {
    const mutation = `
        mutation ($payload: [ManualInvoicingInput]!) {
            manualInvoicing {
              confirm(payload: $payload)
          }
        }`;

    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    }).toPromise();
  }
}
