import * as _ from 'lodash';

import { ComponentAllocationState } from './state';
import { ComponentAllocationAction, ComponentAllocationActions } from './actions';
const initialState = require('./initial-data.json') as ComponentAllocationState;

export function ComponentAllocationReducer(
    state: ComponentAllocationState = initialState,
    action: ComponentAllocationAction
): ComponentAllocationState {
    switch (action.type) {
        case ComponentAllocationActions.LoadProducedComponentListAction:
            return {
                ...state,
                producedComponentList: action.payload
            };
        case ComponentAllocationActions.LoadAllocatedComponentListAction:
            return {
                ...state,
                allocatedComponentList: action.payload
            };
        case ComponentAllocationActions.LoadInStockComponentListAction:
            return {
                ...state,
                stockComponentList: action.payload
            };
        case ComponentAllocationActions.ChangeComponentSearchFormAction:
            return {
                ...state,
                searchForm: action.payload,
                producedPaging: { ...state.producedPaging , page:1 },
                allocatedPaging: { ...state.allocatedPaging, page:1 },
                stockPaging: { ...state.stockPaging, page:1 }
            };
        case ComponentAllocationActions.ChangeProducedComponentListFilterExpressionAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount : 0,
                producedPaging: { ...state.producedPaging, page: 1 },
                producedFilterExpression: action.payload
            };
        case ComponentAllocationActions.ChangeProducedComponentListSortExpressionAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount : 0,
                producedPaging: { ...state.producedPaging, page: 1 },
                producedSortExpression: action.payload
            };
        case ComponentAllocationActions.ChangeProducedComponentListPageAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount : 0,
                producedPaging: { ...state.producedPaging, page: action.payload },
            };
        case ComponentAllocationActions.ChangeProducedComponentListTotalCountAction:
            return {
                ...state,
                producedTotalCount: action.payload.totalCount,
                producedErrorsCount : action.payload.errorsCount
            };
        case ComponentAllocationActions.ChangeAllocatedComponentListFilterExpressionAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount :0,
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                allocatedFilterExpression: action.payload
            };
        case ComponentAllocationActions.ChangeAllocatedComponentListSortExpressionAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount :0,
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                allocatedSortExpression: action.payload
            };
        case ComponentAllocationActions.ChangeAllocatedComponentListPageAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount :0,
                allocatedPaging: { ...state.allocatedPaging, page: action.payload },
            };
        case ComponentAllocationActions.ChangeAllocatedComponentListTotalCountAction:
            return {
                ...state,
                allocatedTotalCount: action.payload.totalCount
            };
        case ComponentAllocationActions.ChangeInStockComponentListFilterExpressionAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: 1 },
                stockFilterExpression: action.payload
            };
        case ComponentAllocationActions.ChangeInStockComponentListSortExpressionAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: 1 },
                stockSortExpression: action.payload
            };
        case ComponentAllocationActions.ChangeInStockComponentListPageAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: action.payload },
            };
        case ComponentAllocationActions.ChangeInStockComponentListTotalCountAction:
            return {
                ...state,
                stockTotalCount: action.payload.totalCount,
                stockErrorsCount : action.payload.errorsCount
            };
        case ComponentAllocationActions.AllocateComponents:
            return {
                ...state,
            };
        case ComponentAllocationActions.MoveComponentsToStock:
            return {
                ...state,
            };
        case ComponentAllocationActions.LoadProducedWidgetAction:
            return {
                ...state,
                producedWidget: action.payload
            };
        case ComponentAllocationActions.LoadAllocatedWidgetAction:
            return {
                ...state,
                allocatedWidget: action.payload
            };
        case ComponentAllocationActions.LoadInStockWidgetAction:
            return {
                ...state,
                stockWidget: action.payload
            };
        default:
            return state;
    }
}
