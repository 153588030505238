<div *ngIf="!productionValuesList || productionValuesList.length === 0" class="alert alert-danger">
  {{ resources.NoDataFound }}
</div>

<mat-accordion class="cal-aligned-accordion">
  <mat-expansion-panel *ngFor="let componentType of componentTypes" (opened)="componentTypeOpen(componentType.id)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ componentType.description }}
      </mat-panel-title>

      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>{{ resources.ConditionId}} </th>
          <th>{{ resources.ConditionDescription}} </th>
          <th>{{ resources.VariantCodes}} </th>
          <th>{{ resources.Price}} </th>
          <th>{{ resources.PriceType}} </th>
        </tr>
      </thead>
      <tr *ngFor="let pv of productionValues">
        <td>{{ pv.productionPriceConditionPrice?.productionPriceCondition.id }}</td>
        <td>{{ pv.productionPriceConditionPrice?.productionPriceCondition.description }}</td>
        <td>{{ pv.productionPriceConditionPrice?.productionPriceCondition.variantCodeSummary }}</td>
        <td>{{ pv.productionValue }}</td>
        <td>
          {{ pv.productionPriceConditionPrice?.productionPriceType.description }}
          <span *ngIf="pv.isUniqueDesign">
            {{resources.UniqueDesign}}
          </span>
        </td>

      </tr>
      <tr *ngFor="let pv of manualProductionValues">
        <td colspan="3">{{ pv.comment }}</td>
        <td>{{ pv.value }}</td>
        <td>{{ pv.productionPriceType.description }}</td>
      </tr>
    </table>
  </mat-expansion-panel>
</mat-accordion>