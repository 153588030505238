import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Loadable } from '../common/loadable';
import { AppState } from '../app.state';
import {
    ChangeSimulationListSortExpressionAction,
    ChangeSimulationListFilterExpressionAction,
    ChangeSimulationListPageAction,        
    LoadSimulationListPageAction,
    ChangeSimulationListTotalCountAction
} from './action';

import { Paging } from '..';
import { SimulationState } from './state';
import { Simulation } from './state-models/simulation-data';
import { SimulationApiService } from 'src/api/services/simulation-api.service';
import moment from 'moment';

@Injectable()
export class SimulationStateService {
    private resources = require('../../state/common/resources.json');
    constructor(
        private apiService: SimulationApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>) { }
   
    @Loadable()
    async loadSimulationList(        
        paging: Paging,
        sortExpression: string,
        filterExpression: string,        
        forceRefetch: boolean = false) {
        const payload = {           
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression,
        };
        const simulationList = await this.apiService.getSimulationsList('retrieve_simulations_data', payload, forceRefetch);
        this.getFormattedDateTimeAndStatus(simulationList);
       
        this.appStore.dispatch(new LoadSimulationListPageAction(simulationList.list));
        this.appStore.dispatch(new ChangeSimulationListTotalCountAction(simulationList.totalCount, simulationList.errorsCount));        
    }

    async refetchList(): Promise<void> {    
        const state = <SimulationState>this.appState.getValue().simulation;

        await this.loadSimulationList(state.simulationPaging, state.simulationSortExpression, state.simulationFilterExpression, true);
    }    

    getList(type: string): Observable<Simulation[]> {
        return this.appStore.select(state => state.simulations[`simulationList`]);
    }

    getSortingExpression(type: string): Observable<string> {
        return this.appStore.select(state => state.simulations[`simulationSortExpression`]);
    }        

    sort(sortExpression: string) {        
        this.appStore.dispatch(new ChangeSimulationListSortExpressionAction(sortExpression));
    }

    getFilterExpression(type: string): Observable<string> {
        return this.appStore.select(state => state.simulations[`simulationFilterExpression`]);
    }

    filter(filterExpression: string) {        
        this.appStore.dispatch(new ChangeSimulationListFilterExpressionAction(filterExpression));
    }

    getPaging(type: string): Observable<Paging> {
        return this.appStore.select(state => state.simulations[`simulationPaging`]);
    }

    goToPage(page: number) {      
        this.appStore.dispatch(new ChangeSimulationListPageAction(page));
    }

    getTotalCount(type: string): Observable<number> {
        return this.appStore.select(state => state.simulations[`simulationTotalCount`]);
    }

    getErrorCount(type: string): Observable<number> {
        return this.appStore.select(state => state.simulations[`simulationErrorsCount`]);
    }

    clearFilterAndSorting() {
        [
            new ChangeSimulationListSortExpressionAction(null),
            new ChangeSimulationListFilterExpressionAction(null)
        ].forEach(action=>this.appStore.dispatch(action));
    }

    resetToDefaults() {         
        this.appStore.dispatch(new LoadSimulationListPageAction([]));
        this.appStore.dispatch(new ChangeSimulationListTotalCountAction(0, 0));        
    }

    getFormattedDateTimeAndStatus(simulationList) {
        if(simulationList){
            simulationList?.list?.forEach((value) => {
                const {createdOn, updatedOn, status} = value
                value.createdOn = moment(createdOn).format('YYYY-MM-DD  hh:mm:ss UTC');
                value.updatedOn = moment(updatedOn).format('YYYY-MM-DD  hh:mm:ss UTC');
                switch(status){
                    case 0:
                        value.status = 'IN_PROGRESS';
                        break;
                    case 1:
                        value.status = 'SUCCESS';
                        break;    
                    case 2:
                        value.status = 'FAILED';
                        break;    
                }
            })
        }
    }

    getStatusKey = (currentStatus: string) => {
        if(currentStatus.toLowerCase() === 'success'){
          return 1;
        }
        else if(currentStatus.toLowerCase() === 'failed'){
          return 2;
        }
        else if(currentStatus.toLowerCase() === 'inprogress'){
          return 0;
        }
        else{
            return null;
        }
    }

    destructureFilterExpressionIntoObject = (filterExpression: string) => {
        if (filterExpression && filterExpression.length) {
            const filterExpArray = filterExpression.split('=')
            if (!filterExpArray.length) return null;
            const filterkey = filterExpArray[0];
            const filterValue = filterExpArray[1];
            return {
                [filterkey]: filterValue
            }
        }
        return null;
    }

      convertStatusCode = (filterExpression: string) => {
        const filterExpObj = this.destructureFilterExpressionIntoObject(filterExpression);
        if(filterExpObj){
          if(filterExpObj?.status){
            const numericStatus = this.getStatusKey(filterExpObj.status);
            if(numericStatus !== null){
                const newFilterExpression = `status=${numericStatus}`;
                return newFilterExpression;
            }
          }
        }
        return null;
      } 
}
