import { Component, OnInit, Input } from '@angular/core';
import { CalsiumListColumnItem, CalsiumListConfig } from '../calsiumlist.component.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-calsiumlist-cell',
    templateUrl: 'calsiumlist-cell.component.html',
    styleUrls: ['calsiumlist-cell.component.css']
})
export class CalsiumListCellComponent implements OnInit {
    @Input() Column: CalsiumListColumnItem;
    @Input() listConfig: CalsiumListConfig;
    @Input() Value = '';
    @Input() RowNumber: Number;
    @Input() Form: UntypedFormGroup;
    @Input() IsAddMode = false;
    @Input() IsEditMode = false;
    @Input() resources: any;

    CurrencySymbol: string;
    ngOnInit(): void {
        // this.CurrencySymbol = this.Column.IsCurrency ? 'SEK' : '';
        if (this.Column.DataType === 'date' && this.Value !== null && this.Value.length > 0) {
            {
                this.Form.controls[this.Column.ColumnName].setValue(this.getNgDateFromDbDate(this.Value));
            }
        }
    }

    onChange(sender: any, event: any) {
        if (this.Column.OnChange != null) {
            this.Column.OnChange.call(this.listConfig.ParentComponent, sender, event);
        }
    }

    getNgDateFromDbDate(value: string | number | Date) {
        const date = new Date(value);
        const ngDate = { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
        return ngDate;
    }
}
