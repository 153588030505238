import { Injectable } from '@angular/core';
import { print } from 'graphql/language/printer';
import { environment } from '../environments/environment';
import { take, map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastrHelperService } from 'src/app/shared/toastr-helper.service';
import { ActiveToast } from 'ngx-toastr';
import toastConfig from 'src/app/shared/custom-toast-options';

@Injectable({
    providedIn: 'root'
})
export class CommonApiService {
    private resources = require('../state/common/resources.json');

    constructor(private http: HttpClient, private toastr: ToastrHelperService) { }

    downloadFile(response: any, fileName: string = null, toast: ActiveToast<any>) {
        const dataType = <string>response.type;
        let fileExtension;
        if (dataType.indexOf('csv') > 0) {
            fileExtension = 'csv';
        } else if (dataType.indexOf('excel') > 0) {
            fileExtension = 'xls';
        } else if (dataType.indexOf('openxml') > 0) {
            fileExtension = 'xlsx';
        }
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([response], { type: dataType }));
        if (fileName) {
            downloadLink.setAttribute('download', `${fileName.replace(/\//g, '-')}.${fileExtension}`);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastr.clear(toast.toastId);
    }

    handleError(error: any, toast: ActiveToast<any>) {
        this.toastr.clear(toast.toastId);
        this.toastr.error(this.resources['ExportError'], null, toastConfig);
        return null;
    }

    exportQuery(query, variables?, fileName: string = null, ignorePropertyPath = false, queryType: string = null): Promise<any> {
        const timeInterval = 5000;
        const timeoutInterval = 900000;
        var counter = 0;
        const url = `${environment.adminUrl}/export/storefile?ignorePropertyPath=${ignorePropertyPath}&fname=${fileName}`;
        const body = {
            variables,
            operationName: queryType,
            query: print(query)
        };

        return this.checkS3Object(timeInterval,url,body,counter,timeoutInterval).toPromise();
    }

    exportSummaryQuery(productIndividualNumber: string,fileName: string, isCorporateMarkupIncluded: boolean = false, corporateMarkupPercentage: number = 0.0): Promise<any> {
        const timeInterval = 5000;
        const timeoutInterval = 900000;
        var counter = 0;
        const url = `${environment.adminUrl}/export/storeReportFile`;
        const body = {
            ProductIndividualNumber: productIndividualNumber,
            FileName: fileName,
            IsCorporateMarkupIncluded: isCorporateMarkupIncluded,
            CorporateMarkupPercentage: corporateMarkupPercentage
        };

        return this.checkS3Object(timeInterval,url,body,counter,timeoutInterval).toPromise();    
    }

    downloadExcel(response: any, fileName: string = null, toast: ActiveToast<any>) {
        const downloadLink = document.createElement('a');
        downloadLink.href = response.fileUrl;
        if (fileName) {
            downloadLink.setAttribute('download', fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastr.clear(toast.toastId);
    }

    checkS3Object(timeInterval,url,body,counter,timeoutInterval) {
        const toast = this.toastr.download(this.resources['ExportingList']);
        return this.http.post<any>(url, body).pipe(
            map(response => {
                const interval = setInterval(() => {
                    counter = counter + timeInterval;
                    const url = `${environment.adminUrl}/export/checks3object?fileName=${response.fileName}`;
                    const body = {};
                    return this.http.get<any>(url, body).pipe(
                        map(res => {
                            if (res.isExist) {
                                clearInterval(interval);
                                this.downloadExcel(res, "", toast);
                            }
                            if (counter == timeoutInterval) {
                                clearInterval(interval)
                                this.handleError(null, toast);
                            }
                        }),
                        catchError(error => {
                            clearInterval(interval);
                            return this.handleError(error, toast);
                        }),
                        take(1)
                    ).toPromise();
                }, timeInterval);
            }),
            catchError(error => this.handleError(error, toast)),
            take(1)
        );
    }
}
