import { StartLoadingAction, FinishLoadingAction } from './actions';
import { LoadingItem } from './state-models/loading-item';
import { Md5 } from 'ts-md5/dist/md5';

export function Loadable(appstoreName: string = 'appStore') {
    return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any) {
            const loadingItem = <LoadingItem>{
                key: Md5.hashStr(JSON.stringify(new Date().getTime()))
            };
            this[appstoreName].dispatch(new StartLoadingAction(loadingItem));
            let result = null;
            try {
                result = await originalMethod.apply(this, args);
            } finally {
                this[appstoreName].dispatch(new FinishLoadingAction(loadingItem));
            }
            return result;
        };
        return descriptor;
    };
}
