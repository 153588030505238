import { Component, OnInit, OnDestroy } from '@angular/core';
import { CalsiumListConfig, CalsiumListSelectItem, ColumnDataType } from '../../../calsiumlist/calsiumlist.component.model';
import { Observable, of, Subscription, combineLatest } from 'rxjs';
import { CalsiumNumberFormatterPipe } from '../../../shared/calsium-number.pipe';
import { MaterialStateService } from '../../../../state/material/service';
import { Material } from '../../../../state/material/state-models/material';
import { Paging, CommonStateService } from '../../../../state';
import { debounceTime, switchMap } from 'rxjs/operators';
import { TextAlignment } from '../../../shared/enums.model';
import { MaterialApiService } from 'src/api/services/material-api.service';

@Component({
    selector: 'cal-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.css']
})
export class MaterialComponent implements OnInit, OnDestroy {

    resources: any = this.commonService.getResources();
    showHint = true;
    dataList: Observable<Material[]>;
    materialListConfig: CalsiumListConfig;
    paging: Observable<Paging>;
    totalCount: Observable<number>;
    subscriptions = new Subscription();
    isLoading: Observable<boolean>;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;

    constructor(private materialService: MaterialStateService,
        private materialApiService: MaterialApiService,
        private commonService: CommonStateService) { }

    ngOnInit() {
        this.isLoading = this.commonService.getLoading();
        this.paging = this.materialService.getPaging();
        this.totalCount = this.materialService.getTotalCount();
        this.sortingExpression = this.materialService.getSortingExpression();
        this.filterExpression = this.materialService.getFilterExpression();

        this.subscriptions.add(
            combineLatest(this.sortingExpression, this.filterExpression, this.paging)
                .pipe(
                    debounceTime(0),
                    switchMap(([sort, filter, paging]) => {
                        return this.materialService.loadMaterials(paging, sort, filter);
                    })
                ).subscribe()
        );

        this.dataList = this.materialService.getMaterials();
        this.buildList();
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    buildList() {
        this.materialListConfig = new CalsiumListConfig();
        this.materialListConfig.ListCaption = this.resources.MaterialPageTitle;
        this.materialListConfig.ShowFilterRow = true;
        this.materialListConfig.ParentComponent = this;
        this.materialListConfig.ShowExportToExcel = true;

        let column = this.materialListConfig.AddListColumn(ColumnDataType.anchor, 'materialNumber', this.resources.MaterialNumber);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsAnchor = () => true;
        column.GetAnchorLink = (row: Material) => `/material/${row.id}`;

        column = this.materialListConfig.AddListColumn(ColumnDataType.string, 'name', this.resources.Description);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.materialListConfig.AddListColumn(ColumnDataType.list, 'materialSupplyTypeCode', this.resources.MaterialType);
        column.AllowFilter = true;
        column.Sortable = true;
        column.ColumnName = 'materialSupplyTypeId';
        column.DisplayColumnName = 'materialSupplyTypeCode';
        column.FilterOptions.AddEmptyDropDownListItem = false;
        column.FilterOptions.FilterListItems = of([
            CalsiumListSelectItem.getInstance('1', 'P'),
            CalsiumListSelectItem.getInstance('2', 'M'),
            CalsiumListSelectItem.getInstance('3', `*${this.resources.Empty}*`)
        ]);

        column = this.materialListConfig.AddListColumn(ColumnDataType.string, 'currentStandardPrice', this.resources.Price);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(4, 4);
        column.TextAlignment = TextAlignment.Right;

        column = this.materialListConfig.AddListColumn(ColumnDataType.number, 'currentManualPrice', this.resources.ManualPrice);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(4, 4);
        column.MaxNumberOfDecimalsAllowed = 4;
        column.MaxNumberOfIntegrsAllowed = 7;
        column.TextAlignment = TextAlignment.Right;

        column = this.materialListConfig.AddListColumn(ColumnDataType.number, 'currentStrategicPrice', this.resources.StrategicPrice);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(4, 4);
        column.MaxNumberOfDecimalsAllowed = 4;
        column.MaxNumberOfIntegrsAllowed = 7;
        column.TextAlignment = TextAlignment.Right;

        column = this.materialListConfig.AddListColumn(ColumnDataType.string, 'modifierComments', this.resources.ModifierComments);
        column.AllowFilter = true;
        column.Sortable = true;
        column.TextAlignment = TextAlignment.Right;        
    }

    onHintDismiss(): void {
        this.showHint = false;
    }

    onPaging(page: number) {
        this.materialService.goToPage(page);
    }

    onSort(sortExpression: string) {
        this.materialService.sort(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.materialService.filter(filterExpression);
    }

    onClearFilterAndSorting() {
        this.materialService.clearFilterAndSorting();
    }

    onExport() {
        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const fileName = `Material_Partlist_${formattedDate}`;        
        var order;
        var filter;
        this.materialService.getSortingExpression().subscribe(sortExpression=> order = sortExpression);
        this.materialService.getFilterExpression().subscribe(filterExpression=> filter = filterExpression);
        const payload = {            
            order: order,
            filter: filter
        };
        this.materialApiService.exportMaterialParts(fileName, payload);
    }
}
