<div class="card">
    <div class="card-header">
        <div class="row">
            <div [ngClass]="config.ShowExportToExcel? 'col-sm-6' : 'col-sm-12'">
                <h3 class="widget-header-title">{{ title }}</h3>
            </div>
            <div *ngIf="config.ShowExportToExcel" class="col-sm-6">
                <input type="button" class="btn btn-secondary btn-sm float-right" value="{{resources.ExportToExcel}}" 
                (click)="exportToExcel()" [disabled]="exportButtonState"/>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 property-title">{{resources.Value}}</div>
                <div class="col-sm-6 property-value">{{ value | CalsiumNumber:0:0 }} {{resources.DefaultCurrency}}</div>
            </div>
        </div>
        <hr>
        <div class="container">
            <div class="row">
                <div class="col-sm-6 property-title">{{resources.Volume}}</div>
                <div class="col-sm-6 property-value">{{ volume }} {{resources.Units}}</div>
            </div>
        </div>
    </div>
</div>