import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '../common';
import { AuthGuardService } from '../authentication';
import { PvaManualAdjustmentComponent } from './pages/pva-manual-adjustment/pva-manual-adjustment.component';

export const pvaManualAdjustmentRoutes: Routes = [
    {
        path: 'pva-cost',
        canActivate: [AuthGuardService],
        component: PvaManualAdjustmentComponent
    }
];

@NgModule({
    declarations: [PvaManualAdjustmentComponent],
    imports: [
        CommonModule,
        AngularCommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule
    ]
})
export class PvaManualAdjustmentModule { }
