<div class="panel">
  <div class="panel-body">
    <form [formGroup]="priceListForm">
      <cal-calsiumlist-listing [config]="productionPriceListConfig" [ListBodyData]="productionPriceLists | async"
        [isLoading]="isPriceListsLoading"  (exportToExcelTriggered)="onExport()" ></cal-calsiumlist-listing>
      <!-- <cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async"></cal-pagination> -->
    </form>
  </div>
</div>
<div class="results-panel" [ngClass]="{ loading: isResultsLoading }">
  <div class="row" *ngIf="selectedPriceList | async as priceList">
    <div class="col col-lg-3 col-md-6 col-xs-12" *ngFor="let priceCondition of priceList.productionPriceCondition">
      <div class="card card-info">
          <div class="card-header text-white bg-info" id="price-condition-description">{{priceCondition.description}}</div>
          <div class="card-body">
            <div class="container">
              <div class="row">
                <div class="col-sm">
                  <b>Condition:</b>
                  <table class="table table-sm table-striped" id="variant-options-table">
                      <thead>
                        <tr>
                          <th>Var. Family</th>
                          <th>Var. Option</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let conditionVariantFamily of priceCondition.productionPriceConditionVariantFamily">
                            <td>{{ conditionVariantFamily.variantFamily.value }}</td>
                            <td> * </td>
                        </tr>
                        <tr *ngFor="let conditionVariantOption of priceCondition.productionPriceConditionVariantOption">
                          <td>{{ conditionVariantOption.variantOption.variantFamily.value }}</td>
                          <td>{{ conditionVariantOption.variantOption.value }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div class="col-sm">
                  <b>Cost:</b>
                  <table class="table table-sm table-striped" id="condition-price-table">
                    <thead>
                      <tr>
                        <th>Price type</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let conditionPrice of priceCondition.productionPriceConditionPrice">
                          <td>{{ conditionPrice.productionPriceType.description }}</td>
                          <td>{{ conditionPrice.price }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </div>     
    </div>
  </div>
</div>