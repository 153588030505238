export interface ComponentAllocation {
    id: number;
    productIndividualId: number;
    componentTypeId: number;
    chassisNumber: string;
    popId: string;
    description: string;
    productionDate: Date;
    calculationCompleted: boolean;
    totalMaterialValue: number;
    totalProductionValue: number;
    totalValue: number;
    flagged: boolean;
}

export interface ComponentAllocationInput {
    productIndividualId: number;
    componentTypeId: number;
}
