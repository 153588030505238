import { Action } from '@ngrx/store';
import * as _ from 'lodash';

import { StepFaultSearchForm } from './state-models/step-fault-search-form';
import { StepFaultView } from './state-models/step-fault-view';

export enum OrchestratorActions {
  LoadStepFault = '[ Step Fault ] load FlowState',
  LoadProductIndividualStepFault = '[ Step Fault ] Load ProductIndividualFlowStepFault',
  ChangeProdIndStepFaultTotalCountAction = '[ Step Fault ] Change ProductIndividualFlowStepFault count',
  ChangeStepFaultPageAction = '[ Step Fault ] Change Page',
  ChangeStepFaultResultsTotalCountAction = '[ Step Fault ] Change Results Count',
  ChangeStepFaultSortExpressionAction = '[ Step Fault ] Change Sort Expression',
  ChangeStepFaultFilterExpressionAction = '[ Step Fault ] Change Filter Expression',
  ChangeStepFaultSearchForm = '[ Step Fault ] Change Search Form'
}

export class LoadStepFaultAction implements Action {
    readonly type = OrchestratorActions.LoadStepFault;
    constructor(public payload: StepFaultView[]) { }
}

export class LoadProductIndividualStepFault implements Action {
    readonly type = OrchestratorActions.LoadProductIndividualStepFault;
    constructor(public payload: StepFaultView[]) { }
}

export class ChangeStepFaultPageAction implements Action {
    readonly type = OrchestratorActions.ChangeStepFaultPageAction;
    constructor(public payload: number) { }
}

export class ChangeStepFaultResultsTotalCountAction implements Action {
    readonly type = OrchestratorActions.ChangeStepFaultResultsTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangeProdIndStepFaultTotalCountAction implements Action {
    readonly type = OrchestratorActions.ChangeProdIndStepFaultTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangeStepFaultSortExpressionAction implements Action {
    readonly type = OrchestratorActions.ChangeStepFaultSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeStepFaultFilterExpressionAction implements Action {
    readonly type = OrchestratorActions.ChangeStepFaultFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeStepFaultSearchForm implements Action {
    readonly type = OrchestratorActions.ChangeStepFaultSearchForm;
    constructor(public payload: StepFaultSearchForm) { }
}
export type OrchestratorAction =
  | LoadStepFaultAction
  | LoadProductIndividualStepFault
  | ChangeProdIndStepFaultTotalCountAction
  | ChangeStepFaultPageAction
  | ChangeStepFaultResultsTotalCountAction
  | ChangeStepFaultSortExpressionAction
  | ChangeStepFaultFilterExpressionAction
  | ChangeStepFaultSearchForm;
