    <ng-container *ngFor="let menu of menuItems">
        <a [id]="menu.id"
            class='parent'
            slot="primary-items"
            [routerLink]="menu.url"
            [attr.active]="isActive(menu) ? 'active' : null"
            (click)="onMenuClicked(null, menu, $event)">
            {{menu.title}}
        </a>
        <c-navigation slot="sub" [caption]="menu.title" 
            [attr.active]="isActive(menu) ? 'active' : null" [target]="menu.url">
            <a *ngFor="let subMenu of menu.subItems"
                slot="primary-items"
                [routerLink]="subMenu.url"
                [id]="subMenu.id" 
                (click)="onMenuClicked(menu, subMenu, $event)" [attr.class]="isActive(subMenu) ? 'active' : ''">{{subMenu.title}}</a>
        </c-navigation>
    </ng-container>
    
    <div slot="secondary-items">
        <cal-product-search></cal-product-search>
    </div>  
    <div slot="secondary-items">
        <a id="userMenu" (click)="userInfoClicked()" routerLink="">
            <i class="far fa-user-circle"></i> {{profile?.sub}}
        </a>
    </div>