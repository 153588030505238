import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';
import { ScaniaUnit } from '../../state';

@Injectable({
    providedIn: 'root'
})
export class ScaniaUnitApiService {
    constructor(private apollo: Apollo) { }

    getAllScaniaUnits(): Promise<ScaniaUnit[]> {
        const result$ =  this.apollo
          .query<ScaniaUnit[]>({
              query: gql`
              {
                scaniaunits {
                  get {
                    list {
                      id
                      name
                      description
                      scaniaUnitTypes
                    }
                  }
                }
              }`
          })
          .pipe<ScaniaUnit[]>(
            
            map((result: any) => {
              return result?.data?.scaniaunits?.get?.list;
              })
          )
          return lastValueFrom(result$);
    }
}
