import { CurrencyAdjustmentState } from './state';
import { CurrencyAdjustmentAction, CurrencyAdjustmentActions } from './actions';

export const CurrencyAdjustmentInitialState = require('./initial-data.json') as CurrencyAdjustmentState;

export function CurrencyAdjustmentReducer(
    state: CurrencyAdjustmentState = CurrencyAdjustmentInitialState,
    action: CurrencyAdjustmentAction
): CurrencyAdjustmentState {
    switch (action.type) {
        case CurrencyAdjustmentActions.LoadCodeListAction:
            return {
                ...state,
                codeList: action.payload
            };
        
        case CurrencyAdjustmentActions.AddCodeAction:
            return {
                ...state,
                codeList: [...state.codeList, action.payload]
            }
        case CurrencyAdjustmentActions.ChangeCodeListFilterExpressionAction:
            return {
                ...state,
                codeListFilterExpression: action.payload
            };
        case CurrencyAdjustmentActions.ChangeCodeListSortExpressionAction:
            return {
                ...state,
                codeListSortExpression: action.payload
            };
        case CurrencyAdjustmentActions.AddPercentageAction:
            return {
                ...state,
                percentageList: [...state.percentageList, action.payload]
            }
        case CurrencyAdjustmentActions.LoadPercentagesAction:
            return {
                ...state,
                percentageList: action.payload
            }
        case CurrencyAdjustmentActions.ChangePercentagesFilterExpressionAction:
            return {
                ...state,
                percentageFilterExpression: action.payload
            };
        case CurrencyAdjustmentActions.ChangePercentagesSortExpressionAction:
            return {
                ...state,
                percentageSortExpression: action.payload
            };
        case CurrencyAdjustmentActions.DeleteCodeAction:
            let codeIndex = state.codeList.findIndex(x => x.id === action.payload);
            return {
                ...state,
                codeList: [...state.codeList.slice(0, codeIndex),
                ...state.codeList.slice(codeIndex + 1)]
            }
        case CurrencyAdjustmentActions.LoadConditionsAction:
            return {
                ...state,
                conditionList: action.payload
            };
        case CurrencyAdjustmentActions.ChangeConditionsFilterExpressionAction:
            return {
                ...state,
                conditionFilterExpression: action.payload
            };
        case CurrencyAdjustmentActions.ChangeConditionsSortExpressionAction:
            return {
                ...state,
                conditionSortExpression: action.payload
            };
        case CurrencyAdjustmentActions.LoadVariantFamilyAction:
            return {
                ...state,
                variantFamilyList: action.payload
            };
        case CurrencyAdjustmentActions.LoadVariantOptionsAction:
            return {
                ...state,
                variantOptionsList: action.payload
            };

        case CurrencyAdjustmentActions.LoadProductClassAction:
            return {
                ...state,
                productClassList: action.payload
            }
        case CurrencyAdjustmentActions.LoadMarketAction:
            return {
                ...state,
                marketList: action.payload
            }
        case CurrencyAdjustmentActions.AddConditionAction:
            return {
                ...state,
                conditionList: [...state.conditionList, action.payload]
            }
        case CurrencyAdjustmentActions.AddConditionVariantAction:
            let conditionIndex = state.conditionList.findIndex(x => x.id === action.payload.currencyAdjustmentConditionId);
            return {
                ...state,
                conditionList: [...state.conditionList.slice(0, conditionIndex),
                {
                    ...state.conditionList[conditionIndex],
                    variantOptions: [...state.conditionList[conditionIndex].variantOptions, action.payload]
                },
                ...state.conditionList.slice(conditionIndex + 1)]
            };
        case CurrencyAdjustmentActions.RemoveConditionVariantAction:
            let deleteConditionIndex = state.conditionList.findIndex(x => x.id === action.payload.conditionId);
            let variantIndex = state.conditionList[deleteConditionIndex].variantOptions.findIndex(x => x.id === action.payload.conditionVariantId);
            return {
                ...state,
                conditionList: [...state.conditionList.slice(0, deleteConditionIndex),
                {
                    ...state.conditionList[deleteConditionIndex],
                    variantOptions: [
                        ...state.conditionList[deleteConditionIndex].variantOptions.slice(0, variantIndex),
                        ...state.conditionList[deleteConditionIndex].variantOptions.slice(variantIndex + 1)
                    ]
                },
                ...state.conditionList.slice(deleteConditionIndex + 1)]
            }
        case CurrencyAdjustmentActions.SaveConditionAction:
            let conditionsArr = state.conditionList.filter(x => x.isNew !== true);
            return {
                ...state,
                conditionList: [...conditionsArr, ...action.payload]
            }
        case CurrencyAdjustmentActions.UpdateCodeAction:
            let updateCodeIndex = state.codeList.findIndex(x => x.id === action.payload.id);
            return {
                ...state,
                codeList: [...state.codeList.slice(0, updateCodeIndex),
                action.payload,
                ...state.codeList.slice(updateCodeIndex + 1)]
            }
        case CurrencyAdjustmentActions.ChangeCodeShowOnlyValidCodesAction:
                return{
                    ...state,
                    showOnlyValidCodes: action.payload
                }
        case CurrencyAdjustmentActions.ChangeConditionsShowOnlyValidConditionsAction:
                    return{
                        ...state,
                        showOnlyValidConditions: action.payload
                    }

        // case CurrencyAdjustmentActions.UpdateConditionsAction:
        //     let updateCondtionsIndex = state.conditionList.findIndex(x => x.id === action.payload.id);
        //     return {
        //         ...state,
        //         conditionList: [...state.conditionList.slice(0, updateCondtionsIndex),
        //         action.payload,
        //         ...state.conditionList.slice(updateCondtionsIndex + 1)]
        //     }
                
        default:
            return state;
    }
}
