import { Action } from '@ngrx/store';
import { CPAItem } from './state-models/cpa-item';

export enum CPAReportActions {
    LoadCPAListAction = '[ CPA Report ] load cpa list'
}

export class LoadCPAListAction implements Action {
    readonly type = CPAReportActions.LoadCPAListAction;
    constructor(public payload: CPAItem[]) { }
}

export type CPAReportAction =
    | LoadCPAListAction
   ;
