<div class="alert alert-info alert-dismissible fade show" role="alert" *ngIf="showHint">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onHintDismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <p><b>{{ resources.Info }}:</b></p>
    <div [innerHTML]="resources.ProductClassComponentUnitInformationBox | sanitizeHtml"></div>
</div>

<div class="card">
    <h3 class="card-header">{{resources.AddProductClassComponentUnit}}</h3>
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="form-group col-md-2">
                    <label>{{resources.ComponentUnitCode}}</label>
                    <div class="input-group">
                        <mat-form-field appearance="outline">
                            <input matInput type="text" [formControl]="componentUnitCode" required
                                style="resize: none;" required placeholder="ex. 1234">
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>{{resources.ComponentUnitDescription}}</label>
                    <div class="input-group">
                        <mat-form-field appearance="outline">
                            <input matInput type="text" [formControl]="componentUnitDescription" required
                                style="resize: none;" required placeholder="(Optional)">
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>{{resources.ProductClass}}</label>
                    <div class="input-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-select [formControl]="productClass" multiple disableOptionCentering #productClassSelector placeholder="Select Product Class">
                                <mat-option *ngFor="let item of productClassList " (click)="onProductClassClick()" [value]="item.id">
                                    {{item.variantOption.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>{{resources.ComponentType}}</label>
                    <div class="input-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-select [formControl]="componentType"  placeholder="Select Component Type">
                                <mat-option *ngFor="let item of componentTypeList " [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>&nbsp;</label>
                    <button mat-raised-button type="button" class="btn btn-sm btn-primary form-control"
                        id="saveDeliveryRoute" (click)="onSave()">
                        <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
                    </button>
                </div>
                <div class="form-group col-md-2">
                    <label>&nbsp;</label>
                    <button mat-raised-button type="button" class="btn btn-sm btn-primary form-control"
                        id="saveDeliveryRoute" (click)="onCancel()">
                        <i class="fa fa-lg fa-times" aria-hidden="true"></i>&nbsp; {{resources.Cancel}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<br />