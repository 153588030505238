import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { ComponentIndividual } from '../flow/state-models/component-individual';
import { ComponentIndividualMaterial } from './state-models/component-individual-material';
import { ProductSpecification } from './state-models/product-specification';

export enum ComponentDetailsActions {
    LoadComponentDetailsAction = '[ ComponentDetails ] load component',
    LoadComponentDetailsMaterialsAction = '[ ComponentDetails ] load Materials',
    ChangeComponentMaterialSortExpressionAction = '[ Component Details ] Change Sort Expression',
    ChangeComponentMaterialFilterExpressionAction = '[ Component Details ] Change Filter Expression',
    ChangeComponentMaterialTotalCountAction = '[ Component Details ] Change Material Count',
    ChangeComponentMaterialPageAction = '[ Component Details ] Change Material Page',
    ChangeComponentMaterialShowZeroQuantityPartsAction = '[ Component Details ] Change Show Zero Quantity Parts',

    LoadComponentDetailsSpecificationsAction = '[ ComponentDetails ] load Materials Specifications',
    ChangeComponentSpecificationsSortExpressionAction = '[ Component Details ] Change Specifications Sort Expression',
    ChangeComponentSpecificationsFilterExpressionAction = '[ Component Details ] Change Specifications Filter Expression',
    ChangeComponentSpecificationsTotalCountAction = '[ Component Details ] Change Material Specifications Count',
    ChangeComponentSpecificationsPageAction = '[ Component Details ] Change Material Specifications Page'
}

export class LoadComponentDetailsAction implements Action {
    readonly type = ComponentDetailsActions.LoadComponentDetailsAction;
    constructor(public payload: ComponentIndividual) { }
}

export class LoadComponentDetailsMaterialsAction implements Action {
    readonly type = ComponentDetailsActions.LoadComponentDetailsMaterialsAction;
    constructor(public payload: ComponentIndividualMaterial[]) { }
}

export class ChangeComponentMaterialSortExpressionAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentMaterialSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeComponentMaterialFilterExpressionAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentMaterialFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeComponentMaterialTotalCountAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentMaterialTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangeComponentMaterialPageAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentMaterialPageAction;
    constructor(public payload: number) { }
}
export class LoadComponentDetailsSpecificationsAction implements Action {
    readonly type = ComponentDetailsActions.LoadComponentDetailsSpecificationsAction;
    constructor(public payload: ProductSpecification[]) { }
}

export class ChangeComponentSpecificationsSortExpressionAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentSpecificationsSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeComponentSpecificationsFilterExpressionAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentSpecificationsFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeComponentSpecificationsTotalCountAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentSpecificationsTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangeComponentSpecificationsPageAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentSpecificationsPageAction;
    constructor(public payload: number) { }
}

export class ChangeComponentMaterialShowZeroQuantityPartsAction implements Action {
    readonly type = ComponentDetailsActions.ChangeComponentMaterialShowZeroQuantityPartsAction;
    constructor(public payload: boolean) { }
}
export type ComponentDetailsAction =
    | LoadComponentDetailsAction
    | ChangeComponentMaterialSortExpressionAction
    | ChangeComponentMaterialFilterExpressionAction
    | ChangeComponentMaterialTotalCountAction
    | ChangeComponentMaterialPageAction
    | ChangeComponentMaterialShowZeroQuantityPartsAction
    | LoadComponentDetailsMaterialsAction
    | LoadComponentDetailsSpecificationsAction
    | ChangeComponentSpecificationsFilterExpressionAction
    | ChangeComponentSpecificationsPageAction
    | ChangeComponentSpecificationsSortExpressionAction
    | ChangeComponentSpecificationsTotalCountAction;
