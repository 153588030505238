import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';
import { ConsumingPlanningAreaMapping } from '../../state/administraion/consuming-planning-area-mapping/state-models/consuming-planning-area-mapping';
import * as cpaReportConfig from '../../assets/config/cpa-report.config.js';
import { CPAItem } from 'src/state/cpa-report/state-models/cpa-item';
import { CommonApiService } from '../common.service';

@Injectable({
    providedIn: 'root'
})
export class ConsumingPlanningAreaMappingApiService {
    constructor(private apollo: Apollo, private commonService: CommonApiService) { }

    private getConsumingPlanningAreaMappingsQuery(): any {
        return gql`
        query consumingPlanningAreaMapping ($paging: Paging, $order:String, $filter:String) {
          consumingPlanningAreaMapping {
            search (paging: $paging, order: $order, filter: $filter) {
              list {
                id
                from
                to
                validFrom
                validTo
              }
              totalCount
            }
          }
        }`;
    }

    getConsumingPlanningAreaMappings(variables?): Promise<any> {
        const result$ = this.apollo
      .query<ConsumingPlanningAreaMapping[]>({
          variables,
          query: this.getConsumingPlanningAreaMappingsQuery(),
          fetchPolicy: 'no-cache'
      })
      .pipe<ConsumingPlanningAreaMapping[]>(
       map((result: any) => {
        return result?.data?.consumingPlanningAreaMapping?.search;
        })
      )
      return lastValueFrom(result$);
    }

    async addConsumingPlanningAreaMapping(payload: ConsumingPlanningAreaMapping): Promise<any> {
        const mutation = `
        mutation ($payload: CpaMappingInput!) {
          cpaMappingMutation {
            addCpaMapping(payload: $payload)
          }
        }`;

        return this.apollo.mutate({
            mutation: gql(mutation),
            variables: { payload }
        })
      .toPromise();
    }

    getConsumingPlanningAreaList(): Promise<CPAItem[]> {
        const result$ = this.apollo
      .query<CPAItem[]>({
          query: gql`
            {
              consumingPlanningAreaMapping {
                list {
                  to
                }
              }
            }`
      })
      .pipe<CPAItem[]>(
       map((result: any) => {
        return result?.data?.consumingPlanningAreaMapping?.list;
        })
      )
      return lastValueFrom(result$);

    }

    exportConsumingPlanningAreaReport(fileName: string, cpa: string): Promise<any> {
        const query = gql`
    query cpaExportQuery($cpa: String) {
      consumingPlanningAreaMappingReport {
            search(cpa:$cpa) {
                ${this.queryProperties(cpa)}
              }
            }
      }`;
        return this.commonService.exportQuery(query, {
            cpa
        }, fileName, true);
    }

    queryProperties(scaniaUnitName: string): string {
        let config = cpaReportConfig.find(config =>
      config.CPAUnits !== null && config.CPAUnits.indexOf(scaniaUnitName) >= 0
    );
        if (!config) {
            config = cpaReportConfig.find(config => config.CPAUnits === null);
        }
        return config.Fields;
    }
}
