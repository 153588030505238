import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';

import { AuthGuardService } from '../authentication';
import { CommonModule } from '../common';
import { OverviewComponent } from './pages/overview/overview.component';
import { FinalAssemblyComponent } from './pages/final-assembly/final-assembly.component';
import { ConsolidationUnitComponent } from './pages/consolidation/consolidation-unit.component';
import { ComponentComponent } from './pages/component/component.component';
import { WidgetComponent } from './shared/widget/widget.component';
import { AllocationComponentListComponent } from './pages/component/component-list/component-list.component';
import { FinalAssemblyAllocationComponentListComponent } from './pages/final-assembly/list/final-assembly-component-list.component';
import { ConsolidationUnitAllocationComponentListComponent } from './pages/consolidation/list/consolidation-unit-component-list.component';
import { CalculationDetailsComponent } from './pages/calculation-details/calculation-details.component';
import { FlowModule } from '../flow/flow.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

export const allocationRoutes: Routes = [
    { path: '',
    redirectTo: 'allocate',
    pathMatch: 'full' },
    {
        path: 'allocate',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: OverviewComponent
            },
            {
                path: 'component',
                component: ComponentComponent
            },
            {
                path: 'finalassembly',
                component: FinalAssemblyComponent
            },
            {
                path: 'consolidation',
                component: ConsolidationUnitComponent
            }
        ]
    },
    {
        path: 'calculation-details/:productIndividualId/:allocationScaniaUnitId',
        canActivate: [AuthGuardService],
        component: CalculationDetailsComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        AngularCommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatDialogModule,
        MatTabsModule,
        MatExpansionModule,
        FlowModule,
        MatTooltipModule,
        MatCheckboxModule
    ],
    declarations: [
        OverviewComponent,
        FinalAssemblyComponent,
        ConsolidationUnitComponent,
        ComponentComponent,
        WidgetComponent,
        AllocationComponentListComponent,
        FinalAssemblyAllocationComponentListComponent,
        ConsolidationUnitAllocationComponentListComponent,
        CalculationDetailsComponent
    ]
})
export class AllocationModule { }
