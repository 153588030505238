import { Component, OnInit, Input } from '@angular/core';
import { Material } from '../../../../../state';

@Component({
    selector: 'cal-material-information',
    templateUrl: './material-information.component.html',
    styleUrls: ['./material-information.component.css']
})
export class MaterialInformationComponent implements OnInit {

    @Input() material: Material;
    @Input() resources: any;

    constructor() { }

    ngOnInit() {
    }

}
