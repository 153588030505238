<cal-add-currency-adjustment-code></cal-add-currency-adjustment-code>
<cal-add-currency-adjustment-percentage></cal-add-currency-adjustment-percentage>


<div class="panel" style="margin-top: 5px;">
   <div class="quantity-filter">
      <mat-checkbox (change)="onShowOnlyValidCodes($event.checked)" [checked]="showOnlyValidCodes | async" #myCheckbox>
          {{resources.ShowValidCodes}}
      </mat-checkbox>
   </div>
   <div class="panel-body">

 
      <cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="dataList | async" (sortTriggered)="onSort($event)"
         (filterTriggered)="onFilter($event)" (clearFilterAndSortingTriggered)="onClearFilterAndSorting()"
         [isLoading]="isLoading | async" [filterExpression]="filterExpression | async"
         [dataTotalCount]="(dataList | async).length">
      </cal-calsiumlist-listing>
   </div>
</div>