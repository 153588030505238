import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { Material, MaterialPriceType, MaterialPrice } from '..';

export enum MaterialActions {
  loadMaterials = '[ Material ] Load material list',
  loadMaterial = '[ Material ] Load material',
  loadMaterialPriceType = '[Material price] Load material price types',
  updatPrice = '[ Material ] Update price',
  changePageAction = '[ Material ] Change Page',
  changeResultsTotalCountAction = '[ Material ] Change Results Count',
  changeSortExpressionAction = '[ Material ] Change Sort Expression',
  changeFilterExpressionAction = '[ Material ] Change Filter Expression',
  addMaterialPriceAction = '[ Material ] Add Price',
  changeMaterialPricesSortExpressionAction = '[ Material Prices ] Change Sort Expression',
  changeMaterialPricesFilterExpressionAction = '[ Material Prices ] Change Filter Expression',
}

export class LoadMaterialsAction implements Action {
    readonly type = MaterialActions.loadMaterials;
    constructor(public payload: Material[]) { }
}
export class LoadMaterialAction implements Action {
    readonly type = MaterialActions.loadMaterial;
    constructor(public payload: Material) { }
}
export class LoadMaterialPriceTypesAction implements Action {
    readonly type = MaterialActions.loadMaterialPriceType;
    constructor(public payload: MaterialPriceType[]) { }
}
export class UpdatPriceAction implements Action {
    readonly type = MaterialActions.updatPrice;
    constructor(public payload: Material) { }
}

export class ChangePageAction implements Action {
    readonly type = MaterialActions.changePageAction;
    constructor(public payload: number) { }
}

export class ChangeResultsTotalCountAction implements Action {
    readonly type = MaterialActions.changeResultsTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangeSortExpressionAction implements Action {
    readonly type = MaterialActions.changeSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeFilterExpressionAction implements Action {
    readonly type = MaterialActions.changeFilterExpressionAction;
    constructor(public payload: string) { }
}

export class AddMaterialPriceAction implements Action {
    readonly type = MaterialActions.addMaterialPriceAction;
    constructor(public payload: MaterialPrice[]) { }
}

export class ChangeMaterialPricesSortExpressionAction implements Action {
    readonly type = MaterialActions.changeMaterialPricesSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeMaterialPricesFilterExpressionAction implements Action {
    readonly type = MaterialActions.changeMaterialPricesFilterExpressionAction;
    constructor(public payload: string) { }
}

export type MaterialAction =
  | LoadMaterialsAction
  | LoadMaterialAction
  | LoadMaterialPriceTypesAction
  | UpdatPriceAction
  | ChangePageAction
  | ChangeResultsTotalCountAction
  | ChangeSortExpressionAction
  | ChangeFilterExpressionAction
  | AddMaterialPriceAction
  | ChangeMaterialPricesFilterExpressionAction
  | ChangeMaterialPricesSortExpressionAction;
