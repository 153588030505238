import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription, combineLatest, of, ReplaySubject } from 'rxjs';
import { CommonStateService, Paging } from 'src/state';
import { CalsiumListConfig, ColumnDataType, CalsiumListColumnItem } from 'src/app/calsiumlist/calsiumlist.component.model';
import { CalsiumNumberFormatterPipe } from 'src/app/shared/calsium-number.pipe';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { TextAlignment, ErrorTypeEnum } from 'src/app/shared/enums.model';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';

import { ConsolidationUnitAllocation } from 'src/state/consolidation-unit-allocation/state-models/consolidation-unit-allocation';
import { AllocationSearchForm } from 'src/state/consolidation-unit-allocation/state-models/allocation-search-form';
import { ConsolidationUnitAllocationStateService } from 'src/state/consolidation-unit-allocation/service';
import { AllocationUnitColumns } from 'src/state/consolidation-unit-allocation/state-models/allocation-unit-columns';
import { CalculationDetailsComponent } from '../../calculation-details/calculation-details.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogService } from 'src/app/common/confirmation-dialog/confirmation-dialog.service';
import { OrchestratorStateService } from 'src/state/orchestrator/service';

@Component({
    selector: "cal-consolidation-unit-allocation-component-list",
    templateUrl: "./consolidation-unit-component-list.component.html",
    styleUrls: ["./consolidation-unit-component-list.component.css"],
})
export class ConsolidationUnitAllocationComponentListComponent
    implements OnInit, OnDestroy {
    @Input() allocationStatus: string;
    @Input()
    set searchExpression(value: string) {
        if (this.prevSearchExpression !== value) {
            this.prevSearchExpression = value;
            this.onSelectAllChange(false);
            if (this.selectorColumn) this.selectorColumn.SelectorValue = false;
        }
    }
    @Input() populateListName: string;
    componentType: string;
    prevSearchExpression: string;
    resources: any = this.commonService.getResources();
    dataList: Observable<ConsolidationUnitAllocation[]>;
    listConfig: CalsiumListConfig;
    paging: Observable<Paging>;
    totalCount: Observable<number>;
    errorCount: Observable<number>;
    subscriptions = new Subscription();
    isLoading: Observable<boolean>;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    componentSearchForm: Observable<AllocationSearchForm>;
    selectedCount: Observable<number> = of(0);
    selectedCountValue: number = 0;
    selectAll: boolean = false;
    selectedItems: number[] = [];
    excludedItems: number[] = [];
    selectorColumn: CalsiumListColumnItem;
    columnsConfigration: Observable<AllocationUnitColumns[]>;
    currentColumns: Observable<string[]> = of([]);
    actionMenuEnabled = true;
    recalculationTriggered = new ReplaySubject<void>();

    constructor(
        private consolidationUnitAllocationService: ConsolidationUnitAllocationStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private orchestratorService: OrchestratorStateService,
        private confirmationDialog: ConfirmationDialogService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.componentSearchForm =
            this.consolidationUnitAllocationService.getComponentSearchForm();
        this.sortingExpression =
            this.consolidationUnitAllocationService.getSortingExpression(
                this.allocationStatus
            );
        this.filterExpression =
            this.consolidationUnitAllocationService.getFilterExpression(
                this.allocationStatus
            );
        this.totalCount = this.consolidationUnitAllocationService.getTotalCount(
            this.allocationStatus
        );
        this.errorCount = this.consolidationUnitAllocationService.getErrorCount(
            this.allocationStatus
        );
        this.paging = this.consolidationUnitAllocationService.getPaging(
            this.allocationStatus
        );
        this.dataList = this.consolidationUnitAllocationService.getList(
            this.allocationStatus
        );
        this.columnsConfigration =
            this.consolidationUnitAllocationService.getAllocationUnitColumnsConfigration();
        this.currentColumns =
            this.consolidationUnitAllocationService.getCurrentUnitColumns();
        this.recalculationTriggered.next();

        this.subscriptions.add(
            combineLatest(
                this.componentSearchForm,
                this.paging,
                this.sortingExpression,
                this.filterExpression,
                this.columnsConfigration,
                this.recalculationTriggered
            )
                .pipe(
                    debounceTime(0),
                    switchMap(
                        ([
                            componentSearchForm,
                            paging,
                            sortingExpression,
                            filterExpression,
                            columnsConfigration,
                            _,
                        ]) => {
                            if (componentSearchForm != null && this.allocationStatus == this.populateListName) {
                                return this.consolidationUnitAllocationService.loadConsolidationUnitAllocationList(
                                    this.allocationStatus,
                                    componentSearchForm.selectedScaniaUnitId,
                                    componentSearchForm.fromDate,
                                    componentSearchForm.toDate,
                                    paging,
                                    sortingExpression,
                                    filterExpression,
                                    columnsConfigration,
                                    true
                                );
                            }
                            return new Promise<void>(null);
                        }
                    )
                )
                .subscribe()
        );
        this.buildList();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.List;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.IsSelectorEnabled = _ => true;
        this.listConfig.IsSelected = this.isSelected;

        let column;
        if (this.allocationStatus === "produced") {
            this.selectorColumn = this.listConfig.AddListColumn(
                ColumnDataType.selector,
                null,
                null
            );
            this.selectorColumn.TextAlignment = TextAlignment.Center;
        }

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "chassisNumber",
            this.resources.ChassisNumber
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "serialNumber",
            this.resources.SerialNumber
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "productIndividualNumber",
            this.resources.ProductIndividual
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "countryCode",
            this.resources.CountryCode
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "chassisType",
            this.resources.ChassisType
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "productionDate",
            this.resources.ProductionDate
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "invoiceDate",
            this.resources.InvoiceDate
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(
            ColumnDataType.boolean,
            "fullyAllocated",
            this.resources.AllocationStatus
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.TextAlignment = TextAlignment.Center;
        column.FilterBooleanTrueText = this.resources.Completed;
        column.FilterBooleanFalseText = this.resources.NotCompleted;

        column = this.listConfig.AddListColumn(
            ColumnDataType.number,
            "finalAssemblyComponentDeliveryValue",
            null
        );
        column.DynamicCaption = this.dataList.pipe(
            map((x) =>
                x && x.length ? x[0].finalAssemblyComponentTypeName : null
            )
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(
            0,
            0
        );
        column.TextAlignment = TextAlignment.Right;
        column.IsVisible = this.dataList.pipe(
            map((x) => (x ? x.length : false))
        );

        const captilize = (str) => str[0].toUpperCase() + str.slice(1);
        [
            "axle",
            "batteryVehicle",
            "cab",
            "engineVehicle",
            "gearboxVehicle",
            "gosParts",
            "painted",
            "spare",
            "electricPropVehicle"
        ].forEach((name) => {
            column = this.listConfig.AddListColumn(
                ColumnDataType.number,
                name,
                this.resources[captilize(name)]
            );
            column.AllowFilter = true;
            column.Sortable = true;
            column.DisplayPipe =
                new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
            column.TextAlignment = TextAlignment.Right;
            column.IsVisible = this.isColumnVisible(name);
        });

        column = this.listConfig.AddListColumn(
            ColumnDataType.number,
            "totalValue",
            this.resources.TotalValue
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(
            0,
            0
        );
        column.TextAlignment = TextAlignment.Right;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.details, "", "");
        column.AllowFilter = false;
        column.Sortable = false;
        column.IsClickable = (_) => true;
        column.OnClick = this.viewCalculationDetails;
        column.GetAnchorLink = (RowData) =>
            `/calculation-details/${RowData.productIndividualId}/0`;
    }

    viewCalculationDetails(RowData) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            productIndividualId: RowData.productIndividualId,
        };
        dialogConfig.height = "80%";
        dialogConfig.width = "80%";

        this.dialog.open(CalculationDetailsComponent, dialogConfig);
    }

    isColumnVisible(columnName: string): Observable<boolean> {
        return this.currentColumns.pipe(
            map((x) => {
                return x.indexOf(columnName) >= 0;
            })
        );
    }

    onPaging(page: number) {
        this.consolidationUnitAllocationService.goToPage(
            this.allocationStatus,
            page
        );
    }

    onSort(sortExpression: string) {
        this.consolidationUnitAllocationService.sort(
            this.allocationStatus,
            sortExpression
        );
    }

    onFilter(filterExpression: string) {
        this.consolidationUnitAllocationService.filter(
            this.allocationStatus,
            filterExpression
        );
    }

    onClearFilterAndSorting() {
        this.consolidationUnitAllocationService.clearFilterAndSorting(
            this.allocationStatus
        );
    }

    onSelectAllChange(select: boolean) {
        this.selectAll = select;
        if (select) {
            combineLatest(this.totalCount, (totalCount) => {
                this.selectedCount = of(totalCount);
            })
                .subscribe()
                .unsubscribe();
        } else {
            this.selectedCount = of(0);
        }
        this.subscriptions.add(
            this.selectedCount.subscribe(
                (value) => (this.selectedCountValue = value)
            )
        );
        this.selectedItems = [];
        this.excludedItems = [];
    }

    onRowSelectionChange(rowSelectionData: {
        selected: boolean;
        rowdata: ConsolidationUnitAllocation;
    }) {
        if (this.selectAll) {
            if (!rowSelectionData.selected) {
                this.excludedItems.push(rowSelectionData.rowdata.id);
            } else if (rowSelectionData.selected) {
                this.excludedItems = this.excludedItems.filter(
                    (x) => x !== rowSelectionData.rowdata.id
                );
            }
        } else {
            if (rowSelectionData.selected) {
                this.selectedItems.push(rowSelectionData.rowdata.id);
            } else if (!rowSelectionData.selected) {
                this.selectedItems = this.selectedItems.filter(
                    (x) => x !== rowSelectionData.rowdata.id
                );
            }
        }
        const selectionChange = rowSelectionData.selected ? 1 : -1;
        this.selectedCount = combineLatest(
            this.selectedCount,
            (selectedCount) => selectedCount + selectionChange
        );
        this.subscriptions.add(
            this.selectedCount.subscribe(
                (value) => (this.selectedCountValue = value)
            )
        );
    }

    onExport() {
        this.consolidationUnitAllocationService.exportAllocationData(
            this.allocationStatus
        );
    }

    isSelected(data: ConsolidationUnitAllocation): boolean {
        const selected =
            (data.fullyAllocated && this.selectAll) ||
            this.selectedItems.findIndex((x) => x === data.id) >= 0;
        return selected;
    }

    async allocate(preview: boolean) {
        if (this.selectAll || this.selectedItems.length > 0) {
            if (!preview) {
                const result = await this.confirmationDialog.show(
                    this.resources.AllocateConfirmation.formatUnicorn(
                        this.selectedCountValue,
                        this.resources.product_s
                    ) +
                    this.resources
                        .AllocateProductConfirmationNotCompletedSelected,
                    this.resources.AllocateSelected
                );
                if (!result) {
                    return;
                }
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(
                this.componentSearchForm,
                this.filterExpression,
                this.columnsConfigration,
                (searchForm, filter, columnsConfigration) => {
                    this.consolidationUnitAllocationService
                        .allocate(
                            searchForm.selectedScaniaUnitId,
                            searchForm.fromDate,
                            searchForm.toDate,
                            filter,
                            this.selectAll,
                            this.selectedItems,
                            this.excludedItems,
                            columnsConfigration,
                            preview,
                            this.populateListName
                        )
                        .then(
                            (data) => {
                                this.actionMenuEnabled = true;
                                this.onSelectAllChange(false);
                                if (data.errors) {
                                    this.errHandler.emitError(
                                        this.resources.AllocateError,
                                        this.resources.Error,
                                        ErrorTypeEnum.Error
                                    );
                                } else {
                                    if (data.success > 0) {
                                        this.errHandler.emitError(
                                            preview
                                                ? this.resources.PreviewFileCreatedSuccessfully.formatUnicorn(
                                                    data.success
                                                )
                                                : this.resources.AllocatedSuccessfully.formatUnicorn(
                                                    data.success
                                                ),
                                            this.resources.Success,
                                            ErrorTypeEnum.Success
                                        );
                                    } else {
                                        this.errHandler.emitError(
                                            this.resources.NotAllocated,
                                            this.resources.Info,
                                            ErrorTypeEnum.Info
                                        );
                                    }
                                }
                            },
                            (error) => {
                                this.actionMenuEnabled = true;
                                this.errHandler.emitError(
                                    this.resources.AllocateError,
                                    this.resources.Error,
                                    ErrorTypeEnum.Error
                                );
                            }
                        );
                }
            ).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(
                this.resources.SelectProduct,
                this.resources.NoProductsSelected,
                ErrorTypeEnum.Info
            );
        }
    }

    async recalculateMarkup(allocationStatus: string) {

        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.RecalculateConfirmation.formatUnicorn(
                    this.selectedCountValue,
                    this.resources.product_s
                ),
                this.resources.Recalculate.formatUnicorn(
                    this.resources.product_s
                )
            );
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(
                this.componentSearchForm,
                this.filterExpression,
                (searchForm, filter) => {
                    this.orchestratorService
                        .recalculateMarkups(
                            searchForm.selectedScaniaUnitId,
                            allocationStatus,
                            searchForm.fromDate,
                            searchForm.toDate,
                            filter,
                            this.selectAll,
                            this.selectedItems,
                            this.excludedItems,
                            "consolidation"
                        )
                        .then(
                            (data) => {
                                this.actionMenuEnabled = true;
                                this.onSelectAllChange(false);
                                if (data > 0) {
                                    this.errHandler.emitError(
                                        this.resources.RecalculationStarted.formatUnicorn(
                                            data || 0,
                                            this.resources.product_s
                                        ),
                                        this.resources.Success,
                                        ErrorTypeEnum.Success
                                    );
                                    this.recalculationTriggered.next();
                                } else {
                                    this.errHandler.emitError(
                                        this.resources.RecalculationNotStarted,
                                        this.resources.Info,
                                        ErrorTypeEnum.Info
                                    );
                                }
                            },
                            (error) => {
                                this.actionMenuEnabled = true;
                                this.errHandler.emitError(
                                    this.resources.RecalculationFailed,
                                    this.resources.Error,
                                    ErrorTypeEnum.Error
                                );
                            }
                        );
                }
            ).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(
                this.resources.SelectComponent,
                this.resources.NoComponentsSelected,
                ErrorTypeEnum.Info
            );
        }
    }

    async recalculateCurrency(allocationStatus: string) {

        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.RecalculateConfirmation.formatUnicorn(
                    this.selectedCountValue,
                    this.resources.product_s
                ),
                this.resources.Recalculate.formatUnicorn(
                    this.resources.product_s
                )
            );
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(
                this.componentSearchForm,
                this.filterExpression,
                (searchForm, filter) => {
                    this.orchestratorService
                        .recalculateCurrencies(
                            searchForm.selectedScaniaUnitId,
                            allocationStatus,
                            searchForm.fromDate,
                            searchForm.toDate,
                            filter,
                            this.selectAll,
                            this.selectedItems,
                            this.excludedItems,
                            "consolidation"
                        )
                        .then(
                            (data) => {
                                this.actionMenuEnabled = true;
                                this.onSelectAllChange(false);
                                if (data > 0) {
                                    this.errHandler.emitError(
                                        this.resources.RecalculationStarted.formatUnicorn(
                                            data || 0,
                                            this.resources.product_s
                                        ),
                                        this.resources.Success,
                                        ErrorTypeEnum.Success
                                    );
                                    this.recalculationTriggered.next();
                                } else {
                                    this.errHandler.emitError(
                                        this.resources.RecalculationNotStarted,
                                        this.resources.Info,
                                        ErrorTypeEnum.Info
                                    );
                                }
                            },
                            (error) => {
                                this.actionMenuEnabled = true;
                                this.errHandler.emitError(
                                    this.resources.RecalculationFailed,
                                    this.resources.Error,
                                    ErrorTypeEnum.Error
                                );
                            }
                        );
                }
            ).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(
                this.resources.SelectComponent,
                this.resources.NoComponentsSelected,
                ErrorTypeEnum.Info
            );
        }
    }
}
