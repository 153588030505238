import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { ComponentIndividualApiService } from './services/component-individual-api.service';
import { ScaniaUnitApiService } from './services/scania-unit-api.service';
import { MaterialApiService } from './services/material-api.service';
import { MasterDataApiService } from './services/master-data-api.service';
import { CommonApiService } from './common.service';
import { GraphQLModule } from './graphql.module';
import { ProductIndividualApiService } from './services/product-individual-api.service';
import { AccessControlApiService } from './services/access-control.service';

@NgModule({
    imports: [HttpClientModule, GraphQLModule],
    providers: [
        CommonApiService,
        ComponentIndividualApiService,
        ScaniaUnitApiService,
        MaterialApiService,
        MasterDataApiService,
        ProductIndividualApiService,
        AccessControlApiService
    ]
})
export class ApiModule { }
