import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MarketApiService } from 'src/api/services/market-api.service';
import { MarkupConditionMaterialApiService } from 'src/api/services/markup-condition-material-api.service';
import { MarkupPercentageApiService } from 'src/api/services/markup-percentage-api.service';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CommonStateService } from 'src/state';
import { MarkupConditionMaterialStateServiceService } from 'src/state/administraion/markup-condition-material/service';
import { MarkupConditionMaterialAdd } from 'src/state/administraion/markup-condition-material/state-models/markup-condition-material';
import { MarkupPercentageStateServiceService } from 'src/state/administraion/markup-percentage/service';
import { MarkupPercentageAdd } from 'src/state/administraion/markup-percentage/state-models/markup-percentage';

@Component({
  selector: 'cal-add-markup-percentage',
  templateUrl: './add-markup-percentage.component.html',
  styleUrls: ['./add-markup-percentage.component.css']
})
export class AddMarkupPercentageComponent implements OnInit {  
  
  resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    productClassId: number;
    productClass: UntypedFormControl;
    markupConditionId: number;
    markupCondition: UntypedFormControl;
    marketId: number;
    market:UntypedFormControl;
    percentage: UntypedFormControl;
    validFrom: UntypedFormControl;
    validTo: UntypedFormControl;
    codeInput: UntypedFormControl;
    productClassList: any;
    markupConditionList: any;
    marketList: any;
    validDate: any;
    constructor(
        private stateService: MarkupPercentageStateServiceService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private apiService: MarkupPercentageApiService        
    ) {
        this.validDate=new Date();
        this.validDate=new Date(this.validDate.getTime() + 86400000);
        this.validDate=this.validDate.toISOString().split('T')[0];       
    }

    async ngOnInit(): Promise<void> {
        this.buildForm();    
        this.productClassList = await this.apiService.getProductClass();  
        this.markupConditionList = await this.apiService.getMarkupCondition();
        this.marketList=await this.apiService.getMarket();
    }

    buildForm() {
        this.productClass = new UntypedFormControl("", [Validators.required]);
        this.markupCondition = new UntypedFormControl("", [Validators.required]);
        this.market = new UntypedFormControl("", [Validators.required]);
        this.percentage=new UntypedFormControl("",[Validators.required]);
        this.validFrom = new UntypedFormControl(null, [Validators.required]);
        this.validTo = new UntypedFormControl(null);
    }

    onSave() {
        if (this.isValidForm()) {
            const payload: MarkupPercentageAdd = {
                productClassId: this.productClass.value,
                markupConditionId: this.markupCondition.value,
                marketId: this.market.value,
                percentage: this.percentage.value,
                validFrom: this.validFrom.value,
                validTo: this.validTo.value,
            };
            
            this.stateService
                .addMarkupPercentages(payload)
                .then((errors) => {
                    let errorMessage = "";
                    if (errors && errors.length) {
                        for (let error of errors) {
                            const errorType = error
                                .split("\n")[0]
                                .replace("GraphQL.ExecutionError:", "")
                                .trim();
                            switch (errorType) {                                                                
                                case "INVALID_MARKUP_CONDITION":
                                    errorMessage +=
                                        this.resources.InvalidMarkupCondition;
                                    break;
                                case "INVALID_DATE_TO":
                                    errorMessage +=
                                        this.resources.ValidFromValidToCorrect;
                                    break;
                                case "RECORD_ALREADY_EXIST_IN_THIS_INTERVAL":
                                    errorMessage += this.resources.RecordAlreadyExistForSpecifiedInterval;
                                    break;                                
                                default:
                                    errorMessage += this.resources.ErrorOccured;
                                    break;
                            }
                        }
                        this.errHandler.emitError(
                            errorMessage,
                            this.resources.Error,
                            ErrorTypeEnum.Error
                        );
                    } else {
                        this.errHandler.emitError(
                            this.resources.SavedSuccessfully,
                            this.resources.Success,
                            ErrorTypeEnum.Success
                        );
                        this.reset();
                    }
                });
            this.stateService.showShowOnlyValidMarkupPercentages(false);
        }
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.market.errors != null) {
            if (this.market.errors.required) {
                this.errHandler.emitError(
                    this.resources.MarketRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }
        if (this.productClass.errors != null) {
            if (this.productClass.errors.required) {
                this.errHandler.emitError(
                    this.resources.ProductClassRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }
        if (this.markupCondition.errors != null) {
            if (this.markupCondition.errors.required) {
                this.errHandler.emitError(
                    this.resources.MarkupConditionRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }

        if (this.percentage.errors != null) {
            if (this.percentage.errors.required) {
                this.errHandler.emitError(
                    this.resources.PercentageRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }

        if (this.validFrom.errors != null) {
            if (this.validFrom.errors.required) {
                this.errHandler.emitError(
                    this.resources.ValidDateFromRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }

        if(this.percentage.value>100 || this.percentage.value<0){
            this.errHandler.emitError(
                this.resources.ValidPercentage,
                this.resources.Error,
                ErrorTypeEnum.Error
            );
            valid = false;        
        }                

        return valid;
    }

    reset() {
        if (this.markupCondition) {
            this.productClass.reset();
            this.markupCondition.reset();
            this.market.reset();
            this.validFrom.reset();
            this.percentage.reset();            
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onCancel(){
        this.reset()
    }

}
