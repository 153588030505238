import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, combineLatest } from 'rxjs';

import { CommonStateService, ScaniaUnitConsolidation, ScaniaUnitAllocation } from 'src/state';
import { CalsiumListConfig } from 'src/app/calsiumlist/calsiumlist.component.model';
import { DeliveryRoute } from 'src/state/administraion/delivery-route/state-models/delivery-route';
import { Validators, UntypedFormControl } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { DeliveryRouteAllocationUnitStateService } from 'src/state/administraion/delivery-route-allocation-unit/service';
import { DeliveryRouteAllocationUnit } from 'src/state/administraion/delivery-route-allocation-unit/state-models/delivery-route-allocation-unit';


@Component({
    selector: 'cal-add-delivery-route-allocation-unit',
    templateUrl: './add-delivery-route-allocation-unit.component.html',
    styleUrls: ['./add-delivery-route-allocation-unit.component.css']
})
export class AddDeliveryRouteAllocationUnitComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    deliveryRouteList: Observable<DeliveryRoute[]>;
    scaniaUnitConsolidationList: Observable<ScaniaUnitConsolidation[]>;
    scaniaUnitAllocationList: Observable<ScaniaUnitAllocation[]>;

    isLoading: Observable<boolean>;
    listConfig: CalsiumListConfig;
    propertyCodeInput: UntypedFormControl;
    deliveryRouteInput: UntypedFormControl;
    scaniaUnitAllocationInput: UntypedFormControl;
    scaniaUnitConsolidationInput: UntypedFormControl;

    constructor(
        private stateService: DeliveryRouteAllocationUnitStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) { }

    ngOnInit(): void {
        this.stateService.loadDeliveryRouteList();
        this.stateService.loadScaniaUnitAllocationList();
        this.stateService.loadScaniaUnitConsolidationList();
        this.deliveryRouteList = this.stateService.getDeliveryRouteList();
        this.scaniaUnitAllocationList = this.stateService.getScaniaUnitAllocationList();
        this.scaniaUnitConsolidationList = this.stateService.getScaniaUnitConsolidationList();
        this.buildForm();
    }
    buildForm() {
        this.propertyCodeInput = new UntypedFormControl('', [Validators.required]);
        this.deliveryRouteInput = new UntypedFormControl('', [Validators.required]);
        this.scaniaUnitAllocationInput = new UntypedFormControl('', [Validators.required]);
        this.scaniaUnitConsolidationInput = new UntypedFormControl('', [Validators.required]);
    }

    onSave() {
        if (this.isValidForm()) {
            const payload: DeliveryRouteAllocationUnit = {
                id: null,
                propertyCode: this.propertyCodeInput.value,
                deliveryRouteId: this.deliveryRouteInput.value,
                scaniaUnitAllocationId: this.scaniaUnitAllocationInput.value,
                scaniaUnitConsolidationId: this.scaniaUnitConsolidationInput.value,
            };

            this.stateService.update(payload).then((errors) => {
                if (errors && errors.length) {
                    let errorMessage = '';
                    for (const error of errors) {
                        switch (error) {
                            case 'ALREADY_EXISTING':
                                errorMessage += this.resources.AlreadyExistingDeliveryRouteAllocationUnit;
                                break;
                            default:
                                errorMessage += this.resources.ErrorOccured;
                                break;
                        }
                    }
                    this.errHandler.emitError(errorMessage, this.resources.Error, ErrorTypeEnum.Error);
                } else {
                    this.errHandler.emitError(this.resources.DeliveryRouteAllocationUnitAddedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
                    this.propertyCodeInput.reset();
                    this.deliveryRouteInput.reset();
                    this.scaniaUnitAllocationInput.reset();
                    this.scaniaUnitConsolidationInput.reset();
                }
            });
        }
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.propertyCodeInput.errors != null) {
            if (this.propertyCodeInput.errors.required) {
                this.errHandler.emitError(this.resources.PropertyCodeIsRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.deliveryRouteInput.errors != null) {
            if (this.deliveryRouteInput.errors.required) {
                this.errHandler.emitError(this.resources.DeliveryRouteIsRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.scaniaUnitAllocationInput.errors != null) {
            if (this.scaniaUnitAllocationInput.errors.required) {
                this.errHandler.emitError(this.resources.ScaniaUnitAllocationIsRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.scaniaUnitConsolidationInput.errors != null) {
            if (this.scaniaUnitConsolidationInput.errors.required) {
                this.errHandler.emitError(this.resources.ScaniaUnitConsolidationIsRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        return valid;
    }
    
    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
