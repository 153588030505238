import { Injectable } from '@angular/core';
import { map, lastValueFrom } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CommonApiService } from '../common.service';
import { Simulation } from 'src/state/simulation/state-models/simulation-data';
import { MissingSIMMaterial } from 'src/state/missing-sim-material/state-models/missing-sim-material';

@Injectable({
  providedIn: 'root'
})
export class SimulationApiService {
  constructor(
    private apollo: Apollo,
    private commonService: CommonApiService
  ) { }

  private simulationRetrieveDataSearchQuery = `
    search(paging: $paging, order: $order, filter: $filter){       
      list {
        id         
        simProdNumber         
        partPeriod                 
        structMarketCOReleaseTime
        userId          
        status          
        baseCost          
        createdOn          
        updatedOn          
        sportMessage          
        errorMessage 
        productIndividualId         
    }        
    totalCount        
    errorsCount: extraHeaderInfo1       
}`;

  private missingSIMMaterialSearchQuery = `
    searchMissingSimMaterial(from: $from, to: $to, paging: $paging, order: $order, filter: $filter){       
      list {
        materialNumber
        materialName
        componentType      
    }        
    totalCount        
    errorsCount: extraHeaderInfo1       
}`;



  getSimulationsList(queryType: string, variables?, forceRefetch: boolean = false): Promise<any> {
    const result$ = this.apollo
      .query<Simulation[]>({
        variables,
        fetchPolicy: forceRefetch ? 'no-cache' : null,
        query: gql`
            query simulationListQuery($paging: Paging, $order:String, $filter:String) {
              simulation {
                ${queryType} {
                  ${this.simulationRetrieveDataSearchQuery}
                }
              }
            }`
      })
      .pipe<Simulation[]>(

        map((result: any) => {
          return result?.data?.simulation?.[queryType]?.search;
        })

      )
    return lastValueFrom(result$);

  }

  getMissingSIMMaterialList(queryType: string, variables?, forceRefetch: boolean = false): Promise<any> {
    const result$ = this.apollo
      .query<MissingSIMMaterial[]>({
        variables,
        fetchPolicy: forceRefetch ? 'no-cache' : null,
        query: gql`
            query missingSIMMaterial($from: DateTime, $to: DateTime, $paging: Paging, $order:String, $filter:String) {
              missingSIMMaterial {
                ${queryType} {
                        ${this.missingSIMMaterialSearchQuery}
                      }
                    }                  
            }`
      })
      .pipe<MissingSIMMaterial[]>(
        map((result: any) => {
          return result?.data?.missingSIMMaterial?.[queryType]?.searchMissingSimMaterial;
        })

      )

    return lastValueFrom(result$);
  }

  exportMissingMaterialExcel(fileName: string, variables?: any): Promise<any> {
    return this.commonService.exportQuery(this.materialQueryFieldsList, variables, fileName);
  }

  private materialQueryFieldsList = gql`
  query ($from: DateTime, $to: DateTime,$paging: Paging, $order: String, $filter: String) {
    missingSIMMaterial {
    missing_SIM_material{
      searchMissingSimMaterial(from: $from, to: $to,paging: $paging, order: $order, filter: $filter) {
        list {
          materialNumber
          materialName
          componentType  
        }
      }
      }
    }
  }`;

}
