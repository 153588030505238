import { MissingSIMMaterialListState } from './state';
import { MssingSIMMaterialListAction, MssingSIMMaterialListActions } from './action';
const initialState = require('./initial-data.json') as MissingSIMMaterialListState;

export function MissingSIMMaterialListReducer(
    state: MissingSIMMaterialListState = initialState,
    action: MssingSIMMaterialListAction
): MissingSIMMaterialListState {
    switch (action.type) {
        case MssingSIMMaterialListActions.LoadMissingSIMMaterialListPageAction:
            return {
                ...state,
                missingSIMMaterialList: action.payload
            };
        case MssingSIMMaterialListActions.ChangeMissingSIMMaterialListFilterExpressionAction:
            return {
                ...state,
                missingSIMMaterialListPaging: { ...state.missingSIMMaterialListPaging, page: 1 },
                missingSIMMaterialListFilterExpression: action.payload
            };
        case MssingSIMMaterialListActions.ChangeMissingSIMMaterialListSortExpressionAction:
            return {
                ...state,
                missingSIMMaterialListPaging: { ...state.missingSIMMaterialListPaging, page: 1 },
                missingSIMMaterialListSortExpression: action.payload
            };
        case MssingSIMMaterialListActions.ChangeMissingSIMMaterialListPageAction:
            return {
                ...state,
                missingSIMMaterialListPaging: { ...state.missingSIMMaterialListPaging, page: action.payload },
            };
        case MssingSIMMaterialListActions.ChangeMissingSIMMaterialListTotalCountAction:
            return {
                ...state,
                missingSIMMaterialListTotalCount: action.payload
            };
        case MssingSIMMaterialListActions.ChangeMissingSIMMaterialListSearchForm:
            return {
                ...state,
                missingSIMMaterialListSearchForm: action.payload,
                missingSIMMaterialListPaging: { ...state.missingSIMMaterialListPaging, page: 1 }
            };
        default:
            return state;
    }
}