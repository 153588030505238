import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { ConsumingPlanningAreaMapping } from './state-models/consuming-planning-area-mapping';

export enum ConsumingPlanningAreaMappingActions {
    loadConsumingPlanningAreaMappings = '[ Admin ] Load consuming planning area mappings',
    changeResultsTotalCountAction = '[ Admin ] Change ConsumingPlanningAreaMapping Count',
    changePageAction = '[ Admin ] Change ConsumingPlanningAreaMapping Page',
    changeSortExpressionAction = '[ Admin ] Change ConsumingPlanningAreaMapping Sort Expression',
    changeFilterExpressionAction = '[ Admin ] Change ConsumingPlanningAreaMapping Filter Expression'
}

export class LoadConsumingPlanningAreaMappingsAction implements Action {
    readonly type = ConsumingPlanningAreaMappingActions.loadConsumingPlanningAreaMappings;
    constructor(public payload: ConsumingPlanningAreaMapping[]) { }
}

export class ChangeResultsTotalCountAction implements Action {
    readonly type = ConsumingPlanningAreaMappingActions.changeResultsTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangePageAction implements Action {
    readonly type = ConsumingPlanningAreaMappingActions.changePageAction;
    constructor(public payload: number) { }
}
export class ChangeSortExpressionAction implements Action {
    readonly type = ConsumingPlanningAreaMappingActions.changeSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeFilterExpressionAction implements Action {
    readonly type = ConsumingPlanningAreaMappingActions.changeFilterExpressionAction;
    constructor(public payload: string) { }
}

export type ConsumingPlanningAreaMappingAction =
    | LoadConsumingPlanningAreaMappingsAction
    | ChangeResultsTotalCountAction
    | ChangeSortExpressionAction
    | ChangeFilterExpressionAction
    | ChangePageAction;
