<cal-add-markup-percentage></cal-add-markup-percentage>
<div class="panel" style="margin-top: 5px;">
    <div class="quantity-filter">
        <mat-checkbox (change)="onShowOnlyValidMarkupPercentage($event.checked)" [checked]="showOnlyValidMarkupPercentages | async" #myCheckbox>
            {{resources.ShowValidMarkupPercentages}}
        </mat-checkbox>
     </div>
    <div class="panel-body">
        <cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="markupPercentageList | async"
            (sortTriggered)="onSort($event)" (filterTriggered)="onFilter($event)"
            (clearFilterAndSortingTriggered)="onClearFilterAndSorting()" [isLoading]="isLoading | async"
            [filterExpression]="filterExpression | async" [dataTotalCount]="totalCount | async">
        </cal-calsiumlist-listing>
        <cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async">
        </cal-pagination>
    </div>
</div>
