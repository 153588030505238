<div class="row" style="margin-top: 20px">
    <div class="col-sm-4">
        <div class="card">
            <div class="card-body" style="padding: 0">
                <div style="padding:5px 5px 5px 15px;display: inline-block;font-style: italic;">
                    {{resources.TotalItems.formatUnicorn(totalCount | async)}}
                    ({{errorCount | async}} errors)</div>
                <div style="padding:5px 10px 5px 10px;font-style: italic;" class="selected">{{selectedCount | async}}
                    {{resources.ItemSelected}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-8" *ngIf="allocationStatus === 'produced'">
        <div class="btn-group dropleft" style="float: right">
            <button class="btn btn-secondary dropdown-toggle" [class.disabled]="!actionMenuEnabled" type="button"
                id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{resources.SelectAction}}
            </button>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="allocate(true)">
                    {{resources.PreviewFileOnly}}
                </a>
                <a class="dropdown-item" (click)="allocate(false)">
                    {{resources.AllocateSelected}}
                </a>
                <a class="dropdown-item" (click)="recalculateCurrency(allocationStatus)">
                    {{resources.RecalculateCurrency}}
                </a>
                <a class="dropdown-item" (click)="recalculateMarkup(allocationStatus)">
                    {{resources.RecalculateMarkup}}
                </a>

            </div>
        </div>
    </div>
</div>
<div class="panel" style="margin-top: 5px;">
    <div class="panel-body">
        <cal-calsiumlist-listing [name]="allocationStatus + 'List'" [config]="listConfig"
            [ListBodyData]="dataList | async" (sortTriggered)="onSort($event)" (filterTriggered)="onFilter($event)"
            (clearFilterAndSortingTriggered)="onClearFilterAndSorting()" [isLoading]="isLoading | async"
            [dataTotalCount]="totalCount | async" [filterExpression]="filterExpression | async"
            (selectAllChanged)="onSelectAllChange($event)" (rowSelectionChanged)="onRowSelectionChange($event)"
            (exportToExcelTriggered)="onExport()">
        </cal-calsiumlist-listing>
        <cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async">
        </cal-pagination>
    </div>
</div>
