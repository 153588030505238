import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
import { UnitOfMeasurement } from '../../state/master-data/state-models/unit-of-measurement';
import { map,lastValueFrom } from 'rxjs';
import { ComponentType } from 'src/state/master-data/state-models/component-type';

@Injectable({
    providedIn: 'root'
})
export class MasterDataApiService {
    constructor(private apollo: Apollo) { }

    getAllUnitOfMeasurments(): Promise<UnitOfMeasurement[]> {
      const result$ =  this.apollo
          .query<UnitOfMeasurement[]>({
              query: gql`
              {
                unitOfMeasurement{
                  get{
                    list{
                      id
                      name
                      isBaseUnit
                      unitOfMeasurementType{
                        id
                        name
                      }
                    }
                  }
                }
              }`
          })
          .pipe<UnitOfMeasurement[]>(
           
            map((result: any) => {
              return result?.data?.unitOfMeasurement?.get?.list;
              })
          )
         
          return lastValueFrom(result$);
    }

    getAllComponentTypes(): Promise<ComponentType[]> {
      const result$ = this.apollo
        .query<ComponentType[]>({
            query: gql`
            {
              componentTypes{
                  list{
                    id
                    name
                    code
                    description
                  }
              }
            }`
        })
        .pipe<ComponentType[]>(
         
          map((result: any) => {
            return result?.data?.componentTypes?.list;
            })
        )
        return lastValueFrom(result$);
  }
}
