export const environment = {
    production: true,
    oidc: {
        authority: 'https://identity.calsium.devtest.aws.scania.com',
        redirectUrl: '/auth-callback',
        postLogoutUrl: '/',
        silentRenewUrl: '/assets/auth/silent-refresh.html'
    },
    adminUrl: 'https://listener.calsium.devtest.aws.scania.com/api',
    standardPriceId: 1,
    strategicPriceId: 2,
    manualPriceId: 3,
    scaniaUnitIdsForSLA: [26,27,28],
    allocationBucketUrl: 'https://calsium-allocation-dev.s3-eu-west-1.amazonaws.com/index.html',
    currentEnvironment: 'dev' // this comes from Corporate-UI. Available options are dev,sys,pre,proto,prod
};
