import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY, from } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = this.authService.getAuthorizationHeaderValue();
        if (token !== null && token !== '') {
            return next.handle(req.clone({
                setHeaders: {
                    Authorization: `${token}`
                }
            }));
        }
        return this.authService.isUserLoggedIn.pipe(
            switchMap((isLoggedIn: boolean) => {
                if (isLoggedIn) {
                    return next.handle(req.clone({
                        setHeaders: {
                            Authorization: `${this.authService.getAuthorizationHeaderValue()}`
                        }
                    }));
                }
                console.log('Waiting for user credentials...');
                return from(this.authService.startAuthenticationPopup()).pipe(
                    switchMap((user) => {
                        return next.handle(req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${user.access_token}`
                            }
                        }));
                    })
                );
            })
        );
    }
}
