import { Action } from "@ngrx/store";
import { MarkupPercentage } from "./state-models/markup-percentage";

export enum MarkupPercentageActionType {
    AddMarkupPercentageAction = "[ Admin ] Add markup percentage ",

    LoadProductClassListAction = "[ Admin ] load product class list",
    LoadMarkupConditionListAction = "[ Admin ] load markup condition code list",
    LoadMarketListAction="[ Admin ] load market code list",
    LoadMarkupPercentageListAction = "[ Admin ] load markup percentage list",
    ChangeMarkupPercentageSortExpressionAction = "[ Admin ] Change markup percentage Sort Expression",
    ChangeMarkupPercentageFilterExpressionAction = "[ Admin ] Change markup percentage Filter Expression",
    UpdateMarkupPercentageAction = "[ Admin ] Update markup percentage material",

    ChangeMarkupPercentageShowOnlyValidPercentagesAction = "[ Admin ] change show valid markup percentages only",
    ChangeMarkupPercentageListPageAction="[ Admin ] change markup percentage list page",
    ChangeMarkupPercentageListTotalCountAction="[ Admin ] change markup percentage list totl count"
}

export class AddMarkupPercentageAction implements Action {
    readonly type =
        MarkupPercentageActionType.AddMarkupPercentageAction;
    constructor(public payload: MarkupPercentage) {}
}

export class LoadMarkupPercentageAction implements Action {
    readonly type =
        MarkupPercentageActionType.LoadMarkupPercentageListAction;
    constructor(public payload: MarkupPercentage[]) {}
}

export class ChangeMarkupPercentageSortExpressionAction
    implements Action
{
    readonly type =
        MarkupPercentageActionType.ChangeMarkupPercentageSortExpressionAction;
    constructor(public payload: string) {}
}

export class ChangeMarkupPercentageFilterExpressionAction
    implements Action
{
    readonly type =
        MarkupPercentageActionType.ChangeMarkupPercentageFilterExpressionAction;
    constructor(public payload: string) {}
}

export class ChangeMarkupPercentageShowOnlyValidPercentagesAction implements Action {
    readonly type =
        MarkupPercentageActionType.ChangeMarkupPercentageShowOnlyValidPercentagesAction;
    constructor(public payload: boolean) {}
}

export class UpdateMarkupPercentageAction implements Action {
    readonly type =
        MarkupPercentageActionType.UpdateMarkupPercentageAction;
    constructor(public payload: MarkupPercentage) {}
}

export class ChangeMarkupPercentageListPageAction implements Action{
    readonly type=
        MarkupPercentageActionType.ChangeMarkupPercentageListPageAction;
        constructor(public payload: number) { }
}

export class ChangeMarkupPercentageListTotalCountAction implements Action{
    readonly type = MarkupPercentageActionType.ChangeMarkupPercentageListTotalCountAction;
    payload: { totalCount: number};
    constructor(totalCount: number) {
        this.payload = { totalCount};
    }
}

export type MarkupPercentageAction =
    | AddMarkupPercentageAction
    | UpdateMarkupPercentageAction    
    | LoadMarkupPercentageAction
    | ChangeMarkupPercentageSortExpressionAction
    | ChangeMarkupPercentageFilterExpressionAction
    | ChangeMarkupPercentageShowOnlyValidPercentagesAction
    | ChangeMarkupPercentageListPageAction
    | ChangeMarkupPercentageListTotalCountAction
;
