import { ConsumingPlanningAreaMappingState } from './state';
import { ConsumingPlanningAreaMappingAction, ConsumingPlanningAreaMappingActions } from './actions';

export const ConsumingPlanningAreaMappingInitialState = require('./initial-data.json') as ConsumingPlanningAreaMappingState;
export function ConsumingPlanningAreaMappingReducer(state: ConsumingPlanningAreaMappingState = ConsumingPlanningAreaMappingInitialState, action: ConsumingPlanningAreaMappingAction): ConsumingPlanningAreaMappingState {
    switch (action.type) {
        case ConsumingPlanningAreaMappingActions.loadConsumingPlanningAreaMappings:
            return {
                ...state,
                consumingPlanningAreaMappings: action.payload
            };
        case ConsumingPlanningAreaMappingActions.changeResultsTotalCountAction:
            return {
                ...state,
                TotalCount: action.payload
            };
        case ConsumingPlanningAreaMappingActions.changePageAction:
            return {
                ...state,
                Paging: { ...state.Paging, page: action.payload },
            };

        case ConsumingPlanningAreaMappingActions.changeFilterExpressionAction:
            return {
                ...state,
                TotalCount: 0,
                Paging: { ...state.Paging, page: 1 },
                FilterExpression: action.payload
            };
        case ConsumingPlanningAreaMappingActions.changeSortExpressionAction:
            return {
                ...state,
                TotalCount: 0,
                Paging: { ...state.Paging, page: 1 },
                SortExpression: action.payload
            };
        default:
            return state;
    }
}
