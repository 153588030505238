import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';

import { CalsiumListConfig, ColumnDataType } from '../../../../calsiumlist/calsiumlist.component.model';
import { ComponentDetailsStateService } from '../../../../../state/componentDetails/service';
import { Paging, CommonStateService } from '../../../../../state';
import { ProductSpecification } from '../../../../../state/componentDetails/state-models/product-specification';

@Component({
    selector: 'cal-comp-specification',
    templateUrl: './specification.component.html'
})
export class SpecificationComponent implements OnInit {
    @Input() productIndividualId: number;
    @Output() totalSpecificationCountChanged = new EventEmitter<number>();
    listConfig: CalsiumListConfig;
    subscriptions = new Subscription();
    resources: any = this.commonService.getResources();

    componentSpecification: Observable<ProductSpecification[]>;
    specificationSortingExpression: Observable<string>;
    specificationFilterExpression: Observable<string>;
    specificationPaging: Observable<Paging>;
    specificationTotalCount: Observable<number>;
    totalSpecificationFullCount: number;
    isSpecificationLoading = true;

    constructor(private apiService: ComponentDetailsStateService,
        private commonService: CommonStateService) { }

    ngOnInit() {
        this.specificationSortingExpression = this.apiService.getComponentSpecificationSortingExpression();
        this.specificationFilterExpression = this.apiService.getComponentSpecificationFilterExpression();
        this.specificationTotalCount = this.apiService.getComponentSpecificationTotalCount();
        this.specificationPaging = this.apiService.getComponentSpecificationPaging();
        this.componentSpecification = this.apiService.getComponentSpecification();

        this.subscriptions.add(
            combineLatest(this.specificationSortingExpression, this.specificationFilterExpression, this.specificationPaging)
                .pipe(
                    debounceTime(0),
                    switchMap(([sort, filter, paging]) => {
                        this.isSpecificationLoading = true;
                        return this.apiService.loadComponentSpecification(this.productIndividualId, paging, sort, filter)
                            .then(_ => this.isSpecificationLoading = false);
                    })
                ).subscribe()
        );

        this.subscriptions.add(
            combineLatest(this.specificationFilterExpression, this.specificationTotalCount)
                .pipe(
                    map(([filterExpression, totalCount]) => {
                        this.totalSpecificationFullCount = filterExpression != null ? this.totalSpecificationFullCount : totalCount;
                        this.totalSpecificationCountChanged.emit(this.totalSpecificationFullCount);
                    })
                ).subscribe()
        );

        this.buildList();
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ShowListCaption = true;
        this.listConfig.ListCaption = this.resources.ComponentSpecificationList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ShowSumRow = false;
        this.listConfig.HasSubComponent = false;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = false;
        this.listConfig.AllowAdd = false;
        this.listConfig.AllowEdit = false;
        this.listConfig.AllowDelete = false;

        let column = this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.variantFamily.variantClass.value', this.resources.VariantClass);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.variantFamily.value', this.resources.VariantFamily);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.value', this.resources.VariantOption);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.variantFamily.description', this.resources.VariantFamilyDescription);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.description', this.resources.VariantOptionDescription);
        column.AllowFilter = true;
        column.Sortable = true;
    }

    onSort(sortExpression: string) {
        this.apiService.sortComponentSpecification(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.apiService.filterComponentSpecification(filterExpression);
    }

    onClearFilter() {
        this.apiService.filterComponentSpecification(null);
    }

    onPaging(page: number) {
        this.apiService.goToComponentSpecificationPage(page);
    }
}
