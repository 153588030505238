import { Action } from '@ngrx/store';
import { UnitOfMeasurement, ScaniaUnit, ComponentType} from '..';

export enum MasterDataActions {
  LoadUnitOfMeasurement = '[ Master Data ] Load Unit of measurments',
  LoadScaniaUnitAction = '[ Master Data ] Load scania units',
  LoadComponentTypesAction = '[ Master Data ] Load Component Types',

}

export class LoadUnitOfMeasurement implements Action {
  readonly type = MasterDataActions.LoadUnitOfMeasurement;
  constructor(public payload: UnitOfMeasurement[]) { }
}

export class LoadScaniaUnitAction implements Action {
  readonly type = MasterDataActions.LoadScaniaUnitAction;
  constructor(public payload: ScaniaUnit[]) { }
}

export class LoadComponentTypesAction implements Action {
  readonly type = MasterDataActions.LoadComponentTypesAction;
  constructor(public payload: ComponentType[]) {
  }
}

export type MasterDataAction =
  | LoadUnitOfMeasurement
  | LoadScaniaUnitAction
  | LoadComponentTypesAction
  ;
