import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';

import { SystemNotificationsComponent } from '../notifications/system-notifications/system-notifications.component';

@NgModule({
    imports: [
        AngularCommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatOptionModule
    ],
    declarations: [
        SystemNotificationsComponent
    ],
    exports: [
        SystemNotificationsComponent
    ]
})
export class NotificationsModule { }
