<nav aria-label="breadcrumb" class="calsium-breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/flow/control/component-list">{{this.resources.FinishedComponentsSearch}}</a></li>
    <li class="breadcrumb-item active">{{resources.Component}} # {{componentId}}</li>
  </ol>
</nav>
<div [ngClass]="{loading: isLoading | async}">
  <div *ngIf="componentIndividual$ | async as componentIndividual">
    <cal-comp-details [componentIndividual]="componentIndividual" [resources]="resources"></cal-comp-details>
    <div class="container-fluid">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="materials-tab" href="#component-materials" data-toggle="tab" role="tab" aria-controls="materials" aria-selected="true">
            {{resources.Materials}}&nbsp;&nbsp;({{totalMaterialCount}})
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="specification-tab" href="#component-specification" data-toggle="tab" role="tab" aria-controls="specification" aria-selected="false">
            {{resources.Specification}}&nbsp;&nbsp;({{totalSpecificationCount}})
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="component-materials" role="tabpanel" aria-labelledby="materials-tab">
            <cal-comp-materials [componentId]="componentIndividual.id" (totalMaterialCountChanged)="totalMaterialCount = $event"></cal-comp-materials>
        </div>
        <div class="tab-pane fade" id="component-specification" role="tabpanel" aria-labelledby="specification-tab">
          <cal-comp-specification [productIndividualId]="componentIndividual.productIndividualId"
            (totalSpecificationCountChanged)="totalSpecificationCount = $event"></cal-comp-specification>
        </div>
      </div>
    </div>
  </div>
</div>