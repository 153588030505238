import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';
import { VariantFamily, VariantOption, Market } from 'src/state';


@Injectable({
  providedIn: 'root'
})
export class MarketApiService {
  constructor(private apollo: Apollo) { }

  getMarket(variables?): Promise<Market[]> {
    const result$ = this.apollo
      .query<Market[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
        query marketQuery($order: String, $filter: String) {
          admin {
            market(order: $order, filter: $filter) {
              id
              name
              description
            }
          }
        }
        `
      })
      .pipe<Market[]>(
       
        map((result: any) => {
          return result?.data?.admin?.market;
          })
      )
      return lastValueFrom(result$);
  }
}
