import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { ManualInvoicingApiService } from 'src/api/services/ManualInvoicingApiService';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CommonStateService } from 'src/state';
import { ManualInvoicing, ManualInvoicingresponse } from '../manual-invoicing-model';
@Component({
    selector: "cal-manual-invoicing",
    templateUrl: "./add-manual-invoicing.component.html",
    styleUrls: ["./add-manual-invoicing.component.css"],
})
export class ManualInvoicingComponent implements OnInit {
    public resources: any = this.commonService.getResources();;
    showConfirm = false;
    isLoading = false;
    csvInput = new UntypedFormControl('', [Validators.required, this.csvFormatValidator(/(\s*|\d+),[a-zA-Z0-9]*(?!\s*$).,\d*\.?\d*,[0-9]+,\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])/)]);
    manualinvoicingList: ManualInvoicing[] = [];
    public validmanualinvocingList: ManualInvoicingresponse[] = [];
    showResponsiblityCodeTable: boolean = false;
    constructor(
        private commonService: CommonStateService,
        private manualInvoicingApiService: ManualInvoicingApiService,
        private errHandler: ErrorHandlerService
    ) { }

    ngOnInit() {
    }

    async validate() {
        this.resetManualInvoiceList();
        if (!this.csvInput.valid) return;
        this.csvInput.value.split("\n").forEach(line => {
            if (line.trim().length > 0) {
                const lineArr = line.split(',');
                const manualInvoice: ManualInvoicing = {
                    chassisNumber: lineArr[0],
                    countryCode: lineArr[1],
                    invoiceAmount: +lineArr[2],
                    responsibilityCode: lineArr[3],
                    invoiceDate: lineArr[4]
                }
                this.manualinvoicingList.push(manualInvoice);
            }
        });

        this.isLoading = true;
        const result = await this.manualInvoicingApiService.verify(this.manualinvoicingList);
        this.isLoading = false;
        if (result.errors) {
            result.errors.forEach((error) => {
                const message = error.message.replace('GraphQL.ExecutionError: ', '');
                this.errHandler.emitError(message, 'invalid format', ErrorTypeEnum.Error);
            });
        } else {
            this.validmanualinvocingList = result.data.manualInvoicing.verify;
            this.showConfirm = true;
        }
    }

    async confirm() {
        this.isLoading = true;
        this.showConfirm = false;
        const result = await this.manualInvoicingApiService.confirm(this.manualinvoicingList);
        this.isLoading = false;
        this.resetManualInvoiceList();
        this.validmanualinvocingList = [];
        if (result.errors) {
            result.errors.forEach((error) => {
                const message = error.message.replace('GraphQL.ExecutionError: ', '');
                this.errHandler.emitError(message, 'invalid format', ErrorTypeEnum.Error);
            });
        } else if (result.data.manualInvoicing.confirm) {
            this.errHandler.emitError(this.resources.ManualInvoicesSavedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
            this.csvInput.reset();
        } else {
            this.errHandler.emitError('error', 'error', ErrorTypeEnum.Error);
        }
    }

    back() {
        this.resetManualInvoiceList();
        this.validmanualinvocingList = [];
        this.isLoading = false;
        this.showConfirm = false;
    }

    resetManualInvoiceList() {
        this.manualinvoicingList = [];
    }

    csvInputChange(value) {
        if (value) {
            const newValue = value.split('\t').join(',');
            if (this.getLineCount(newValue) <= 500) {
                this.csvInput.setValue(newValue, { emitViewToModelChange: false });
            } else {
                this.errHandler.emitError("Maximum of 500 entries allowed.", "Limit Exceeded!", ErrorTypeEnum.Error);
                this.csvInput.setErrors({ 'exceededMaxEntries': true });
            }
        }
    }

    getErrorMessage() {
        return this.csvInput.hasError('required') ? this.resources.RequiredFieldError :
            this.csvInput.hasError('invalidFormat') ? this.resources.InvalidCSV :
            this.csvInput.hasError('exceededMaxEntries') ? "You can only enter a maximum of 500 entries" : '';
    }


    csvFormatValidator(pattern: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value: string = control.value;
            let valid = true;

            if (this.getLineCount(value) > 500) {
                return { 'exceededMaxEntries': { value: control.value } };
            }
            if (value !== null) {
                value.toUpperCase().split("\n").forEach(line => valid = valid && (line.length == 0 || pattern.test(line)));
            }
            return valid ? null : { 'invalidFormat': { value: control.value } };
        };
    }

    getLineCount(value: string): number {
        return (value.match(/\n/g) || []).length + 1;
    }

}
