import * as _ from 'lodash';

import { OrchestratorState } from './state';
import { OrchestratorAction, OrchestratorActions } from './actions';
const initialState = require('./initial-data.json') as OrchestratorState;

export function OrchestratorReducer(
  state: OrchestratorState = initialState,
  action: OrchestratorAction
): OrchestratorState {
    switch (action.type) {
        case OrchestratorActions.LoadStepFault:
            return {
                ...state,
                stepFault: action.payload
            };
        case OrchestratorActions.LoadProductIndividualStepFault:
            return {
                ...state,
                productIndividualStepFault: action.payload
            };
        case OrchestratorActions.ChangeProdIndStepFaultTotalCountAction:
            return {
                ...state,
                prodIndStepFaultTotalCount: action.payload
            };
        case OrchestratorActions.ChangeStepFaultResultsTotalCountAction:
            return {
                ...state,
                stepFaultTotalCount: action.payload
            };
        case OrchestratorActions.ChangeStepFaultPageAction:
            return {
                ...state,
                stepFaultPaging: {
                    ...state.stepFaultPaging,
                    page: action.payload
                }
            };
        case OrchestratorActions.ChangeStepFaultSortExpressionAction:
            return {
                ...state,
                stepFaultPaging: { ...state.stepFaultPaging, page: 1 },
                stepFaultSortExpression: action.payload
            };
        case OrchestratorActions.ChangeStepFaultFilterExpressionAction:
            return {
                ...state,
                stepFaultPaging: { ...state.stepFaultPaging, page: 1 },
                stepFaultFilterExpression: action.payload
            };
        case OrchestratorActions.ChangeStepFaultSearchForm:
            return {
                ...state,
                stepFaultSearchForm: action.payload,
                stepFaultPaging: { ...state.stepFaultPaging, page: 1 }
            };
        default:
            return state;
    }
}
