import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { Simulation } from './state-models/simulation-data';

export enum SimulationActions {
    LoadSimulationListPageAction='[ Simulation ] Load simulation list',  
    ChangeSimulationListPageAction='[ Simulation ] Change simulation list',
    ChangeSimulationListSortExpressionAction='[ Simualation ] Change Sort Expression',
    ChangeSimulationListFilterExpressionAction='[ Simulation ] Change Filter Expression',
    ChangeSimulationListTotalCountAction='[ Simulation ] Total Count Expression'
}

export class ChangeSimulationListSortExpressionAction implements Action {
    readonly type = SimulationActions.ChangeSimulationListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeSimulationListFilterExpressionAction implements Action {
    readonly type = SimulationActions.ChangeSimulationListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeSimulationListTotalCountAction implements Action {
    readonly type = SimulationActions.ChangeSimulationListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class ChangeSimulationListPageAction implements Action {
    readonly type = SimulationActions.ChangeSimulationListPageAction;
    constructor(public payload: number) { }
}

export class LoadSimulationListPageAction implements Action {
    readonly type = SimulationActions.LoadSimulationListPageAction;
    constructor(public payload: Simulation[]) { }
}


export type SimulationAction =
    | LoadSimulationListPageAction   
    | ChangeSimulationListSortExpressionAction
    | ChangeSimulationListFilterExpressionAction
    | ChangeSimulationListTotalCountAction
    | ChangeSimulationListPageAction
    ;
