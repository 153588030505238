<div class="calsium-panel">
    <table [class]="listClasses" [ngClass]="{loading: isLoading}">
        <caption *ngIf="listConfig.ShowListCaption">
            <div style="float: left;" class="caption">
                {{listConfig.ListCaption}}&nbsp;&nbsp;(<span id="totalRowCount">{{getTotalResultCount()}}</span>)
            </div>
            <div style="float: right;">
                <button class="btn btn-sm btn-default" *ngIf="listConfig.ShowClearFilterSortingButton"
                    id="clearFilterAndSorting" (click)="clearFilterAndSorting()">
                    <i class="fas fa-lg fa-trash-alt"></i>&nbsp; {{resources.ClearFilterAndSort}}
                </button>
                <button class="btn btn-sm btn-default" *ngIf="listConfig.ShowPrint" id="print" (click)="print()">
                    <i class="fas fa-lg fa-print"></i>&nbsp; {{resources.Print}}
                </button>
                <button class="btn btn-sm btn-default" *ngIf="listConfig.ShowExportToExcel" id="exportToExcel"
                    (click)="exportToExcel()" [disabled]="exportButtonState">
                    <i class="fas fa-lg fa-file-excel"></i>&nbsp; {{resources.ExportToExcel}}
                </button>
            </div>
        </caption>

        <thead class="thead-dark">
            <!-- COLUMNS HEADERS ROW -->
            <tr>
                <th *ngIf="listConfig.HasSubComponent"></th>
                <ng-container *ngFor="let column of listConfig.ListColumns">
                    <th *ngIf="column.IsVisible | async" [class]="(column.TextAlignment || '')">
                        <!-- COLUMNS HEADERS -->
                        <div *ngIf="column.Caption" style="white-space: nowrap" [title]="column.Tooltip">
                            {{column.Caption}}
                        </div>
                        <div *ngIf="column.DynamicCaption" style="white-space: nowrap" [title]="column.Tooltip">
                            {{column.DynamicCaption | async}}
                        </div>
                    </th>
                </ng-container>
                <th *ngIf="listConfig.AllowEdit || listConfig.AllowDelete || listConfig.AllowAdd || listConfig.ActionsButttons.length">
                    &nbsp;
                </th>
            </tr>

            <!-- FILTER FIELDS ROW -->
            <tr *ngIf="listConfig.ShowFilterRow" class="filter-row" style="white-space: nowrap">
                <td *ngIf="listConfig.HasSubComponent"></td>
                <ng-container *ngFor="let column of listConfig.ListColumns">
                    <td *ngIf="column.IsVisible | async">
                        <div class="input-group input-group-sm {{column.DataType}}-parent-div">
                            <!-- FILTER INPUT -->
                            <ng-container *ngIf="column.DataType=='selector'">
                                <mat-checkbox (change)="selectAll($event)" [checked]="column.SelectorValue">
                                </mat-checkbox>
                            </ng-container>
                            <ng-container
                                *ngIf="column.AllowFilter && (column.DataType=='string' || column.DataType=='anchor')">
                                <input type="text" [(ngModel)]="column.FilterValue" (keyup.enter)="filterItems(column)"
                                    [placeholder]="resources.TypeToFilter" class="form-control"
                                    id="{{getSafeId('filter' + name + column.ColumnName)}}">
                            </ng-container>
                            <ng-container *ngIf="column.AllowFilter && (column.DataType=='number')">
                                <input type="number" [(ngModel)]="column.FilterValue"
                                    (keyup.enter)="filterItems(column)" [placeholder]="resources.TypeToFilter"
                                    class="form-control" id="{{getSafeId('filter' + name + column.ColumnName)}}">
                            </ng-container>
                            <ng-container *ngIf="column.AllowFilter && column.DataType=='boolean'">
                                <select [(ngModel)]="column.FilterValue" (ngModelChange)="filterItems(column)"
                                    class="form-control" id="{{getSafeId('filter'+ name + column.ColumnName)}}">
                                    <option disabled="disabled" selected>{{resources.SelectToFilter}}</option>
                                    <option value="">*{{resources.All}}*</option>
                                    <option value="true">
                                        {{column.FilterBooleanTrueText || resources.Yes}}
                                    </option>
                                    <option value="false">
                                        {{column.FilterBooleanFalseText || resources.No}}
                                    </option>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="column.AllowFilter && column.DataType=='date'">
                                <input type="date" [(ngModel)]="column.FilterValue"
                                    (ngModelChange)="filterItems(column)" [placeholder]="resources.TypeToFilter"
                                    class="form-control" id="{{getSafeId('filter' + name + column.ColumnName)}}"
                                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" max="9999-12-31">
                            </ng-container>
                            <ng-container *ngIf="column.AllowFilter && column.DataType=='list'">
                                <select [(ngModel)]="column.FilterValue" (ngModelChange)="filterItems(column)"
                                    class="form-control" id="{{getSafeId('filter'+ name + column.ColumnName)}}">
                                    <option disabled="disabled" selected>{{resources.SelectToFilter}}</option>
                                    <option value="">*{{resources['All']}}*</option>
                                    <option *ngIf="column.FilterOptions.AddEmptyDropDownListItem" value="null">
                                        *{{resources.Empty}}*
                                    </option>
                                    <option value="{{subItem.Value}}"
                                        *ngFor="let subItem of column.FilterOptions.FilterListItems | async">
                                        {{subItem.Text}}
                                    </option>
                                </select>
                            </ng-container>

                            <!-- SORT BUTTON -->
                            <ng-container *ngIf="column.Sortable">
                                <div class="input-group-append" [ngClass]="column.AllowFilter ? '' : 'no-filter'">
                                    <button class="btn btn-sm btn-info" id="sortDropdown" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="{{getSortIconClass(column)}}"></span>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" (click)="sortBy(column, 1)">
                                            <span class="fa fa-sort-amount-down-alt"></span> {{resources.Ascending}}
                                        </a>
                                        <a class="dropdown-item" (click)="sortBy(column, -1)">
                                            <span class="fa fa-sort-amount-down"></span> {{resources.Descending}}
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <td *ngIf="listConfig.AllowEdit || listConfig.AllowDelete || listConfig.AllowAdd || listConfig.ActionsButttons.length">&nbsp;</td>
            </tr>
        </thead>
        <tbody>
            <!-- DATA ROW -->
            <ng-container *ngFor="let item of filteredList; let i = index; trackBy: rowTrackById ">
                <tr app-calsiumlist-row [listConfig]="listConfig" [RowData]="item" [RowNumber]="i" [AddForm]="addForm"
                    [EditForm]="editForm" (id)="'row' + i" [selected]="isSelected(item)" [ngStyle]="listConfig.getSpeicalRowStyle(item)" appGlowElementOnFoucs></tr>
                <ng-container *ngIf="listConfig.HasSubComponent">
                    <tr *ngIf="toggleSubContent === i" (id)="'subContent' + i">
                        <td [attr.colspan]="listConfig.ListColumns.length + 1" style="background-color:wheat;">
                            <app-calsiumlist-sublist [inputComponent]="listConfig.SubComponent" [listComponent]="this">
                            </app-calsiumlist-sublist>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <!-- New Item Row-->
            <ng-container *ngIf="listConfig.AllowAdd">
                <tr app-calsiumlist-row [listConfig]="listConfig" [RowNumber]="filteredList.length + 1"
                    [IsAddMode]="true" [AddForm]="addForm" (id)="'row' + filteredList.length + 1" appGlowElementOnFoucs>
                </tr>
            </ng-container>

            <!--         <tr *ngIf="listConfig.AllowAdd ">
                <td *ngIf="listConfig.HasSubComponent"></td>
                <td *ngFor="let column of listConfig.ListColumns">
                    <app-calsiumlist-cell CurrentMode="AddMode" [Column]="column" [RowNumber]="i" [AddForm]="addForm" [EditForm]="editForm"></app-calsiumlist-cell>
                </td>
                <td *ngIf="listConfig.AllowEdit || listConfig.AllowDelete || listConfig.AllowAdd">
                    <button *ngIf="listConfig.AllowAdd" (click)="AddItem(addNewItemForm)">
                        <i *ngIf="listConfig.AllowAdd" class="fas fa-save"></i>
                    </button>
                </td>
            </tr> -->
            <!-- SUM ROW -->
            <tr *ngIf="listConfig.ShowSumRow">
                <th *ngIf="listConfig.HasSubComponent"></th>
                <th *ngFor="let column of listConfig.ListColumns " style="white-space: nowrap">
                    <span *ngIf="column.IsCurrency">{{getSum(column)}} SEK</span>
                    <span *ngIf="!column.IsCurrency">{{getSum(column)}}</span>
                </th>
            </tr>
        </tbody>

        <!-- TABLE FOOTER -->
        <tfoot *ngIf="listConfig.ShowFooter ">
            <tr>
                <td *ngIf="listConfig.HasSubComponent"></td>
                <td [attr.colspan]="listConfig.ListColumns.length ">
                    <div class="col-md-12 " *ngFor="let item of listConfig.ListFooterData "
                        style="border-bottom: 1px lightgrey solid ">
                        <div class="col-md-2 ">
                            <strong>{{item.Label}}</strong> : </div>
                        <div class="col-md-10 ">{{item.Text}}</div>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>