import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, combineLatest } from 'rxjs';

import { CommonStateService } from 'src/state';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { DeliveryRoute } from 'src/state/administraion/delivery-route/state-models/delivery-route';
import { DeliveryRouteStateService } from 'src/state/administraion/delivery-route/service';


@Component({
    selector: 'cal-delivery-route',
    templateUrl: './delivery-route.component.html',
    styleUrls: ['./delivery-route.component.css']
})
export class DeliveryRouteComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    dataList: Observable<DeliveryRoute[]>;
    isLoading: Observable<boolean>;
    listConfig: CalsiumListConfig;
    editItem: DeliveryRoute;

    constructor(
        private deliveryRouteService: DeliveryRouteStateService,
        private commonService: CommonStateService,
    ) { }

    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.sortingExpression = this.deliveryRouteService.getSortingExpression();
        this.filterExpression = this.deliveryRouteService.getFilterExpression();
        this.dataList = this.deliveryRouteService.getDeliveryRouteList();

        this.subscriptions.add(
            combineLatest(this.sortingExpression, this.filterExpression).pipe(
                debounceTime(0),
                switchMap(([sortingExpression, filterExpression]) => {
                    return this.deliveryRouteService.loadDeliveryRoute(sortingExpression, filterExpression);
                })
            ).subscribe()
        );
        this.buildList();
    }


    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.DeliveryRouteList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;
        this.listConfig.AllowEdit = false;
        this.listConfig.AllowDelete = false;
        this.listConfig.ShowExportToExcel = false;

        this.listConfig.ActionsButttons.push({ IconCssClass: 'fas fa-edit black', Title: 'Edit', OnClick: this.onEdit })

        let column;
        column = this.listConfig.AddListColumn(ColumnDataType.string, 'id', this.resources.Id);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'code', this.resources.Code);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'description', this.resources.Description);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.boolean, 'isDeliveryValue', this.resources.IsDeliveryValue);
        column.AllowFilter = true;
        column.Sortable = true;
        column.BooleanTrueIcon = 'check_box';
        column.BooleanFalseIcon = 'check_box_outline_blank';
        column.BooleanTrueIconStyle = { 'color': 'var(--info)' };
        column.BooleanFalseIconStyle = { 'color': 'var(--info)' };

        column = this.listConfig.AddListColumn(ColumnDataType.boolean, 'isMarkUpValue', this.resources.IsMarkUpValue);
        column.AllowFilter = true;
        column.Sortable = true;
        column.BooleanTrueIcon = 'check_box';
        column.BooleanFalseIcon = 'check_box_outline_blank';
        column.BooleanTrueIconStyle = { 'color': 'var(--info)' };
        column.BooleanFalseIconStyle = { 'color': 'var(--info)' };
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }


    onSort(sortExpression: string) {
        this.deliveryRouteService.sort(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.deliveryRouteService.filter(filterExpression);
    }

    onClearFilterAndSorting() {
        this.deliveryRouteService.clearFilterAndSorting();
    }
    onEdit(rowdata: DeliveryRoute){
        this.editItem = rowdata;
    }
}
