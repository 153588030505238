import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
const moment = _rollupMoment || _moment;

import { CommonStateService } from 'src/state';

export const CAL_DATE_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM',
    },
    display: {
        dateInput: 'YYYY-MM',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

@Component({
    selector: 'cal-month-picker',
    templateUrl: './month-picker.component.html',
    styleUrls: ['./month-picker.component.css'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: CAL_DATE_FORMATS }
    ]
})
export class MonthPickerComponent {
    @Input()
    set value(value: Moment) {
        this.date = new UntypedFormControl(value || moment());
    }
    @Output() valueChanged = new EventEmitter();
    date: UntypedFormControl;
    resources: any;

    constructor(private commonService: CommonStateService) {
        this.resources = this.commonService.getResources();
    }

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        if (ctrlValue) ctrlValue.year(normalizedYear.year());
        this.date.setValue(normalizedYear);
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>) {
        this.date.setValue(normalizedMonth);
        this.valueChanged.emit(this.date.value);
        datepicker.close();
    }
}
