import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { ErrorObject } from './error-object';
import { ErrorTypeEnum } from '../shared/enums.model';

@Directive()
@Injectable()
export class ErrorHandlerService {
    @Output() errorEvent: EventEmitter<ErrorObject>;
    private errorObj: ErrorObject;

    constructor() {
        this.errorEvent = new EventEmitter<ErrorObject>();
    }

    public getError(): ErrorObject {
        return this.errorObj;
    }

    public emitErrorObject(error: ErrorObject): void {
        this.errorObj = error;
        this.errorEvent.emit(this.errorObj);
    }

    public emitError(errorText: string, originator: string, errorType: ErrorTypeEnum): void {
        const eObj: ErrorObject = new ErrorObject(errorText, originator, errorType);
        this.emitErrorObject(eObj);
    }
}
