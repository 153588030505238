import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';

import { ProductIndividual } from 'src/state';
import { ComponentTypeProductionValue } from 'src/state/product-individual/state-models/component-type-production-value';
import { ComponentTypeManualProductionValue } from 'src/state/product-individual/state-models/component-type-manual-production-value';

@Injectable({
    providedIn: 'root'
})
export class ProductIndividualApiService {
    constructor(private apollo: Apollo) { }

    getProductIndividuals(variables?): Promise<ProductIndividual[]> {
        const result$ = this.apollo
            .query<ProductIndividual[]>({
                variables,
                query: gql`
                query($productIndividualId: Int) {
                    products {
                        search(productIndividualId: $productIndividualId) {
                            list {
                                id
                                productClassText
                                chassisNumber
                                productIndividualNumber
                                country {
                                    name
                                    iSOCode
                                }
                                fitForUseNumber
                                specialOrderNumber
                                uniqueDesignNumber
                                invoicedDate
                                finalAssemblyUnit
                                serialNumber
                                knr
                                totalMaterialValue
                                totalProductionValue
                                totalMarkupValue
                                totalCurrencyValue
                                baseCost
                                totalDeliveryValue
                                manualProductionValue
                                corporateMarkupPercentage
                            }
                        }
                    }
                }
            `})
            .pipe<ProductIndividual[]>(
             
                map((result: any) => {
                    return result?.data?.products?.search?.list;
                    })
                )
               
                return lastValueFrom(result$);
    }

    getProductIndividualSpecification(variables?) {
        const result$ = this.apollo
            .query<any>({
                variables,
                query: gql`
                query(
                    $paging: Paging
                    $order: String
                    $filter: String
                    $productIndividualId: Int
                ) {
                    products {
                    specification(
                        paging: $paging
                        order: $order
                        filter: $filter
                        productIndividualId: $productIndividualId
                    ) {
                        list {
                        id
                        variantOption {
                            value
                            description
                            variantFamily {
                            value
                            description
                            variantClass {
                                value
                                description
                            }
                            }
                        }
                        }
                        totalCount
                    }
                    }
                }
            `})
            .pipe(
           
               map((result: any) => {
                return result?.data?.products?.specification;
                })
            )
           
            return lastValueFrom(result$);
    }

    getProductIndividualProductionValues(variables?): Promise<ComponentTypeProductionValue[]> {
        const result$ = this.apollo
            .query<ComponentTypeProductionValue[]>({
                variables,
                query: gql`
                query($productIndividualId: Int, $allocationScaniaUnitId: Int) {
                    products {
                        productionValue(productIndividualId: $productIndividualId, allocationScaniaUnitId: $allocationScaniaUnitId) {
                            id
                            componentType {
                                id
                                name
                                description
                                code
                            }
                            productionPriceConditionPrice {
                                price
                                productionPriceCondition {
                                    id
                                    description
                                    productionPriceList {
                                        description
                                    }
                                    variantCodeSummary
                                }
                                productionPriceType {
                                    name
                                    description
                                    isManualType
                                }
                            }
                            productionValue
                            isUniqueDesign
                        }
                    }
                }
            `})
            .pipe<ComponentTypeProductionValue[]>(
           
             map((result: any) => {
                return result?.data?.products?.productionValue;
                })
            )
           
            return lastValueFrom(result$);
    }

    getProductIndividualManualProductionValues(variables?): Promise<ComponentTypeManualProductionValue[]> {
        const result$ = this.apollo
            .query<ComponentTypeManualProductionValue[]>({
                variables,
                query: gql`
                query($productIndividualId: Int, $allocationScaniaUnitId: Int) {
                    products {
                        manualProductionValue(productIndividualId: $productIndividualId, allocationScaniaUnitId: $allocationScaniaUnitId) {
                            id
                            componentType {
                                id
                                name
                                description
                                code
                            }
                            productionPriceType {
                                name
                                description
                                isManualType
                            }
                            value
                            comment
                        }
                    }
                }
            `})
            .pipe<ComponentTypeManualProductionValue[]>(
                             map((result: any) => {
                return result?.data?.products?.manualProductionValue;
                })
            )
           
            return lastValueFrom(result$);
    }

    findProductIndividual(variables?): Promise<ProductIndividual[]> {
        const result$ = this.apollo
            .query<ProductIndividual[]>({
                variables,
                query: gql`
                query($chassisNumberOrProdIndNumber: String) {
                    products {
                        search(chassisNumberOrProdIndNumber: $chassisNumberOrProdIndNumber) {
                            list {
                                id
                                chassisNumber
                                productIndividualNumber
                            }
                        }
                    }
                }
            `})
            .pipe<ProductIndividual[]>(
               map((result: any) => {
                return result?.data?.products?.search?.list;
                })
            )
           
            return lastValueFrom(result$);
    }

    findArchiveProductIndividual(variables?): Promise<ProductIndividual[]> {
        const result$ = this.apollo
            .query<ProductIndividual[]>({
                variables,
                query: gql`
                query($chassisNumberOrProdIndNumber: String) {
                    products {
                        searchArchive(chassisNumberOrProdIndNumber: $chassisNumberOrProdIndNumber) {
                            list {
                                id
                                chassisNumber
                                productIndividualNumber
                            }
                        }
                    }
                }
            `})
            .pipe<ProductIndividual[]>(
             
               map((result: any) => {
                return result?.data?.products?.searchArchive?.list;
                }
          ));

          return  lastValueFrom(result$);
    }
}
