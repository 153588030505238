<div class="card">
    <h3 class="card-header">{{resources.AddPrice}}</h3>
    <div class="card-body">
        <div class="container-fluid">
            <form [formGroup]="addPriceForm" (ngSubmit)="onSave()">
                <div class="row">
                    <div class="form-group col-md-2">
                        <label>{{resources.PriceType}}</label>
                        <div class="input-group">
                            <ng-container>
                                <select formControlName="materialPriceTypeId" class="form-control"
                                    (ngModelChange)="toggleValidTo($event); getStartDateIfNoPreviousPrices($event);"
                                    required>
                                    <option selected disabled="disabled" value="">Select price type...</option>
                                    <option *ngFor="let materialPriceType of materialPriceTypeList"
                                        value={{materialPriceType.id}}>
                                        {{materialPriceType.name}}
                                    </option>
                                </select>
                            </ng-container>
                        </div>
                    </div>
                    <div class="form-group col-md-2">
                        <label>{{resources.Price}}</label>
                        <div class="input-group">
                            <input formControlName="price" type="number" min="0" class="form-control"
                                placeholder="{{resources.Price}}" required />
                        </div>
                    </div>
                    <div class="form-group col-md-2">
                            <label>{{resources.From}}</label>
                        <div class="input-group">
                            <input formControlName="validFrom" type="date" class="form-control" max="9999-12-31"
                                required />
                        </div>
                    </div>
                    <div class="form-group col-md-2">
                        <label>{{resources.To}}</label>
                        <div class="input-group">
                            <input formControlName="validTo" type="date" class="form-control" max="9999-12-31"
                                required />
                        </div>
                    </div>
                    <div class="form-group col-md-2">
                        <label>{{resources.Comment}}</label>
                        <div class="input-group">
                            <textarea formControlName="comments" class="form-control" maxlength="1000"
                                placeholder="{{resources.Comment}}" required style="resize: none;" required></textarea>
                        </div>
                    </div>
                    <div class="form-group col-md-2">
                        <label>&nbsp;</label>
                        <button class="btn btn-sm btn-primary form-control" id="savePrice" type="submit">
                            <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<br />