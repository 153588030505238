<div class="card">
   <h3 class="card-header">{{resources.AddCurrencyAdjustmentCondition}}</h3>
   <div class="card-body">
      <div class="container-fluid">
         <div class="row">
            <div class="form-group col-sm-2">
               <label>{{resources.ProductClass}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="productClassInput">
                        <mat-option *ngFor="let item of productClassList | async" [value]="item.id">
                           {{item.variantOption.description}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.Market}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="marketInput">
                        <mat-option *ngFor="let item of marketList | async" [value]="item.id">
                           {{item.name}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{ resources.From }}</label>
               <div class="input-group">
                   <mat-form-field style="width: 100%;" appearance="outline">
                         <input matInput [formControl]="validFrom"  type="date" placeholder="{{resources.validFrom}}"  />
               </mat-form-field>
               </div>
       </div>
       <div class="form-group col-md-2">
           <label>{{ resources.To }}</label>
           <div class="input-group">
               <mat-form-field style="width: 100%;" appearance="outline">
                    <input matInput [formControl]="validTo"  type="date"  placeholder="{{ resources.validTo }}" />
               </mat-form-field>
           </div>
       </div>
            <div class="form-group col-md-2">
               <label>{{resources.MaterialCode}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="materialCodeInput">
                        <mat-option *ngFor="let item of codeList | async" [value]="item.id">
                           {{item.code}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.ProductionValueCode}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="pvCodeInput">
                        <mat-option *ngFor="let item of codeList | async" [value]="item.id">
                           {{item.code}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>         
         </div>
         <div class="row flex-row-reverse">
            <div class="form-group col-md-2">
               <label>&nbsp;</label>
               <button type="button" class="btn btn-sm btn-primary form-control" id="saveDeliveryRoute"
                  (click)="onAdd()">
                  <i class="fas fa-lg fa-plus-square"></i>&nbsp; {{resources.Add}}
               </button>
            </div>
            <div class="form-group col-md-2" *ngIf="showSave">
               <label>&nbsp;</label>
               <button id="savebtn" type="button" class="btn btn-sm btn-primary form-control" [ngClass]="{'disabled': isSaving }"
                [disabled]="isSaving" (click)="onSave()">
                  <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
               </button>
            </div>
         </div> 
      </div>
   </div>
</div>
<br />
