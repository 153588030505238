<nav *ngIf="pagesCount > 1" role="navigation">
    <ul class="cd-pagination animated-buttons custom-icons">
        <li class="button"><a [ngClass]="{'disabled': !showPrevious }" (click)="onPageChanging(currentPage - 1)"><i
                    class="fas fa-arrow-left"></i></a></li>
        <a *ngIf="showFirstPage" (click)="onPageChanging(1)">1</a>
        <input #firstGoToPage [hidden]="!showFirstPage || !showFirstGoToPage" type="string" size="3" minlength="1" maxlength="4"
            (keyup.enter)="goToPage($event)">
        <li *ngIf="showFirstPage && !showFirstGoToPage"><a *ngIf="showFirstPage"
                (click)="showGoToPageClicked(true,firstGoToPage)">...</a></li>

        <li *ngFor="let page of displayPages">
            <a [ngClass]="{'current': currentPage == page }" (click)="onPageChanging(page)">{{page}}</a>
        </li>
        <input #lastGoToPage  [hidden]="!showLastPage || !showLastGoToPage" type="string" size="3" minlength="1" maxlength="4"
            (keyup.enter)="goToPage($event)">
        <li *ngIf="showLastPage && !showLastGoToPage"><a *ngIf="showLastPage" (click)="showGoToPageClicked(false,lastGoToPage)">...</a></li>
        <a *ngIf="showLastPage" (click)="onPageChanging(pagesCount)">{{pagesCount}}</a>
        <li class="button"><a [ngClass]="{'disabled': !showNext }" (click)="onPageChanging(currentPage + 1)"><i
                    class="fas fa-arrow-right"></i></a></li>
    </ul>
</nav> <!-- cd-pagination-wrapper -->