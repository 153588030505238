import { Component, OnInit } from '@angular/core';
import {  OnDestroy } from "@angular/core";
import {  UntypedFormControl, Validators } from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { ErrorHandlerService } from "src/app/errorhandler/error-handler.service";
import { ErrorTypeEnum } from "src/app/shared/enums.model";
import { CommonStateService } from "src/state";
import { MarkupConditionMaterialStateServiceService } from "src/state/administraion/markup-condition-material/service";
import {    MarkupConditionMaterialAdd,} from "src/state/administraion/markup-condition-material/state-models/markup-condition-material";
import { MarkupConditionMaterialApiService } from "src/api/services/markup-condition-material-api.service";

@Component({
    selector: "cal-add-markup-condition-material",
    templateUrl: "./add-markup-condition-material.component.html",
    styleUrls: ["./add-markup-condition-material.component.css"],
})
export class AddMarkupConditionMaterialComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    markupConditionId: number;
    markupCondition: UntypedFormControl;
    materialId: UntypedFormControl;
    validFrom: UntypedFormControl;
    validTo: UntypedFormControl;
    codeInput: UntypedFormControl;
    markupConditionList: any;
    constructor(
        private stateService: MarkupConditionMaterialStateServiceService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private apiService: MarkupConditionMaterialApiService
    ) {}

    async ngOnInit(): Promise<void> {
        this.buildForm();
        this.stateService.loadMarkupCondition();
        this.markupConditionList = await this.apiService.getMarkupCondition();
    }

    buildForm() {
        this.markupCondition = new UntypedFormControl("", [Validators.required]);
        this.materialId = new UntypedFormControl("", [Validators.required]);
        this.validFrom = new UntypedFormControl(null, [Validators.required]);
        this.validTo = new UntypedFormControl(null);
    }

    onSave() {
        if (this.isValidForm()) {
            const payload: MarkupConditionMaterialAdd = {
                markupConditionId: this.markupCondition.value,
                materialId: this.materialId.value,
                validFrom: this.validFrom.value,
                validTo: this.validTo.value,
            };

            this.stateService
                .addMarkupConditionMaterial(payload)
                .then((errors) => {
                    let errorMessage = "";
                    if (errors && errors.length) {
                        for (let error of errors) {
                            const errorType = error
                                .split("\n")[0]
                                .replace("GraphQL.ExecutionError:", "")
                                .trim();
                            switch (errorType) {
                                case "ALREADY_EXIST":
                                    errorMessage +=
                                        this.resources
                                            .MarkupConditionMaterialExist;
                                    break;
                                case "INVALID_PART_NUMBER":
                                    errorMessage +=
                                        this.resources.InvalidPartNumber;
                                    break;
                                case "INVALID_MARKUP_CONDITION":
                                    errorMessage +=
                                        this.resources.InvalidMarkupCondition;
                                    break;
                                case "INVALID_DATE_TO":
                                    errorMessage +=
                                        this.resources.ValidFromValidToCorrect;
                                    break;
                                default:
                                    errorMessage += this.resources.ErrorOccured;
                                    break;
                            }
                        }
                        this.errHandler.emitError(
                            errorMessage,
                            this.resources.Error,
                            ErrorTypeEnum.Error
                        );
                    } else {
                        this.errHandler.emitError(
                            this.resources.SavedSuccessfully,
                            this.resources.Success,
                            ErrorTypeEnum.Success
                        );
                        this.reset();
                    }
                });
        }
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.markupCondition.errors != null) {
            if (this.markupCondition.errors.required) {
                this.errHandler.emitError(
                    this.resources.MarkupConditionRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }
        if (this.materialId.errors != null) {
            if (this.materialId.errors.required) {
                this.errHandler.emitError(
                    this.resources.PartNumberRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }

        if (this.validFrom.errors != null) {
            if (this.validFrom.errors.required) {
                this.errHandler.emitError(
                    this.resources.ValidDateFromRequired,
                    this.resources.RequiredFieldError,
                    ErrorTypeEnum.Error
                );
                valid = false;
            }
        }

        if (this.validFrom.value > this.validTo.value) {
            this.errHandler.emitError(
                this.resources.ValidFromValidToCorrect,
                this.resources.Error,
                ErrorTypeEnum.Error
            );
            valid = false;
        }

        return valid;
    }

    reset() {
        if (this.markupCondition) {
            this.markupCondition.reset();
            this.materialId.reset();
            this.validFrom.reset();
            this.validTo.reset();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onCancel(){
        this.reset()
    }
}
