import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map,lastValueFrom } from 'rxjs';
import { SystemNotification } from '../../state';

@Injectable({
    providedIn: 'root'
})
export class NotificationsApiService {
    constructor(private apollo: Apollo) { }

    getSystemNotifications(): Promise<SystemNotification[]> {
        const result$ = this.apollo
            .query<SystemNotification[]>({
                query: gql`
                {
                    notifications{
                        systemNotification{
                          message
                          notificationType
                          validFrom
                          validTo
                        }
                      }
                }`
            })
            .pipe<SystemNotification[]>(
               
                map((result: any) => {
                    return result?.data?.notifications?.systemNotification;
                    })
            )

            return lastValueFrom(result$);
    }
}
