import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, combineLatest } from 'rxjs';

import { CommonStateService, Paging } from 'src/state';
import { DeliveryRouteAllocationUnit } from 'src/state/administraion/delivery-route-allocation-unit/state-models/delivery-route-allocation-unit';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { DeliveryRouteAllocationUnitStateService } from 'src/state/administraion/delivery-route-allocation-unit/service';
import { debounceTime, switchMap } from 'rxjs/operators';


@Component({
    selector: 'cal-delivery-route-allocation-unit',
    templateUrl: './delivery-route-allocation-unit.component.html',
    styleUrls: ['./delivery-route-allocation-unit.component.css']
})
export class DeliveryRouteAllocationUnitComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    dataList: Observable<DeliveryRouteAllocationUnit[]>;
    isLoading: Observable<boolean>;
    listConfig: CalsiumListConfig;
    paging: Observable<Paging>;
    totalCount: Observable<number>;

    constructor(
        private stateService: DeliveryRouteAllocationUnitStateService,
        private commonService: CommonStateService,
    ) { }

    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.sortingExpression = this.stateService.getSortingExpression();
        this.filterExpression = this.stateService.getFilterExpression();
        this.dataList = this.stateService.getList();
        this.paging = this.stateService.getPaging()
        this.totalCount = this.stateService.getTotalCount();

        this.subscriptions.add(
            combineLatest(this.sortingExpression, this.filterExpression, this.paging).pipe(
                debounceTime(0),
                switchMap(([sortingExpression, filterExpression, paging]) => {
                    return this.stateService.loadList(sortingExpression, filterExpression, paging);
                })
            ).subscribe()
        );
        this.buildList();
    }


    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.DeliveryRouteList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;
        this.listConfig.AllowEdit = false;
        this.listConfig.AllowDelete = false;
        this.listConfig.ShowExportToExcel = false;

        let column;
        column = this.listConfig.AddListColumn(ColumnDataType.string, 'id', this.resources.Id);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'propertyCode', this.resources.PropertyCode);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'deliveryRoute.code', this.resources.DeliveryRouteCode);
        column.AllowFilter = true;
        column.Sortable = true;
        
        column = this.listConfig.AddListColumn(ColumnDataType.string, 'scaniaUnitAllocation.scaniaUnitComponentType.componentType.name', this.resources.AllocationComponentType);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'scaniaUnitAllocation.scaniaUnitComponentType.scaniaUnit.name', this.resources.AllocationFrom);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'scaniaUnitAllocation.allocationScaniaUnit.name', this.resources.AllocationTo);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'scaniaUnitConsolidation.productClass.variantOption.description', this.resources.ConsolidationProductClass);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'scaniaUnitConsolidation.scaniaUnit.name', this.resources.ConsolidationFrom);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'scaniaUnitConsolidation.consolidatingScaniaUnit.name', this.resources.ConsolidationTo);
        column.AllowFilter = true;
        column.Sortable = true;
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }


    onSort(sortExpression: string) {
        this.stateService.sort(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.stateService.filter(filterExpression);
    }

    onClearFilterAndSorting() {
        this.stateService.clearFilterAndSorting();
    }

    onPaging(page: number) {
        this.stateService.goToPage(page);
    }
}