import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CalsiumListConfig, CalsiumListColumnItem, ColumnDataType } from '../../../../calsiumlist/calsiumlist.component.model';
import { ComponentDetailsStateService } from '../../../../../state/componentDetails/service';
import { Paging, CommonStateService, MasterDataStateService } from '../../../../../state';
import { CalsiumNumberFormatterPipe } from '../../../../shared/calsium-number.pipe';
import { ComponentIndividualMaterial } from '../../../../../state/componentDetails/state-models/component-individual-material';
import { UnitOfMeasurement } from '../../../../../state/master-data/state-models/unit-of-measurement';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { TextAlignment } from '../../../../shared/enums.model';
import { debounceTime, switchMap, map } from 'rxjs/operators';

@Component({
    selector: 'cal-comp-materials',
    templateUrl: './materials.component.html',
    styleUrls: ['./materials.component.css']
})
export class MaterialsComponent implements OnInit, OnDestroy {
    @Input() componentId: number;
    @Output() totalMaterialCountChanged = new EventEmitter<number>();

    resources: any = this.commonService.getResources();
    materialList: ComponentIndividualMaterial[] = [];
    unitOfMeasurmentList: UnitOfMeasurement[];

    listConfig: CalsiumListConfig;
    componentUnitColumn: CalsiumListColumnItem;
    filterExpression: Observable<string>;
    showZeroQuantityParts: Observable<boolean>;
    componentMaterial: Observable<ComponentIndividualMaterial[]>;
    materialSortingExpression: Observable<string>;
    materialFilterExpression: Observable<string>;
    materialPaging: Observable<Paging>;
    materialTotalCount: Observable<number>;
    unitOfMeasurementList$: Observable<UnitOfMeasurement[]>;
    totalMaterialFullCount: number;
    isMaterialsLoading = true;

    subscriptions = new Subscription();

    constructor(private apiService: ComponentDetailsStateService,
        private commonService: CommonStateService,
        private masterDataService: MasterDataStateService) { }
    ngOnInit() {
        this.filterExpression = this.apiService.getComponentMaterialsFilterExpression();
        this.materialSortingExpression = this.apiService.getComponentMaterialsSortingExpression();
        this.materialFilterExpression = this.apiService.getComponentMaterialsFilterExpression();
        this.materialTotalCount = this.apiService.getComponentMaterialTotalCount();
        this.materialPaging = this.apiService.getComponentMaterialPaging();
        this.componentMaterial = this.apiService.getComponentMaterials();
        this.unitOfMeasurementList$ = this.masterDataService.getUnitOfMeasurments();
        this.showZeroQuantityParts = this.apiService.getShowZeroQuantityParts();

        this.subscriptions.add(
            combineLatest(this.materialSortingExpression, this.materialFilterExpression, this.materialPaging, this.showZeroQuantityParts)
                .pipe(
                    debounceTime(0),
                    switchMap(([sort, filter, paging, showZeroQuantityParts]) => {
                        this.isMaterialsLoading = true;
                        return this.apiService.loadComponentDetailsMaterials(this.componentId, paging, sort, filter, showZeroQuantityParts)
                            .then(_ => this.isMaterialsLoading = false);
                    })
                ).subscribe()
        );

        this.subscriptions.add(
            combineLatest(this.materialFilterExpression, this.materialTotalCount)
                .pipe(
                    map(([filterExpression, totalCount]) => {
                        this.totalMaterialFullCount = filterExpression != null ? this.totalMaterialFullCount : totalCount;
                        this.totalMaterialCountChanged.emit(this.totalMaterialFullCount);
                    })
                ).subscribe()
        );

        this.subscriptions.add(
            combineLatest(this.componentMaterial, this.unitOfMeasurementList$)
                .pipe(
                    map(([materialList, unitOfMeasurementList]) => {
                        this.materialList = materialList;
                        this.unitOfMeasurmentList = unitOfMeasurementList;
                        this.calculateBaseUnit();
                    })
                ).subscribe()
        );

        this.buildList();
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    calculateBaseUnit() {
        if (this.materialList != null && this.materialList.length > 0 &&
            this.unitOfMeasurmentList != null && this.unitOfMeasurmentList.length > 0) {
            const newMaterialList = [];
            for (let i = 0; i < this.materialList.length; i++) {
                const newItem = { ...this.materialList[i], baseunit: this.getUOMBaseUnit(this.materialList[i].consumedUnitOfMeasurement) };
                newMaterialList.push(newItem);
            }
            this.materialList = newMaterialList;
        }
    }

    getUOMBaseUnit(consumedUnitOfMeasurement: UnitOfMeasurement) {
        let typeId = -1;
        for (let i = 0; i < this.unitOfMeasurmentList.length; i++) {
            if (this.unitOfMeasurmentList[i].name === consumedUnitOfMeasurement.name) {
                typeId = this.unitOfMeasurmentList[i].unitOfMeasurementType.id;
                break;
            }
        }
        if (typeId >= 0) {
            for (let i = 0; i < this.unitOfMeasurmentList.length; i++) {
                if (this.unitOfMeasurmentList[i].unitOfMeasurementType.id === typeId &&
                    this.unitOfMeasurmentList[i].isBaseUnit) {
                    return this.unitOfMeasurmentList[i].name;
                }
            }
        }
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ShowListCaption = true;
        this.listConfig.ListCaption = this.resources.ComponentPartsList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ShowSumRow = false;
        this.listConfig.HasSubComponent = false;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = false;
        this.listConfig.AllowAdd = false;
        this.listConfig.AllowEdit = false;
        this.listConfig.AllowDelete = false;
        this.listConfig.ShowExportToExcel = true;

        let column = this.listConfig.AddListColumn(ColumnDataType.string, 'material.materialNumber', this.resources.MaterialNumber);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'material.name', this.resources.Description);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'componentUnit.code', this.resources.ComponentUnitShort, this.resources.ComponentUnitCode);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'componentUnit.description', this.resources.ComponentUnitDescriptionShort, this.resources.ComponentUnitDescription);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'quantityInBaseUnit', this.resources.Quantity);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 4);

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'baseunit', this.resources.BaseUnit);
        column.AllowFilter = false;
        column.Sortable = false;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'pricePerPartInBaseUnit', this.resources.PricePerUnit);
        column.AllowFilter = false;
        column.Sortable = false;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(4, 4);
        column.TextAlignment = TextAlignment.Right;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'price', this.resources.TotalPrice);
        column.AllowFilter = false;
        column.Sortable = false;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(2, 2);
        column.TextAlignment = TextAlignment.Right;
    }

    onSort(sortExpression: string) {
        this.apiService.sortComponentMaterials(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.apiService.filterComponentMaterials(filterExpression);
    }

    onClearFilter() {
        this.apiService.filterComponentMaterials(null);
    }

    onPaging(page: number) {
        this.apiService.goToComponentMaterialPage(page);
    }

    onExport() {
        this.apiService.exportComponentMaterials(this.componentId);
    }

    onShowZeroQuantityChange(showZeroQuantityParts: boolean) {
        this.apiService.showZeroQuantityParts(showZeroQuantityParts);
    }
}
