import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription, combineLatest, of, ReplaySubject } from 'rxjs';
import { CommonStateService, Paging } from 'src/state';
import { CalsiumListConfig, ColumnDataType, CalsiumListColumnItem } from 'src/app/calsiumlist/calsiumlist.component.model';
import { CalsiumNumberFormatterPipe } from 'src/app/shared/calsium-number.pipe';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { TextAlignment, ErrorTypeEnum } from 'src/app/shared/enums.model';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { FinalAssemblyAllocation } from 'src/state/final-assembly-allocation/state-models/final-assembly-allocation';
import { AllocationSearchForm } from 'src/state/final-assembly-allocation/state-models/allocation-search-form';
import { FinalAssemblyAllocationStateService } from 'src/state/final-assembly-allocation/service';
import { AllocationUnitColumns } from 'src/state/final-assembly-allocation/state-models/final-assembly-allocation-unit-columns';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CalculationDetailsComponent } from '../../calculation-details/calculation-details.component';
import { ConfirmationDialogService } from 'src/app/common/confirmation-dialog/confirmation-dialog.service';
import { OrchestratorStateService } from 'src/state/orchestrator/service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'cal-final-assembly-allocation-component-list',
    templateUrl: './final-assembly-component-list.component.html',
    styleUrls: ['./final-assembly-component-list.component.css']
})
export class FinalAssemblyAllocationComponentListComponent implements OnInit, OnDestroy {

    @Input() allocationStatus: string;
    @Input()
    set searchExpression(value: string) {
        if (this.prevSearchExpression !== value) {
            this.prevSearchExpression = value;
            this.onSelectAllChange(false);
            if (this.selectorColumn) this.selectorColumn.SelectorValue = false;
        }
    }
    @Input() populateListName: string;
    componentType: string;
    prevSearchExpression: string;
    resources: any = this.commonService.getResources();
    dataList: Observable<FinalAssemblyAllocation[]>;
    listConfig: CalsiumListConfig;
    paging: Observable<Paging>;
    totalCount: Observable<number>;
    errorCount: Observable<number>;
    subscriptions = new Subscription();
    isLoading: Observable<boolean>;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    componentSearchForm: Observable<AllocationSearchForm>;
    selectedCount: Observable<number> = of(0);
    selectedCountValue: number = 0;
    selectAll: boolean = false;
    selectedItems: number[] = [];
    excludedItems: number[] = [];
    selectorColumn: CalsiumListColumnItem;
    columnsConfigration: Observable<AllocationUnitColumns[]>;
    currentColumns: Observable<string[]> = of([]);
    actionMenuEnabled = true;
    recalculationTriggered = new ReplaySubject<void>();
    scaniaUnitId:number;

    constructor(private finalAssemblyAllocationService: FinalAssemblyAllocationStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private orchestratorService: OrchestratorStateService,
        private confirmationDialog: ConfirmationDialogService,
        public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.componentSearchForm = this.finalAssemblyAllocationService.getComponentSearchForm();
        this.sortingExpression = this.finalAssemblyAllocationService.getSortingExpression(this.allocationStatus);
        this.filterExpression = this.finalAssemblyAllocationService.getFilterExpression(this.allocationStatus);
        this.totalCount = this.finalAssemblyAllocationService.getTotalCount(this.allocationStatus);
        this.errorCount = this.finalAssemblyAllocationService.getErrorCount(this.allocationStatus);
        this.paging = this.finalAssemblyAllocationService.getPaging(this.allocationStatus);
        this.dataList = this.finalAssemblyAllocationService.getList(this.allocationStatus);
        this.columnsConfigration = this.finalAssemblyAllocationService.getAllocationUnitColumnsConfigration();
        this.currentColumns = this.finalAssemblyAllocationService.getCurrentUnitColumns();
        this.recalculationTriggered.next();

        this.subscriptions.add(
            combineLatest(this.componentSearchForm, this.paging, this.sortingExpression, this.filterExpression, this.columnsConfigration, this.recalculationTriggered).pipe(
                debounceTime(0),
                switchMap(([componentSearchForm, paging, sortingExpression, filterExpression, columnsConfigration, _]) => {
                    if (componentSearchForm != null && this.allocationStatus == this.populateListName) {
                        this.scaniaUnitId=componentSearchForm.selectedScaniaUnitId;
                        return this.finalAssemblyAllocationService.loadFinalAssemblyAllocationList(
                            this.allocationStatus,
                            componentSearchForm.selectedScaniaUnitId,
                            componentSearchForm.fromDate,
                            componentSearchForm.toDate,
                            paging,
                            sortingExpression,
                            filterExpression,
                            columnsConfigration,
                            true
                        );
                    }
                    return new Promise<void>(null);
                })
            ).subscribe()
        );
        this.buildList();
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.List;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.IsSelectorEnabled = _ => true;
        this.listConfig.IsSelected = this.isSelected;

        let column;

        this.selectorColumn = this.listConfig.AddListColumn(ColumnDataType.selector, null, null);
        this.selectorColumn.TextAlignment = TextAlignment.Center;


        column = this.listConfig.AddListColumn(ColumnDataType.string, 'chassisNumber', this.resources.ChassisNumber);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'serialNumber', this.resources.SerialNumber);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'productIndividualNumber', this.resources.ProductIndividual);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'countryCode', this.resources.CountryCode);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'chassisType', this.resources.ChassisType);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'productionDate', this.resources.ProductionDate);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'invoiceDate', this.resources.InvoiceDate);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.boolean, 'fullyAllocated', this.resources.AllocationStatus);
        column.AllowFilter = true;
        column.Sortable = true;
        column.TextAlignment = TextAlignment.Center;
        column.FilterBooleanTrueText = this.resources.Completed;
        column.FilterBooleanFalseText = this.resources.NotCompleted;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'finalAssemblyComponentDeliveryValue', null);
        column.DynamicCaption = this.dataList.pipe(map(x => x && x.length ? x[0].finalAssemblyComponentTypeName : null));
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
        column.TextAlignment = TextAlignment.Right;
        column.IsVisible = this.dataList.pipe(map(x => x ? x.length : false));

        const captilize = str => str[0].toUpperCase() + str.slice(1);
        ['axle', 'batteryVehicle', 'cab', 'engineVehicle', 'gearboxVehicle', 'gosParts', 'painted', 'spare', 'electricPropVehicle'].forEach((name) => {
            column = this.listConfig.AddListColumn(ColumnDataType.number, name, this.resources[captilize(name)]);
            column.AllowFilter = true;
            column.Sortable = true;
            column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
            column.TextAlignment = TextAlignment.Right;
            column.IsVisible = this.isColumnVisible(name);
        });

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'totalValue', this.resources.TotalValue);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
        column.TextAlignment = TextAlignment.Right;
        column.IsVisible = this.isColumnVisible(column.ColumnName);

        column = this.listConfig.AddListColumn(ColumnDataType.details, '', '');
        column.AllowFilter = false;
        column.Sortable = false;
        column.IsClickable = _ => true;
        column.OnClick = this.viewCalculationDetails;
        column.GetAnchorLink = RowData => `/calculation-details/${RowData.productIndividualId}/0`;
    }

    viewCalculationDetails(RowData) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = { productIndividualId: RowData.productIndividualId };
        dialogConfig.height = '80%';
        dialogConfig.width = '80%';

        this.dialog.open(CalculationDetailsComponent, dialogConfig);
    }

    isColumnVisible(columnName: string): Observable<boolean> {
        return this.currentColumns.pipe(map((x) => {
            return x.indexOf(columnName) >= 0;
        }));
    }

    onPaging(page: number) {
        this.finalAssemblyAllocationService.goToPage(this.allocationStatus, page);
    }

    onSort(sortExpression: string) {
        this.finalAssemblyAllocationService.sort(this.allocationStatus, sortExpression);
    }

    onFilter(filterExpression: string) {
        this.finalAssemblyAllocationService.filter(this.allocationStatus, filterExpression);
    }

    onClearFilterAndSorting() {
        this.finalAssemblyAllocationService.clearFilterAndSorting(this.allocationStatus);
    }

    onSelectAllChange(select: boolean) {
        this.selectAll = select;
        if (select) {
            combineLatest(this.totalCount, totalCount => { this.selectedCount = of(totalCount); }).subscribe().unsubscribe();
        } else {
            this.selectedCount = of(0);
        }

        this.subscriptions.add(this.selectedCount.subscribe(value => this.selectedCountValue = value));
        this.selectedItems = [];
        this.excludedItems = [];
    }

    showSelectActionButton()
    {    
        // Hiding Select Action Button, if FA product is SLA and its status is Allocated
        if(this.allocationStatus === 'allocated' && environment.scaniaUnitIdsForSLA.includes(this.scaniaUnitId))
            return false;
        return true;
    }

    onRowSelectionChange(rowSelectionData: { selected: boolean, rowdata: FinalAssemblyAllocation }) {
        if (this.selectAll) {
            if (!rowSelectionData.selected) {
                this.excludedItems.push(rowSelectionData.rowdata.id);
            } else if (rowSelectionData.selected) {
                this.excludedItems = this.excludedItems.filter(x => x !== rowSelectionData.rowdata.id);
            }
        } else {
            if (rowSelectionData.selected) {
                this.selectedItems.push(rowSelectionData.rowdata.id);
            } else if (!rowSelectionData.selected) {
                this.selectedItems = this.selectedItems.filter(x => x !== rowSelectionData.rowdata.id);
            }
        }
        const selectionChange = rowSelectionData.selected ? 1 : -1;
        this.selectedCount = combineLatest(this.selectedCount, selectedCount => selectedCount + selectionChange);
        this.subscriptions.add(this.selectedCount.subscribe(value => this.selectedCountValue = value));
    }

    onExport() {
        this.finalAssemblyAllocationService.exportAllocationData(this.allocationStatus);
    }

    isSelected(data: FinalAssemblyAllocation): boolean {
        const selected = this.selectAll || this.selectedItems.findIndex(x => x === data.id) >= 0;
        return selected;
    }

    async allocate() {
        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.AllocateConfirmation.formatUnicorn(this.selectedCountValue, this.resources.product_s)
                + this.resources.AllocateProductConfirmationNotCompletedSelected,
                this.resources.AllocateSelected);
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(this.componentSearchForm, this.filterExpression, this.columnsConfigration, (searchForm, filter, columnsConfigration) => {
                this.finalAssemblyAllocationService.allocate(
                    searchForm.selectedScaniaUnitId,
                    searchForm.fromDate,
                    searchForm.toDate,
                    filter,
                    this.selectAll,
                    this.selectedItems,
                    this.excludedItems,
                    columnsConfigration, this.populateListName)
                    .then((data) => {
                        this.actionMenuEnabled = true;
                        this.onSelectAllChange(false);
                        if (data.errors) {
                            this.errHandler.emitError(this.resources.AllocateError, this.resources.Error, ErrorTypeEnum.Error);
                        } else {
                            if (data.success > 0) {
                                this.errHandler.emitError(this.resources.AllocatedSuccessfully.formatUnicorn(data.success), this.resources.Success, ErrorTypeEnum.Success);
                            } else {
                                this.errHandler.emitError(this.resources.NotAllocated, this.resources.Info, ErrorTypeEnum.Info);
                            }
                        }
                    }, (error) => {
                        this.actionMenuEnabled = true;
                        this.errHandler.emitError(this.resources.AllocateError, this.resources.Error, ErrorTypeEnum.Error);
                    });
            }).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(this.resources.SelectProduct, this.resources.NoProductsSelected, ErrorTypeEnum.Info);
        }
    }

    async recalculate(allocationStatus: string) {
        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.RecalculateConfirmation.formatUnicorn(this.selectedCountValue, this.resources.product_s),
                this.resources.Recalculate.formatUnicorn(this.resources.product_s));
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(this.componentSearchForm, this.filterExpression,
                (searchForm, filter) => {
                    this.orchestratorService.recalculateProducts(
                        searchForm.selectedScaniaUnitId,
                        allocationStatus,
                        searchForm.fromDate,
                        searchForm.toDate,
                        filter,
                        this.selectAll,
                        this.selectedItems,
                        this.excludedItems,
                        'FinalAssembly')
                        .then((data) => {
                            this.actionMenuEnabled = true;
                            this.onSelectAllChange(false);
                            if (data > 0) {
                                this.errHandler.emitError(this.resources.RecalculationStarted.formatUnicorn(data || 0, this.resources.product_s), this.resources.Success, ErrorTypeEnum.Success);
                                this.recalculationTriggered.next();
                            } else {
                                this.errHandler.emitError(this.resources.RecalculationNotStarted, this.resources.Info, ErrorTypeEnum.Info);
                            }
                        }, (error) => {
                            this.actionMenuEnabled = true;
                            this.errHandler.emitError(this.resources.RecalculationFailed, this.resources.Error, ErrorTypeEnum.Error);
                        });
                }).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(this.resources.SelectComponent, this.resources.NoComponentsSelected, ErrorTypeEnum.Info);
        }
    }

    async deallocation(allocationStatus: string) {
        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.DeallocationConfirmation.formatUnicorn(this.selectedCountValue, this.resources.product_s),
                this.resources.Deallocate.formatUnicorn(this.resources.product_s));
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(this.componentSearchForm, this.filterExpression,
                this.columnsConfigration,
                (searchForm, filter, columnsConfigration) => {
                    this.orchestratorService.deallocateProducts(
                        searchForm.selectedScaniaUnitId,
                        allocationStatus,
                        searchForm.fromDate,
                        searchForm.toDate,
                        filter,
                        this.selectAll,
                        this.selectedItems,
                        this.excludedItems,
                        'FinalAssembly')
                        .then(async (data) => {
                            this.actionMenuEnabled = true;
                            this.onSelectAllChange(false);
                            if (data > 0) {
                                let result = await this.finalAssemblyAllocationService.deallocateByProducts(this.allocationStatus, searchForm.selectedScaniaUnitId, searchForm.fromDate, searchForm.toDate, columnsConfigration, data);
                                if (result) {
                                    this.selectAll == true ? false : this.selectAll;
                                    this.errHandler.emitError(this.resources.DeallocationSuccessfully.formatUnicorn(data || 0, this.resources.product_s), this.resources.Success, ErrorTypeEnum.Success);
                                }
                            } else {
                                this.errHandler.emitError(this.resources.DeallocationFANotStarted, this.resources.Info, ErrorTypeEnum.Info);
                            }
                        }, (error) => {
                            this.actionMenuEnabled = true;
                            this.errHandler.emitError(this.resources.DeallocationFailed, this.resources.Error, ErrorTypeEnum.Error);
                        });
                }).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(this.resources.SelectComponent, this.resources.NoComponentsSelected, ErrorTypeEnum.Info);
        }
    }
}
