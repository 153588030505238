import { IndividualConfig } from 'ngx-toastr';
const toastConfig = {
    disableTimeOut: true,
    closeButton: true,
    tapToDismiss: false
}

export class CustomToastOption {
    public getErrorOptions(): any {
        const option: any = {
            showCloseButton: true,
            newestOnTop: false,
            maxShown: 10,
            ...toastConfig
        };
        return option;
    }

    public getWarningOptions(): any {
        return this.getErrorOptions();
    }

    public getInfoOptions(): any {
        const option: any = {
            showCloseButton: true,
            newestOnTop: false,
            maxShown: 10,
            ...toastConfig
        };
        return option;
    }

    public getSuccessOptions(): any {
        return this.getInfoOptions();
    }

    public getCustomOptions(messageCssClass: string, titleCssClass: string): any {
        const option: any = {
            showCloseButton: true,
            newestOnTop: false,
            maxShown: 10,
            enableHTML: true,
            messageClass: messageCssClass,
            titleClass: titleCssClass,
            ...toastConfig
        };
        return option;
    }
}

export default toastConfig;
