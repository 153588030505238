import { Injectable } from '@angular/core';
import { AppState } from '../app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MenuItem } from './state-models/menu-item';
import { StartLoadingAction, FinishLoadingAction, UpdateActiveMenuAction } from './actions';
import { LoadingItem } from './state-models/loading-item';

@Injectable()
export class CommonStateService {
    private resources = require('./resources.json');

    constructor(private appStore: Store<AppState>) { }

    getMenus(): Observable<MenuItem[]> {
        // TODO: check roles from users.
        return this.appStore.select(state => state.common.menu);
    }

    getActiveMenu(): Observable<MenuItem> {
        return this.appStore.select(state => state.common.activeMenu);
    }

    updateActiveMenu(menu: MenuItem) {
        const action = new UpdateActiveMenuAction(menu);
        this.appStore.dispatch(action);
    }

    getLoading(): Observable<boolean> {
        return this.appStore.select(state => state.common.loadingItems.length > 0);
    }

    startLoading(loading: LoadingItem) {
        const action = new StartLoadingAction(loading);
        this.appStore.dispatch(action);
    }

    finishLoading(loading: LoadingItem) {
        const action = new FinishLoadingAction(loading);
        this.appStore.dispatch(action);
    }

    getResources(): Object {
        return this.resources;
    }

    getResource(key: string): string {
        return this.getResources()[key];
    }
}
