import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../authentication';
import { CommonModule } from '../common';
import { AddDeliveryRouteComponent } from './pages/delivery-route/add-delivery-route/add-delivery-route.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConsumingPlanningAreaMappingComponent } from './pages/consuming-planning-area-mapping/cpa-mappings-list/consuming-planning-area-mapping.component';
import { CpaAddMappingComponent } from './pages/consuming-planning-area-mapping/cpa-mappings-add-cpa/cpa-mappings-add-cpa.component';
import { DeliveryRouteComponent } from './pages/delivery-route/delivery-route-list/delivery-route.component';
import { DeliveryRouteAllocationUnitComponent } from './pages/delivery-route-allocation-unit/delivery-route-allocation-unit-list/delivery-route-allocation-unit.component';
import { AddDeliveryRouteAllocationUnitComponent } from './pages/delivery-route-allocation-unit/add-delivery-route-allocation-unit/add-delivery-route-allocation-unit.component';
import { AddCurrencyAdjustmentCodeComponent } from './pages/currency-adjustment/code/add-code/add-currency-adjustment-code.component';
import { CurrencyAdjustmentCodeComponent } from './pages/currency-adjustment/code/code-list/currency-adjustment-code.component';
import { CurrencyAdjustmentCodeDetailsComponent } from './pages/currency-adjustment/code-details/code-details/currency-adjustment-code-details.component';
import { CurrencyAdjustmentPercentageComponent } from './pages/currency-adjustment/code-details/percentage-list/currency-adjustment-percentage.component';
import { AddCurrencyAdjustmentPercentageComponent } from './pages/currency-adjustment/code-details/add-percentage/add-currency-adjustment-percentage.component';
import { CurrencyAdjustmentConditionComponent } from './pages/currency-adjustment/condition/condition-list/currency-adjustment-condition.component';
import { AddCurrencyAdjustmentConditionComponent } from './pages/currency-adjustment/condition/add-condition/add-currency-adjustment-condition.component';
import { CurrencyAdjustmentConditionVariantComponent } from './pages/currency-adjustment/condition/condition-variant-list/currency-adjustment-condition-variant.component';
import { AddCurrencyAdjustmentConditionVariantComponent } from './pages/currency-adjustment/condition/add-condition-variant/add-currency-adjustment-condition-variant.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { ManualInvoicingComponent } from './pages/manual-invoicing/add-manual-invoicing/add-manual-invoicing.component';
import { AddMarkupConditionMaterialComponent } from './pages/markup-condition-material/add-markup-condition-material/add-markup-condition-material.component';
import { MarkupConditionMaterialListComponent } from './pages/markup-condition-material/markup-condition-material-list/markup-condition-material-list.component';
import { MarkupPercentageListComponent } from './pages/markup-percentage/markup-percentage-list/markup-percentage-list.component';
import { AddMarkupPercentageComponent } from './pages/markup-percentage/add-markup-percentage/add-markup-percentage.component';
import { ProductClassComponentUnitListComponent } from './pages/product-class-component-unit/product-class-component-unit-list/product-class-component-unit-list.component';
import { AddProductClassComponentUnitComponent } from './pages/product-class-component-unit/add-product-class-component-unit/add-product-class-component-unit.component';

export const adminRoutes: Routes = [
    { path: "", redirectTo: "admin", pathMatch: "full" },
    {
        path: "admin",
        canActivate: [AuthGuardService],
        children: [
            {
                path: "delivery-route",
                component: DeliveryRouteComponent,
            },
            {
                path: "delivery-route-allocation-unit",
                component: DeliveryRouteAllocationUnitComponent,
            },
            {
                path: "consuming-planning-area-mapping",
                canActivate: [AuthGuardService],
                component: ConsumingPlanningAreaMappingComponent,
            },
            {
                path: "currency-adjustment-code",
                canActivate: [AuthGuardService],
                component: CurrencyAdjustmentCodeComponent,
            },
            {
                path: "currency-adjustment-code/:id",
                canActivate: [AuthGuardService],
                component: CurrencyAdjustmentCodeDetailsComponent,
            },
            {
                path: "currency-adjustment-condition",
                canActivate: [AuthGuardService],
                component: CurrencyAdjustmentConditionComponent,
            },
            {
                path: "manual-invoicing",
                canActivate: [AuthGuardService],
                component: ManualInvoicingComponent,
            },
            {
                path: "markup-condition-material",
                canActivate: [AuthGuardService],
                component: MarkupConditionMaterialListComponent,
            },
            {
                path: "markup-percentage",
                canActivate: [AuthGuardService],
                component: MarkupPercentageListComponent,
            },
            {
                path: "product-class-component-unit",
                canActivate: [AuthGuardService],
                component: ProductClassComponentUnitListComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        AngularCommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatOptionModule,
    ],
    declarations: [
        DeliveryRouteComponent,
        AddDeliveryRouteComponent,
        DeliveryRouteAllocationUnitComponent,
        AddDeliveryRouteAllocationUnitComponent,
        ConsumingPlanningAreaMappingComponent,
        CpaAddMappingComponent,
        CurrencyAdjustmentCodeComponent,
        AddCurrencyAdjustmentCodeComponent,
        CurrencyAdjustmentPercentageComponent,
        AddCurrencyAdjustmentPercentageComponent,
        CurrencyAdjustmentConditionComponent,
        CurrencyAdjustmentCodeDetailsComponent,
        AddCurrencyAdjustmentConditionComponent,
        CurrencyAdjustmentConditionVariantComponent,
        AddCurrencyAdjustmentConditionVariantComponent,
        ManualInvoicingComponent,

        AddMarkupConditionMaterialComponent,
        MarkupConditionMaterialListComponent,
        MarkupPercentageListComponent,
        AddMarkupPercentageComponent,
        ProductClassComponentUnitListComponent,
        AddProductClassComponentUnitComponent
    ],
})
export class AdminModule {}
