import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { FormControlName } from '@angular/forms';

if (environment.production && !JSON.parse(window.localStorage.getItem('ng:disableProdMode'))) {
    enableProdMode();
} else {
    console.log('You can check Calsium\'s state using %cwindow.state' + '%c property', 'color: blue; font-weight: bold;', '');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// fix to access native html element from formcontrol https://stackoverflow.com/questions/39642547/is-it-possible-to-get-native-element-for-formcontrol
const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
    const result = originFormControlNameNgOnChanges.apply(this, arguments);
    this.control.valueAccessor = this.valueAccessor;
    return result;
};
