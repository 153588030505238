import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RoleGuardService  {
    constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

    canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
        const routeRoles = route.data['roles'] as string[];
        if (routeRoles === undefined || routeRoles.length === 0) {
            return true;
        }
        return this.authService.userInfo.pipe(map((usr) => {
            if (usr && usr.profile && usr.profile.roles && usr.profile.roles.length > 0) {
                if (this.isUserAllowed(routeRoles, usr.profile.roles)) {
                    return true;
                }
            }
            this.router.navigate(['/deny-access']);
            return false;
        }));
    }

    isUserAllowed(allowedRoles: string[], userRoles: string[]): boolean {
        for (const role of allowedRoles) {
            if (userRoles.indexOf(role) >= 0) {
                return true;
            }
        }
        return false;
    }
}
