import { Action } from '@ngrx/store';
import * as _ from 'lodash';

import { ComponentIndividual } from './state-models/component-individual';
import { ComponentSearchForm } from './state-models/component-search-form';

export enum ComponentActions {
  LoadComponentAction = '[ Component ] load Components',
  LoadProductIndividualComponentsAction = '[ Product ] Load components',
  ChangeComponentPageAction = '[ Component ] Change Page',
  ChangeComponentResultsTotalCountAction = '[ Component ] Change Results Count',
  ChangeComponentSortExpressionAction = '[ Component ] Change Sort Expression',
  ChangeComponentFilterExpressionAction = '[ Component ] Change Filter Expression',
  ChangeComponentSearchFormAction = '[ Component ] Change Search Form'
}

export class LoadComponentAction implements Action {
    readonly type = ComponentActions.LoadComponentAction;
    constructor(public payload: ComponentIndividual[]) {
    }
}

export class LoadProductIndividualComponentsAction implements Action {
    readonly type = ComponentActions.LoadProductIndividualComponentsAction;
    constructor(public payload: ComponentIndividual[]) {
    }
}

export class ChangeComponentPageAction implements Action {
    readonly type = ComponentActions.ChangeComponentPageAction;
    constructor(public payload: number) { }
}

export class ChangeComponentResultsTotalCountAction implements Action {
    readonly type = ComponentActions.ChangeComponentResultsTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangeComponentSortExpressionAction implements Action {
    readonly type = ComponentActions.ChangeComponentSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeComponentFilterExpressionAction implements Action {
    readonly type = ComponentActions.ChangeComponentFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeComponentSearchFormAction implements Action {
    readonly type = ComponentActions.ChangeComponentSearchFormAction;
    constructor(public payload: ComponentSearchForm) { }
}
export type ComponentAction =
  | LoadComponentAction
  | LoadProductIndividualComponentsAction
  | ChangeComponentPageAction
  | ChangeComponentResultsTotalCountAction
  | ChangeComponentSortExpressionAction
  | ChangeComponentFilterExpressionAction
  | ChangeComponentSearchFormAction;
