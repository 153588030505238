<div class="card">
   <h3 class="card-header">{{resources.AddDeliveryRouteAllocationUnit}}</h3>
   <div class="card-body">
      <div class="container-fluid">
         <div class="row">
            <div class="form-group col-md-2">
               <label>{{resources.PropertyCode}}</label>
               <mat-form-field style="width: 100%;" appearance="outline">
                  <input matInput [formControl]="propertyCodeInput" type="text" class="form-control"
                     placeholder="{{resources.PropertyCode}}" />
               </mat-form-field>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.DeliveryRoute}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="deliveryRouteInput">
                        <mat-option *ngFor="let item of deliveryRouteList | async" [value]="item.id">
                           {{item.code}} - {{item.description}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.ScaniaUnitAllocation}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="scaniaUnitAllocationInput">
                        <mat-option *ngFor="let item of scaniaUnitAllocationList | async" [value]="item.id">
                           {{item.scaniaUnitComponentType.scaniaUnit.name}}
                           {{item.scaniaUnitComponentType.componentType.name}} -> {{item.allocationScaniaUnit.name}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.ScaniaUnitConsolidation}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="scaniaUnitConsolidationInput">
                        <mat-option *ngFor="let item of scaniaUnitConsolidationList | async" [value]="item.id">
                           {{item.scaniaUnit.name}} {{item.productClass.variantOption.description}} ->
                           {{item.consolidatingScaniaUnit.name}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>&nbsp;</label>
               <button type="button" class="btn btn-sm btn-primary form-control" id="saveDeliveryRoute"
                  (click)="onSave()">
                  <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<br />