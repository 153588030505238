import { Component } from '@angular/core';
import { CommonStateService } from '../../../state';

@Component({
    selector: 'cal-unauthorized',
    template: `
    <h3 class="center error">
        {{statusText}}
    </h3>
    <br />
    <div class="center">
        <button type="button" class="btn btn-primary" (click)="retry()">Retry</button>
    </div>`,
    styles: [
        '.center { text-align: center }',
        '.error { color: red }'
    ]
})
export class UnauthorizedComponent {
    statusText: string;

    constructor(private commonService: CommonStateService) {
        this.statusText = this.commonService.getResource('UserNotAuthorizedError');
    }

    retry() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(window.location.origin);
    }
}
