import { Injectable } from "@angular/core";
import { State, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ProductClassComponentUnitApiService } from "src/api/services/product-class-component-unit-api.service";
import { AppState } from "src/state/app.state";
import { Loadable } from "src/state/common/loadable";


import { Paging } from 'src/state/common/state-models/paging';
import { 
            AddProductClassComponentUnitAction,
            ChangeProductClassComponentUnitFilterExpressionAction, 
            ChangeProductClassComponentUnitListPageAction, 
            ChangeProductClassComponentUnitListTotalCountAction, 
            ChangeProductClassComponentUnitSortExpressionAction, 
            LoadProductClassComponentUnitAction 
        } from "./action";

import { ProductClassComponentUnit, ProductClassComponentUnitAdd } from "./state-model/product-class-component-unit";

@Injectable({
    providedIn: "root",
})

export class ProductClassComponentUnitStateService {
    constructor(
        private apiService: ProductClassComponentUnitApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>
    ) {}

    getSortingExpression(type: string): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.productClassComponentUnitState.productClassComponentUnitListSortExpression);
    }   

    getFilterExpression(type:string):Observable<string>{
        return this.appStore.select(
            (state) =>
                state.admin.productClassComponentUnitState.productClassComponentUnitListFilterExpression);
    }

    async addProductClassComponentUnits(
        productClassComponentUnit: ProductClassComponentUnitAdd
    ): Promise<any> {
        const response = await this.apiService.AddProductClassComponentUnit(
            productClassComponentUnit
        );
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(
            new AddProductClassComponentUnitAction(
                response.data.productClassComponentUnit.addProductClassComponentUnit
            )
        );
                
        const res = await this.apiService.getProductClassComponentUnits();        
        this.appStore.dispatch(new LoadProductClassComponentUnitAction(res.list));        
        this.appStore.dispatch(new ChangeProductClassComponentUnitListTotalCountAction(res.totalCount));
    }

    getProductClassComponentUnitList(): Observable<ProductClassComponentUnit[]> {
        return this.appStore.select(
            (state) =>
                state.admin.productClassComponentUnitState
                    .productClassComponentUnitList
        );
    }

    getProductClassComponentUnitListSortExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.productClassComponentUnitState
                    .productClassComponentUnitListSortExpression
        );
    }

    getProductClassComponentUnitListFilterExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.productClassComponentUnitState
                    .productClassComponentUnitListFilterExpression
        );
    }

    sortProductClassComponentUnit(sortExpression: string) {
        this.appStore.dispatch(
            new ChangeProductClassComponentUnitSortExpressionAction(
                sortExpression
            )
        );
    }

    filterProductClassComponentUnit(filterExpression: string) {
        this.appStore.dispatch(
            new ChangeProductClassComponentUnitFilterExpressionAction(
                filterExpression
            )
        );
    }

    getPaging(): Observable<Paging> {        
        return this.appStore.select(
            (state) =>
                state.admin.productClassComponentUnitState.productClassComponentUnitPaging);
    }

    goToPage(page: number) {              
        this.appStore.dispatch(new ChangeProductClassComponentUnitListPageAction(page));
    }

    getTotalCount(): Observable<number> {        
        return this.appStore.select(
            (state) =>
                state.admin.productClassComponentUnitState.productClassComponentUnitTotalCount);
    }

    clearProductClassComponentUnitFilterAndSorting() {
        this.sortProductClassComponentUnit(null);
        this.filterProductClassComponentUnit(null);
    }

    @Loadable()
    async loadProductClassComponentUnit(): Promise<void> {
        let list = <ProductClassComponentUnit[]>(
            this.appState.getValue().admin.productClassComponentUnitState
                .productClassComponentUnitList
        );
        if (list.length==0) {
            const response =
                await this.apiService.getProductClassComponentUnits();
            this.appStore.dispatch(
                new LoadProductClassComponentUnitAction(response)
            );
        }
    }

    @Loadable()
    async loadProductClassComponentUnits(
        paging: Paging,
        sortExpression: string,
        filterExpression: string
    ){
        const payload = {
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression
        };
        const response = await this.apiService.getProductClassComponentUnits(
            payload
        );
        this.appStore.dispatch(new LoadProductClassComponentUnitAction(response.list));
        this.appStore.dispatch(new ChangeProductClassComponentUnitListTotalCountAction(response.totalCount));
    }
}