import { Component, OnInit, OnDestroy, Inject, Optional, EventEmitter, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CommonStateService, ComponentStateService, ComponentIndividual, ProductIndividualStateService, ProductIndividual, Paging } from 'src/state';
import { OrchestratorStateService } from 'src/state/orchestrator/service';
import { StepFaultView } from 'src/state/orchestrator/state-models/step-fault-view';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { CalsiumDateFormatterPipe } from 'src/app/shared/calsium-date.pipe';
import { ActivatedRoute } from '@angular/router';
import { ComponentDetailsStateService } from 'src/state/componentDetails/service';
import { ComponentAllocationStateService } from 'src/state/component-allocation/service';
import { CommonApiService } from 'src/api/common.service';

@Component({
    selector: 'cal-allocation-calculation-details',
    templateUrl: './calculation-details.component.html',
    styleUrls: ['./calculation-details.component.css']
})
export class CalculationDetailsComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    productIndividualId: number;
    allocationScaniaUnitId: number;
    productIndividual$: Observable<ProductIndividual>;
    isProductIndividualLoading: boolean = true;

    dataList$: Observable<StepFaultView[]>;
    totalCount$: Observable<number>;
    isFlowErrorLoading: boolean = true;
    listConfig: CalsiumListConfig;

    componentList$: Observable<ComponentIndividual[]>;
    isComponentsLoading: boolean = true;
    selectedComponentId: number = 0;
    totalSpecificationCount: number;
    isExportToExcelClicked: boolean = false;

    specificationPaging: Paging = {
        "offset": 5000,
        "page": 1
    }

    constructor(
        private route: ActivatedRoute,
        private stateService: ComponentAllocationStateService,
        private commonService: CommonStateService,
        private orchestratorService: OrchestratorStateService,
        private componentIndividualService: ComponentStateService,
        private productIndividualService: ProductIndividualStateService,
        private componentDetailsStateService: ComponentDetailsStateService,
        private commonApiService: CommonApiService,
        @Optional() public dialogRef: MatDialogRef<CalculationDetailsComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.productIndividual$ = this.productIndividualService.getProductIndividual();
        this.dataList$ = this.orchestratorService.getProductIndividualStepFault();
        this.totalCount$ = this.orchestratorService.getProductIndividualStepFaultTotalCount();
        this.componentList$ = this.componentIndividualService.getProductIndividualComponents();

        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                if (params !== null && params.productIndividualId > 0) {
                    this.loadCalculationDetailsData(params.productIndividualId, params.allocationScaniaUnitId);
                }
            })
        );

        if (this.data !== null && this.data.productIndividualId > 0) {
            this.loadCalculationDetailsData(this.data.productIndividualId,  this.data.allocationScaniaUnitId);
        }

        this.buildFlowErrorList();
    }

    ngOnDestroy() { this.subscriptions.unsubscribe();  }

    buildFlowErrorList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.List;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowClearFilterSortingButton = false;

        let column = this.listConfig.AddListColumn(ColumnDataType.string, 'type', this.resources.Type);
        column = this.listConfig.AddListColumn(ColumnDataType.string, 'details', this.resources.ReferenceNumber);

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'message', this.resources.Message);

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'flowExecutionStep.startDate', this.resources.ExecutionDate);
        column.DisplayPipe = new CalsiumDateFormatterPipe();
    }

    loadCalculationDetailsData(productIndividualId: number, allocationScaniaUnitId: number) {
        this.productIndividualId = productIndividualId;
        this.allocationScaniaUnitId = allocationScaniaUnitId;

        this.isProductIndividualLoading = this.isComponentsLoading = this.isFlowErrorLoading = true;

        this.productIndividualService.loadProductIndividualProductionValues(productIndividualId, allocationScaniaUnitId)
        .then(_ => this.isProductIndividualLoading = false);

        this.productIndividualService.loadProductIndividual(productIndividualId)
            .then(_ => this.isProductIndividualLoading = false);

        this.componentIndividualService.loadComponentsByProductIndividual(productIndividualId, allocationScaniaUnitId)
            .then(_ => this.isComponentsLoading = false);

        this.orchestratorService.loadProductIndividualStepFault(productIndividualId, allocationScaniaUnitId)
            .then(_ => this.isFlowErrorLoading = false);

        this.componentDetailsStateService.loadComponentSpecification(productIndividualId, this.specificationPaging, null, null)
            .then(_ => this.isFlowErrorLoading = false)
    }

    componentOpen(componentIndividual: ComponentIndividual) {
        this.selectedComponentId = componentIndividual.id;
    }

    exportToExcel() {
        this.isExportToExcelClicked = true;     //to avoid auto update of values due to .subscribe function

        this.productIndividual$.subscribe((data: any) => {
            if(this.isExportToExcelClicked) {
                const productClassTextRename = data.productClassText.replace("\r", "");
                let fileNameOfExcel;
                
                let fileExt = data.chassisNumber != null ? data.chassisNumber : data.serialNumber;
                fileExt = fileExt != null && fileExt != "" ? fileExt : data.productIndividualNumber; // This is for Simulated products
                
                fileNameOfExcel = `${productClassTextRename}_${fileExt}`;            

             this.isExportToExcelClicked = false;
             return data.corporateMarkupPercentage == null ? 
                this.commonApiService.exportSummaryQuery(data.productIndividualNumber,fileNameOfExcel) : 
                this.commonApiService.exportSummaryQuery(data.productIndividualNumber,fileNameOfExcel,true,data.corporateMarkupPercentage);
            }
        })
        
    }

}
