import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { CommonStateService } from 'src/state';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { CurrencyAdjustmentStateService } from 'src/state/administraion/currency-adjustment/service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs/operators';
import { CurrencyAdjustmentPercentage } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-percentage';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';


@Component({
    selector: 'cal-currency-adjustment-percentage',
    templateUrl: './currency-adjustment-percentage.component.html'
})
export class CurrencyAdjustmentPercentageComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    dataList: Observable<CurrencyAdjustmentPercentage[]>;
    listConfig: CalsiumListConfig;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    isLoading: Observable<boolean>;
    showSimulationPercentage: boolean;

    constructor(
        private route: ActivatedRoute,
        private currencyAdjustmentService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
    ) {
        this.isLoading = this.commonService.getLoading();
        this.dataList = this.currencyAdjustmentService.getPercentages();
        this.sortingExpression = this.currencyAdjustmentService.getCodeListSortingExpression();
        this.filterExpression = this.currencyAdjustmentService.getCodeListFilterExpression();
        this.showSimulationPercentage = false;
    }

    ngOnInit(): void {
        this.route.params
            .pipe(combineLatest(this.sortingExpression, this.filterExpression))
            .subscribe(([params, sort, filter]) => {
                this.currencyAdjustmentService.loadPercentageByCode(params.id,params.validFrom,params.validTo, sort, filter,this.showSimulationPercentage);
            })

        this.buildList();
    }


    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.CurrencyAdjustmentPercentageList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;
        this.listConfig.AllowEdit = false;
        this.listConfig.AllowDelete = false;
        this.listConfig.ShowExportToExcel = false;

        let column;
        column = this.listConfig.AddListColumn(ColumnDataType.string, 'id', this.resources.Id);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'percentage', this.resources.Percentage);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'validFrom', this.resources.ValidFrom);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'validTo', this.resources.ValidTo);
        column.AllowFilter = true;
        column.Sortable = true;
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }


    onSort(sortExpression: string) {
        this.currencyAdjustmentService.sortPercentages(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.currencyAdjustmentService.filterPercentages(filterExpression);
    }

    onClearFilterAndSorting() {
        this.currencyAdjustmentService.clearPercentagesFilterAndSorting();
    }
}
