import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CommonStateService } from 'src/state';
import { debounceTime, switchMap } from "rxjs/operators";
import { MarkupConditionMaterialStateServiceService } from 'src/state/administraion/markup-condition-material/service';
import { MarkupConditionMaterial } from 'src/state/administraion/markup-condition-material/state-models/markup-condition-material';

@Component({
    selector: "cal-markup-condition-material-list",
    templateUrl: "./markup-condition-material-list.component.html",
    styleUrls: ["./markup-condition-material-list.component.css"],
})
export class MarkupConditionMaterialListComponent implements OnInit {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    markupConditionList: Observable<MarkupConditionMaterial[]>;
    isLoading: Observable<boolean>;
    showOnlyValidMarkupConditionMaterials: Observable<boolean>;
    listConfig: CalsiumListConfig;

    constructor(
        private markupConditionMaterialService: MarkupConditionMaterialStateServiceService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) {
        this.isLoading = this.commonService.getLoading();
        this.sortingExpression =
            this.markupConditionMaterialService.getMarkupConditionMaterialListSortExpression();
        this.filterExpression =
            this.markupConditionMaterialService.getMarkupConditionMaterialListFilterExpression();
        this.markupConditionList =
            this.markupConditionMaterialService.getMarkupConditionMaterialList();
        this.showOnlyValidMarkupConditionMaterials =
            this.markupConditionMaterialService.getShowOnlyValidMarkupConditionMaterialList();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            combineLatest(
                this.sortingExpression,
                this.filterExpression,
                this.showOnlyValidMarkupConditionMaterials
            )
                .pipe(
                    debounceTime(0),
                    switchMap(
                        ([
                            sortingExpression,
                            filterExpression
                        ]) => {
                            return this.markupConditionMaterialService.loadMarkupConditionMaterials(
                                sortingExpression,
                                filterExpression
                            );
                        }
                    )
                )
                .subscribe()
        );

        this.buildList();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    isEditable(condition): boolean {
        let isEditable = false;

        if (condition.validTo === null) {
            isEditable = true;
        }

        return isEditable;
    }

    saveEditedItem(formGroup, selectedDate) {
        // call the edit/save api
        const payload = {
            id: selectedDate.id,
            validFrom: selectedDate.validFrom,
            validTo: formGroup.controls.validTo.value,
        };
        this.markupConditionMaterialService
            .updateMarkupConditionMaterial(payload)
            .then((errors) => {
                if (errors && errors.length) {
                    let errorMessage = "";
                    for (let error of errors) {
                        const errorType = error
                            .split("\n")[0]
                            .replace("GraphQL.ExecutionError:", "")
                            .trim();
                        switch (errorType) {
                            case "INVALID_DATE":
                                errorMessage +=
                                    this.resources
                                        .ToDateShouldBeEqualOrGreaterthanToday;
                                break;
                            case "INVALID_DATE_TO":
                                errorMessage +=
                                    this.resources.ToDateGreaterThanFromDate;
                                break;
                            default:
                                errorMessage += this.resources.ErrorOccured;
                                break;
                        }
                    }
                    this.errHandler.emitError(
                        errorMessage,
                        this.resources.Error,
                        ErrorTypeEnum.Error
                    );
                } else {
                    this.markupConditionMaterialService.filterMarkupConditionMaterials(
                        payload.validTo
                    );
                    this.errHandler.emitError(
                        this.resources.UpdateSuccessfully,
                        this.resources.Success,
                        ErrorTypeEnum.Success
                    );
                }
            });
        return of(true);
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption =
            this.resources.MarkupConditionMaterialList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;
        this.listConfig.AllowEdit = true;
        this.listConfig.isRowEditable = this.isEditable;
        this.listConfig.EditItemMethod = this.saveEditedItem;
        this.listConfig.ShowExportToExcel = false;

        let column;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "markupCondition.markupConditionCode",
            this.resources.MarkupCondition
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "material.materialNumber",
            this.resources.MaterialNumber
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validFrom",
            this.resources.From
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validTo",
            this.resources.To
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.AllowEdit = true;
    }

    onSort(sortExpression: string) {
        this.markupConditionMaterialService.sortMarkupConditionMaterials(
            sortExpression
        );
    }

    onFilter(filterExpression: string) {
        this.markupConditionMaterialService.filterMarkupConditionMaterials(
            filterExpression
        );
    }

    onClearFilterAndSorting() {
        this.markupConditionMaterialService.clearMarkupConditionMaterialsFilterAndSorting();
    }

    onShowOnlyValidMarkupConditionMaterials(showOnlyValidMarkupConditionMaterials: boolean) {
        this.markupConditionMaterialService.showShowOnlyValidMarkupConditionMaterials(
            showOnlyValidMarkupConditionMaterials
        );
    }
}
