import { MaterialPriceType } from './material-price-type';

export interface MaterialPrice {
    materialId: number;
    validFrom: Date;
    validTo: Date;
    materialPriceType: MaterialPriceType;
    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    updatedBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    modifierComments: string;
    currentManualPrice: number;
    currentStandardPrice: number;
    currentStrategicPrice: number;
}
