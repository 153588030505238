<cal-add-markup-condition-material></cal-add-markup-condition-material>
<div class="panel" style="margin-top: 5px;">
    <div class="panel-body">
    <cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="markupConditionList | async"
        (sortTriggered)="onSort($event)" (filterTriggered)="onFilter($event)"
        (clearFilterAndSortingTriggered)="onClearFilterAndSorting()" [isLoading]="isLoading | async"
        [filterExpression]="filterExpression | async" [dataTotalCount]="(markupConditionList | async)?.length">
    </cal-calsiumlist-listing>
</div>
</div>
