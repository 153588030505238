import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { CalsiumWidgetConfig } from 'src/app/calsiumlist/calsiumlist.component.model';

import { CommonStateService } from 'src/state';

@Component({
    selector: 'cal-allocation-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.css']
})
export class WidgetComponent implements OnInit, OnChanges {
    @Input('widget-title') title: string;
    @Input() value: number;
    @Input() volume: number;
    @Input() config: CalsiumWidgetConfig;
    
    @Output() exportToExcelTriggered: EventEmitter<any> = new EventEmitter();

    resources: any = this.commonService.getResources();
    exportButtonState = 'disabled';

    constructor(private commonService: CommonStateService) { }
    
    ngOnInit() {
        this.config = this.config ? this.config : new CalsiumWidgetConfig();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.exportButtonState = this.value > 0 || this.volume > 0 ? '' : 'disabled';;
    }

    exportToExcel() {
        this.exportToExcelTriggered.emit(null);
    }
}
