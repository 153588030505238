import { Country } from 'src/state/master-data/state-models/country';

export interface ProductIndividual {
    id: number;
    productIndividualNumber: number;
    chassisNumber: number;
    productClassText: string;
    country: Country;
    fitForUseNumber: number;
    specialOrderNumber: number;
    uniqueDesignNumber: number;
    invoicedDate: Date;
    finalAssemblyUnit: string;
    TotalMaterialValue: number;
    TotalProductionValue: number;
    TotalMarkupValue: number;
    TotalCurrencyValue: number;
    BaseCost: number;
    ManualProductionValue:number;
}
