import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ScaniaUnit, MasterDataStateService, CommonStateService } from 'src/state';

@Component({
    selector: 'cal-allocation-unit-selection',
    templateUrl: './allocation-unit-selection.component.html',
    styleUrls: ['./allocation-unit-selection.component.css']
})
export class AllocationUnitSelectionComponent implements OnInit {

    @Input() selected: number;
    @Input() type: string[];
    @Output() selectionChanged = new EventEmitter();
    scaniaUnitList: Observable<ScaniaUnit[]>;
    resources: any;

    constructor(
        private masterDataService: MasterDataStateService,
        private commonService: CommonStateService
    ) {
        this.resources = this.commonService.getResources();

    }
    ngOnInit(): void {
        this.scaniaUnitList = this.masterDataService.getScaniaUnitsByTypes(this.type);
    }

    onChange(evt: any) {
        this.selectionChanged.emit(this.selected);
    }
}
