import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CalsiumNumber'
})
export class CalsiumNumberFormatterPipe implements PipeTransform {
    private minimumDecimalPlaces = 2;
    private maximumDecimalPlaces = 2;

    setDecimalPlaces(minimumDecimalPlaces: number, maximumDecimalPlaces: number): CalsiumNumberFormatterPipe {
        this.minimumDecimalPlaces = minimumDecimalPlaces;
        this.maximumDecimalPlaces = maximumDecimalPlaces;
        return this;
    }

    transform(value?: number, minimumDecimalPlaces?: number, maximumDecimalPlaces?: number, notation?: string): string {
        if (value == null) {
            return null;
        }
        let finalValue = value;
        this.minimumDecimalPlaces = minimumDecimalPlaces !== undefined ? minimumDecimalPlaces : this.minimumDecimalPlaces;
        this.maximumDecimalPlaces = maximumDecimalPlaces !== undefined ? maximumDecimalPlaces : this.maximumDecimalPlaces;

        if (notation !== undefined) {
            switch (notation.toLowerCase()) {
                case 'k':
                    finalValue /= 1E3;
                    break;
                case 'm':
                    finalValue /= 1E6;
                    break;
            }
        }

        const formattedValue = +finalValue.toFixed(this.maximumDecimalPlaces);
        return formattedValue.toLocaleString(undefined, {
            minimumFractionDigits: this.minimumDecimalPlaces,
            maximumFractionDigits: this.maximumDecimalPlaces
        }) + (finalValue !== 0 ? notation || '' : '');
    }
}
