<cal-add-product-class-component-unit></cal-add-product-class-component-unit>
<div class="panel" style="margin-top: 5px;">
    <div class="panel-body">
        <cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="productClassComponentUnitList | async"
            (sortTriggered)="onSort($event)" (filterTriggered)="onFilter($event)"
            (clearFilterAndSortingTriggered)="onClearFilterAndSorting()" [isLoading]="isLoading | async"
            [filterExpression]="filterExpression | async" [dataTotalCount]="totalCount | async">
        </cal-calsiumlist-listing>
        <cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async">
        </cal-pagination>
    </div>
</div>