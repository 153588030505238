<div class="card">
  <div class="card-body">
    <form [formGroup]="searchComponentsForm" (ngSubmit)="onSearch()" class="row">
      <div class="form-group col-lg-3 col-md-4 col-xs-12">
        <label>{{resources.AllocationScaniaUnit}}:</label>
        <div class="input-group">
          <select formControlName="selectedScaniaUnit" class="form-control">
            <option value="" selected disabled="disabled"></option>
            <option *ngFor="let scaniaUnit of scaniaUnitList | async" value={{scaniaUnit.id}}>
              {{scaniaUnit.name}} - {{scaniaUnit.description}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group col-lg-2 col-md-3 col-xs-12">
        <label>{{resources.From}}:</label>
        <div class="input-group">
          <input formControlName="startDate" type="date" placeholder="yyyy-mm-dd" class="form-control" />
        </div>
      </div>
      <div class="form-group col-lg-2 col-md-3 col-xs-12">
        <label>{{resources.To}}:</label>
        <div class="input-group">
          <input formControlName="endDate" type="date" class="form-control" placeholder="yyyy-mm-dd" />
        </div>
      </div>
      <div class="form-group col-md-1 col-xs-12">
        <label>&nbsp;</label>
        <button class="btn btn-primary form-control" type="submit">
          {{resources.Search}}
        </button>
      </div>
    </form>
  </div>
</div>
<p></p>
<cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="dataList | async" (sortTriggered)="onSort($event)"
  (filterTriggered)="onFilter($event)" (clearFilterAndSortingTriggered)="onClearFilter()" [isLoading]="isLoading | async"
  (exportToExcelTriggered)="onExport()" [filterExpression]="filterExpression | async" [dataTotalCount]="totalCount | async">
</cal-calsiumlist-listing>
<cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async">
</cal-pagination>