<td *ngIf="listConfig.HasSubComponent && isSubComponentVisible()" (click)="toggleListSubContent(RowNumber)">
    <i class="fas fa-caret-right" *ngIf="listConfig.CalsiumListComponent.toggleSubContent != RowNumber"></i>
    <i class="fas fa-caret-down" *ngIf="listConfig.CalsiumListComponent.toggleSubContent == RowNumber"></i>
</td>
<td *ngIf="listConfig.HasSubComponent && !isSubComponentVisible()">
    &nbsp;
</td>
<ng-container *ngIf="IsViewMode()">
    <ng-container *ngFor="let column of listConfig.ListColumns">
        <td [ngClass]="{'scrollable-span': column.ColumnName == 'sportMessage' || column.ColumnName == 'errorMessage'}" *ngIf="column.IsVisible | async" [class]="(column.CssClass || '') + ' ' + (column.TextAlignment || '')">
            <ng-container
                *ngIf="column.DataType === 'string' || column.DataType === 'number' || column.DataType === 'list' || column.DataType === 'date' ">
                <span
                    *ngIf="column.DisplayColumnName !== ''">{{GetValue(column.DisplayColumnName) | pipe:[column.DisplayPipe] }}</span>
                <span
                    *ngIf="column.DisplayColumnName === ''">{{GetValue(column.ColumnName) | pipe:[column.DisplayPipe] }}</span>
                <span *ngIf="column.IsCurrency">SEK</span>
            </ng-container>
            <ng-container *ngIf="column.DataType === 'anchor'">
                <span *ngIf="column.DisplayColumnName !== ''">
                    <ng-container *ngIf="IsAnchor(column)">
                        <a routerLink="{{GetAnchorLink(column)}}">{{GetValue(column.DisplayColumnName)}}</a>
                    </ng-container>
                    <ng-container *ngIf="!IsAnchor(column)">
                        <span>{{GetValue(column.DisplayColumnName)}}</span>
                    </ng-container>
                </span>
                <span *ngIf="column.DisplayColumnName === ''">
                    <ng-container *ngIf="IsAnchor(column)">
                        <a routerLink="{{GetAnchorLink(column)}}">{{GetValue(column.ColumnName)}}</a>
                    </ng-container>
                    <ng-container *ngIf="!IsAnchor(column)">
                        <span>{{GetValue(column.ColumnName)}}</span>
                    </ng-container>
                </span>
            </ng-container>
            <ng-container *ngIf="column.DataType === 'details' || column.DataType === 'manage'">
                <a id="details-btn" class="btn" *ngIf="IsAnchor(column) || IsClickable(column)"
                    [routerLink]="GetAnchorLink(column)">
                    <span (click)="OnClick($event, column)">
                        <i *ngIf="column.DataType === 'details'" class="scania-icon-see-details sm"></i>
                        <i *ngIf="column.DataType === 'manage'" class="material-icons" style="color: var(--dark);">
                            build
                        </i>
                    </span>
                </a>
            </ng-container>
            <ng-container *ngIf="column.DataType === 'radio'">
                <mat-radio-button name="optradio" (change)="onChange(column)"></mat-radio-button>
            </ng-container>
            <ng-container *ngIf="column.DataType === 'selector'">
                <mat-checkbox name="optcheckbox" [(checked)]="selected" [disabled]="!isSelectorEnabled()"
                    (change)="onRowSelectionChanged($event)"></mat-checkbox>
            </ng-container>
            <ng-container *ngIf="column.DataType === 'boolean'">
                <div class="icon">
                    <i *ngIf="GetValue(column.ColumnName); else notTrue" [ngStyle]="column.BooleanTrueIconStyle"
                        class="true material-icons">
                        {{column.BooleanTrueIcon}}
                    </i>
                    <ng-template #notTrue>
                        <i class="false material-icons" [ngStyle]="column.BooleanFalseIconStyle">
                            {{column.BooleanFalseIcon}}
                        </i>
                    </ng-template>
                </div>
            </ng-container>
        </td>
    </ng-container>

</ng-container>
<ng-container *ngIf="!IsViewMode()">
    <ng-container *ngIf="IsEditMode()">
        <ng-container *ngFor="let column of listConfig.ListColumns">
            <td *ngIf="column.IsVisible | async">
                <app-calsiumlist-cell *ngIf="column.AllowEdit" [Column]="column" [Value]="GetValue(column.ColumnName)"
                    [IsEditMode]="true" [listConfig]="listConfig" [RowNumber]="RowNumber" [Form]="Form"
                    [resources]="resources"></app-calsiumlist-cell>
                <div *ngIf="!column.AllowEdit" [class]="column.TextAlignment">
                    <span
                        *ngIf="column.DisplayColumnName !== ''">{{GetValue(column.DisplayColumnName) | pipe:[column.DisplayPipe]}}</span>
                    <span
                        *ngIf="column.DisplayColumnName === ''">{{GetValue(column.ColumnName) | pipe:[column.DisplayPipe]}}</span>
                    <span *ngIf="column.IsCurrency">SEK</span>
                </div>
            </td>
        </ng-container>

    </ng-container>
    <ng-container *ngIf="IsAddMode">
        <td *ngFor="let column of listConfig.ListColumns">
            <app-calsiumlist-cell [Column]="column" [RowNumber]="RowNumber" [IsAddMode]="true" [listConfig]="listConfig"
                [Form]="Form"></app-calsiumlist-cell>
        </td>
    </ng-container>
</ng-container>
<td *ngIf="listConfig.AllowEdit || listConfig.AllowDelete || listConfig.AllowAdd || listConfig.AllowClone || listConfig.ActionsButttons.length"
    class="menu-column">
    <button *ngFor="let action of listConfig.ActionsButttons" class="btn btn-default" (click)="ActionOnClick(action)">
        <i [ngClass]="action.IconCssClass" [title]="action.Title"></i>
    </button>
    <button *ngIf="IsAddMode" class="btn btn-default" (click)="AddItem()">
        <i class="fas fa-save black" title="Save"></i>
    </button>
    <button *ngIf="listConfig.AllowClone && IsViewMode()" class="btn btn-default" (click)="CloneItem()">
        <i class="fa fa-clone black" aria-hidden="true" title="Clone"></i>
    </button>
    <button *ngIf="listConfig.AllowEdit && IsViewMode() && isEditVisible()" class="btn btn-default" (click)="editRow()"
        [disabled]="disableEditButton">
        <i class="fas fa-edit black" title="Edit"></i>
    </button>
    <button *ngIf="listConfig.AllowDelete && IsViewMode() && isDeleteVisible()" class="btn btn-default"
        (click)="DeleteRow()">
        <i class="fas fa-trash-alt black" title="Delete"></i>
    </button>
    <button *ngIf="listConfig.AllowEdit && IsEditMode()" class="btn btn-default" (click)="saveEditedRow()">
        <i class="fas fa-save black" title="Save Edited"></i>
    </button>
    <button *ngIf="(listConfig.AllowEdit && IsEditMode()) || IsAddMode" class="btn btn-default"
        (click)="cancelEditRow()">
        <i class="fas fa-ban black" title="Cancel"></i>
    </button>
</td>