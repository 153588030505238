import * as _ from 'lodash';
import { FinalAssemblyAllocationState } from './state';
import { FinalAssemblyAllocationAction, FinalAssemblyAllocationActions } from './actions';

const initialState = require('./initial-data.json') as FinalAssemblyAllocationState;

export function FinalAssemblyAllocationReducer(
    state: FinalAssemblyAllocationState = initialState,
    action: FinalAssemblyAllocationAction
): FinalAssemblyAllocationState {
    switch (action.type) {
        case FinalAssemblyAllocationActions.LoadProducedComponentListAction:
            return {
                ...state,
                producedComponentList: action.payload
            };
        case FinalAssemblyAllocationActions.LoadAllocatedComponentListAction:
            return {
                ...state,
                allocatedComponentList: action.payload
            };
        case FinalAssemblyAllocationActions.LoadInStockComponentListAction:
            return {
                ...state,
                stockComponentList: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeComponentSearchFormAction:
            return {
                ...state,
                searchForm: action.payload,
                producedPaging: { ...state.producedPaging, page: 1 },
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                stockPaging: { ...state.stockPaging, page: 1 }
            };
        case FinalAssemblyAllocationActions.ChangeProducedComponentListFilterExpressionAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount: 0,
                producedPaging: { ...state.producedPaging, page: 1 },
                producedFilterExpression: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeProducedComponentListSortExpressionAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount: 0,
                producedPaging: { ...state.producedPaging, page: 1 },
                producedSortExpression: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeProducedComponentListPageAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount: 0,
                producedPaging: { ...state.producedPaging, page: action.payload },
            };
        case FinalAssemblyAllocationActions.ChangeProducedComponentListTotalCountAction:
            return {
                ...state,
                producedTotalCount: action.payload.totalCount,
                producedErrorsCount: action.payload.errorsCount
            };
        case FinalAssemblyAllocationActions.ChangeAllocatedComponentListFilterExpressionAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount: 0,
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                allocatedFilterExpression: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeAllocatedComponentListSortExpressionAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount: 0,
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                allocatedSortExpression: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeAllocatedComponentListPageAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount: 0,
                allocatedPaging: { ...state.allocatedPaging, page: action.payload },
            };
        case FinalAssemblyAllocationActions.ChangeAllocatedComponentListTotalCountAction:
            return {
                ...state,
                allocatedTotalCount: action.payload.totalCount
            };
        case FinalAssemblyAllocationActions.ChangeInStockComponentListFilterExpressionAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: 1 },
                stockFilterExpression: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeInStockComponentListSortExpressionAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: 1 },
                stockSortExpression: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeInStockComponentListPageAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: action.payload },
            };
        case FinalAssemblyAllocationActions.ChangeInStockComponentListTotalCountAction:
            return {
                ...state,
                stockTotalCount: action.payload.totalCount,
                stockErrorsCount: action.payload.errorsCount
            };
        case FinalAssemblyAllocationActions.AllocateComponents:
            return {
                ...state,
            };
        case FinalAssemblyAllocationActions.LoadProducedWidgetAction:
            return {
                ...state,
                producedWidget: action.payload
            };
        case FinalAssemblyAllocationActions.LoadAllocatedWidgetAction:
            return {
                ...state,
                allocatedWidget: action.payload
            };
        case FinalAssemblyAllocationActions.LoadInStockWidgetAction:
            return {
                ...state,
                stockWidget: action.payload
            };
        case FinalAssemblyAllocationActions.ChangeCurrentUnitColumns:
            return {
                ...state,
                currentUnitColumns: action.payload
            };
        default:
            return state;
    }
}
