import { NotificationsState } from './state';
import { NotificationAction, NotificationsActions } from './actions';
const initialState = require('./initial-data.json') as NotificationsState;

export function NotificationsReducer(
    state: NotificationsState = initialState,
    action: NotificationAction
): NotificationsState {
    switch (action.type) {
        case NotificationsActions.LoadSystemNotifications:
            return {
                ...state,
                systemNotifications: action.payload
            };
        default:
            return state;
    }
}
