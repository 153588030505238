<div class="row">
    <div class="col-sm-4">
        <cal-allocation-unit-selection [(selected)]="selectedScaniaUnitId"
            (selectionChanged)="onScaniaUnitSelected($event)" [type]="['Final Assembly','Allocation']">
        </cal-allocation-unit-selection>
    </div>
    <div class="col-sm-2">
        <cal-month-picker [value]="selectedPeriod" (valueChanged)="onPeriodSelected($event)">
        </cal-month-picker>
    </div>
    <div class="col-sm-1">
        <button class="btn btn-primary form-control inline" type="submit" (click)="onSearchClick()">
            {{resources.Search}}
        </button>
    </div>
    <div class="col-sm-5">
        <a [href]="allocationBucketUrl" target="_blank" class="btn btn-primary float-right">
            {{ resources.ViewAllocationDetails }}
        </a>
    </div>
</div>

<div class="row">
    <div class="col-sm-4" *ngIf="widgetValue.produced | async as produced">
        <cal-allocation-widget [widget-title]="resources.Produced" [value]="produced.totalValue"
            [volume]="produced.totalAmount" (exportToExcelTriggered)="onExport('produced')" [config]="widgetConfig" >
        </cal-allocation-widget>
    </div>
    <div class="col-sm-4" *ngIf="widgetValue.allocated | async as allocated">
        <cal-allocation-widget [widget-title]="resources.Allocated" [value]="allocated.totalValue"
            [volume]="allocated.totalAmount" (exportToExcelTriggered)="onExport('allocated')" [config]="widgetConfig">
        </cal-allocation-widget>
    </div>
    <div class="col-sm-4" *ngIf="widgetValue.stock | async as stock">
        <cal-allocation-widget [widget-title]="resources.Stock" [value]="stock.totalValue"
            [volume]="stock.totalAmount" (exportToExcelTriggered)="onExport('stock')" [config]="widgetConfig">
        </cal-allocation-widget>
    </div>
</div>
<div class="component-list">
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a class="nav-link" id="materials-tab" href="#produced-list" data-toggle="tab" role="tab"
                aria-controls="produced-tab" aria-selected="true" (click)="setSelectedTab('produced')">
                {{resources.Produced}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="specification-tab" href="#allocated-list" data-toggle="tab" role="tab"
                aria-controls="allocated-tab" aria-selected="false" (click)="setSelectedTab('allocated')">
                {{resources.Allocated}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="specification-tab" href="#stock-list" data-toggle="tab" role="tab"
                aria-controls="allocated-tab" aria-selected="false" (click)="setSelectedTab('stock')">
                {{resources.Stock}}
            </a>
        </li>
    </ul>

    <div class="tab-content">
        <div class="tab-pane fade show active" id="produced-list" role="tabpanel" aria-labelledby="produced-tab">
            <cal-consolidation-unit-allocation-component-list allocationStatus="produced"
                [searchExpression]="searchExpression" [populateListName]="selectedTab"></cal-consolidation-unit-allocation-component-list>
        </div>
        <div class="tab-pane fade show" id="allocated-list" role="tabpanel" aria-labelledby="allocated-tab">
            <cal-consolidation-unit-allocation-component-list allocationStatus="allocated"
                [searchExpression]="searchExpression" [populateListName]="selectedTab"></cal-consolidation-unit-allocation-component-list>
        </div>
        <div class="tab-pane fade show" id="stock-list" role="tabpanel" aria-labelledby="stock-tab">
            <cal-consolidation-unit-allocation-component-list allocationStatus="stock"
                [searchExpression]="searchExpression" [populateListName]="selectedTab"></cal-consolidation-unit-allocation-component-list>
        </div>
    </div>
</div>