<div class="quantity-filter">
    <mat-checkbox (change)="onShowZeroQuantityChange($event.checked)" [checked]="showZeroQuantityParts">
        {{resources.ShowZeroQuantityParts}}
    </mat-checkbox>
</div>

<cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="materialList" (sortTriggered)="onSort($event)"
    (filterTriggered)="onFilter($event)" [filterExpression]="filterExpression | async"
    (exportToExcelTriggered)="onExport()" (clearFilterAndSortingTriggered)="onClearFilter()"
    [dataTotalCount]="materialTotalCount | async" [isLoading]="isMaterialsLoading">
</cal-calsiumlist-listing>

<cal-pagination [paging]="materialPaging | async" (pageChange)="onPaging($event)"
    [dataTotalCount]="materialTotalCount | async">
</cal-pagination>
<hr />
