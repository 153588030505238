import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { ComponentAllocation } from '../component-allocation/state-models/component-allocation';

export enum ReplacementComponentListActions {
    LoadReplacementComponentListPageAction='[ ComponentAllocation ] Load replacement component list',  
    ChangeReplacementComponentListPageAction='[ ComponentAllocation ] Change replacement component list',
    ChangeReplacementComponentListSortExpressionAction='[ ComponentAllocation ] Change Sort Expression',
    ChangeReplacementComponentListFilterExpressionAction='[ ComponentAllocation ] Change Filter Expression',
    ChangeReplacementComponentListTotalCountAction='[ ComponentAllocation ] Total Count Expression'
}

export class ChangeReplacementComponentListSortExpressionAction implements Action {
    readonly type = ReplacementComponentListActions.ChangeReplacementComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeReplacementComponentListFilterExpressionAction implements Action {
    readonly type = ReplacementComponentListActions.ChangeReplacementComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeReplacementComponentListTotalCountAction implements Action {
    readonly type = ReplacementComponentListActions.ChangeReplacementComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class ChangeReplacementComponentListPageAction implements Action {
    readonly type = ReplacementComponentListActions.ChangeReplacementComponentListPageAction;
    constructor(public payload: number) { }
}

export class LoadReplacementComponentListPageAction implements Action {
    readonly type = ReplacementComponentListActions.LoadReplacementComponentListPageAction;
    constructor(public payload: ComponentAllocation[]) { }
}


export type ReplacementComponentListAction =
    | LoadReplacementComponentListPageAction   
    | ChangeReplacementComponentListSortExpressionAction
    | ChangeReplacementComponentListFilterExpressionAction
    | ChangeReplacementComponentListTotalCountAction
    | ChangeReplacementComponentListPageAction
    ;
