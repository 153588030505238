import * as _ from 'lodash';

import { ComponentDetailsState } from './state';
import { ComponentDetailsAction, ComponentDetailsActions } from './actions';
const initialState = require('./initial-data.json') as ComponentDetailsState;

export function ComponentDetailsReducer(
    state: ComponentDetailsState = initialState,
    action: ComponentDetailsAction
): ComponentDetailsState {
    switch (action.type) {
        case ComponentDetailsActions.LoadComponentDetailsAction:
            return {
                ...state,
                componentDetails: action.payload,
                componentMaterialPaging: { ...state.componentMaterialPaging, page: 1 },
                componentSpecificationFilterExpression: null,
                componentSpecificationSortExpression: null,
                componentMaterialFilterExpression: null,
                componentMaterialSortExpression: null
            };
        case ComponentDetailsActions.LoadComponentDetailsMaterialsAction:
            return {
                ...state,
                componentMaterial: action.payload
            };
        case ComponentDetailsActions.ChangeComponentMaterialFilterExpressionAction:
            return {
                ...state,
                componentMaterial: null,
                componentMaterialPaging: { ...state.componentMaterialPaging, page: 1 },
                componentMaterialFilterExpression: action.payload
            };
        case ComponentDetailsActions.ChangeComponentMaterialSortExpressionAction:
            return {
                ...state,
                componentMaterial: null,
                componentMaterialPaging: { ...state.componentMaterialPaging, page: 1 },
                componentMaterialSortExpression: action.payload
            };
        case ComponentDetailsActions.ChangeComponentMaterialPageAction:
            return {
                ...state,
                componentMaterial: null,
                componentMaterialPaging: { ...state.componentMaterialPaging, page: action.payload },
            };
        case ComponentDetailsActions.ChangeComponentMaterialTotalCountAction:
            return {
                ...state,
                componentMaterialTotalCount: action.payload
            };

        case ComponentDetailsActions.ChangeComponentMaterialShowZeroQuantityPartsAction:
            return {
                ...state,
                showZeroQuantityParts: action.payload
            };
        case ComponentDetailsActions.LoadComponentDetailsSpecificationsAction:
            return {
                ...state,
                componentSpecification: action.payload
            };
        case ComponentDetailsActions.ChangeComponentSpecificationsFilterExpressionAction:
            return {
                ...state,
                componentSpecification: null,
                componentSpecificationPaging: { ...state.componentSpecificationPaging, page: 1 },
                componentSpecificationFilterExpression: action.payload
            };
        case ComponentDetailsActions.ChangeComponentSpecificationsPageAction:
            return {
                ...state,
                componentSpecification: null,
                componentSpecificationPaging: { ...state.componentSpecificationPaging, page: action.payload },
            };
        case ComponentDetailsActions.ChangeComponentSpecificationsSortExpressionAction:
            return {
                ...state,
                componentSpecification: null,
                componentSpecificationPaging: { ...state.componentSpecificationPaging, page: 1 },
                componentSpecificationSortExpression: action.payload
            };
        case ComponentDetailsActions.ChangeComponentSpecificationsTotalCountAction:
            return {
                ...state,
                componentSpecificationTotalCount: action.payload
            };
        default:
            return state;
    }
}
