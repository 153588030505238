import { escapeRegExp } from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CalsiumNumberSeperator'
})
export class CalsiumNumberSeperatorPipe implements PipeTransform {

    transform(value: number, notation: string = 'en-US', separatorRequest: string = ","): string {
        if (value == null) {
            return null;
        }

        const thousandSeparatorRegex = new RegExp(
          escapeRegExp(separatorRequest),'g'
        );
        let output = value.toLocaleString(notation).replace(thousandSeparatorRegex, ' ');
        return output;
    }
}