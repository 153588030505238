import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonStateService } from 'src/state';
import { CurrencyAdjustmentStateService } from 'src/state/administraion/currency-adjustment/service';
import { ActivatedRoute } from '@angular/router';
import { CurrencyAdjustmentCode } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-code';


@Component({
    selector: 'cal-currency-adjustment-code-details',
    templateUrl: './currency-adjustment-code-details.component.html'
})
export class CurrencyAdjustmentCodeDetailsComponent implements OnInit {
    resources: any = this.commonService.getResources();
    data: CurrencyAdjustmentCode;
    loading: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private currencyAdjustmentService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
    ) { }

    ngOnInit(): void {
        this.route.params
            .subscribe(async params => {
                this.data = await this.currencyAdjustmentService.getCodeById(params.id);
                this.loading = false;
            })
    }
}
