import { state } from '@angular/animations';
import { Injectable } from "@angular/core";
import { State, Store } from "@ngrx/store";
import { stat } from 'fs';
import { stringify } from 'querystring';
import { Observable } from "rxjs";
import { MarkupConditionMaterialApiService } from "src/api/services/markup-condition-material-api.service";
import { AppState } from "src/state/app.state";
import { Loadable } from "src/state/common/loadable";
import {
    AddMarkupConditionMaterialAction,
    ChangeMarkupConditionMaterialFilterExpressionAction,
    ChangeMarkupConditionMaterialsAction,
    ChangeMarkupConditionMaterialSortExpressionAction,
    LoadMarkupConditionListAction,
    LoadMarkupConditionMaterialAction,
    UpdateMarkupConditionMaterialAction,
} from "./action";
import { MarkupCondition } from "./state-models/markup-condition";
import {
    MarkupConditionMaterial,
    MarkupConditionMaterialAdd,
    MarkupConditionMaterialUpdate,
} from "./state-models/markup-condition-material";

@Injectable({
    providedIn: "root",
})
export class MarkupConditionMaterialStateServiceService {
    constructor(
        private apiService: MarkupConditionMaterialApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>
    ) {}

    @Loadable()
    async loadMarkupCondition(): Promise<void> {
        let list = <MarkupCondition[]>(
            this.appState.getValue().admin.markupConditionMaterialState
                .markupConditionList
        );
        if (list.length === 0) {
            const response = await this.apiService.getMarkupCondition();
            this.appStore.dispatch(new LoadMarkupConditionListAction(response));
        }
    }

    async addMarkupConditionMaterial(
        markupConditionMaterial: MarkupConditionMaterialAdd
    ): Promise<any> {
        const response = await this.apiService.AddMarkupConditionMaterial(
            markupConditionMaterial
        );
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(
            new AddMarkupConditionMaterialAction(
                response.data.markupConditionMaterial.addMarkupConditionMaterial
            )
        );
        //To do ..
        const res = await this.apiService.getMarkupConditionMaterials();
        this.appStore.dispatch(new LoadMarkupConditionMaterialAction(res));
    }

    async updateMarkupConditionMaterial(
        MarkupConditionMaterialUpdate: MarkupConditionMaterialUpdate
    ): Promise<any> {
        const response = await this.apiService.updateMarkupConditionMaterial(
            MarkupConditionMaterialUpdate
        );
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(
            new UpdateMarkupConditionMaterialAction(
                response.data.markupConditionMaterial.updateMarkupConditionMaterial
            )
        );
    }

    getMarkupCondition(): Observable<MarkupCondition[]> {
        return this.appStore.select(
            (state) =>
                state.admin.markupConditionMaterialState.markupConditionList
        );
    }

    getShowOnlyValidMarkupConditionMaterialList(): Observable<boolean> {
        return this.appStore.select(
            (state) =>
                state.admin.markupConditionMaterialState
                    .showOnlyValidMarkupConditionMaterials
        );
    }

    getMarkupConditionMaterialList(): Observable<MarkupConditionMaterial[]> {
        return this.appStore.select(
            (state) =>
                state.admin.markupConditionMaterialState
                    .markupConditionMaterialList
        );
    }

    getMarkupConditionMaterialListSortExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.markupConditionMaterialState
                    .markupConditionMaterialListSortExpression
        );
    }

    getMarkupConditionMaterialListFilterExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.markupConditionMaterialState
                    .markupConditionMaterialListFilterExpression
        );
    }

    sortMarkupConditionMaterials(sortExpression: string) {
        this.appStore.dispatch(
            new ChangeMarkupConditionMaterialSortExpressionAction(
                sortExpression
            )
        );
    }

    filterMarkupConditionMaterials(filterExpression: string) {
        this.appStore.dispatch(
            new ChangeMarkupConditionMaterialFilterExpressionAction(
                filterExpression
            )
        );
    }

    clearMarkupConditionMaterialsFilterAndSorting() {
        this.sortMarkupConditionMaterials(null);
        this.filterMarkupConditionMaterials(null);
    }

    @Loadable()
    async loadMarkupConditionMaterial(): Promise<void> {
        let list = <MarkupConditionMaterial[]>(
            this.appState.getValue().admin.markupConditionMaterialState
                .markupConditionMaterialList
        );
        if (list.length) {
            const response =
                await this.apiService.getMarkupConditionMaterials();
            this.appStore.dispatch(
                new LoadMarkupConditionMaterialAction(response)
            );
        }
    }

    @Loadable()
    async loadMarkupConditionMaterials(
        sortExpression: string,
        filterExpression: string
    ): Promise<void> {
        const payload = {
            order: sortExpression,
            filter: filterExpression,
        };
        const response = await this.apiService.getMarkupConditionMaterials(
            payload
        );
        this.appStore.dispatch(new LoadMarkupConditionMaterialAction(response));
    }

    showShowOnlyValidMarkupConditionMaterials(
        showOnlyValidMarkupConditionMaterials: boolean
    ) {
        this.appStore.dispatch(
            new ChangeMarkupConditionMaterialsAction(
                showOnlyValidMarkupConditionMaterials
            )
        );
    }
}
