<div class="fixActionRow">
    <h2 mat-dialog-title *ngIf="!!dialogRef">{{ resources.CalculationDetails }}</h2>

    <div mat-dialog-content>
        <div class="card" *ngIf="productIndividual$ | async; let productIndividual"
            [class.loading]="isProductIndividualLoading">
            <div class="row card-header">
                <div class="col-sm-6">
                    <h3 class="heading">{{resources.Summary}}</h3>
                </div>
                <div class="col-sm-6">
                    <input type="button" class="btn btn-secondary btn-sm float-right" value="{{resources.ExportToExcel}}" 
                (click)="exportToExcel()"/>
                </div>
            </div>
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.ProductIndividualNumber }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.productIndividualNumber
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ productIndividual.chassisNumber == null ? resources.SerialNumber :
                                    resources.ChassisNumber }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.chassisNumber == null ?
                                        productIndividual.serialNumber : productIndividual.chassisNumber
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.PopId }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.popId }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.ProductClass }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.productClassText
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.Country }}:</label>&nbsp;
                                <span class="form-control-static">
                                    <strong>
                                        {{ productIndividual.country?.name }} ({{ productIndividual.country?.iSOCode }})
                                    </strong>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.SpecialOrderNumber }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.specialOrderNumber
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.FitForUseNumber }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.fitForUseNumber
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.UniqueDesignNumber }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.uniqueDesignNumber
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.InvoiceDate }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.invoicedDate |
                                        date:'shortDate' }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.FinalAssemblyUnit }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.finalAssemblyUnit
                                        }}</strong></span>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.TotalMaterialValue }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.totalMaterialValue |
                                        CalsiumNumber:0:0
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label  title="{{ 'ManualProductionValue : ' + productIndividual.manualProductionValue }}"> {{ resources.TotalProductionValue }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.totalProductionValue |
                                        CalsiumNumber:0:0
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label matTooltipDisabled="{{ productIndividual.corporateMarkupPercentage==null }}" matTooltip="{{ 'Including Corporate Markup ' + productIndividual.corporateMarkupPercentage + '%'}}" matTooltipClass='custom-mat-tooltip'>{{ resources.TotalMarkupValue }}:</label>&nbsp;
                                <span class="form-control-static">
                                    <strong>{{ productIndividual.totalMarkupValue | CalsiumNumber:0:0 }}</strong>
                                </span>
                                <span  class="form-control-static">
                                    <strong *ngIf="productIndividual.totalMarkupValue"> (<span>{{ productIndividual.totalMarkupValue*100/productIndividual.totalDeliveryValue | number:'1.2-2' }}</span>%)</strong>                                    
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.TotalCurrencyValue }}:</label>&nbsp;
                                <span class="form-control-static">
                                    <strong>{{ productIndividual.totalCurrencyValue | CalsiumNumber:0:0 }}</strong>
                                </span>
                                <span  class="form-control-static">
                                    <strong  *ngIf="productIndividual.totalCurrencyValue"> (<span>{{ productIndividual.totalCurrencyValue*100/productIndividual.totalDeliveryValue | number:'1.2-2' }}</span>%)</strong>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.BaseCost }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.baseCost |
                                        CalsiumNumber:0:0
                                        }}</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label>{{ resources.KnrNumber }}:</label>&nbsp;
                                <span class="form-control-static"><strong>{{ productIndividual.knr 
                                        }}</strong></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div>
            <mat-tab-group [selectedIndex]="0">
                <mat-tab [label]="resources.MaterialValue">
                    <div class="tab-content" [class.loading]="isComponentsLoading">
                        <div *ngIf="componentList$ | async; let componentList">
                            <div *ngIf="!componentList || componentList.length === 0" class="alert alert-danger">
                                {{ resources.NoDataFound }}
                            </div>
                            <mat-accordion class="cal-aligned-accordion">
                                <mat-expansion-panel *ngFor="let component of componentList"
                                    (opened)="componentOpen(component)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{ component.componentUnitCode }} | {{ component.componentUnitDescription }}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{ component.componentDescription }}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div *ngIf="selectedComponentId === component.id">
                                        <cal-comp-materials [componentId]="component.id"></cal-comp-materials>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="resources.ProductionValue">
                    <div class="tab-content" *ngIf="productIndividualId > 0">
                        <cal-production-value [productIndividualId]="productIndividualId"
                            [allocationScaniaUnitId]="allocationScaniaUnitId"></cal-production-value>
                    </div>
                </mat-tab>
                <mat-tab [label]="resources.FlowErrors">
                    <div class="tab-content">
                        <cal-calsiumlist-listing [config]="listConfig" [ListBodyData]="dataList$ | async"
                            [isLoading]="isFlowErrorLoading" [dataTotalCount]="totalCount$ | async">
                        </cal-calsiumlist-listing>
                    </div>
                </mat-tab>
                <mat-tab [label]="resources.Specification">
                    <div class="tab-content">
                        <cal-comp-specification [productIndividualId]="productIndividualId"
                            (totalSpecificationCountChanged)="totalSpecificationCount = $event"></cal-comp-specification>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div class="spacer"></div>

    <div mat-dialog-actions *ngIf="!!dialogRef" align="end">
        <button class="btn btn-secondary" (click)="dialogRef.close()">{{ resources.Close }}</button>
    </div>
</div>