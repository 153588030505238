import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CalsiumDate'
})
export class CalsiumDateFormatterPipe implements PipeTransform {

    constructor() { }

    readonly applicationTimeZone: string = 'Europe/Stockholm';

    transform(value: string | Date, useApplicationTimeZone: boolean = true): string {
        if (!value) {
            return '';
        }
        if (value instanceof Date) {
            return value.toISOString();
        }
        const date = new Date(value);
        if (this.dateHasTime(date)) {
            if (useApplicationTimeZone) {
                return date.toLocaleString(undefined, { timeZone: this.applicationTimeZone });
            }
            return date.toLocaleString();

        }
        return date.toLocaleDateString();

    }

    dateHasTime(date: Date): boolean {
        return (date.getUTCSeconds() || date.getUTCMinutes() || date.getUTCHours()) !== 0;
    }
}
