import { SortOrder, TextAlignment } from '../shared/enums.model';
import { CalsiumListSubListComponent } from './calsiumlist-subcomponent/calsiumlist-sublist.component';
import { Observable, of } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { CalsiumListComponent } from './calsiumlist.component';

export class CalsiumListColumnItem {
    DataType: ColumnDataType = ColumnDataType.string;                            // Component consumer set to 'number', 'boolean', 'date' or 'list'
    ColumnName = '';                                // Component consumer set to name om dataset column
    DisplayColumnName = '';
    Caption = '';                                   // Component consumer set table column caption for columnName
    Tooltip = '';
    FilterOptions: CalsiumListColumnFilterOptions = new CalsiumListColumnFilterOptions();  // Component consumer must fill this prop if Type is list
    NewItemListItems: Observable<CalsiumListSelectItem[]>;
    EditItemListItems: Observable<CalsiumListSelectItem[]>;
    FilterValue = '';                               // Component consumer set initial filter value for list
    FilterBooleanTrueText = '';
    FilterBooleanFalseText = '';
    Summable = false;                               // If true all values in column are summed at end of list
    AllowEdit = false;                              // If true the column can be put in edit state
    AllowDelete = false;                            // If true the column can be deleted
    AllowFilter = false;                            // If true the column can be filtered on
    Sortable = false;                               // If true the column can be sorted on
    IsCurrency = false;                             // If true the column will be displyed with curency formatting
    Size: Number = 10;
    Maxlength: Number = 100;
    DisplayOnNewItemCreation = true;
    EnabledOnNewItemCreation = true;
    DisplayPipe = null;
    EditPipe = null;
    SortInOrder: SortOrder = SortOrder.NotDefined;
    TextAlignment: TextAlignment;
    OnChange: (sender: any, event: any) => void;
    MaxNumberOfIntegrsAllowed = 5;
    MaxNumberOfDecimalsAllowed = 0;
    CssClass: String = null;
    GetAnchorLink: (RowData) => string;
    IsAnchor: (RowData) => boolean;
    SelectorValue: boolean = false;
    IsVisible: Observable<boolean> = of(true);
    DynamicCaption: Observable<string>;
    IsClickable: (RowData) => boolean;
    OnClick: (RowData) => any;
    BooleanTrueIcon = "done";
    BooleanFalseIcon = "error_outline";
    BooleanTrueIconStyle = { 'color': 'var(--success)' };
    BooleanFalseIconStyle = { 'color': 'var(--danger)' };

    public static getListColumnItem(dataType: ColumnDataType,
        columnName: string,
        caption: string,
        tooltip: string = ''): CalsiumListColumnItem {
        const column = new CalsiumListColumnItem();
        column.Caption = caption;
        column.DataType = dataType;
        column.ColumnName = columnName;
        column.Tooltip = tooltip;
        column.FilterValue = '';
        column.Summable = false;
        column.AllowEdit = false;
        column.AllowDelete = false;
        column.AllowFilter = false;
        column.Sortable = false;
        column.IsCurrency = false;
        column.Size = 10;

        return column;
    }

    public getFilterExpression(): string {
        if (this.FilterValue !== null && this.FilterValue.toString().trim().length > 0) {
            return `${this.ColumnName}=${this.FilterValue}`;
        }
        return null;
    }
}

export class CalsiumListSelectItem {
    Value = 'Not set';
    Text = 'Not set';

    public static getInstance(optionValue: string, displayText: string): CalsiumListSelectItem {
        const instance: CalsiumListSelectItem = new CalsiumListSelectItem();
        instance.Value = optionValue;
        instance.Text = displayText;
        return instance;
    }

    public static getInstanceArr(optionValue: string[], displayText: string[]): CalsiumListSelectItem[] {
        const result: CalsiumListSelectItem[] = [];
        for (let x = 0; x < optionValue.length; x++) {
            const instance: CalsiumListSelectItem = new CalsiumListSelectItem();
            instance.Value = optionValue[x];
            instance.Text = displayText[x];
            result.push(instance);
        }
        return result;
    }
}

export class CalsiumListColumnFilterOptions {
    FilterListItems: Observable<CalsiumListSelectItem[]>;
    AddEmptyDropDownListItem: boolean = true;
}

export class ClasiumColumnAction {
    IconCssClass: string;
    Title: string;
    OnClick: (RowData) => void;
}

export class CalsiumListFooterItem {
    Label = 'Not set';
    Text = 'Not set';

    public static getInstance(label: string, text): CalsiumListFooterItem {
        const instance: CalsiumListFooterItem = new CalsiumListFooterItem();
        instance.Label = label;
        instance.Text = text;
        return instance;
    }
}

export class CalsiumListConfig {
    ListColumns: CalsiumListColumnItem[] = [];
    ListCaption = 'Not set';
    ListFooterData: CalsiumListFooterItem[] = [];
    SubComponentData: CalsiumListSubListComponent[] = [];
    ListBordered = false;
    ShowFilterRow = true;
    ShowListCaption = true;
    ShowPrint = false;
    ShowAdvancedFilter = false;
    ShowSumRow = false;
    ShowFooter = false;
    ShowClearFilterSortingButton = true;
    AllowEdit = false;
    AllowEditExpression = '';
    AllowAdd = false;
    AllowDelete = false;
    IsRowDeletable: (RowData) => boolean = () => false;
    DeleteItemMethod: (any) => void;
    HasSubComponent = false;
    IsSubComponentVisible: (RowData) => boolean = () => false;
    SubComponent: any;
    ParentComponent: any;
    getSpeicalRowStyle: (RowData) => object = () => { return {} };
    ShowExportToExcel = false;
    getDeleteItemConfirmationMessage: (any) => string;
    AddItemMethod: (FormGroup) => Observable<boolean>;
    EditItemMethod: (FormGroup, any) => Observable<boolean>;
    isRowEditable: (RowData) => boolean;
    OnRowEditMethod: (itemData: any, form: UntypedFormGroup) => void;
    // advanceFilterControls: AdvanceFilterColumns[] = [];
    CalsiumListComponent: CalsiumListComponent;
    AllowClone = false;
    CloneItemMethod: (FormGroup, any) => void;
    IsSelectorEnabled: (RowData) => boolean;
    IsSelected: (RowData) => boolean;
    ActionsButttons: ClasiumColumnAction[] = [];

    public constructor(init?: Partial<CalsiumListConfig>) {
        Object.assign(this, init);
    }

    public AddListColumn(
        dataType: ColumnDataType,
        columnName: string,
        caption: string,
        tooltip: string = ''
    ) {
        const column = CalsiumListColumnItem.getListColumnItem(dataType,
            columnName,
            caption,
            tooltip);
        this.ListColumns.push(column);
        return column;
    }

    public AddFooterItem(label: string, text: string) {
        this.ListFooterData.push(CalsiumListFooterItem.getInstance(label, text));
    }

    // Call Parent component clear filter
    public ClearFilter() {
        this.CalsiumListComponent.clearFilterAndSorting();
    }

    public GetColumns(dataType: ColumnDataType): CalsiumListColumnItem[] {
        const result = [];
        for (let i = 0; i < this.ListColumns.length; i++) {
            if (this.ListColumns[i].DataType === dataType) {
                result.push(this.ListColumns[i]);
            }
        }
        return result;
    }
}

export enum ColumnDataType {
    string = 'string',
    number = 'number',
    list = 'list',
    anchor = 'anchor',
    date = 'date',
    radio = 'radio',
    selector = 'selector',
    details = 'details',
    manage = 'manage',
    boolean = 'boolean'
}

export class CalsiumWidgetConfig {
    ShowExportToExcel = false;
}