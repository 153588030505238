<div class="card">
   <h3 class="card-header">{{resources.AddCurrencyAdjustmentPercentage}}</h3>
   <div class="card-body">
      <div class="container-fluid">
         <div class="row">
            <div class="form-group col-md-2" *ngIf="!currencyAdjustmentCodeId">
               <label>{{resources.Code}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <mat-select [formControl]="codeInput">
                        <mat-option *ngFor="let item of codeList | async" [value]="item.id">
                           {{item.code}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.Percentage}}</label>
               <div class="input-group">
                  <mat-form-field style="width: 100%;" appearance="outline">
                     <input [formControl]="percentageInput" min="0" class="form-control"
                        placeholder="{{resources.Percentage}}" required matInput style="height: 20px;" appNumberOnly
                        [maxNumberOfIntegers]="6" [maxNumberOfDecimals]="2" [useCommaDecimalSeperator]="false" />
                  </mat-form-field>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.Month}}</label>
               <div class="input-group">
                  <cal-month-picker [value]="selectedPeriod" (valueChanged)="onPeriodSelected($event)" required>
                  </cal-month-picker>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>&nbsp;</label>
               <button type="button" class="btn btn-sm btn-primary form-control" id="saveDeliveryRoute"
                  [ngClass]="{'disabled': isSaving }" [disabled]="isSaving" (click)="onSave()">
                  <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<br />