import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonStateService } from 'src/state';
import { Validators, UntypedFormControl } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CurrencyAdjustmentCode, CurrencyAdjustmentCodeAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-code';
import { CurrencyAdjustmentStateService } from 'src/state/administraion/currency-adjustment/service';


@Component({
    selector: 'cal-add-currency-adjustment-code',
    templateUrl: './add-currency-adjustment-code.component.html',
    styleUrls: ['./add-currency-adjustment-code.component.css']
})
export class AddCurrencyAdjustmentCodeComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    CodeInput: UntypedFormControl;
    validFrom:UntypedFormControl;
    validTo:UntypedFormControl;

    constructor(
        private stateService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) { }

    ngOnInit(): void {
        this.buildForm();
    }
    buildForm() {
        this.CodeInput = new UntypedFormControl('', [Validators.required]);
        this.validFrom = new UntypedFormControl(null,  [Validators.required]);
        this.validTo = new UntypedFormControl(null);
    }

    onSave() {
        if (this.isValidForm()) {

            const payload: CurrencyAdjustmentCodeAdd = {
                    code:      this.CodeInput.value,
                validFrom:      this.validFrom.value,
                  validTo:      this.validTo.value
            };

            this.stateService.addCode(payload).then((errors) => {
                let errorMessage = '';
                if (errors && errors.length) {
                    for (let error of errors) {
                        const errorType = error.split('\n')[0].replace('GraphQL.ExecutionError:', '').trim();
                        switch (errorType) {
                            case 'ALREADY_EXIST':
                                errorMessage += this.resources.CurrencyAdjustmentCodeExist;
                                break;
                            default:
                                errorMessage += this.resources.ErrorOccured;
                                break;
                        }

                    }
                    this.errHandler.emitError(errorMessage, this.resources.Error, ErrorTypeEnum.Error);
                }
                else {
                    this.errHandler.emitError(this.resources.SavedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
                    this.reset();
                    }
            });
    }
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.CodeInput.errors != null) {
            if (this.CodeInput.errors.required) {
                this.errHandler.emitError(this.resources.CodeRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }

        if (this.validFrom.errors != null) {
            if (this.validFrom.errors.required) {
                this.errHandler.emitError(this.resources.ValidDateFromRequired,this.resources.RequiredFieldError,ErrorTypeEnum.Error);
                valid = false;
            }
        }

        if (this.validFrom.value > this.validTo.value) {
                 this.errHandler.emitError(this.resources.ValidFromValidToCorrect, this.resources.Error, ErrorTypeEnum.Error);
                 valid = false;
        }

        return valid;
    }

    reset() {
        if (this.CodeInput) {
            this.CodeInput.reset();
            this.validFrom.reset();
            this.validTo.reset();
        }
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
