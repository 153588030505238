import { Action } from "@ngrx/store";
import { Simulation } from "../simulation/state-models/simulation-data";
import { MissingSIMMaterial } from "./state-models/missing-sim-material";
import { MaterialSearchForm } from "./state-models/missing-material-search-form";

export enum MssingSIMMaterialListActions {
    ChangeMissingSIMMaterialListPageAction = '[ MissingSIMMaterial ] Change missing sim material list',
    LoadMissingSIMMaterialListPageAction = '[ MissingSIMMaterial ] Load missing sim material list',
    ChangeMissingSIMMaterialListSortExpressionAction = '[ MissingSIMMaterial ] Change Sort Expression',
    ChangeMissingSIMMaterialListFilterExpressionAction = '[ MissingSIMMaterial ] Change Filter Expression',
    ChangeMissingSIMMaterialListTotalCountAction = '[ MissingSIMMaterial ] Total Count Expression',
    ChangeMissingSIMMaterialListSearchForm = '[ MissingSIMMaterial ] Change Search Form'
}

export class LoadMissingSIMMaterialListPageAction implements Action {
    readonly type = MssingSIMMaterialListActions.LoadMissingSIMMaterialListPageAction;
    constructor(public payload: MissingSIMMaterial[]) { }
}

export class ChangeMissingSIMMaterialListTotalCountAction implements Action {
    readonly type = MssingSIMMaterialListActions.ChangeMissingSIMMaterialListTotalCountAction;
    constructor(public payload: number) { }
}

export class ChangeMissingSIMMaterialListSearchForm implements Action {
    readonly type = MssingSIMMaterialListActions.ChangeMissingSIMMaterialListSearchForm;
    constructor(public payload: MaterialSearchForm) { }
}

export class ChangeMissingSIMMaterialListSortExpressionAction implements Action {
    readonly type = MssingSIMMaterialListActions.ChangeMissingSIMMaterialListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeMissingSIMMaterialListFilterExpressionAction implements Action {
    readonly type = MssingSIMMaterialListActions.ChangeMissingSIMMaterialListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeMissingSIMMaterialListPageAction implements Action {
    readonly type = MssingSIMMaterialListActions.ChangeMissingSIMMaterialListPageAction;
    constructor(public payload: number) { }
}

export type MssingSIMMaterialListAction =
    | ChangeMissingSIMMaterialListPageAction
    | LoadMissingSIMMaterialListPageAction
    | ChangeMissingSIMMaterialListSortExpressionAction
    | ChangeMissingSIMMaterialListFilterExpressionAction
    | ChangeMissingSIMMaterialListTotalCountAction
    | ChangeMissingSIMMaterialListSearchForm
    ;
