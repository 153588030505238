///<reference path="../typings/string.d.ts" />

String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
function () {
    'use strict';
    let str = this.toString();
    if (arguments.length) {
        const t = typeof arguments[0];
        const args = ('string' === t || 'number' === t) ?
        Array.prototype.slice.call(arguments)
        : arguments[0];

        for (const key in args) {
            if (args.hasOwnProperty(key)) {
                str = str.replace(new RegExp(`\\{${key}\\}`, 'gi'), args[key]);
            }
        }
    }

    return str;
};
