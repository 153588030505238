import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProductClassApiService } from 'src/api/services/product-class-api.service';
import { ProductClassComponentUnitApiService } from 'src/api/services/product-class-component-unit-api.service';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CommonStateService, MasterDataStateService } from 'src/state';
import { ProductClassComponentUnitStateService } from 'src/state/administraion/product-class-component-unit/service';
import { ProductClassComponentUnitAdd } from 'src/state/administraion/product-class-component-unit/state-model/product-class-component-unit';

@Component({
  selector: 'cal-add-product-class-component-unit',
  templateUrl: './add-product-class-component-unit.component.html'
})
export class AddProductClassComponentUnitComponent implements OnInit {
  @ViewChild('productClassSelector') productClassSelector;
  resources: any = this.commonService.getResources();
  subscriptions = new Subscription();
  productClassId: number;
  productClass: FormControl; //Unlike the other FC-objects, the value of this returns a list due to it being "mat-select multiple"
  componentTypeId: number;
  componentType: FormControl;
  componentUnitId: number;
  componentUnitCode: FormControl;
  productClassList: any;
  componentTypeList: any;
  componentUnitList: any;
  componentUnitDescription: FormControl;
  showHint = true;

  constructor(
    private stateService: ProductClassComponentUnitStateService,
    private commonService: CommonStateService,
    private errHandler: ErrorHandlerService,
    private apiService: ProductClassApiService,
    private apiPccuService: ProductClassComponentUnitApiService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.buildForm();
    this.productClassList = await this.apiService.getProductClass();
    this.componentTypeList = await this.apiPccuService.getComponentType();
  }

  buildForm() {
    this.productClass = new FormControl("", [Validators.required]);
    this.componentType = new FormControl("", [Validators.required]);
    this.componentUnitCode = new FormControl("", [Validators.required, Validators.pattern("^[1-9][0-9]{2,6}$")]); //Validates that input is an integer, doesn't start with 0 and is within the range 3-7 digits
    this.componentUnitDescription = new FormControl(null);
  }

  onSave() {
    if (this.isValidForm()) {

      this.productClass.value.forEach(productClassSelection => {
        const payload: ProductClassComponentUnitAdd = {
          componentUnit: {
            code: this.componentUnitCode.value,
            description: this.componentUnitDescription.value
          },
          productClassId: productClassSelection,
          componentTypeId: this.componentType.value,
        };

        this.stateService
          .addProductClassComponentUnits(payload)
          .then((errors) => {
            let errorMessage = "";
            if (errors && errors.length) {
              for (let error of errors) {
                const errorType = error
                  .split("\n")[0]
                  .replace("GraphQL.ExecutionError:", "")
                  .trim();
                switch (errorType) {
                  case "RECORD_ALREADY_EXISTS":
                    errorMessage += this.resources.RecordAlreadyExist;
                    break;
                  case "INVALID_COMPONENT_UNIT_CODE":
                    errorMessage += this.resources.InvalidComponentUnitCode;
                  default:
                    errorMessage += this.resources.ErrorOccured;
                    break;
                }
              }
              this.errHandler.emitError(
                errorMessage,
                this.resources.Error,
                ErrorTypeEnum.Error
              );
            } else {
              this.errHandler.emitError(
                this.resources.SavedSuccessfully,
                this.resources.Success,
                ErrorTypeEnum.Success
              );
              this.reset();
            }
          });
      });
    }
  }

  isValidForm(): boolean {
    let valid = true;
    if (this.componentUnitCode.errors != null) {
      if (this.componentUnitCode.errors.required) {
        this.errHandler.emitError(
          this.resources.ComponentUnitCodeRequired,
          this.resources.RequiredFieldError,
          ErrorTypeEnum.Error
        );
        valid = false;
      }
      if (this.componentUnitCode.errors.pattern) {
        this.errHandler.emitError(
          this.resources.InvalidComponentUnitCode,
          this.resources.RequiredFieldError,
          ErrorTypeEnum.Error
        );
        valid = false;
      }
    }
    if (this.productClass.errors != null) {
      if (this.productClass.errors.required) {
        this.errHandler.emitError(
          this.resources.ProductClassRequired,
          this.resources.RequiredFieldError,
          ErrorTypeEnum.Error
        );
        valid = false;
      }
    }
    if (this.componentType.errors != null) {
      if (this.componentType.errors.required) {
        this.errHandler.emitError(
          this.resources.ComponentTypeRequired,
          this.resources.RequiredFieldError,
          ErrorTypeEnum.Error
        );
        valid = false;
      }
    }
    return valid;
  }



  reset() {
    if (this.componentType) {
      this.productClass.reset();
      this.componentType.reset();
      this.componentUnitCode.reset();
      this.componentUnitDescription.reset();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCancel() {
    this.reset()
  }

  onHintDismiss(): void {
    this.showHint = false;
  }

  onProductClassClick() {
    this.productClassSelector.open();
  }

}
