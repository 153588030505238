import { MasterDataState } from './state';
import { MasterDataAction, MasterDataActions } from './actions';
const initialState = require('./initial-data.json') as MasterDataState;

export function MasterDataReducer(
    state: MasterDataState = initialState,
    action: MasterDataAction
): MasterDataState {
    switch (action.type) {
        case MasterDataActions.LoadUnitOfMeasurement:
            return {
                ...state,
                unitOfMeasurement: action.payload
            };
        case MasterDataActions.LoadScaniaUnitAction:
            return {
                ...state,
                scaniaUnits: action.payload
            };
        case MasterDataActions.LoadComponentTypesAction:
            return {
                ...state,
                componentTypes: action.payload
            };
        default:
            return state;
    }
}
