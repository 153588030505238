import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appCalsiumlistSublist]'
})
export class CalsiumlistSublistDirective {

    constructor(public viewContainerRef: ViewContainerRef) { }

}
