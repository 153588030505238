import { Component, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Subscription, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
const moment = _rollupMoment || _moment;
import { environment } from 'src/environments/environment';
import { AllocationSearchForm } from 'src/state/consolidation-unit-allocation/state-models/allocation-search-form';
import { AllocationSummary } from 'src/state/consolidation-unit-allocation/state-models/allocation-summary';
import { ConsolidationUnitAllocationStateService } from 'src/state/consolidation-unit-allocation/service';
import { CommonStateService } from 'src/state';
import { FinalAssemblyAllocationStateService } from 'src/state/final-assembly-allocation/service';
import { CalsiumWidgetConfig } from 'src/app/calsiumlist/calsiumlist.component.model';

@Component({
    selector: 'cal-allocation-consolidation-unit',
    templateUrl: './consolidation-unit.component.html',
    styleUrls: ['./consolidation-unit.component.css']
})
export class ConsolidationUnitComponent  implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    selectedScaniaUnitId: number = 0;
    selectedPeriod: Moment;
    componentSearchForm: Observable<AllocationSearchForm>;
    subscriptions = new Subscription();
    widgetValue = {
        produced: null as Observable<AllocationSummary>,
        allocated: null as Observable<AllocationSummary>,
        stock: null as Observable<AllocationSummary>
    };
    allocationBucketUrl = environment.allocationBucketUrl;
    searchExpression: string;
    widgetConfig: CalsiumWidgetConfig;
    selectedTab: string = "";

    constructor(
        private stateService: ConsolidationUnitAllocationStateService,
        private commonService: CommonStateService,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) { }

    ngOnInit() {
        this.buildWidget();
        this.componentSearchForm = this.stateService.getComponentSearchForm();
        this.widgetValue.produced = this.stateService.getConsolidationUnitSummary('produced') || of({totalValue:0, totalAmount:0});
        this.widgetValue.allocated = this.stateService.getConsolidationUnitSummary('allocated') || of({totalValue:0, totalAmount:0});
        this.widgetValue.stock = this.stateService.getConsolidationUnitSummary('stock') || of({totalValue:0, totalAmount:0});
        
        this.subscriptions.add(
            combineLatest(this.componentSearchForm).pipe(
                debounceTime(0),
                switchMap(([componentSearchForm]) => {
                    if (componentSearchForm != null) {
                        this.fillSearchForm(componentSearchForm);
                        return this.stateService.loadConsolidationUnitAllocationSummary(
                            componentSearchForm.selectedScaniaUnitId,
                            componentSearchForm.fromDate,
                            componentSearchForm.toDate,
                            true
                        );
                    }
                    return new Promise<void>(null);
                })
            ).subscribe()
        );
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    onScaniaUnitSelected(scaniaUnitId: any) {
        this.selectedScaniaUnitId = scaniaUnitId;
        this.resetToDefaults();
        
    }

    onPeriodSelected(period: any) {
        this.selectedPeriod = period;
        this.resetToDefaults();
    }

    onSearchClick() {
        const period = this.selectedPeriod || moment();
        const selectedScaniaUnitId = this.selectedScaniaUnitId;

        if (selectedScaniaUnitId === 0) return;

        const fromDate = period.date(1).format('YYYY-MM-DD');
        const toDate = period.add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD');
        const param: AllocationSearchForm = {
            fromDate,
            toDate,
            selectedScaniaUnitId
        };
        this.stateService.search(param);
        this.searchExpression = `${fromDate}${toDate}${selectedScaniaUnitId}`;
    }

    fillSearchForm(form: AllocationSearchForm) {
        this.selectedScaniaUnitId = form.selectedScaniaUnitId;
        this.selectedPeriod = moment(form.fromDate, 'YYYY-MM-DD');
    }
    
    onExport(allocationStatus: string) {
        this.stateService.exportAllocationData(allocationStatus);
    }

    buildWidget(){
        this.widgetConfig = new CalsiumWidgetConfig();
        this.widgetConfig.ShowExportToExcel = true;
    }

    setSelectedTab(selectedTab: string)
    {        
            this.selectedTab = selectedTab;
            this.onSearchClick();
    }

    resetToDefaults() {
        this.selectedTab = "";
        this.stateService.resetToDefaults();
        let el = this.elementRef.nativeElement.querySelector('.nav-link.active');
        if(el)
        this.renderer.removeClass(el, 'active');
    }
}
