import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { AppState } from '../app.state';
import { LoadSystemNotifications } from './actions';

import { Loadable } from '../common/loadable';
import { NotificationsApiService } from '../../api/services/notifications-api.service';
import { SystemNotification } from './state-models/system-notifications';
import { map } from 'rxjs/operators';
import { NotificationsState } from './state';

@Injectable()
export class NotificationsService {
    constructor(
        private appStore: Store<AppState>,
        private appState: State<AppState>,
        private apiService: NotificationsApiService) { }

    getSystemNotifications(): Observable<SystemNotification[]> {
        return this.appStore.select(state => state.notifications.systemNotifications)
            .pipe(map(systemNotifications => _.values(systemNotifications)));
    }

    getState(): NotificationsState {
        return <NotificationsState>this.appState.getValue().notifications;
    }

    @Loadable()
    async loadSystemNotifications(): Promise<void> {
        const systemNotificationsResponse = await this.apiService.getSystemNotifications();
        this.appStore.dispatch(new LoadSystemNotifications(systemNotificationsResponse));
    }
}
