<div class="alert alert-info alert-dismissible fade show" role="alert" *ngIf="showHint">
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onHintDismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
  <p><b>{{ resources.Info }}:</b></p>
  <div [innerHTML]="resources.PriceInformationBox | sanitizeHtml"></div>
</div>
<br>
<div [ngClass]="{loading: loading}" style="min-height: 10rem">
  <div *ngIf="material$ | async as material">
    <cal-material-information [material]="material" [resources]="resources"></cal-material-information>
    <cal-material-add-price [material$]="material$" [materialPriceTypes]="materialPriceTypes$" [resources]="resources"></cal-material-add-price>
    <cal-material-prices [material]="material" [materialPriceTypes]="materialPriceTypes$" [resources]="resources"></cal-material-prices>
  </div>
</div>