import { CPAReportState } from './state';
import { CPAReportAction, CPAReportActions } from './actions';
const initialState = require('./initial-data.json') as CPAReportState;

export function CPAReportReducer(
    state: CPAReportState = initialState,
    action: CPAReportAction
): CPAReportState {
    switch (action.type) {
        case CPAReportActions.LoadCPAListAction:
            return {
                ...state,
                cpaList: action.payload
            };
        default:
            return state;
    }
}
