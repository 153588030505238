import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map,lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComponentAllocationInput } from 'src/state';

@Injectable({
    providedIn: "root",
})
export class OrchestratorsApiService {
    constructor(private apollo: Apollo, private http: HttpClient) {}

    getStepFault(variables?): Promise<any> {
        const result$ = this.apollo
            .query<any>({
                variables,
                query: gql`
                    query (
                        $from: DateTime
                        $to: DateTime
                        $paging: Paging
                        $order: String
                        $filter: String
                        $scaniaUnitId: Int
                    ) {
                        orchestrator {
                            stepfault(
                                from: $from
                                to: $to
                                paging: $paging
                                order: $order
                                filter: $filter
                                scaniaUnitId: $scaniaUnitId
                            ) {
                                list {
                                    type
                                    details
                                    message
                                    referenceValue
                                    componentIndividualId
                                    startDate
                                    stepDescription
                                    faultType
                                }
                                totalCount
                            }
                        }
                    }
                `,
            })
            .pipe(
                map((result: any) => {
                    return result?.data?.orchestrator?.stepfault;
                    })
            )
            
            return lastValueFrom(result$);
    }

    getProductIndividualStepFault(variables?): Promise<any> {
        const result$ = this.apollo
            .query<any>({
                variables,
                query: gql`
                    query (
                        $productIndividualId: Int
                        $allocationScaniaUnitId: Int
                    ) {
                        orchestrator {
                            productIndividualStepFault(
                                productIndividualId: $productIndividualId
                                allocationScaniaUnitId: $allocationScaniaUnitId
                            ) {
                                type
                                details
                                message
                                referenceValue
                                flowExecutionStep {
                                    startDate
                                }
                            }
                        }
                    }
                `,
            })
            .pipe(
                
                map((result: any) => {
                    return result?.data?.orchestrator?.productIndividualStepFault;
                    }) 
                )
            return lastValueFrom(result$);
    }

    recalculateComponents(componentIdList: number[]): Observable<number[]> {
        const url = `${environment.adminUrl}/recalculation/components`;
        return this.http.post<number[]>(url, componentIdList);
    }

    recalculateProductsByComponentTypes(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: ComponentAllocationInput[],
        excludedItems: ComponentAllocationInput[]
    ): Observable<number[]> {
        const url = `${environment.adminUrl}/recalculation/productsByComponentType`;
        const reqData = {
            AllocationStatus: allocationStatus,
            AllocationScaniaUnitId: allocationScaniaUnitId,
            FromDate: from,
            ToDate: to,
            SelectAll: selectAll,
            SelectedProductsComponentTypes: includedItems,
            ExcludedProductsComponentTypes: excludedItems,
            Filter: filterExpression,
        };
        return this.http.post<number[]>(url, reqData);
    }

    recalculateProducts(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Observable<number[]> {
        const url = `${environment.adminUrl}/recalculation/products`;
        const reqData = {
            AllocationStatus: allocationStatus,
            AllocationScaniaUnitId: allocationScaniaUnitId,
            FromDate: from,
            ToDate: to,
            SelectAll: selectAll,
            SelectedProducts: includedItems,
            ExcludedProducts: excludedItems,
            Filter: filterExpression,
            RequestSource: requestSource,
        };
        return this.http.post<number[]>(url, reqData);
    }

    recalculateCurrencies(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Observable<number[]> {
        const url = `${environment.adminUrl}/recalculation/currencies`;
        const reqData = {
            AllocationStatus: allocationStatus,
            AllocationScaniaUnitId: allocationScaniaUnitId,
            FromDate: from,
            ToDate: to,
            SelectAll: selectAll,
            SelectedProducts: includedItems,
            ExcludedProducts: excludedItems,
            Filter: filterExpression,
            RequestSource: requestSource,
        };
        return this.http.post<number[]>(url, reqData);
    }

    recalculateMarkups(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Observable<number[]> {
        const url = `${environment.adminUrl}/recalculation/markups`;
        const reqData = {
            AllocationStatus: allocationStatus,
            AllocationScaniaUnitId: allocationScaniaUnitId,
            FromDate: from,
            ToDate: to,
            SelectAll: selectAll,
            SelectedProducts: includedItems,
            ExcludedProducts: excludedItems,
            Filter: filterExpression,
            RequestSource: requestSource,
        };
        return this.http.post<number[]>(url, reqData);
    }

    deallocateByComponents(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: ComponentAllocationInput[],
        excludedItems: ComponentAllocationInput[]): Observable<number[]> 
        {
        const url = `${environment.adminUrl}/deallocation/deallocateByComponents`;
        const reqData = {
            AllocationStatus: allocationStatus,
            AllocationScaniaUnitId: allocationScaniaUnitId,
            FromDate: from,
            ToDate: to,
            SelectAll: selectAll,
            SelectedProductsComponentTypes: includedItems,
            ExcludedProductsComponentTypes: excludedItems,
            Filter: filterExpression,
        };
        return this.http.post<number[]>(url, reqData);
    }

    deallocateProducts(
        allocationScaniaUnitId: number,
        allocationStatus: string,
        from: string,
        to: string,
        filterExpression: string,
        selectAll: boolean,
        includedItems: number[],
        excludedItems: number[],
        requestSource: string
    ): Observable<number[]> {
        const url = `${environment.adminUrl}/deallocation/deallocateByProducts`;
        const reqData = {
            AllocationStatus: allocationStatus,
            AllocationScaniaUnitId: allocationScaniaUnitId,
            FromDate: from,
            ToDate: to,
            SelectAll: selectAll,
            SelectedProducts: includedItems,
            ExcludedProducts: excludedItems,
            Filter: filterExpression,
            RequestSource: requestSource,
        };
        return this.http.post<number[]>(url, reqData);
    }
}
