import { Directive, ElementRef, Renderer2, Input,  HostListener } from '@angular/core';

@Directive({
    selector: '[appGlowElementOnFoucs]'
})
export class GlowElementOnFoucsDirective {

    constructor(private renderer: Renderer2, private el: ElementRef) {
    //     this.renderer.addClass(this.el.nativeElement, 'ControlLocked');
    //     this.renderer.setProperty(this.el.nativeElement, 'readOnly', true);
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.renderer.addClass(this.el.nativeElement, 'glowElementOn');
        this.renderer.removeClass(this.el.nativeElement, 'glowElementOff');

    }

    @HostListener('mouseleave') onMouseLeave() {
        this.renderer.removeClass(this.el.nativeElement, 'glowElementOn');
        this.renderer.addClass(this.el.nativeElement, 'glowElementOff');
    }
}
