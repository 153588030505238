import { ProductIndividualState } from './state';
import { ProductIndividualAction, ProductIndividualActions } from './actions';

const initialState = require('./initial-data.json') as ProductIndividualState;
export function ProductIndividualReducer(state: ProductIndividualState = initialState, action: ProductIndividualAction): ProductIndividualState {
    switch (action.type) {
        case ProductIndividualActions.loadProductIndividual:
            return {
                ...state,
                productIndividual: action.payload
            };
        case ProductIndividualActions.loadProductProductionValue:
            return {
                ...state,
                componentTypeProductionValues: action.payload
            };
        case ProductIndividualActions.loadProductManualProductionValue:
            return {
                ...state,
                componentTypeManualProductionValues: action.payload
            };
        default:
            return state;
    }
}
