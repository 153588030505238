import { CommonState } from './state';
import { CommonActions, CommonAction } from './actions';

const initialState = require('./initial-data.json');

export function commonReducer(
    state: CommonState = initialState,
    action: CommonAction
): CommonState {
    switch (action.type) {
        case CommonActions.StartLoadingAction:
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case CommonActions.FinishLoadingAction:
            return {
                ...state,
                loadingItems: state.loadingItems.filter(item => item.key !== action.payload.key)
            };
        case CommonActions.UpdateActiveMenuAction:
            return {
                ...state,
                activeMenu: action.payload
            };
        default:
            return { ...state };
    }
}
