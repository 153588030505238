import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { CommonStateService, ProductClass, Market } from 'src/state';
import { Validators, UntypedFormControl, FormControlName, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CurrencyAdjustmentCode } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-code';
import { CurrencyAdjustmentStateService } from 'src/state/administraion/currency-adjustment/service';
import { CurrencyAdjustmentConditionAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition';
import { ConfirmationDialogService } from 'src/app/common/confirmation-dialog/confirmation-dialog.service';
import { CalsiumDateFormatter } from 'src/app/shared/calsium-date-formatter';
import { switchMap } from 'rxjs/operators';


@Component({
    selector: 'cal-add-currency-adjustment-condition',
    templateUrl: './add-currency-adjustment-condition.component.html',
    styleUrls: ['./add-currency-adjustment-condition.component.css']
})
export class AddCurrencyAdjustmentConditionComponent implements OnInit, OnDestroy {
    @Output() saved = new EventEmitter();
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    codeList: Observable<CurrencyAdjustmentCode[]>;    
    productClassList: Observable<ProductClass[]>;
    marketList: Observable<Market[]>;
    showSave: boolean = false;
    isSaving: boolean = false;
    datesForm: UntypedFormGroup;
    
    


    productClassInput: UntypedFormControl;
    marketInput: UntypedFormControl;
    materialCodeInput: UntypedFormControl;
    pvCodeInput: UntypedFormControl;
    validFrom: UntypedFormControl;
    validTo: UntypedFormControl;
    constructor(
        private stateService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private confirmationDialog: ConfirmationDialogService,
        private formBuilder: UntypedFormBuilder
    ) { }

    ngOnInit(): void {
        this.stateService.loadMarket();
        this.stateService.loadProductClass();
        this.stateService.loadCode(null, null, null);
        this.stateService.loadConditions(null,null,null);
        this.codeList = this.stateService.getCodeList();
        this.marketList = this.stateService.getMarket();
        this.productClassList = this.stateService.getProductClass();

         

        this.subscriptions.add(
            this.stateService.getConditionList().subscribe(conditions => this.showSave = conditions.findIndex(c => c.isNew === true) > -1)
        );

        this.buildForm();
    }
    buildForm() {
        this.productClassInput = new UntypedFormControl('', [Validators.required]);
        this.marketInput = new UntypedFormControl('', [Validators.required]);
        this.materialCodeInput = new UntypedFormControl('', [Validators.required]);
        this.pvCodeInput = new UntypedFormControl('', [Validators.required]);
        this.validFrom = new UntypedFormControl('', [Validators.required]);
        this.validTo= new UntypedFormControl(null);

    }
   

    onAdd() {
        let errorMessage = '';
        if (this.isValidForm()) {
            const payload: CurrencyAdjustmentConditionAdd = {
                marketId: this.marketInput.value,
                productClassId: this.productClassInput.value,
                materialCurrencyAdjustmentCodeId: this.materialCodeInput.value,
                pvCurrencyAdjustmentCodeId: this.pvCodeInput.value,
                variantOptions: [],
                validFrom:this.validFrom.value,
                validTo: this.validTo.value

            };
                this.stateService.addCondition(payload);
                this.reset();

        }
    }
    async onSave() {
        const result = await this.confirmationDialog.show(
            this.resources.SaveConfirmation,
            this.resources.Confirm);
        if (!result) {
            return;
        }
        this.isSaving = true;
        this.stateService.saveConditions().then((errors) => {
            this.isSaving = false;
            if (errors && errors.length) {
                let errorMessage = '';
                for (let error of errors) {
                    const errorType = error.split('\n')[0].replace('GraphQL.ExecutionError:', '').trim();
                    switch (errorType) {
                        case 'ALREADY_EXIST':
                            errorMessage += this.resources.CurrencyAdjustmentConditionExist;
                            break;
                        default:
                            errorMessage += this.resources.ErrorOccured;
                            break;
                    }
                }
                this.errHandler.emitError(errorMessage, this.resources.Error, ErrorTypeEnum.Error);
            } else {
                this.saved.emit();
                this.errHandler.emitError(this.resources.SavedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
                this.reset();
            }
        });
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.productClassInput.errors != null) {
            if (this.productClassInput.errors.required) {
                this.errHandler.emitError(this.resources.ProductClassRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.marketInput.errors != null) {
            if (this.marketInput.errors.required) {
                this.errHandler.emitError(this.resources.MarketRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.materialCodeInput.errors != null) {
            if (this.materialCodeInput.errors.required) {
                this.errHandler.emitError(this.resources.MaterialCodeRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.pvCodeInput.errors != null) {
            if (this.pvCodeInput.errors.required) {
                this.errHandler.emitError(this.resources.ProductionValueCodeRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
         if (this.validFrom.errors != null) {
             if (this.validFrom.errors.required) {
                 this.errHandler.emitError( this.resources.ValidDateFromRequired, this.resources.RequiredFieldError,ErrorTypeEnum.Error);
                 valid = false;
             }
         }
        if(this.validTo.value != '')
        if(this.validFrom.value > this.validTo.value){
                this.errHandler.emitError(this.resources.ValidFromValidToCorrect, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
        }
        return valid;
    }


    reset() {
        if (this.productClassInput) {
            this.productClassInput.reset();
        }
        if (this.marketInput) {
            this.marketInput.reset();
        }
        if (this.materialCodeInput) {
            this.materialCodeInput.reset();
        }
        if (this.pvCodeInput) {
            this.pvCodeInput.reset();
        }
        if(this.validFrom){
              this.validFrom.reset();
        }
        if (this.validTo) {
            this.validTo.reset();
        }

    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
