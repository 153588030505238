<div class="row" *ngIf="!showConfirm" [ngClass]="{loading: isLoading}">
  <div class="alert alert-info col-md-10" role="alert">
    <p><b>{{ resources.Info }}:</b></p>
    <div>{{ resources.PasteCSVLines}}
      {{resources.ChassisNumber}}/ {{resources.SerialNumber}},
      {{ resources.CountryCode}},
      {{ resources.InvoiceAmount}}, <a (click)="showResponsiblityCodeTable = !showResponsiblityCodeTable"
      style="text-decoration: underline;cursor: pointer;">{{resources.ResponsiblityCode}}</a>,
      {{ resources.InvoiceDate}}({{ resources.InvoiceDateFormat}})
    </div>
  </div>
  <div  class="form-group col-md-10">
    <div class="form-group col-md-1.5; max-limit__text-div-adjustment">
      <span class="max-limit__text-font "><span class="max-limit__text-style">* </span>{{resources.MaximumEntriesAllowedForInvoicing}}</span>
    </div>
    <table class="table" *ngIf="showResponsiblityCodeTable">
      <thead>
        <tr>
          <th scope="col">{{resources.Units | titlecase}}</th>
          <th scope="col">{{resources.ResponsiblityCode}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">DGE</th>
          <td>0000</td>
        </tr>
        <tr>
          <th scope="row">IM</th>
          <td>2NNN</td>
        </tr>
        <tr>
          <th scope="row">Chassis Number</th>
          <td>NNNN</td>
        </tr>
        <tr>
          <td colspan="2"><b>Note: </b>N is any natural number.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br />
  <div class="form-group col-md-10">
    <mat-form-field style="width: 100%;" appearance="outline">
      <mat-label>{{resources.WriteCSV}}</mat-label>
      <textarea matInput cdkTextareaAutosize style="width: 100%;font-size: 20px;" [formControl]="csvInput"
        #autosize="cdkTextareaAutosize" (ngModelChange)="csvInputChange($event)" cdkAutosizeMinRows="10"
        cdkAutosizeMaxRows="50"></textarea>
      <mat-error *ngIf="csvInput.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="validate()">{{resources.Validate}}</button>
    </div>
  </div>
</div>
<div class="row" *ngIf="showConfirm" [ngClass]="{loading: isLoading}">
  <div class="col-md-10">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{resources.ChassisNumber}}</th>
          <th scope="col">{{resources.SerialNumber}}</th>
          <th scope="col">{{resources.CountryCode}}</th>
          <th scope="col">{{ resources.InvoiceAmount}}</th>
          <th scope="col">{{resources.ResponsiblityCode}}</th>
          <th scope="col">{{resources.InvoiceDate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let manualinvoice of validmanualinvocingList">
          <th scope="row">{{manualinvoice.chassisNumber}}</th>
          <th>{{manualinvoice.serialNumber}}</th>
          <td>{{manualinvoice.countryCode}}</td>
          <td class="font-weight-bold">{{manualinvoice.invoiceAmount}}</td>
          <td>{{manualinvoice.responsibilityCode}}</td>
          <td>{{manualinvoice.invoiceDate}}</td>
        </tr>
      </tbody>
    </table>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="back()"
        style="margin-right: 50px;">{{resources.Back}}</button>
      <button type="button" class="btn btn-primary" (click)="confirm()">{{resources.Confirm}}</button>
    </div>
  </div>
</div>