<div class="modal-header">
  <h4 class="modal-title" id="UserInfoLabel">{{resources.UserInformation}}</h4>
  <button type="button" class="close" aria-hidden="true" (click)="close()">
    &times;
  </button>
</div>
<div class="modal-body">
  {{resources.Username}}: <b>{{ profile.sub }}</b> <br />
  {{resources.Name}}: <b>{{ profile.given_name }} {{profile.family_name}}</b> <br />
  {{resources.Email}}: <b>{{ profile.email }}</b> <br />
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-default" (click)="close()">{{resources.Close}}</button>
</div>
