import * as _ from 'lodash';
import { SimulationActions } from "./action";
import { SimulationState } from "./state";
import { SimulationAction } from './action';

const initialState = require('./initial-data.json') as SimulationState;
export function SimulationReducer(
    state: SimulationState = initialState, 
    action: SimulationAction
): SimulationState {
    switch (action.type)
    {
            case SimulationActions.LoadSimulationListPageAction:
                return {
                    ...state,
                    simulationList: action.payload
                };                        
            case SimulationActions.ChangeSimulationListFilterExpressionAction:
                return {
                    ...state,
                    simulationList: null,
                    simulationTotalCount: 0,
                    simulationPaging: { ...state.simulationPaging, page: 1 },
                    simulationFilterExpression: action.payload
                };
            case SimulationActions.ChangeSimulationListSortExpressionAction:
                return {
                    ...state,
                    simulationList: null,
                    simulationTotalCount: 0,
                    simulationPaging: { ...state.simulationPaging, page: 1 },
                    simulationSortExpression: action.payload
                };
            case SimulationActions.ChangeSimulationListPageAction:
                return {
                    ...state,
                    simulationList: null,
                    simulationTotalCount: 0,
                    simulationPaging: { ...state.simulationPaging, page: action.payload },
                };
            case SimulationActions.ChangeSimulationListTotalCountAction:
                return {
                    ...state,
                    simulationTotalCount: action.payload.totalCount,
                    simulationErrorsCount: action.payload.errorsCount
                };            
            default:
                return state;
        }
    }
    