// SEK <input size="item[subItem.ColumnName].length" id="{{subItem.ColumnName}}{{i}}" type="text" [(ngModel)]="item[subItem.ColumnName]" (dblclick)="makeEditable(item, subItem, i)" (keydown)="endEdit($event, item, subItem, i)" [readonly]="true"
// [value]="item[subItem.ColumnName] | currency:'SEK':'code':'1.0-4'" class="ControlLocked">
import { Directive, ElementRef, Renderer2, Input,  HostListener } from '@angular/core';

@Directive({
    selector: '[highLightEditableCell]'
})
export class HighLightEditableCell {
    @Input('toggleEditState') id: string;

    constructor(private renderer: Renderer2, private el: ElementRef) {
    //     this.renderer.addClass(this.el.nativeElement, 'ControlLocked');
    //     this.renderer.setProperty(this.el.nativeElement, 'readOnly', true);
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.renderer.addClass(this.el.nativeElement, 'HoverOverLockedEditable');
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.renderer.removeClass(this.el.nativeElement, 'HoverOverLockedEditable');
    }
}
