export enum ErrorTypeEnum {
    NoError = 0,
    Info = 1,
    Warning = 2,
    Error = 3,
    Success = 4,
    Custom = 5
}

export enum SortOrder {
    NotDefined = 0,
    Asc = 1,
    Desc = -1
}

export enum KeyDownCodes {
    BackSpace = 8,
    Tab = 9,
    Enter = 13,
    Escape = 27,
    Space = 32,
    PageUp = 33,
    PageDown = 34,
    End = 35,
    Home = 36,
    ArrowLeft = 37,
    ArrowUp = 38,
    ArrorRight = 39,
    ArrowDown = 40,
    Insert = 45,
    Delete = 46,
    Plus = 170,
    Minus = 109
}

export enum TextAlignment {
    Left = 'text-left',
    Center = 'text-center',
    Right = 'text-right'
}

export enum ProductIndividualSearchFilter
{
    ChassisNumberOrPINumber = "Chassis/Product Individual Number",    
    SerialNumber = "Serial Number",
    KNRNumber="KNR Number",
    ArchivedProduct = "Archived Product"
}
