import { Component, OnInit } from '@angular/core';
import { CommonStateService, Paging } from 'src/state';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { ProductClassComponentUnit } from 'src/state/administraion/product-class-component-unit/state-model/product-class-component-unit';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ProductClassComponentUnitStateService } from 'src/state/administraion/product-class-component-unit/service';
import { debounceTime, switchMap } from 'rxjs/operators';


@Component({
  selector: 'cal-product-class-component-unit-list',
  templateUrl: './product-class-component-unit-list.component.html'
})
export class ProductClassComponentUnitListComponent implements OnInit {

  resources: any = this.commonService.getResources();
  subscriptions = new Subscription();
  sortingExpression: Observable<string>;
  filterExpression: Observable<string>;
  totalCount: Observable<number>;
  productClassComponentUnitList: Observable<ProductClassComponentUnit[]>;
  isLoading: Observable<boolean>;
  listConfig: CalsiumListConfig;    
  paging: Observable<Paging>;

  constructor(
      private productClassComponentUnitService: ProductClassComponentUnitStateService,
      private commonService: CommonStateService,
      private errHandler: ErrorHandlerService
  ) {} 

  ngOnInit(): void {
        
    this.isLoading = this.commonService.getLoading();
    this.sortingExpression = this.productClassComponentUnitService.getProductClassComponentUnitListSortExpression();
    this.filterExpression = this.productClassComponentUnitService.getProductClassComponentUnitListFilterExpression();
    this.productClassComponentUnitList = this.productClassComponentUnitService.getProductClassComponentUnitList();
    this.paging = this.productClassComponentUnitService.getPaging();           
    this.totalCount = this.productClassComponentUnitService.getTotalCount();

    this.subscriptions.add(
        combineLatest(
            this.paging,
            this.sortingExpression,
            this.filterExpression
        )
        .pipe(
            debounceTime(0),
            switchMap(
                ([
                    paging,
                    sortingExpression,
                    filterExpression
                ]) => {
                    return this.productClassComponentUnitService.loadProductClassComponentUnits(
                        paging,
                        sortingExpression,
                        filterExpression                             
                    );
                }
            )
        )
        .subscribe()
    );

    this.buildList();
  }

  buildList() 
    {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.ProductClassComponentUnitList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;        
        this.listConfig.ShowExportToExcel = false;

        let column;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "componentType.name",
            this.resources.ComponentType
        );
        column.AllowFilter = true;
        column.Sortable = true;
        
        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "productClass.variantOption.description",
            this.resources.ProductClass
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.number,
            "componentUnit.code",
            this.resources.ComponentUnitCode
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "componentUnit.description",
            this.resources.ComponentUnitDescription
        );
        column.AllowFilter = true;
        column.Sortable = true;       
    }

    ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    }

    onSort(sortExpression: string) {
    this.productClassComponentUnitService.sortProductClassComponentUnit(
        sortExpression
    );
    }

    onFilter(filterExpression: string) {
        this.productClassComponentUnitService.filterProductClassComponentUnit(
            filterExpression
        );
    }
    onPaging(page: number) {
        this.productClassComponentUnitService.goToPage(page);
      }

    onClearFilterAndSorting() {
        this.productClassComponentUnitService.clearProductClassComponentUnitFilterAndSorting();
    }
}
