import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from './authentication';
import { CommonStateService, MenuItem } from '../state';
import { environment } from '../environments/environment';
// TODO: to review.
import { ErrorHandlerService } from './errorhandler/error-handler.service';
import { ErrorObject } from './errorhandler/error-object';
import { ErrorTypeEnum } from './shared/enums.model';
import { CustomToastOption } from './shared/custom-toast-options';
import { MasterDataStateService } from '../state/master-data/service';
import { defineCustomElements, addTheme } from 'corporate-ui';
import { theme as scaniaTheme } from 'scania-theme';

defineCustomElements(['c-theme', 'c-header', 'c-footer', 'c-content', 'c-navigation', 'c-icon']);
addTheme(scaniaTheme);

@Component({
    selector: '#app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    profile$: Observable<any>;
    menu$: Observable<MenuItem[]>;
    activeMenu$: Observable<MenuItem>;
    subscriptions = new Subscription();
    isLoggedIn$: Observable<boolean>;

    constructor(
        private authService: AuthService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private toastr: ToastrService,
        private masterDataService: MasterDataStateService
    ) {
        this.menu$ = this.commonService.getMenus();
        this.activeMenu$ = this.commonService.getActiveMenu();
        this.profile$ = this.authService.userInfo.pipe(distinctUntilChanged(), map(u => (u ? u.profile : {})));
        this.subscriptions.add(
            this.authService.userInfo.subscribe((user) => {
                if (!!user && !user.expired) {
                    this.masterDataService.loadMasterData();
                }
            })
        );
        this.subscriptions.add(
            this.errHandler.errorEvent.subscribe((err: ErrorObject) => this.onError(err))
        );
    }

    ngOnInit(): void {
        this.isLoggedIn$ = combineLatest(this.authService.isUserLoggedIn, this.authService.isUserAuthorized$)
            .pipe(
                map(([isUserLoggedIn, isUserAuthorized]) => isUserLoggedIn && isUserAuthorized)
            );
        document.body.setAttribute('data-env', environment.currentEnvironment);
        if(environment.currentEnvironment !== 'prod'){
            document.title += " " + environment.currentEnvironment
        }
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    // TODO: add to common state.
    onError(error: ErrorObject) {
        const errorObj = this.errHandler.getError();
        if (errorObj != null) {
            if (errorObj.errType === ErrorTypeEnum.Error) {
                const errOption = new CustomToastOption().getErrorOptions();
                this.toastr.error(errorObj.errorText, errorObj.originator, errOption);
            } else if (errorObj.errType === ErrorTypeEnum.Info) {
                const infoOption = new CustomToastOption().getInfoOptions();
                this.toastr.info(errorObj.errorText, errorObj.originator, infoOption);
            } else if (errorObj.errType === ErrorTypeEnum.Success) {
                const successOption = new CustomToastOption().getSuccessOptions();
                this.toastr.success(
                    errorObj.errorText,
                    errorObj.originator,
                    successOption
                );
            } else if (errorObj.errType === ErrorTypeEnum.Warning) {
                const warnOption = new CustomToastOption().getWarningOptions();
                this.toastr.warning(
                    errorObj.errorText,
                    errorObj.originator,
                    warnOption
                );
            } else if (errorObj.errType === ErrorTypeEnum.Custom) {
                const custOption = new CustomToastOption().getCustomOptions(
                    'customErrMsgClass',
                    'customTitleClass'
                );
                this.toastr.error(errorObj.errorText, errorObj.originator, custOption);
            }
        }
    }
}
