<div class="card" *ngIf="InputReady()">
  <h3 class="card-header">Summary</h3>
  <div class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm">
          <div class="form-group">
            <label>ID:</label>&nbsp;
            <span class="form-control-static"><strong>{{ componentIndividual.id }}</strong></span>
          </div>
          <div class="form-group">
            <label>{{resources.ProductIndividual}}:</label>&nbsp;
            <span class="form-control-static">
              <strong>
                {{ componentIndividual.productIndividualNumber }}
              </strong>
            </span>
          </div>
          <div class="form-group">
            <label>{{resources.ComponentUnitShort}}:</label>&nbsp;
            <span class="form-control-static">
              <strong>
                {{ componentIndividual.componentUnitCode }}
              </strong>
            </span>
            <i *ngIf="componentIndividual.componentUnit?.description?.length > 0">
              <strong>
                ({{ componentIndividual.componentUnitDescription }})
              </strong>
            </i>
          </div>
        </div>
        <div class="col-sm">
          <div class="form-group">
            <label>{{resources.ChassisNumber}}:</label>&nbsp;
            <span class="form-control-static">
              <strong>
                {{ componentIndividual.chassisNumber }}
              </strong>
            </span>
          </div>
          <div class="form-group">
            <label>{{resources.SerialNumber}}:</label>&nbsp;
            <span class="form-control-static">
              <strong>
                {{ componentIndividual.serialNumber }}
              </strong>
            </span>
          </div>
          <div class="form-group">
            <label>{{resources.MaterialValue}}:</label>&nbsp;
            <span class="form-control-static">
              <strong>
                {{ componentIndividual.materialValue | CalsiumNumber:0:2 }}
              </strong>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <button type="button" class="btn btn-primary" (click)="recalculateComponent()">
            {{resources.Recalculate.formatUnicorn(resources.component)}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row" *ngFor="let chunk of toAttributesArray(componentIndividual.extraAttributes)">
    <div class="col-sm-3" *ngFor="let pair of chunk">
      <label>{{ resources[pair.key] || pair.key }}:</label>&nbsp;
      <span class="form-control-static"><strong>{{ pair.value }}</strong> </span>
    </div>
  </div>
</div>
<br />