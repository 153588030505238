<div class="card" *ngIf="!loading">
   <h3 class="card-header">{{resources.CurrencyAdjustmentCode}}</h3>
   <div class="card-body">
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm">
               <div class="form-group">
                  <label>{{resources.Id}}:</label>&nbsp;
                  <span class="form-control-static"><strong>{{ data.id }}</strong></span>
               </div>
            </div>
            <div class="col-sm">
               <div class="form-group">
                  <label>{{resources.Code}}:</label>&nbsp;
                  <span class="form-control-static">
                     <strong>
                        {{ data.code }}
                     </strong>
                  </span>
               </div>

            </div>
            <div class="col-sm">
                <div class="form-group">
                    <label>{{resources.From}}:</label>&nbsp;
                    <span class="form-control-static"><strong>{{ data.validFrom }}</strong></span>
                </div>
            </div>
            <div class="col-sm">
                <div class="form-group">
                    <label>{{resources.To}}:</label>&nbsp;
                    <span class="form-control-static">
                        <strong>
                            {{ data.validTo }}
                        </strong>
                    </span>
                </div>
            </div>

         </div>
      </div>
   </div>
</div>
<br />
<cal-add-currency-adjustment-percentage></cal-add-currency-adjustment-percentage>
<cal-currency-adjustment-percentage></cal-currency-adjustment-percentage>
