<div class="card">
  <h3 class="card-header">{{resources.MaterialInformation}}</h3>
  <div class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>{{resources.MaterialNumber}}:</label>&nbsp;
            <span class="form-control-static"><strong>{{ material.materialNumber }}</strong></span>
          </div>
          <div class="form-group">
            <label>{{resources.MaterialType}}:</label>&nbsp;
            <span class="form-control-static"><strong>{{ material.materialSupplyType.code }}</strong></span>
          </div>
          <div class="form-group">
            <label>{{resources.WeightInGrams}}:</label>&nbsp;
            <span class="form-control-static"><strong>{{ material.weightInGrams }}</strong></span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>{{resources.Description}}:</label>&nbsp;
            <span class="form-control-static"><strong>{{ material.name }}</strong></span>
          </div>
          <div class="form-group">
            <label>{{resources.PurchaserCode}}:</label>&nbsp;
            <span class="form-control-static"><strong> {{ material.purchaserCode }}</strong></span>
          </div>
          <div class="form-group">
            <label>{{resources.PricedUnitOfMeasurement}}:</label>&nbsp;
            <span class="form-control-static"><strong>{{ material.pricedUnitOfMeasurementName }}</strong></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />