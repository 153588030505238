import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CalsiumDateFormatter extends NgbDateParserFormatter {
    static dateToNgbDate(date: Date, start?: boolean): NgbDate {
        if (!date) {
            const today = new Date();
            if (start) {
                return this.dateToNgbDate(
                    new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1, 0, 0, 0))
                );
            }
            return this.dateToNgbDate(
                new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0))
            );
        }
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    static getDateOrDefault(date: Date, start: boolean): string {
        if (date) {
            if (typeof date === 'string') return date;
            return date.toISOString().substring(0, 10);
        }
        const today = new Date();
        if (start) {
            return new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1, 0, 0, 0))
                .toISOString()
                .substring(0, 10);
        }
        return new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0))
            .toISOString()
            .substring(0, 10);
    }

    static ngbDateToDate(ngbDate: NgbDate | NgbDateStruct): Date {
        return new Date(Date.UTC(ngbDate.year, ngbDate.month - 1, ngbDate.day, 0, 0, 0));
    }

    parse(value: string): NgbDateStruct {
        return CalsiumDateFormatter.dateToNgbDate(new Date(value));
    }

    format(date: NgbDateStruct): string {
        return CalsiumDateFormatter.ngbDateToDate(date).toLocaleDateString();
    }
}
