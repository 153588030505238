import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';
import { CurrencyAdjustmentCode, CurrencyAdjustmentCodeAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-code';
import { CurrencyAdjustmentPercentage, CurrencyAdjustmentPercentageAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-percentage';
import { CurrencyAdjustmentCondition, CurrencyAdjustmentConditionAdd, CurrencyAdjustmentConditionUpdate } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition';
import { CurrencyAdjustmentConditionVariantOptionAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition-variant-option';



@Injectable({
  providedIn: 'root'
})
export class CurrencyAdjustmentApiService {
  constructor(private apollo: Apollo) { }

  getCodeList(variables?): Promise<CurrencyAdjustmentCode[]> {
    const result$ = this.apollo
      .query<CurrencyAdjustmentCode[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
              query codeQuery($order:String, $filter:String, $id:Int, $showOnlyValidCodes: Boolean)
              {
                currencyAdjustment {
                  code(order: $order, filter: $filter, id: $id, showOnlyValidCodes:$showOnlyValidCodes) {
                    id
                    code
                    validFrom
                    validTo
                  }
                }
              }`
      })
      .pipe<CurrencyAdjustmentCode[]>(
     
        map((result: any) => {
          return result?.data?.currencyAdjustment?.code;
          })
      )
     
      return lastValueFrom(result$);
  }

  getPercentages(variables?): Promise<CurrencyAdjustmentPercentage[]> {
    const result$ = this.apollo
      .query<CurrencyAdjustmentPercentage[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
              query percentageQuery($order:String, $filter:String, $codeId:Int, $simulation: Boolean)
              {
                currencyAdjustment {
                  percentage(order: $order, filter: $filter, codeId: $codeId, simulation: $simulation) {
                    id
                    currencyAdjustmentCodeId
                    percentage
                    validFrom
                    validTo
                    simulation
                  }
                }
              }`
      })
      .pipe<CurrencyAdjustmentPercentage[]>(
       
        map((result: any) => {
          return result?.data?.currencyAdjustment?.percentage;
          })
      )
     
      return lastValueFrom(result$);
  }

  async addCode(payload: CurrencyAdjustmentCodeAdd): Promise<any> {
    const mutation = `
    mutation addCode($payload: CodeInput!) {
      currencyAdjustment {
        addCode(payload: $payload)
        {
          id
          code
          validFrom
          validTo
        }
      }
    }
    `;
    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    })
      .toPromise();
  }

  async updateCode(payload: CurrencyAdjustmentCodeAdd): Promise<any>{
     const mutation = `
          mutation updateCode($payload: CodeInput!) {
            currencyAdjustment {
              updateCode (payload: $payload) {
                id
                code
                validFrom
                validTo
              }
            }
          }`;

        return this.apollo.mutate({
            mutation: gql(mutation),
            variables: { payload }
        })
        .toPromise();
    }

    async updateConditions(payload: CurrencyAdjustmentConditionUpdate): Promise<any>{
      const mutation = `
           mutation updateConditions($payload: ConditionUpdate!) {
             currencyAdjustment {
              updateConditions (payload: $payload) {
                 id                 
                 validFrom
                 validTo
               }
             }
           }`;
 
         return this.apollo.mutate({
             mutation: gql(mutation),
             variables: { payload }
         })
         .toPromise();
     }



  async deleteCode(payload: number): Promise<any> {
    const mutation = `
    mutation deleteCode($id: Int!) {
      currencyAdjustment {
        deleteCode(id: $id)
      }
    }
    `;
    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { 'id': payload }
    })
      .toPromise();
  }

  async addPercentage(payload: CurrencyAdjustmentPercentageAdd): Promise<any> {
    const mutation = `
    mutation addPercentage($payload: PercentageInput!) {
      currencyAdjustment {
        addPercentage(payload: $payload)
        {
          id
          currencyAdjustmentCodeId
          percentage
          validFrom
          validTo
        }
      }
    }
    `;
    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    })
      .toPromise();
  }

  getConditions(variables?): Promise<CurrencyAdjustmentCondition[]> {
    const result$ = this.apollo
      .query<CurrencyAdjustmentCondition[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
        query conditionsQuery($order: String, $filter: String, $showOnlyValidConditions: Boolean) {
          currencyAdjustment {
            conditions(order: $order, filter: $filter, showOnlyValidConditions: $showOnlyValidConditions) {
              id
              marketId
              productClassId
              materialCurrencyAdjustmentCodeId
              pvCurrencyAdjustmentCodeId
              validFrom
              validTo
              market {
                name
                description
              }
              productClass {
                variantOption {
                  value
                  description
                }
              }
              materialCurrencyAdjustmentCode {
                id
                code
              }
              pvCurrencyAdjustmentCode {
                id
                code
              }
              variantOptions {
                id
                currencyAdjustmentConditionId
                variantOptionId
                variantOption {
                  description
                  value
                  variantFamily {
                    description
                    value
                  }
                }
              }
            }
          }
        }
        `
      })
      .pipe<CurrencyAdjustmentCondition[]>(
       
        map((result: any) => {
          return result?.data?.currencyAdjustment?.conditions;
          })
      )
     
      return lastValueFrom(result$);
  }

  async addCondition(payload: CurrencyAdjustmentConditionAdd[]): Promise<any> {
    const mutation = `
    mutation addConditions($payload: [ConditionInput!]) {
      currencyAdjustment {
        addConditions(payload: $payload) {
          id
          marketId
          productClassId
          materialCurrencyAdjustmentCodeId
          pvCurrencyAdjustmentCodeId
          validFrom
          validTo
          market {
            name
            description
          }
          productClass {
            variantOption {
              value
              description
            }
          }
          materialCurrencyAdjustmentCode {
            id
            code
          }
          pvCurrencyAdjustmentCode {
            id
            code
          }
          variantOptions {
            id
            currencyAdjustmentConditionId
            variantOptionId
            variantOption {
              description
              value
              variantFamily {
                description
                value
              }
            }
          }
        }
      }
    }
    `;
    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    })
      .toPromise();
  }
}
