import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';

import { ProductSearchComponent } from '../search/product-search/product-search.component';

@NgModule({
    imports: [
        AngularCommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,        
        MatOptionModule,
        MatMenuModule
    ],
    declarations: [
        ProductSearchComponent
    ],
    exports: [
        ProductSearchComponent
    ]
})
export class SearchModule { }
