import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { DeliveryRoute } from './state-models/delivery-route';

export enum DeliveryRouteActions {
  LoadListAction = '[ Admin ] load delivery route',
  ChangeSortExpressionAction = '[ Admin ] Change delivery route Sort Expression',
  ChangeFilterExpressionAction = '[ Admin ] Change delivery route Filter Expression',
}
export class LoadListAction implements Action {
    readonly type = DeliveryRouteActions.LoadListAction;
    constructor(public payload: DeliveryRoute[]) { }
}

export class ChangeSortExpressionAction implements Action {
  readonly type = DeliveryRouteActions.ChangeSortExpressionAction;
  constructor(public payload: string) { }
}

export class ChangeFilterExpressionAction implements Action {
  readonly type = DeliveryRouteActions.ChangeFilterExpressionAction;
  constructor(public payload: string) { }
}

export type DeliveryRouteAction =
  | LoadListAction
  | ChangeSortExpressionAction
  | ChangeFilterExpressionAction
;
