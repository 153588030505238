import { Action } from '@ngrx/store';
import { MenuItem } from './state-models/menu-item';
import { LoadingItem } from './state-models/loading-item';

export enum CommonActions {
    StartLoadingAction = '[ Common ] Start Loading',
    FinishLoadingAction = '[ Common ] Finish Loading',
    UpdateActiveMenuAction = '[ Common ] Update Active Menu'
}

export class StartLoadingAction implements Action {
    readonly type = CommonActions.StartLoadingAction;
    constructor(public payload: LoadingItem) { }
}

export class FinishLoadingAction implements Action {
    readonly type = CommonActions.FinishLoadingAction;
    constructor(public payload: LoadingItem) { }
}

export class UpdateActiveMenuAction implements Action {
    readonly type = CommonActions.UpdateActiveMenuAction;
    constructor(public payload: MenuItem) { }
}

export type CommonAction =
    | StartLoadingAction
    | FinishLoadingAction
    | UpdateActiveMenuAction;
