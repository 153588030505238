import { Injectable } from '@angular/core';
import { CalsiumListColumnItem, ColumnDataType } from '../calsiumlist.component.model';

@Injectable()
export class CalsiumfilterService {

    constructor() { }

    filterData(data: any[], filterExpression: string, columns: CalsiumListColumnItem[]): Promise<any[]> {
        return new Promise((resolve, reject) => {
            let filtered = data;
            const filters = filterExpression.split('&');
            for (let i = 0; i < filters.length; i++) {
                const columnName = filters[i].split('=')[0];
                const filterValue = filters[i].split('=')[1];
                const column = columns.find(c => c.ColumnName === columnName);
                if (filterValue.trim() === '') {
                    continue;
                } else {
                    filtered = <any>filtered.filter((item) => {
                        if (filterValue === 'null') {
                            return item[columnName] === null || item[columnName] === '';
                        }
                        if (item[columnName] === null) {
                            return false;
                        }
                        if (column.DataType === ColumnDataType.list) { // && column.FilterValue !== '')
                            return this.getNestedObject(item, columnName).toString().toLowerCase() === filterValue.toLowerCase();
                        }

                        if (column.DataType === ColumnDataType.number) {
                            return item[columnName].toString().indexOf(filterValue.replace(' ', '')) !== -1;
                        }

                        return item[columnName].toString().toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    });
                }
            }
            resolve(filtered);
        });
    }

    private getNestedObject(nestedObj: any, columnName: string): any {
        const pathArr = columnName.split('.');
        return pathArr.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    }
}
