<div class="alert alert-info alert-dismissible fade show" role="alert" *ngIf="showHint">
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onHintDismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
  <p><b>{{ resources.Info }}:</b></p>
  <div [innerHTML]="resources.PriceInformationBox | sanitizeHtml"></div>
</div>
<br>
<cal-calsiumlist-listing [config]="materialListConfig" [ListBodyData]="dataList | async" (sortTriggered)="onSort($event)"
  (filterTriggered)="onFilter($event)" (clearFilterAndSortingTriggered)="onClearFilterAndSorting()" [isLoading]="isLoading | async"
  [dataTotalCount]="totalCount | async" (exportToExcelTriggered)="onExport()" [filterExpression]="filterExpression | async"></cal-calsiumlist-listing>
<cal-pagination [paging]="paging | async" (pageChange)="onPaging($event)" [dataTotalCount]="totalCount | async"></cal-pagination>