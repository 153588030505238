export interface ScaniaUnit {
    id: number;
    name: string;
    description: string;
    scaniaUnitTypes: string[];
}

export abstract class ScaniaUnitType {
    public static AllocationUnit = 'allocation';
    public static ComponentWorkshop = 'component';
    public static ConsolidatingUnit = 'consolidation';
    public static FinalAssemblyWorkshop = 'final assembly';
    public static ProductionUnit = 'production';
}
