import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { CommonStateService, VariantFamily, VariantOption } from 'src/state';
import { Validators, UntypedFormControl } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { CurrencyAdjustmentStateService } from 'src/state/administraion/currency-adjustment/service';
import { CurrencyAdjustmentConditionVariantOptionAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition-variant-option';
import { CurrencyAdjustmentCondition } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition';
import { startWith, map } from 'rxjs/operators';


@Component({
    selector: 'cal-add-currency-adjustment-condition-variant',
    templateUrl: './add-currency-adjustment-condition-variant.component.html',
    styleUrls: ['./add-currency-adjustment-condition-variant.component.css']
})
export class AddCurrencyAdjustmentConditionVariantComponent implements OnInit, OnDestroy {
    @Input()
    condition: CurrencyAdjustmentCondition;
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    variantFamilyList: Observable<VariantFamily[]>;
    filteredVariantFamilyList: Observable<VariantFamily[]>;
    variantOptionList: Observable<VariantOption[]>;
    conditionList: Observable<CurrencyAdjustmentCondition[]>;

    variantFamilyInput: UntypedFormControl;
    variantOptionInput: UntypedFormControl;
    constructor(
        private stateService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) { }

    ngOnInit(): void {
        this.stateService.loadVariantFamilies();
        this.variantFamilyList = this.stateService.getVariantFamilies();
        this.variantOptionList = this.stateService.getVariantOptions();
        this.conditionList = this.stateService.getConditionList().pipe(map((x) => x.filter(y => y.id === this.condition.id)));
        this.buildForm();
        this.filteredVariantFamilyList =
            combineLatest(this.variantFamilyInput.valueChanges, this.variantFamilyList, this.conditionList)
                .pipe(
                    startWith(''),
                    map(([value, VariantFamilyList, condition]) => this._filterVariantFamilyList(value, VariantFamilyList, condition))
                );
    }
    buildForm() {
        this.variantFamilyInput = new UntypedFormControl('', [Validators.required]);
        this.variantOptionInput = new UntypedFormControl('', [Validators.required]);
    }

    private _filterVariantFamilyList(value: string, list: VariantFamily[], conditions: CurrencyAdjustmentCondition[]): VariantFamily[] {
        if (value && list) {
            const filterValue = value.toString().toLowerCase();
            let filteredList = list.filter(option => option.value.toLowerCase().indexOf(filterValue) === 0);
            if (conditions.length)
                filteredList = filteredList.filter(x => conditions[0].variantOptions.map(vo => vo.variantOption.variantFamily.id).filter(y => y === x.id).length === 0);
            return filteredList;
        }
    }

    onAdd() {
        if (this.isValidForm()) {
            const payload: CurrencyAdjustmentConditionVariantOptionAdd = {
                currencyAdjustmentConditionId: this.condition.id,
                variantOptionId: this.variantOptionInput.value
            };
            this.stateService.addConditionVariantOption(payload);
            this.reset();
        }
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.variantFamilyInput.errors != null) {
            if (this.variantFamilyInput.errors.required) {
                this.errHandler.emitError(this.resources.VariantFamilyRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.variantOptionInput.errors != null) {
            if (this.variantOptionInput.errors.required) {
                this.errHandler.emitError(this.resources.VariantOptionRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        return valid;
    }


    reset() {
        if (this.variantFamilyInput) {
            this.variantFamilyInput.reset();
        }
        if (this.variantOptionInput) {
            this.variantOptionInput.reset();
            this.stateService.resetVariantOptions();
        }
    }

    onVariantFamilyChange(e) {
        this.stateService.loadVariantOptions(e.option.value.id);
        this.variantFamilyInput.setValue(e.option.value.value);
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
