import { Injectable } from '@angular/core';
import { OverviewApiService } from 'src/api/services/overview-api.service';
import { Observable } from 'rxjs';
import { AllocationOverviewConfirmationCheck } from './state-models/overview-data';


@Injectable()
export class OverviewStateService {
    constructor(
        private apiService: OverviewApiService) { }

        public GetByComponents(from,to,scaniaUnitId): Observable<any> {
            return this.apiService.GetByComponents(from,to,scaniaUnitId);
        }
        
        public GetByFinalAssembly(from, to,scaniaUnitId): Observable<any> {
            return this.apiService.GetByFinalAssembly(from,to,scaniaUnitId);
        }

        public GetByConsolidation(from, to,scaniaUnitId): Observable<any> {
            return this.apiService.GetByConsolidation(from,to,scaniaUnitId);
        }

        SaveAllocationOverviewConfirmationCheck(allocationOverviewConfirmationCheck: AllocationOverviewConfirmationCheck) {
            const response = this.apiService.SaveAllocationOverviewConfirmationCheck(allocationOverviewConfirmationCheck);
        }
}
