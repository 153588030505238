import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { CommonStateService, ComponentType } from '../../../../../state';
import { ComponentTypeProductionValue } from 'src/state/product-individual/state-models/component-type-production-value';
import { ProductIndividualStateService } from 'src/state/product-individual/service';
import { ComponentTypeManualProductionValue } from 'src/state/product-individual/state-models/component-type-manual-production-value';

@Component({
    selector: 'cal-production-value',
    templateUrl: './production-value.component.html',
    styleUrls: ['./production-value.component.css']
})
export class ProductionValueComponent implements OnInit, OnDestroy {
    @Input() productIndividualId: number;
    @Input() allocationScaniaUnitId: number;

    resources: any = this.commonService.getResources();

    productionValuesList$: Observable<ComponentTypeProductionValue[]>;
    productionValuesList: ComponentTypeProductionValue[];
    productionValues: ComponentTypeProductionValue[];

    manualProductionValuesList$: Observable<ComponentTypeManualProductionValue[]>;
    manualProductionValuesList: ComponentTypeManualProductionValue[];
    manualProductionValues: ComponentTypeManualProductionValue[];

    componentTypes: ComponentType[] = [];

    isProductionValuesLoading = true;

    subscriptions = new Subscription();

    constructor(private apiService: ProductIndividualStateService,
        private commonService: CommonStateService) { }

    ngOnInit() {
        this.productionValuesList$ = this.apiService.getProductProductionValues();
        this.manualProductionValuesList$ = this.apiService.getProductManualProductionValues();

        this.subscriptions.add(
            this.productionValuesList$.subscribe((productionValues) => {
                this.componentTypes = [];
                this.productionValuesList = productionValues;
                this.filterComponentTypes(this.componentTypes, productionValues);
            })
        );

        this.subscriptions.add(
            this.manualProductionValuesList$.subscribe((productionValues) => {
                this.manualProductionValuesList = productionValues;
                this.filterComponentTypes(this.componentTypes, productionValues);
            })
        );

        this.isProductionValuesLoading = true;
        this.apiService.loadProductIndividualProductionValues(this.productIndividualId, this.allocationScaniaUnitId)
            .then(_ => this.isProductionValuesLoading = false);
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    filterComponentTypes(componentTypes: ComponentType[], productionValues: ComponentTypeProductionValue[] | ComponentTypeManualProductionValue[]) {
        const compTypeIds = componentTypes.map(ct => ct.id);
        productionValues.forEach((pv) => {
            if (compTypeIds.indexOf(pv.componentType.id) < 0) {
                compTypeIds.push(pv.componentType.id);
                componentTypes.push(pv.componentType);
            }
        });
    }

    componentTypeOpen(componentTypeId: number) {
        this.productionValues = this.productionValuesList.filter(pv => pv.componentType.id === componentTypeId);
        this.manualProductionValues = this.manualProductionValuesList.filter(pv => pv.componentType.id === componentTypeId);
    }
}
