import { ScaniaUnitAllocation, DeliveryRoute, ScaniaUnitConsolidation } from 'src/state';

export interface DeliveryRouteAllocationUnit {
    id: number;
    propertyCode: string;
    deliveryRouteId: number;
    scaniaUnitAllocationId: number;
    scaniaUnitConsolidationId: number;
    deliveryRoute? : DeliveryRoute
    scaniaUnitAllocation?: ScaniaUnitAllocation;
    scaniaUnitConsolidation? : ScaniaUnitConsolidation;
}
