import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastrHelperService extends ToastrService {
    download(title: string): ActiveToast<any> {
        return this.show(
            `<div class="slider">
                <div class="line"></div>
                <div class="subline inc"></div>
                <div class="subline dec"></div>
            </div>`,
            title,
            {
                enableHtml: true,
                disableTimeOut: true,
                tapToDismiss: false
            },
            'toast-download'
        );
    }
}
