import { Action } from "@ngrx/store";
import { ProductClassComponentUnit } from "./state-model/product-class-component-unit";


export enum ProductClassComponentUnitActionType {
    AddProductClassComponentUnitAction = "[ Admin ] Add product class component unit list ",

    LoadProductClassListAction = "[ Admin ] load product class list",
    LoadComponentTypeListAction = "[ Admin ] load component type list",
    LoadComponentUnitAction="[ Admin ] load component unit list",
    LoadProductClassComponentUnitListAction = "[ Admin ] load product class component unit list",
    ChangeProductClassComponentUnitSortExpressionAction = "[ Admin ] Change product class component unit Sort Expression",
    ChangeProductClassComponentUnitFilterExpressionAction = "[ Admin ] Change product class component unit Filter Expression",
    UpdateProductClassComponentUnitAction = "[ Admin ] Update product class component unit material",

    ChangeProductClassComponentUnitListPageAction="[ Admin ] change product class component unit list page",
    ChangeProductClassComponentUnitListTotalCountAction="[ Admin ] change product class component unit list total count"
}

export class AddProductClassComponentUnitAction implements Action {
    readonly type =
        ProductClassComponentUnitActionType.AddProductClassComponentUnitAction;
    constructor(public payload: ProductClassComponentUnit) {}
}

export class LoadProductClassComponentUnitAction implements Action {
    readonly type =
        ProductClassComponentUnitActionType.LoadProductClassComponentUnitListAction;
    constructor(public payload: ProductClassComponentUnit[]) {}
}

export class ChangeProductClassComponentUnitSortExpressionAction
    implements Action
{
    readonly type =
        ProductClassComponentUnitActionType.ChangeProductClassComponentUnitSortExpressionAction;
    constructor(public payload: string) {}
}

export class ChangeProductClassComponentUnitFilterExpressionAction
    implements Action
{
    readonly type =
        ProductClassComponentUnitActionType.ChangeProductClassComponentUnitFilterExpressionAction;
    constructor(public payload: string) {}
}

export class UpdateProductClassComponentUnitAction implements Action {
    readonly type =
        ProductClassComponentUnitActionType.UpdateProductClassComponentUnitAction;
    constructor(public payload: ProductClassComponentUnit) {}
}

export class ChangeProductClassComponentUnitListPageAction implements Action{
    readonly type=
        ProductClassComponentUnitActionType.ChangeProductClassComponentUnitListPageAction;
        constructor(public payload: number) { }
}

export class ChangeProductClassComponentUnitListTotalCountAction implements Action{
    readonly type = ProductClassComponentUnitActionType.ChangeProductClassComponentUnitListTotalCountAction;
    payload: { totalCount: number};
    constructor(totalCount: number) {
        this.payload = { totalCount};
    }
}

export type ProductClassComponentUnitAction =
    | AddProductClassComponentUnitAction
    | UpdateProductClassComponentUnitAction    
    | LoadProductClassComponentUnitAction
    | ChangeProductClassComponentUnitSortExpressionAction
    | ChangeProductClassComponentUnitFilterExpressionAction
    | ChangeProductClassComponentUnitListPageAction
    | ChangeProductClassComponentUnitListTotalCountAction
;