import { NgModule } from '@angular/core';
import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './guards/auth.guard';
import { RoleGuardService } from './guards/roles.guard';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export function getClientSettings(): UserManagerSettings {
    const oidcQueryString = getQueryStringByName('oidc_client');
    if (oidcQueryString) {
        sessionStorage.setItem('oidc_client_id', oidcQueryString);
    }
    const client_id = sessionStorage.getItem('oidc_client_id') || 'calsium';
    const baseUrl = window.location.origin;
    return {
        client_id,
        authority: environment.oidc.authority,
        redirect_uri: baseUrl + environment.oidc.redirectUrl,
        post_logout_redirect_uri: baseUrl + environment.oidc.postLogoutUrl,
        response_type: 'id_token token',
        scope: 'openid profile calsium-api profile-extra',
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true,
        silent_redirect_uri: baseUrl + environment.oidc.silentRenewUrl,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        accessTokenExpiringNotificationTime: 600
    };
}

export function createUserManagerFactory() {
    return new UserManager(getClientSettings());
}

export const authRoutes: Routes = [
    {
        path: 'auth-callback',
        component: AuthCallbackComponent
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    }
];

function getQueryStringByName(name:string) {
    const formattedName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${formattedName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

@NgModule({
    imports: [HttpClientModule],
    providers: [
        { provide: UserManager, useFactory: createUserManagerFactory },
        AuthService,
        AuthGuardService,
        RoleGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }
    ],
    declarations: [
        AuthCallbackComponent,
        UnauthorizedComponent
    ]
})
export class AuthenticationModule { }
