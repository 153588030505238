import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AllocationOverviewConfirmationCheck, Overview } from 'src/state/overview/state-models/overview-data';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
    providedIn: 'root'
})
export class OverviewApiService {
    constructor(
        private http: HttpClient,
        private apollo: Apollo
    ) { }

    GetByComponents( from: string,
        to: string,
        scaniaUnitId: number,
       ): Observable<Overview[]> 
        {
        const url = `${environment.adminUrl}/allocationoverview/byComponentUnit`;
        const reqData = {
            FromDate: from,
            ToDate: to,
            ScaniaUnitId: scaniaUnitId,
        };
        return this.http.get<any>(url, {params: reqData});
    }

    GetByFinalAssembly( from: string,
        to: string,
        scaniaUnitId: number,
       ): Observable<Overview[]> 
        {
        const url = `${environment.adminUrl}/allocationoverview/byFinalAssemblyUnit`;
        const reqData = {
            FromDate: from,
            ToDate: to,
            ScaniaUnitId: scaniaUnitId,
        };
        return this.http.get<any>(url, {params: reqData});
    }

    GetByConsolidation( from: string,
        to: string,
        scaniaUnitId: number,
       ): Observable<Overview[]> 
        {
        const url = `${environment.adminUrl}/allocationoverview/byConsolidationUnit`;
        const reqData = {
            FromDate: from,
            ToDate: to,
            ScaniaUnitId: scaniaUnitId,
        };
        return this.http.get<any>(url, {params: reqData});
    }

    SaveAllocationOverviewConfirmationCheck(payload: AllocationOverviewConfirmationCheck) {
        const mutation = `
        mutation saveAllocationOverviewConfirmationCheck($payload: AllocationOverviewConfirmationCheckInput!){
            allocationOverviewConfirmationCheck{
                saveAllocationOverviewConfirmationCheck(payload: $payload)
                {
                    scaniaUnitId
                    updatedBy
                    isCompleted
                }
            }
        }`;
        return this.apollo
            .mutate({
                mutation: gql(mutation),
                variables: {payload},
            })
            .toPromise();
    }
}
