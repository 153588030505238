import { FlowExecutionStep } from './flow-execution-step';

export interface StepFault {
    id: number;
    message: string;
    details: string;
    reference: string;
    referenceValue: string;
    type: string;
    flowExecutionStep: FlowExecutionStep;
}
export const MissingPriceStepFault: string = 'MissingPrice';
export const InvalidScaniaUnitStepFault: string = 'InvalidScaniaUnit';
export const InvalidProductClassStepFault: string = 'InvalidProductClass';
export const NoSpecificationStepFault: string = 'NoSpecification';
