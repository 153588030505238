import { Injectable } from '@angular/core';
import { map,lastValueFrom } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CommonApiService } from '../common.service';
import { ConsolidationUnitAllocation } from 'src/state/consolidation-unit-allocation/state-models/consolidation-unit-allocation';
import { AllocationSummary } from 'src/state/consolidation-unit-allocation/state-models/allocation-summary';

import * as consolidationUnitAllocationConfig from '../../assets/config/consolidation-unit-allocation.config.js';

@Injectable({
  providedIn: 'root'
})
export class ConsolidationUnitAllocationApiService {
  constructor(
    private apollo: Apollo,
    private commonService: CommonApiService
  ) { }

  private consolidationUnitAllocationSearchQuery = `
      search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to, paging: $paging, order: $order, filter: $filter) {
        list {
          id
          productIndividualId
          productIndividualNumber
          allocationScaniaUnitId
          allocationStatusId
          chassisNumber
          serialNumber
          chassisType
          countryCode
          fullyAllocated
          invoiceDate
          productionDate
          finalAssemblyComponentDeliveryValue
          finalAssemblyComponentTypeName
          spare
          axle
          batteryVehicle
          gosParts
          cab
          engineVehicle
          gearboxVehicle
          painted
          electricPropVehicle
          totalValue
        }
        totalCount
        errorsCount:extraHeaderInfo1
      }`;

  private consolidationUnitAllocationSummaryQuery = `
      summary (allocationScaniaUnit: $allocationScaniaUnit, from: $from, to: $to) {
        totalValue
        totalAmount
      }`;

  getConsolidationUnitComponentsList(queryType: string, variables?, forceRefetch: boolean = false): Promise<any> {
    const result$ = this.apollo
      .query<ConsolidationUnitAllocation[]>({
        variables,
        fetchPolicy: forceRefetch ? 'no-cache' : null,
        query: gql`
                    query consolidationUnitAllocationListQuery($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $paging: Paging, $order:String, $filter:String) {
                      consolidationUnitAllocation {
                        ${queryType} {
                          ${this.consolidationUnitAllocationSearchQuery}
                        }
                      }
                    }`
      })
      .pipe<ConsolidationUnitAllocation[]>(
      
       map((result: any) => {
        return result?.data?.consolidationUnitAllocation?.[queryType]?.search;
        })

       
      )
      return lastValueFrom(result$);
  }

  getConsolidationUnitComponentsSummary(queryType: string, variables?, forceRefetch: boolean = false): Promise<AllocationSummary> {
    const result$ = this.apollo
      .query<AllocationSummary>({
        variables,
        fetchPolicy: forceRefetch ? 'no-cache' : null,
        query: gql`
                    query consolidationUnitAllocationListQuery($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime) {
                      consolidationUnitAllocation {
                        ${queryType} {
                          ${this.consolidationUnitAllocationSummaryQuery}
                        }
                      }
                    }`
      })
      .pipe<AllocationSummary>(
               map((result: any) => {
          return result?.data?.consolidationUnitAllocation?.[queryType]?.summary;
          })
      )
      return lastValueFrom(result$)
  }

  exportConsolidationUnitAllocationList(queryType: string, scaniaUnitName: string, variables?, fileName: string = null): Promise<any> {
    const query = gql`
        query consolidationUnitAllocation($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime) {
          consolidationUnitAllocation {
            export_${queryType} {
              search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to) {
                  ${this.queryProperties(scaniaUnitName)}
              }
            }
          }
        }`;
    return this.commonService.exportQuery(query, variables, fileName, true, queryType);
  }

  queryProperties(scaniaUnitName: string): string {
    let config = consolidationUnitAllocationConfig.find(config =>
      config.AllocationUnits !== null && config.AllocationUnits.indexOf(scaniaUnitName) >= 0
    );

    if (!config) {
      config = consolidationUnitAllocationConfig.find(config => config.AllocationUnits === null);
    }

    return config.Fields;
  }

  allocate(variables?): Promise<any> {
    return this.apollo
      .mutate({
        variables,
        mutation: gql`
              mutation ($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $selectAll: Boolean, $includedItems: [Int], $excludedItems: [Int], $filter:String, $preview: Boolean) {
                consolidationUnitAllocation {
                  allocate(allocationScaniaUnit: $allocationScaniaUnit, from: $from, to: $to, selectAll: $selectAll, includedItems: $includedItems, excludedItems: $excludedItems, filter: $filter , preview: $preview)
                }
              }`
      })
      .toPromise();
  }
}
