import { ScaniaUnit, ProductClass } from 'src/state';

export interface ScaniaUnitConsolidation {
    id: number;
    productClassId: number;
    scaniaUnitId: number;
    consolidationUnitId: number;
    productClass : ProductClass ;
    scaniaUnit : ScaniaUnit
    consolidatingScaniaUnit : ScaniaUnit
}
