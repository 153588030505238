<form [formGroup]="searchProductForm" (ngSubmit)="onSearch()" class="row">
    <div class="input-group"> 
        <input type="text" #inputSearchProduct  id="search-product" formControlName="searchProduct" [placeholder]="placeholder"
        [matAutocomplete]="auto"  class="form-control" /> 
             <div class="input-group-append">
                <button [matMenuTriggerFor]="menu" class="btn btn-secondary user" type="button" >
                    <i class="fas fa-ellipsis-v">
                    </i>
                </button>
                <mat-menu #menu="matMenu">
                    <div *ngFor="let item of searchFilterValues">
                    <button mat-menu-item (click)="selectedFilter(item)">{{item}}</button>
                </div>
                  </mat-menu>
                
            <button class="btn btn-secondary" [ngClass]="{ 'loading': (isSearching$ | async) }" type="button" (click)="onSearchButtonClick()">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>    
    

    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let productIndividual of productIndividuals$ | async" (onSelectionChange)="onSelected(productIndividual.id)">
            <div class="row">
                <label class="col-6">{{ resources.ProductIndividual }}</label> 
                <small class="col-6" [ngClass]="isMatch(productIndividual.productIndividualNumber)">{{ productIndividual.productIndividualNumber }}</small>
                <label class="col-6">{{ resources.ChassisNumber }}</label>
                <small class="col-6" [ngClass]="isMatch(productIndividual.chassisNumber)">{{ productIndividual.chassisNumber }}</small>
            </div>
        </mat-option>
    </mat-autocomplete>
</form>