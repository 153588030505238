import { Injectable } from '@angular/core';
import { map,lastValueFrom } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CommonApiService } from '../common.service';
import { ComponentAllocation, AllocationSummary } from '../../state';

@Injectable({
    providedIn: 'root'
})
export class ComponentAllocationApiService {
    constructor(
        private apollo: Apollo,
        private commonService: CommonApiService
    ) { }

    private replacementComponentSearchQuery=`
      search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to, paging: $paging, order: $order, filter: $filter, isReplacementComponent: $isReplacementComponent) {
        list {
          productIndividualId
          productIndividualNumber
          componentTypeId
          allocationScaniaUnitId
          chassisNumber
          popId
          productionDate
          calculationCompleted
          totalMaterialValue
          totalProductionValue
          totalValue
        }
        totalCount
        errorsCount:extraHeaderInfo1
      }`;
    
    private componentAllocationSearchQuery = `
      search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to, paging: $paging, order: $order, filter: $filter) {
        list {
          productIndividualId
          productIndividualNumber
          componentTypeId
          allocationScaniaUnitId
          chassisNumber
          popId
          productionDate
          calculationCompleted
          totalMaterialValue
          totalProductionValue
          totalValue
        }
        totalCount
        errorsCount:extraHeaderInfo1
      }`;

    private componentAllocationSummaryQuery = `
      summary (allocationScaniaUnit: $allocationScaniaUnit, from: $from, to: $to) {
        totalValue
        totalAmount
      }`;

    getComponentsList(queryType: string, variables?, forceRefetch: boolean = false): Promise<any> {
      const result$ = this.apollo
            .query<ComponentAllocation[]>({
                variables,
                fetchPolicy: forceRefetch ? 'no-cache' : null,
                query: gql`
                query componentAllocationListQuery($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $paging: Paging, $order:String, $filter:String) {
                  componentFactoryAllocation {
                    ${queryType} {
                      ${this.componentAllocationSearchQuery}
                    }
                  }
                }`
            })
            
            .pipe<ComponentAllocation[]>(
                map((result: any) => {
                  return result?.data?.componentFactoryAllocation?.[queryType]?.search;
                  }
            ));

            return  lastValueFrom(result$);
    }

    getComponentsSummary(queryType: string, variables?, forceRefetch: boolean = false): Promise<AllocationSummary> {
        const result$ =  this.apollo
            .query<AllocationSummary>({
                variables,
                fetchPolicy: forceRefetch ? 'no-cache' : null,
                query: gql`
                query componentAllocationListQuery($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime) {
                  componentFactoryAllocation {
                    ${queryType} {
                      ${this.componentAllocationSummaryQuery}
                    }
                  }
                }`
            })

            
            .pipe<AllocationSummary>(
                               map((result: any) => {
                  return result?.data?.componentFactoryAllocation?.[queryType]?.summary;
              })
            );
            return lastValueFrom(result$);  
          }

    exportReplacementComponentAllocationListForSpecificFinalAssembly(variables?, fileName: string = null): Promise<any> {
      const query = gql`
        query ReplacementComponentAllocation($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $isReplacementComponent: Boolean) {
          componentFactoryAllocation {
            export_allocated{
              search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to, isReplacementComponent: $isReplacementComponent) {
                IpoId: ipoId
                Chassis_No: chassisNumber
                Component_unit: componentUnit
                KNR: knr
                Consuming_planning_area: consumingPlanningArea
                Type_designation: typeDesignation
                Production_date: productionDate
                Product_individual_number: productIndividualNumber
                Calculation_completed: calculationCompleted
                Total_cost: totalValue
                Material_value: totalMaterialValue
                Production_value: totalProductionValue
                productionValueDetails {
                  key
                  value
                }
                manualProductionValueDetails {
                  key
                  value
                }
                variantDetails {
                  Variant_Family: variantFamily
                  Variant_Option: variantOption
                  Variant_Family_Desc: variantFamilyDescription
                  Variant_Option_Desc: variantOptionDescription
                }
              }
            }
          }
        }`;
        return this.commonService.exportQuery(query, variables, fileName, true, 'replacement comp list');
    }

    exportComponentAllocationList(queryType: string, variables?, fileName: string = null): Promise<any> {
        const query = gql`
        query componentAllocation($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime) {
          componentFactoryAllocation {
            export_${queryType} {
              search(allocationScaniaUnit:$allocationScaniaUnit, from: $from, to: $to) {
                IpoId: ipoId
                Chassis_No: chassisNumber
                Component_unit: componentUnit
                KNR: knr
                Consuming_planning_area: consumingPlanningArea
                Type_designation: typeDesignation
                Production_date: productionDate
                Product_individual_number: productIndividualNumber
                Calculation_completed: calculationCompleted
                Total_cost: totalValue
                Material_value: totalMaterialValue
                Production_value: totalProductionValue
                productionValueDetails {
                  key
                  value
                }
                manualProductionValueDetails {
                  key
                  value
                }
                variantDetails {
                  Variant_Family: variantFamily
                  Variant_Option: variantOption
                  Variant_Family_Desc: variantFamilyDescription
                  Variant_Option_Desc: variantOptionDescription
                }
              }
            }
          }
        }`;
        return this.commonService.exportQuery(query, variables, fileName, true, queryType);
    }

    allocate(variables?): Promise<any> {
      const result$ = this.apollo
            .mutate({
                variables,
                mutation: gql`
                mutation ($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $selectAll: Boolean, $includedItems: [ComponentSelection], $excludedItems: [ComponentSelection], $allocationStatus: String, $filter:String) {
                  componentAllocation {
                    allocate(allocationScaniaUnit: $allocationScaniaUnit, from: $from, to: $to, selectAll: $selectAll, includedItems: $includedItems, excludedItems: $excludedItems, allocationStatus: $allocationStatus, filter: $filter)
                  }
                }`
            })
            return lastValueFrom(result$);
    }

    moveToStock(variables?): Promise<any> {
      const result$ =  this.apollo
            .mutate({
                variables,
                mutation: gql`
                mutation ($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $selectAll: Boolean, $includedItems: [ComponentSelection], $excludedItems: [ComponentSelection], $filter:String) {
                  componentAllocation {
                    moveToStock(allocationScaniaUnit: $allocationScaniaUnit, from: $from, to: $to, selectAll: $selectAll, includedItems: $includedItems, excludedItems: $excludedItems, filter: $filter)
                  }
                }`
            })

            return lastValueFrom(result$);
    }

    getReplacementComponentList(variables?, forceRefetch: boolean = false): Promise<any> {
      const result$ =  this.apollo
          .query<ComponentAllocation[]>({
              variables,
              fetchPolicy: forceRefetch ? 'no-cache' : null,
              query: gql`
              query replacementComponentListQuery($allocationScaniaUnit: Int, $from: DateTime, $to: DateTime, $paging: Paging, $order:String, $filter:String, $isReplacementComponent: Boolean) {
                componentFactoryAllocation {
                  allocated {
                    ${this.replacementComponentSearchQuery}
                  }
                }
              }`
          })
          .pipe<ComponentAllocation[]>(
                           map((result: any) => {
                  return result?.data?.componentFactoryAllocation?.allocated?.search;
              })
            );
                     
            return lastValueFrom(result$);  
  }
}
