import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { AuthGuardService } from '../authentication';
import { CommonModule } from '../common';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { ComponentListComponent } from './pages/component-list/component-list.component';
import { StepFaultListComponent } from './pages/stepFault-list/stepFault-list.component';
import { ComponentDetailsComponent } from './pages/component-details/component-details.component';
import { DetailsComponent } from './pages/component-details/details/details.component';
import { MaterialsComponent } from './pages/component-details/materials/materials.component';
import { SpecificationComponent } from './pages/component-details/specification/specification.component';
import { ProductionValueComponent } from './pages/component-details/production-value/production-value.component';
import { SimulationComponent } from './pages/simulation/simulation.component';

export const flowRoutes: Routes = [
    { path: '',
    redirectTo: '/flow/control/component-list',
    pathMatch: 'full' },
    {
        path: 'flow',
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'control',
                children: [
                    {
                        path: 'component-list',
                        component: ComponentListComponent,
                    },
                    {
                        path: 'component-details/:id',
                        component: ComponentDetailsComponent,
                    }
                ]
            },
            {
                path: 'execution-fault',
                component: StepFaultListComponent,
            },
            {
                path: 'simulation',
                component: SimulationComponent,
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        AngularCommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatExpansionModule,
        MatCheckboxModule
    ],
    declarations: [
        ComponentListComponent,
        StepFaultListComponent,
        ComponentDetailsComponent,
        DetailsComponent,
        MaterialsComponent,
        SpecificationComponent,
        ProductionValueComponent,
        SimulationComponent
    ],
    exports: [MaterialsComponent, ProductionValueComponent, SpecificationComponent]
})
export class FlowModule { }
