import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pipe'
})
export class DynamicPipe implements PipeTransform {
    transform(val, pipes: any[]) {
        let result = val;
        for (const pipe of pipes) {
            if (pipe !== null) {
                result = pipe.transform(result);
            }
        }
        return result;
    }
}
