import { Routes } from '@angular/router';
import { MaterialComponent } from './pages/material-list/material.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '../common';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { MaterialDetailsComponent } from './pages/material-details/material-details.component';
import { MaterialInformationComponent } from './pages/material-details/material-information/material-information.component';
import { MaterialPricesComponent } from './pages/material-details/material-prices/material-prices.component';
import { MaterialAddPriceComponent } from './pages/material-details/material-add-price/material-add-price.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService } from '../authentication';

export const materialRoutes: Routes = [
    {
        path: 'material',
        canActivate: [AuthGuardService],
        component: MaterialComponent
    },
    {
        path: 'material/:id',
        canActivate: [AuthGuardService],
        component: MaterialDetailsComponent
    }
];

@NgModule({
    imports: [CommonModule, AngularCommonModule, FormsModule, ReactiveFormsModule],
    declarations: [
        MaterialComponent,
        MaterialDetailsComponent,
        MaterialInformationComponent,
        MaterialPricesComponent,
        MaterialAddPriceComponent
    ],
})
export class MaterialModule { }
