import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { result } from 'lodash';
import { map,lastValueFrom } from 'rxjs';
import { DeliveryRoute } from 'src/state/administraion/delivery-route/state-models/delivery-route';


@Injectable({
  providedIn: 'root'
})
export class DeliveryRouteApiService {
  constructor(private apollo: Apollo) { }

  getDeliveryRouteList(variables?): Promise<DeliveryRoute[]> {
    const result$ = this.apollo
      .query<DeliveryRoute[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
              query deliveryRouteQuery($order:String, $filter:String)
              {
                admin{
                  deliveryRoute(order: $order, filter: $filter) 
                  {
                    id
                    code
                    description
                    isDeliveryValue
                    isMarkUpValue
                  }
                }                
              }`
      })
      .pipe<DeliveryRoute[]>(
       
        map((result: any) => {
          return result?.data?.admin?.deliveryRoute;
          })
    );

    return lastValueFrom(result$);

  }


  async updateDeliveryRoute(payload: DeliveryRoute): Promise<any> {
    const mutation = `
    mutation updateDeliveryRoute($payload: DeliveryRouteInput!) {
      deliveryRoute {
        updateDeliveryRoute(payload: $payload)
      }
    }
    `;

    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    })
      .toPromise();
  }
}
