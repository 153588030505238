<c-theme name="scania" global="true"></c-theme>
<cal-system-notifications>
</cal-system-notifications>
<c-header site-name="Cost allocation and Simulation management" 
  short-name="CALSIUM" 
  site-url="./">
</c-header>

<c-navigation [cal-menu] [style.visibility]="(isLoggedIn$ | async) ? 'visible' : 'hidden'"
  [menu]="menu$ | async"
  [activeMenu]="activeMenu$ | async"
  [profile]="profile$ | async">
</c-navigation>

<c-content>
  <section>
    <router-outlet></router-outlet>
  </section>
</c-content>

<c-footer>
</c-footer>