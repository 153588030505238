<div class="card">
   <h3 class="card-header">{{title}}</h3>
   <div class="card-body">
      <div class="container-fluid">
         <div class="row">
            <div class="form-group col-md-2">
               <label>{{resources.Code}}</label>
               <mat-form-field style="width: 100%;" appearance="outline">
                  <input matInput [formControl]="deliveryRouteCodeInput" type="text" class="form-control"
                     placeholder="{{resources.Code}}" />
               </mat-form-field>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.Description}}</label>
               <mat-form-field style="width: 100%;" appearance="outline">
                  <input matInput [formControl]="deliveryRouteDescriptionInput" type="text" class="form-control"
                     placeholder="{{resources.Description}}" />
               </mat-form-field>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.IsDeliveryValue}}</label>
               <div class="input-group">
                  <mat-checkbox [formControl]="deliveryRouteIsDeliveryValueInput" type="checkbox">
                     {{resources.IsDeliveryValue}}</mat-checkbox>
               </div>
            </div>
            <div class="form-group col-md-2">
               <label>{{resources.IsMarkUpValue}}</label>
               <div class="input-group">
                  <mat-checkbox [formControl]="deliveryRouteIsMarkUpValueInput" type="checkbox">
                     {{resources.IsMarkUpValue}}
                  </mat-checkbox>
               </div>
            </div>
            <div *ngIf="isEdit" class="form-group col-md-2">
               <label>&nbsp;</label>
               <button type="button" class="btn btn-sm btn-primary form-control" id="saveDeliveryRoute"
                  (click)="onCancelEdit()">
                  <i class="fas fa-lg fa-ban"></i>&nbsp; {{resources.Cancel}}
               </button>
            </div>
            <div class="form-group col-md-2">
               <label>&nbsp;</label>
               <button type="button" class="btn btn-sm btn-primary form-control" id="saveDeliveryRoute"
                  (click)="onSave()">
                  <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<br />