import { Injectable } from "@angular/core";
import { State, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { MarkupPercentageApiService } from 'src/api/services/markup-percentage-api.service';
import { AppState } from "src/state/app.state";
import { Loadable } from "src/state/common/loadable";
import {
    AddMarkupPercentageAction,
    ChangeMarkupPercentageFilterExpressionAction,    
    ChangeMarkupPercentageSortExpressionAction,    
    LoadMarkupPercentageAction,    
    ChangeMarkupPercentageListPageAction,
    ChangeMarkupPercentageListTotalCountAction,
    ChangeMarkupPercentageShowOnlyValidPercentagesAction,
} from "./action";

import {
    MarkupPercentage,
    MarkupPercentageAdd    
} from "./state-models/markup-percentage";

import { Paging } from 'src/state/common/state-models/paging';

@Injectable({
    providedIn: "root",
})
export class MarkupPercentageStateServiceService {
    constructor(
        private apiService: MarkupPercentageApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>
    ) {}

    getSortingExpression(type: string): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState.markupPercentageListSortExpression);
    }   

    getFilterExpression(type:string):Observable<string>{
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState.markupPercentageListFilterExpression);
    }

    async addMarkupPercentages(
        markupPercentage: MarkupPercentageAdd
    ): Promise<any> {
        const response = await this.apiService.AddMarkupPercentage(
            markupPercentage
        );
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(
            new AddMarkupPercentageAction(
                response.data.markupPercentage.addMarkupPercentage
            )
        );
                
        const res = await this.apiService.getMarkupPercentages();        
        this.appStore.dispatch(new LoadMarkupPercentageAction(res.list));        
        this.appStore.dispatch(new ChangeMarkupPercentageListTotalCountAction(res.totalCount));
    }

    getShowOnlyValidMarkupPercentages(): Observable<boolean> {
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState
                    .showOnlyValidMarkupPercentages
        );
    }

    getMarkupPercentageList(): Observable<MarkupPercentage[]> {
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState
                    .markupPercentageList
        );
    }

    getMarkupPercentageListSortExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState
                    .markupPercentageListSortExpression
        );
    }

    getMarkupPercentageListFilterExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState
                    .markupPercentageListFilterExpression
        );
    }

    sortMarkupPercentage(sortExpression: string) {
        this.appStore.dispatch(
            new ChangeMarkupPercentageSortExpressionAction(
                sortExpression
            )
        );
    }

    filterMarkupPercentage(filterExpression: string) {
        this.appStore.dispatch(
            new ChangeMarkupPercentageFilterExpressionAction(
                filterExpression
            )
        );
    }

    getPaging(): Observable<Paging> {        
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState.markupPercentagePaging);
    }

    goToPage(page: number) {              
        this.appStore.dispatch(new ChangeMarkupPercentageListPageAction(page));
    }

    getTotalCount(): Observable<number> {        
        return this.appStore.select(
            (state) =>
                state.admin.markupPercentageState.markupPercentageTotalCount);
    }

    clearMarkupPercentageFilterAndSorting() {
        this.sortMarkupPercentage(null);
        this.filterMarkupPercentage(null);
    }

    @Loadable()
    async loadMarkupPercentage(): Promise<void> {
        let list = <MarkupPercentage[]>(
            this.appState.getValue().admin.markupPercentageState
                .markupPercentageList
        );
        if (list.length==0) {
            const response =
                await this.apiService.getMarkupPercentages();
            this.appStore.dispatch(
                new LoadMarkupPercentageAction(response)
            );
        }
    }

    @Loadable()
    async loadMarkupPercentages(
        paging: Paging,
        sortExpression: string,
        filterExpression: string,
        showOnlyValidMarkupPercentages: Boolean
    ){
        const payload = {
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression,
            showOnlyValidPercentages: showOnlyValidMarkupPercentages
        };
        const response = await this.apiService.getMarkupPercentages(
            payload
        );
        this.appStore.dispatch(new LoadMarkupPercentageAction(response.list));
        this.appStore.dispatch(new ChangeMarkupPercentageListTotalCountAction(response.totalCount));
    }

    showShowOnlyValidMarkupPercentages(
        showOnlyValidMarkupPercentages: boolean
    ) {
        this.appStore.dispatch(
            new ChangeMarkupPercentageShowOnlyValidPercentagesAction(
                showOnlyValidMarkupPercentages
            )
        );
    }
}
