import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CommonStateService } from '../../../state';

@Component({
    selector: 'cal-auth-callback',
    template: `<h3 style='text-align: center'>{{statusText}}</h3>`
})
export class AuthCallbackComponent implements OnInit {
    statusText: string;
    resources: any;

    constructor(private authService: AuthService,
        private router: Router,
        private commonService: CommonStateService) {
        this.resources = this.commonService.getResources();
    }

    async ngOnInit() {
        this.statusText = this.resources.UserAuthorizationCheck;
        if (this.authService.isPopupAuthentication()) {
            await this.authService.completePopupAuthentication();
        } else {
            await this.authService.completeAuthentication();
            if (!this.authService.isUserAuthorized()) {
                this.router.navigate(['/unauthorized']);
            } else {
                this.statusText = this.resources.UserAuthenticated;
                const route = window.localStorage.getItem('last-url');
                window.localStorage.removeItem('last-url');
                this.router.navigateByUrl(route);
            }
        }
    }
}
