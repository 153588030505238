import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, Observable, combineLatest, of, merge } from "rxjs";

import { CommonStateService } from "src/state";
import { debounceTime, switchMap } from "rxjs/operators";
import {
    CalsiumListConfig,
    ColumnDataType,
} from "src/app/calsiumlist/calsiumlist.component.model";
import { CurrencyAdjustmentStateService } from "src/state/administraion/currency-adjustment/service";
import { CurrencyAdjustmentCode } from "src/state/administraion/currency-adjustment/state-models/currency-adjustment-code";
import { Router } from "@angular/router";
import { CurrencyAdjustmentCondition } from "src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition";
import { CurrencyAdjustmentConditionVariantComponent } from "../condition-variant-list/currency-adjustment-condition-variant.component";
import { ErrorHandlerService } from "src/app/errorhandler/error-handler.service";
import { ErrorTypeEnum } from "src/app/shared/enums.model";

@Component({
    selector: "cal-currency-adjustment-condition",
    templateUrl: "./currency-adjustment-condition.component.html",
    styleUrls: ["./currency-adjustment-condition.component.css"],
})
export class CurrencyAdjustmentConditionComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    dataList: Observable<CurrencyAdjustmentCondition[]>;
    isLoading: Observable<boolean>;
    listConfig: CalsiumListConfig;
    showOnlyValidConditions: Observable<boolean>;

    constructor(
        private router: Router,
        private currencyAdjustmentService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) {}

    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.sortingExpression = this.currencyAdjustmentService.getCodeListSortingExpression();
        this.filterExpression = this.currencyAdjustmentService.getCodeListFilterExpression();
        this.dataList = this.currencyAdjustmentService.getConditionList();
        this.showOnlyValidConditions = this.currencyAdjustmentService.getshowOnlyValidConditions();
        this.subscriptions.add(
            combineLatest(
                this.sortingExpression,
                this.filterExpression,
                this.showOnlyValidConditions
            )
                .pipe(
                    debounceTime(0),
                    switchMap(
                        ([
                            sortingExpression,
                            filterExpression,
                            showOnlyValidConditions,
                        ]) => {
                            return this.currencyAdjustmentService.loadConditions(
                                sortingExpression,
                                filterExpression,
                                showOnlyValidConditions
                            );
                        }
                    )
                )
                .subscribe()
        );
        this.buildList();
    }
    isEditable(condition): boolean {
        let isEditable = false;

        if (condition.validTo === null) {
            isEditable = true;
        }

        return isEditable;
    }

    saveEditedItem(formGroup, selectedDate) {
        const payload = {
            id: selectedDate.id,
            validFrom: selectedDate.validFrom,
            validTo: formGroup.controls.validTo.value,
        };

        this.currencyAdjustmentService
            .updateConditions(payload)
            .then((errors) => {
                if (errors && errors.length) {
                    let errorMessage = "";
                    for (const error of errors) {
                        switch (error) {
                            case "INVALID_DATE":
                                errorMessage += this.resources
                                    .ToDateGreaterThanFromDate;
                                break;
                        }
                    }
                    this.errHandler.emitError(
                        this.resources.ToDateShouldBeTomorrowOrLater,
                        this.resources.Error,
                        ErrorTypeEnum.Error
                    );
                } else {
                    this.currencyAdjustmentService.filterCodeList(
                        payload.validTo
                    );
                    this.errHandler.emitError(
                        this.resources.UpdateSuccessfully,
                        this.resources.Success,
                        ErrorTypeEnum.Success
                    );
                }
            });

        return of(true);
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.CurrencyAdjustmentConditionList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;
        this.listConfig.AllowEdit = true;
        this.listConfig.isRowEditable = this.isEditable;
        this.listConfig.EditItemMethod = this.saveEditedItem;
        this.listConfig.AllowDelete = false;
        this.listConfig.ShowExportToExcel = false;
        this.listConfig.HasSubComponent = true;
        this.listConfig.IsSubComponentVisible = (
            rowdata: CurrencyAdjustmentCondition
        ) => rowdata.variantOptions.length > 0 || rowdata.isNew === true;
        this.listConfig.getSpeicalRowStyle = (
            rowdata: CurrencyAdjustmentCondition
        ) => {
            if (rowdata.isNew === true) {
                return { "background-color": "darkseagreen" };
            } else return {};
        };
        this.listConfig.SubComponent = CurrencyAdjustmentConditionVariantComponent;
        this.listConfig.ParentComponent = this;

        let column;
        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "id",
            this.resources.Id
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "productClass.variantOption.description",
            this.resources.ProductClass
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "market.name",
            this.resources.Market
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.anchor,
            "materialCurrencyAdjustmentCode.code",
            this.resources.MaterialCode
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsAnchor = () => true;
        column.GetAnchorLink = (row: CurrencyAdjustmentCondition) =>
            `/admin/currency-adjustment-code/${row.materialCurrencyAdjustmentCode.id}`;

        column = this.listConfig.AddListColumn(
            ColumnDataType.anchor,
            "pvCurrencyAdjustmentCode.code",
            this.resources.ProductionValueCode
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsAnchor = () => true;
        column.GetAnchorLink = (row: CurrencyAdjustmentCondition) =>
            `/admin/currency-adjustment-code/${row.pvCurrencyAdjustmentCode.id}`;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "variantOptions.length",
            this.resources.ConditionsCount
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validFrom",
            this.resources.From
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validTo",
            this.resources.To
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.AllowEdit = true;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSort(sortExpression: string) {
        this.currencyAdjustmentService.sortCodeList(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.currencyAdjustmentService.filterCodeList(filterExpression);
    }

    onClearFilterAndSorting() {
        this.currencyAdjustmentService.clearCodeListFilterAndSorting();
    }

    onPercentageClick(rowdata: CurrencyAdjustmentCode) {
        this.router.navigate([
            "/admin/currency-adjustment-percentage/" + rowdata.id,
        ]);
    }

    onSaved() {
        this.listConfig.CalsiumListComponent.toggleSubContent = -1;
    }

    onShowOnlyValidConditions(showOnlyValidConditions: boolean) {
        this.currencyAdjustmentService.showOnlyValidConditions(
            showOnlyValidConditions
        );
    }
}
