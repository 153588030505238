import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { AllocationSearchForm } from './state-models/allocation-search-form';
import { FinalAssemblyAllocation } from './state-models/final-assembly-allocation';
import { AllocationSummary } from './state-models/allocation-summary';

export enum FinalAssemblyAllocationActions {
    ChangeComponentSearchFormAction = '[ Final Assembly Allocation ] Change Search Form',
    ChangeProducedComponentListSortExpressionAction = '[ Final Assembly Allocation ] Change Produced Sort Expression',
    ChangeProducedComponentListFilterExpressionAction = '[ Final Assembly Allocation ] Change Produced Filter Expression',
    ChangeProducedComponentListPageAction = '[ Final Assembly Allocation ] Change Produced Page',
    ChangeProducedComponentListTotalCountAction = '[ Final Assembly Allocation ] Change Produced Total Count',
    LoadProducedComponentListAction = '[ Final Assembly Allocation ] Load produced components',
    ChangeAllocatedComponentListSortExpressionAction = '[ Final Assembly Allocation ] Change Allocated Sort Expression',
    ChangeAllocatedComponentListFilterExpressionAction = '[ Final Assembly Allocation ] Change Allocated Filter Expression',
    ChangeAllocatedComponentListPageAction = '[ Final Assembly Allocation ] Change Allocated Page',
    ChangeAllocatedComponentListTotalCountAction = '[ Final Assembly Allocation ] Change allocated Total Count',
    LoadAllocatedComponentListAction = '[ Final Assembly Allocation ] Load allocated components',
    ChangeInStockComponentListSortExpressionAction = '[ Final Assembly Allocation ] Change in stock Sort Expression',
    ChangeInStockComponentListFilterExpressionAction = '[ Final Assembly Allocation ] Change in stock Filter Expression',
    ChangeInStockComponentListPageAction = '[ Final Assembly Allocation ] Change in stock Page',
    ChangeInStockComponentListTotalCountAction = '[ Final Assembly Allocation ] Change in stock Total Count',
    LoadInStockComponentListAction = '[ Final Assembly Allocation ] Load in stock components',
    LoadProducedWidgetAction = '[ Final Assembly Allocation ] load produced widget',
    LoadAllocatedWidgetAction = '[ Final Assembly Allocation ] load allocated widget',
    LoadInStockWidgetAction = '[ Final Assembly Allocation ] load in stock widget',
    AllocateComponents = '[ Final Assembly Allocation ] allocate components',
    ChangeCurrentUnitColumns = '[ Final Assembly Allocation ] Change Current Allocation Scania Unit Columns',
}

export class ChangeComponentSearchFormAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeComponentSearchFormAction;
    constructor(public payload: AllocationSearchForm) { }
}

export class ChangeProducedComponentListSortExpressionAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeProducedComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeProducedComponentListFilterExpressionAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeProducedComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeProducedComponentListTotalCountAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeProducedComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class ChangeProducedComponentListPageAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeProducedComponentListPageAction;
    constructor(public payload: number) { }
}

export class LoadProducedComponentListAction implements Action {
    readonly type = FinalAssemblyAllocationActions.LoadProducedComponentListAction;
    constructor(public payload: FinalAssemblyAllocation[]) { }
}

export class ChangeAllocatedComponentListTotalCountAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeAllocatedComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class LoadAllocatedComponentListAction implements Action {
    readonly type = FinalAssemblyAllocationActions.LoadAllocatedComponentListAction;
    constructor(public payload: FinalAssemblyAllocation[]) { }
}

export class ChangeInStockComponentListTotalCountAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeInStockComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class LoadInStockComponentListAction implements Action {
    readonly type = FinalAssemblyAllocationActions.LoadInStockComponentListAction;
    constructor(public payload: FinalAssemblyAllocation[]) { }
}

export class LoadProducedWidgetAction implements Action {
    readonly type = FinalAssemblyAllocationActions.LoadProducedWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class LoadAllocatedWidgetAction implements Action {
    readonly type = FinalAssemblyAllocationActions.LoadAllocatedWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class LoadInStockWidgetAction implements Action {
    readonly type = FinalAssemblyAllocationActions.LoadInStockWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class ChangeAllocatedComponentListSortExpressionAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeAllocatedComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeAllocatedComponentListFilterExpressionAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeAllocatedComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeAllocatedComponentListPageAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeAllocatedComponentListPageAction;
    constructor(public payload: number) { }
}

export class ChangeInStockComponentListSortExpressionAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeInStockComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeInStockComponentListFilterExpressionAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeInStockComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeInStockComponentListPageAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeInStockComponentListPageAction;
    constructor(public payload: number) { }
}

export class AllocateComponentsAction implements Action {
    readonly type = FinalAssemblyAllocationActions.AllocateComponents;
    constructor(public payload: FinalAssemblyAllocation[]) { }
}

export class ChangeCurrentUnitColumnsAction implements Action {
    readonly type = FinalAssemblyAllocationActions.ChangeCurrentUnitColumns;
    constructor(public payload: string[]) { }
}
export type FinalAssemblyAllocationAction =
    | LoadProducedComponentListAction
    | ChangeComponentSearchFormAction
    | ChangeProducedComponentListSortExpressionAction
    | ChangeProducedComponentListFilterExpressionAction
    | ChangeProducedComponentListTotalCountAction
    | ChangeProducedComponentListPageAction

    | ChangeAllocatedComponentListTotalCountAction
    | LoadAllocatedComponentListAction
    | ChangeAllocatedComponentListSortExpressionAction
    | ChangeAllocatedComponentListFilterExpressionAction
    | ChangeAllocatedComponentListPageAction

    | ChangeInStockComponentListTotalCountAction
    | LoadInStockComponentListAction
    | ChangeInStockComponentListSortExpressionAction
    | ChangeInStockComponentListFilterExpressionAction
    | ChangeInStockComponentListPageAction

    | LoadProducedWidgetAction
    | LoadAllocatedWidgetAction
    | LoadInStockWidgetAction
    | AllocateComponentsAction
    | ChangeCurrentUnitColumnsAction
    ;
