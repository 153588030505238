import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { AppState } from '../app.state';
import { LoadUnitOfMeasurement, LoadScaniaUnitAction, LoadComponentTypesAction } from './actions';

import { Loadable } from '../common/loadable';
import { MasterDataApiService } from '../../api/services/master-data-api.service';
import { ScaniaUnitApiService } from '../../api/services/scania-unit-api.service';
import { ScaniaUnit, ScaniaUnitType } from './state-models/scania-unit';
import { UnitOfMeasurement } from './state-models/unit-of-measurement';
import { map } from 'rxjs/operators';
import { MasterDataState } from './state';
import { ComponentType } from './state-models/component-type';

@Injectable()
export class MasterDataStateService {
    constructor(
        private appStore: Store<AppState>,
        private appState: State<AppState>,
        private apiService: MasterDataApiService,
        private scaniaUnitApiService: ScaniaUnitApiService) { }

    getUnitOfMeasurments(): Observable<UnitOfMeasurement[]> {
        return this.appStore.select(state => state.masterData.unitOfMeasurement);
    }

    getScaniaUnits(): Observable<ScaniaUnit[]> {
        return this.appStore.select(state => state.masterData.scaniaUnits)
            .pipe(map(scaniaUnits => _.values(scaniaUnits)));
    }

    getScaniaAllocationUnits(): Observable<ScaniaUnit[]> {
        return this.getScaniaUnitsByTypes([ScaniaUnitType.AllocationUnit]);
    }

    getScaniaUnitsByTypes(scaniaUnitTypeName: string[]): Observable<ScaniaUnit[]> {
        return this.appStore.select(state => state.masterData.scaniaUnits)
            .pipe(map((scaniaUnitList) => {
                return scaniaUnitList.filter(
                    (scaniaUnit) => {
                        let result = true && scaniaUnit.scaniaUnitTypes.length > 0;
                        scaniaUnitTypeName.forEach(type =>
                            result = result && scaniaUnit.scaniaUnitTypes.findIndex(x => x.toLowerCase() === type.toLowerCase()) >= 0
                        );
                        return result;
                    }
                );
            }));
    }

    getState(): MasterDataState {
        return <MasterDataState>this.appState.getValue().masterData;
    }

    getComponentTypes(): Observable<ComponentType[]> {
        return this.appStore.select(state => state.masterData.componentTypes);
    }

    // =============================================
    @Loadable()
    async loadMasterData(): Promise<void> {
        const unitOfMeasurementResponse = await this.apiService.getAllUnitOfMeasurments();
        this.appStore.dispatch(new LoadUnitOfMeasurement(unitOfMeasurementResponse));

        const scaniaUnitResponse = await this.scaniaUnitApiService.getAllScaniaUnits();
        this.appStore.dispatch(new LoadScaniaUnitAction(scaniaUnitResponse));
    }

    @Loadable()
    async loadComponentType(): Promise<void> {
        const response = await this.apiService.getAllComponentTypes();
        this.appStore.dispatch(new LoadComponentTypesAction(response));
    }
}
