import { DeliveryRouteState } from './state';
import { DeliveryRouteAction, DeliveryRouteActions } from './actions';

export const DeliveryRouteInitialState = require('./initial-data.json') as DeliveryRouteState;

export function DeliveryRouteReducer(
    state: DeliveryRouteState = DeliveryRouteInitialState,
    action: DeliveryRouteAction
): DeliveryRouteState {
    switch (action.type) {
        case DeliveryRouteActions.LoadListAction:
            return {
                ...state,
                list: action.payload
            };
        case DeliveryRouteActions.ChangeFilterExpressionAction:
            return {
                ...state,
                filterExpression: action.payload
            };
        case DeliveryRouteActions.ChangeSortExpressionAction:
            return {
                ...state,
                sortExpression: action.payload
            };
        default:
            return state;
    }
}
