import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonStateService } from '../../../state';

@Component({
    selector: 'cal-user-info-modal',
    templateUrl: './user-info-modal.component.html',
    styleUrls: ['./user-info-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoModalComponent {
    @Input()
    profile: any;
    resources: any;

    constructor(private activeModal: NgbActiveModal,
        private commonService: CommonStateService) {
        this.resources = this.commonService.getResources();
    }

    close() {
        this.activeModal.close();
    }
}
