import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest } from 'rxjs';

import {
    Paging,
    CommonStateService,
    ComponentStateService,
    MasterDataStateService,
    ScaniaUnit,
    ComponentIndividual,
    ComponentSearchForm
} from '../../../../state';
import { CalsiumListConfig, ColumnDataType } from '../../../calsiumlist/calsiumlist.component.model';
import { switchMap, debounceTime, map } from 'rxjs/operators';
import { CalsiumNumberFormatterPipe } from '../../../shared/calsium-number.pipe';
import { CalsiumDateFormatter } from '../../../shared/calsium-date-formatter';
import { CalsiumDateFormatterPipe } from '../../../shared/calsium-date.pipe';
import { TextAlignment } from '../../../shared/enums.model';

@Component({
    selector: 'cal-component-list',
    templateUrl: './component-list.component.html',
    styleUrls: ['./component-list.component.css']
})
export class ComponentListComponent implements OnInit, OnDestroy {

    resources: any = this.commonService.getResources();
    dataList: Observable<ComponentIndividual[]>;
    listConfig: CalsiumListConfig;
    paging: Observable<Paging>;
    totalCount: Observable<number>;
    subscriptions = new Subscription();
    isLoading: Observable<boolean>;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    searchComponentsForm: UntypedFormGroup;
    componentSearchForm: Observable<ComponentSearchForm>;
    scaniaUnitList: Observable<ScaniaUnit[]>;

    constructor(
        private apiService: ComponentStateService,
        private masterDataService: MasterDataStateService,
        private commonService: CommonStateService,
        private formBuilder: UntypedFormBuilder
    ) { }

    ngOnInit() {
        this.isLoading = this.commonService.getLoading();
        this.paging = this.apiService.getComponentPaging();
        this.totalCount = this.apiService.getComponentTotalCount();
        this.sortingExpression = this.apiService.getComponentSortingExpression();
        this.filterExpression = this.apiService.getComponentFilterExpression();
        this.componentSearchForm = this.apiService.getComponentSearchForm();
        this.dataList = this.apiService.getComponents();
        this.scaniaUnitList = this.masterDataService.getScaniaAllocationUnits();

        let pageInitialLoad = true;
        this.subscriptions.add(
            combineLatest(this.componentSearchForm, this.sortingExpression, this.filterExpression, this.paging).pipe(
                debounceTime(0),
                switchMap(([searchForm, sort, filter, paging]) => {
                    if (searchForm) {
                        this.fillSearchForm(searchForm.fromDate, searchForm.toDate, searchForm.scaniaUnit.toString());
                    }
                    if (!pageInitialLoad) {
                        return this.apiService.loadComponentData(searchForm, paging, sort, filter);
                    }
                    pageInitialLoad = false;
                    return new Promise<void>(null);
                })
            ).subscribe()
        );

        this.fillSearchForm();
        this.buildList();
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    fillSearchForm(fromDate: Date = null, toDate: Date = null, scaniaUnit: string = '') {
        this.searchComponentsForm = this.formBuilder.group({
            startDate: [CalsiumDateFormatter.getDateOrDefault(fromDate, true), []],
            endDate: [CalsiumDateFormatter.getDateOrDefault(toDate, false), []],
            selectedScaniaUnit: [scaniaUnit, <any>Validators.required]
        });
    }

    onPaging(page: number) {
        this.apiService.goToComponentPage(page);
    }

    onSort(sortExpression: string) {
        this.apiService.sortComponent(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.apiService.filterComponent(filterExpression);
    }

    onClearFilter() {
        this.apiService.clearFilterAndSorting();
    }

    buildList() {
        this.listConfig = new CalsiumListConfig({
            ShowListCaption: true,
            ShowExportToExcel: true,
            ListCaption: this.resources.FinishedProductsPageTitle,
            ShowFilterRow: true,
            ShowSumRow: false,
            ParentComponent: this
        });

        let column = this.listConfig.AddListColumn(ColumnDataType.details, '', '');
        column.AllowFilter = false;
        column.Sortable = false;
        column.IsAnchor = () => true;
        column.GetAnchorLink = (row:ComponentIndividual) => `/flow/control/component-details/${row.id}`;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'productIndividualNumber', this.resources.ProductIndividual);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'chassisNumber', this.resources.ChassisNumber);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'componentUnitCode', this.resources.ComponentUnitShort, this.resources.ComponentUnit);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'componentUnitDescription', this.resources.ComponentUnitDescriptionShort, this.resources.ComponentUnitDescription);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'popId', this.resources.PopId);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'ipoId', this.resources.IpoId);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'serialNumber', this.resources.SerialNumber);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'productionDate', this.resources.ProductionDate);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumDateFormatterPipe();

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'componentDescription', this.resources.ComponentDescription);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'countryCode', this.resources.Country);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'materialValue', this.resources.MaterialValue);
        column.AllowFilter = false;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(2, 2);
        column.TextAlignment = TextAlignment.Right;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'allocationStatus', this.resources.AllocationStatus);
        column.CssClass = 'nowrap';
        column.AllowFilter = true;
        column.Sortable = true;
    }

    onSearch() {
        if (this.searchComponentsForm.valid) {
            const fromDate = this.searchComponentsForm.value.startDate;
            const toDate = this.searchComponentsForm.value.endDate;
            const scaniaUnit = this.searchComponentsForm.value.selectedScaniaUnit;
            const param: ComponentSearchForm = {
                fromDate,
                toDate,
                scaniaUnit
            };
            this.apiService.changeComponentSearchForm(param);
        }
    }

    onExport() {
        const scaniaUnitId = parseInt(this.searchComponentsForm.value.selectedScaniaUnit, 10);
        const scaniaUnit = this.masterDataService.getState().scaniaUnits.find(su => su.id === scaniaUnitId);
        this.apiService.exportComponentData(scaniaUnit.name);
    }
}
