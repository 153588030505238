<div *ngIf="!showConfirm" class="row" [ngClass]="{loading: isLoading}">
  <div class="alert alert-info col-md-10" role="alert">
    <p><b>{{ resources.Info }}:</b></p>
    <div>{{ resources.PasteCSVLines}} <a (click)="showComponentTypeTable = !showComponentTypeTable"
        style="text-decoration: underline;cursor: pointer;">{{resources.ComponentTypeId}}</a>
      ,{{resources.ProductIndividualNumber}}
      ,{{ resources.Adjustment}}, {{resources.AdjustmentType}}
      ,{{ resources.Comment}}</div>
    <div>
      <table class="table" *ngIf="showComponentTypeTable">
        <thead>
          <tr>
            <th scope="col">{{resources.ComponentId}}</th>
            <th scope="col">{{resources.ComponentType}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let componentType of (componentTypes | async)">
            <th scope="row">{{componentType.id}}</th>
            <td>{{componentType.name}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br>
  <div  class="form-group col-md-10">
    <div class="form-group col-md-1.5; max-limit__text-div-adjustment">
      <span class="max-limit__text-font "><span class="max-limit__text-style">* </span>{{resources.MaximumEntriesAllowedForAdjustment}}</span>
    </div>
  </div>
  <div class="form-group col-md-10">
    <mat-form-field style="width: 100%;" appearance="outline">
      <mat-label>{{resources.WriteCSV}}</mat-label>
      <textarea matInput cdkTextareaAutosize style="width: 100%;font-size: 20px;" [formControl]="csvInput"
        #autosize="cdkTextareaAutosize" (ngModelChange)="csvInputChange($event)" cdkAutosizeMinRows="10" cdkAutosizeMaxRows="50"></textarea>
      <mat-error *ngIf="csvInput.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="validate()">{{resources.Validate}}</button>
    </div>
  </div>
</div>
<div class="row" *ngIf="showConfirm" [ngClass]="{loading: isLoading}">
  <div class="col-md-10">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{resources.ComponentTypeId}}</th>
          <th scope="col">{{resources.ProductIndividualNumber}}</th>
          <th scope="col">{{resources.PvaTotal}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let totalsLine of totalsLines">
          <th scope="row">{{totalsLine.componentTypeId}}</th>
          <td>{{totalsLine.productIndividualNumber}}</td>
          <td class="font-weight-bold">{{totalsLine.total}}</td>
        </tr>
      </tbody>
    </table>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="back()" style="margin-right: 50px;">{{resources.Back}}</button>
      <button type="button" class="btn btn-primary" (click)="confirm()">{{resources.Confirm}}</button>
    </div>
  </div>
</div>