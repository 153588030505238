import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as AngularCommonModule } from '@angular/common';

import { CommonModule } from '../common';
import { ProductionPriceListComponent } from './pages/production-price-list/production-price-list.component';
import { AuthGuardService } from '../authentication';

export const productionPriceListRoutes: Routes = [
    {
        path: 'production-price-list',
        canActivate: [AuthGuardService],
        component: ProductionPriceListComponent
    }
];

@NgModule({
    declarations: [ProductionPriceListComponent],
    imports: [
        CommonModule,
        AngularCommonModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class ProductionPriceListModule { }
