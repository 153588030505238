import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { DeliveryRouteAllocationUnit } from './state-models/delivery-route-allocation-unit';
import { DeliveryRoute } from '../delivery-route/state-models/delivery-route';
import { ScaniaUnitAllocation } from './state-models/scania-unit-allocation';
import { ScaniaUnitConsolidation } from './state-models/scania-unit-consolidation';

export enum DeliveryRouteAllocationUnitActions {
  LoadListAction = '[ Admin ] load delivery route allocation unit',
  ChangeSortExpressionAction = '[ Admin ] Change delivery route allocation unit Sort Expression',
  ChangeFilterExpressionAction = '[ Admin ] Change delivery route allocation unit Filter Expression',
  ChangeResultsTotalCountAction = '[ Admin ] Change delivery route allocation unit results count',
  ChangePageAction = '[ Admin ] Change delivery route allocation unit results page',
  LoadDeliveryRouteListAction = '[ Admin ] load delivery route',
  LoadScaniaUnitAllocationListAction = '[ Admin ] load scania unit allocation',
  LoadScaniaUnitConsolidationListAction = '[ Admin ] load scania Unit Consolidation'
}
export class LoadListAction implements Action {
    readonly type = DeliveryRouteAllocationUnitActions.LoadListAction;
    constructor(public payload: DeliveryRouteAllocationUnit[]) { }
}

export class ChangeSortExpressionAction implements Action {
  readonly type = DeliveryRouteAllocationUnitActions.ChangeSortExpressionAction;
  constructor(public payload: string) { }
}

export class ChangeFilterExpressionAction implements Action {
  readonly type = DeliveryRouteAllocationUnitActions.ChangeFilterExpressionAction;
  constructor(public payload: string) { }
}

export class ChangeResultsTotalCountAction implements Action {
  readonly type = DeliveryRouteAllocationUnitActions.ChangeResultsTotalCountAction;
  constructor(public payload: number) { }
}

export class ChangePageAction implements Action {
  readonly type = DeliveryRouteAllocationUnitActions.ChangePageAction;
  constructor(public payload: number) { }
}

export class LoadDeliveryRouteListAction implements Action {
  readonly type = DeliveryRouteAllocationUnitActions.LoadDeliveryRouteListAction;
  constructor(public payload: DeliveryRoute[]) { }
}

export class LoadScaniaUnitAllocationListAction implements Action {
  readonly type = DeliveryRouteAllocationUnitActions.LoadScaniaUnitAllocationListAction;
  constructor(public payload: ScaniaUnitAllocation[]) { }
}

export class LoadScaniaUnitConsolidationListAction implements Action {
  readonly type = DeliveryRouteAllocationUnitActions.LoadScaniaUnitConsolidationListAction;
  constructor(public payload: ScaniaUnitConsolidation[]) { }
}
export type DeliveryRouteAllocationUnitsAction =
  | LoadListAction
  | ChangeSortExpressionAction
  | ChangeFilterExpressionAction
  | ChangeResultsTotalCountAction
  | ChangePageAction
  | LoadDeliveryRouteListAction
  | LoadScaniaUnitAllocationListAction
  | LoadScaniaUnitConsolidationListAction
;
