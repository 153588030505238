import * as _ from 'lodash';
import { ConsolidationUnitAllocationState } from './state';
import { ConsolidationUnitAllocationAction, ConsolidationUnitAllocationActions } from './actions';

const initialState = require('./initial-data.json') as ConsolidationUnitAllocationState;

export function ConsolidationUnitAllocationReducer(
    state: ConsolidationUnitAllocationState = initialState,
    action: ConsolidationUnitAllocationAction
): ConsolidationUnitAllocationState {
    switch (action.type) {
        case ConsolidationUnitAllocationActions.LoadProducedComponentListAction:
            return {
                ...state,
                producedComponentList: action.payload
            };
        case ConsolidationUnitAllocationActions.LoadAllocatedComponentListAction:
            return {
                ...state,
                allocatedComponentList: action.payload
            };
        case ConsolidationUnitAllocationActions.LoadInStockComponentListAction:
            return {
                ...state,
                stockComponentList: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeComponentSearchFormAction:
            return {
                ...state,
                searchForm: action.payload,
                producedPaging: { ...state.producedPaging, page: 1 },
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                stockPaging: { ...state.stockPaging, page: 1 }
            };
        case ConsolidationUnitAllocationActions.ChangeProducedComponentListFilterExpressionAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount: 0,
                producedPaging: { ...state.producedPaging, page: 1 },
                producedFilterExpression: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeProducedComponentListSortExpressionAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount: 0,
                producedPaging: { ...state.producedPaging, page: 1 },
                producedSortExpression: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeProducedComponentListPageAction:
            return {
                ...state,
                producedComponentList: null,
                producedTotalCount: 0,
                producedPaging: { ...state.producedPaging, page: action.payload },
            };
        case ConsolidationUnitAllocationActions.ChangeProducedComponentListTotalCountAction:
            return {
                ...state,
                producedTotalCount: action.payload.totalCount,
                producedErrorsCount: action.payload.errorsCount
            };
        case ConsolidationUnitAllocationActions.ChangeAllocatedComponentListFilterExpressionAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount: 0,
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                allocatedFilterExpression: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeAllocatedComponentListSortExpressionAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount: 0,
                allocatedPaging: { ...state.allocatedPaging, page: 1 },
                allocatedSortExpression: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeAllocatedComponentListPageAction:
            return {
                ...state,
                allocatedComponentList: null,
                allocatedTotalCount: 0,
                allocatedPaging: { ...state.allocatedPaging, page: action.payload },
            };
        case ConsolidationUnitAllocationActions.ChangeAllocatedComponentListTotalCountAction:
            return { 
                ...state,
                allocatedTotalCount: action.payload.totalCount        
            };
        case ConsolidationUnitAllocationActions.ChangeInStockComponentListFilterExpressionAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: 1 },
                stockFilterExpression: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeInStockComponentListSortExpressionAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: 1 },
                stockSortExpression: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeInStockComponentListPageAction:
            return {
                ...state,
                stockComponentList: null,
                stockTotalCount: 0,
                stockPaging: { ...state.stockPaging, page: action.payload },
            };
        case ConsolidationUnitAllocationActions.ChangeInStockComponentListTotalCountAction:
            return {
                ...state,
                stockTotalCount: action.payload.totalCount
            };
        case ConsolidationUnitAllocationActions.AllocateComponents:
            return {
                ...state,
            };
        case ConsolidationUnitAllocationActions.LoadProducedWidgetAction:
            return {
                ...state,
                producedWidget: action.payload
            };
        case ConsolidationUnitAllocationActions.LoadAllocatedWidgetAction:
            return {
                ...state,
                allocatedWidget: action.payload
            };
        case ConsolidationUnitAllocationActions.LoadInStockWidgetAction:
            return {
                ...state,
                stockWidget: action.payload
            };
        case ConsolidationUnitAllocationActions.ChangeCurrentUnitColumns:
            return {
                ...state,
                currentUnitColumns: action.payload
            };
        default:
            return state;
    }
}
