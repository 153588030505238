<div class="card">
    <h3 class="card-header">{{resources.AddMarkupConditionMaterial}}</h3>
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="form-group col-md-2 ">
                    <label>{{resources.MarkupCondition}}</label>
                    <div class="input-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-select [formControl]="markupCondition">
                                <mat-option value="0" selected disabled="disabled">Select condition</mat-option>
                                <mat-option *ngFor="let item of markupConditionList " [value]="item.id">
                                    {{item.markupConditionCode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group col-md-2 ">
                    <label>{{resources.MaterialNumber}}</label>
                    <div class="input-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <input matInput [formControl]="materialId" type="text"
                                placeholder="{{resources.MaterialNumber}}" maxlength="25" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group col-md-2 ">
                    <label>{{ resources.From }}</label>
                    <div class="input-group " mat-raised-button matTooltip="Allowed to enter past date."
                        matTooltipPosition="below" matTooltipClass="tooltipStyle">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <input matInput [formControl]="validFrom" type="date" placeholder="{{resources.validFrom}}">
                        </mat-form-field>
                    </div>

                </div>
                <div class="form-group  col-md-2 ">
                    <label>{{ resources.To }}</label>
                    <div class="input-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <input matInput [formControl]="validTo" type="date" placeholder="{{ resources.validTo }}" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>&nbsp;</label>
                    <button mat-raised-button type="button" class="btn btn-sm btn-primary form-control" id="saveDeliveryRoute"
                        (click)="onSave()">
                        <i class="fas fa-lg fa-save"></i>&nbsp; {{resources.Save}}
                    </button>
                </div>
                <div class="form-group col-md-2">
                    <label>&nbsp;</label>
                    <button mat-raised-button type="button" class="btn btn-sm btn-primary form-control"
                        id="saveDeliveryRoute" (click)="onCancel()">
                        <i class="fa fa-lg fa-times" aria-hidden="true"></i>&nbsp; {{resources.Cancel}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
