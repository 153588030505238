import { Component, OnInit, ComponentFactoryResolver, Input, ViewChild, ViewRef, AfterContentChecked } from '@angular/core';
import { CalsiumlistSublistDirective } from './calsiumlist-sublist.directive';
import { CalsiumListComponent } from '../calsiumlist.component';
import { CalsiumSubListInterface } from './calsiumlist-sublist-interface';

@Component({
    selector: 'app-calsiumlist-sublist',
    template: '<ng-template appCalsiumlistSublist></ng-template>'
})
export class CalsiumListSubListComponent implements OnInit {
    @Input() inputComponent: any;
    @Input() listComponent: CalsiumListComponent;
    @ViewChild(CalsiumlistSublistDirective, { static: true }) Host: CalsiumlistSublistDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
        this.loadComponent();
    }

    loadComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.inputComponent);
        const viewContainerRef = this.Host.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        (<CalsiumSubListInterface>componentRef.instance).listComponent = this.listComponent;
        this.listComponent.SubComponentRef = componentRef.instance;
    }
}
