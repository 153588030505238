import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription, combineLatest, of, ReplaySubject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { CommonStateService, Paging } from 'src/state';
import { ComponentAllocationStateService } from 'src/state/component-allocation/service';
import { CalsiumListConfig, ColumnDataType, CalsiumListColumnItem } from 'src/app/calsiumlist/calsiumlist.component.model';
import { ComponentAllocation, ComponentAllocationInput } from 'src/state/component-allocation/state-models/component-allocation';
import { CalsiumNumberFormatterPipe } from 'src/app/shared/calsium-number.pipe';
import { ComponentAllocationSearchForm } from 'src/state/component-allocation/state-models/component-allocation-search-form';
import { TextAlignment, ErrorTypeEnum } from 'src/app/shared/enums.model';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { CalculationDetailsComponent } from '../../calculation-details/calculation-details.component';
import { ConfirmationDialogService } from 'src/app/common/confirmation-dialog/confirmation-dialog.service';
import { OrchestratorStateService } from 'src/state/orchestrator/service';

@Component({
    selector: 'cal-allocation-component-list',
    templateUrl: './component-list.component.html',
    styleUrls: ['./component-list.component.css']
})
export class AllocationComponentListComponent implements OnInit, OnDestroy {

    @Input() allocationStatus: string;
    @Input()
    set searchExpression(value: string) {
        if (this.prevSearchExpression !== value) {
            this.prevSearchExpression = value;
            this.onSelectAllChange(false);
            if (this.selectorColumn) this.selectorColumn.SelectorValue = false;
        }
    }
    @Input() populateListName: string;
    prevSearchExpression: string;
    resources: any = this.commonService.getResources();
    dataList: Observable<ComponentAllocation[]>;
    listConfig: CalsiumListConfig;
    paging: Observable<Paging>;
    totalCount: Observable<number>;
    errorCount: Observable<number>;
    subscriptions = new Subscription();
    isLoading: Observable<boolean>;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    componentSearchForm: Observable<ComponentAllocationSearchForm>;
    selectedCount: Observable<number> = of(0);
    selectedCountValue: number = 0;
    selectAll: boolean = false;
    selectedItems: ComponentAllocationInput[] = [];
    excludedItems: ComponentAllocationInput[] = [];
    selectorColumn: CalsiumListColumnItem;
    actionMenuEnabled = true;
    recalculationTriggered = new ReplaySubject<void>();

    constructor(private componentAllocationService: ComponentAllocationStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService,
        private orchestratorService: OrchestratorStateService,
        private confirmationDialog: ConfirmationDialogService,
        public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.componentSearchForm = this.componentAllocationService.getComponentSearchForm();
        this.sortingExpression = this.componentAllocationService.getSortingExpression(this.allocationStatus);
        this.filterExpression = this.componentAllocationService.getFilterExpression(this.allocationStatus);
        this.totalCount = this.componentAllocationService.getTotalCount(this.allocationStatus);
        this.errorCount = this.componentAllocationService.getErrorCount(this.allocationStatus);
        this.paging = this.componentAllocationService.getPaging(this.allocationStatus);
        this.dataList = this.componentAllocationService.getList(this.allocationStatus);
        this.recalculationTriggered.next();

        this.subscriptions.add(
            combineLatest(this.componentSearchForm, this.paging, this.sortingExpression, this.filterExpression, this.recalculationTriggered).pipe(
                debounceTime(0),
                switchMap(([componentSearchForm, paging, sortingExpression, filterExpression, _]) => {
                    if (componentSearchForm != null && this.allocationStatus == this.populateListName) {
                        return this.componentAllocationService.loadComponentAllocationList(
                            this.allocationStatus,
                            componentSearchForm.selectedScaniaUnitId,
                            componentSearchForm.fromDate,
                            componentSearchForm.toDate,
                            paging,
                            sortingExpression,
                            filterExpression,
                            true
                        );
                    }
                    return new Promise<void>(null);
                })
            ).subscribe()
        );
        this.buildList();
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.List;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.IsSelectorEnabled = _ => true;
        this.listConfig.IsSelected = this.isSelected;

        let column;

        this.selectorColumn = this.listConfig.AddListColumn(ColumnDataType.selector, null, null);
        this.selectorColumn.TextAlignment = TextAlignment.Center;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'chassisNumber', this.resources.ChassisNumber);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'popId', this.resources.PopId);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'productionDate', this.resources.ProductionDate);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.boolean, 'calculationCompleted', this.resources.CalculationStatus);
        column.AllowFilter = true;
        column.Sortable = true;
        column.TextAlignment = TextAlignment.Center;
        column.FilterBooleanTrueText = this.resources.Completed;
        column.FilterBooleanFalseText = this.resources.NotCompleted;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'totalMaterialValue', this.resources.MaterialValue);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
        column.TextAlignment = TextAlignment.Right;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'totalProductionValue', this.resources.ProductionValue);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
        column.TextAlignment = TextAlignment.Right;

        column = this.listConfig.AddListColumn(ColumnDataType.number, 'totalValue', this.resources.TotalValue);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
        column.TextAlignment = TextAlignment.Right;

        column = this.listConfig.AddListColumn(ColumnDataType.details, '', '');
        column.AllowFilter = false;
        column.Sortable = false;
        column.IsClickable = _ => true;
        column.OnClick = this.viewCalculationDetails;
        column.GetAnchorLink = RowData => `/calculation-details/${RowData.productIndividualId}/${RowData.allocationScaniaUnitId}`;
    }

    viewCalculationDetails(RowData) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = { productIndividualId: RowData.productIndividualId, allocationScaniaUnitId: RowData.allocationScaniaUnitId };
        dialogConfig.height = '80%';
        dialogConfig.width = '80%';

        this.dialog.open(CalculationDetailsComponent, dialogConfig);
    }

    onPaging(page: number) {
        this.componentAllocationService.goToPage(this.allocationStatus, page);
    }

    onSort(sortExpression: string) {
        this.componentAllocationService.sort(this.allocationStatus, sortExpression);
    }

    onFilter(filterExpression: string) {
        this.componentAllocationService.filter(this.allocationStatus, filterExpression);
    }

    onClearFilterAndSorting() {
        this.componentAllocationService.clearFilterAndSorting(this.allocationStatus);
    }

    onSelectAllChange(select: boolean) {
        this.selectAll = select;
        if (select) {
            combineLatest(this.totalCount, totalCount => { this.selectedCount = of(totalCount); }).subscribe().unsubscribe();
        } else {
            this.selectedCount = of(0);
        }

        this.subscriptions.add(this.selectedCount.subscribe(value => this.selectedCountValue = value));
        this.selectedItems = [];
        this.excludedItems = [];
    }

    onRowSelectionChange(rowSelectionData: { selected: boolean, rowdata: ComponentAllocation }) {
        const rowData = <ComponentAllocationInput>{
            productIndividualId: rowSelectionData.rowdata.productIndividualId,
            componentTypeId: rowSelectionData.rowdata.componentTypeId
        };
        if (this.selectAll) {
            if (!rowSelectionData.selected) {
                this.excludedItems.push(rowData);
            } else if (rowSelectionData.selected) {
                this.excludedItems = this.excludedItems.filter(x => `${x.productIndividualId}|${x.componentTypeId}` !== `${rowData.productIndividualId}|${rowData.componentTypeId}`);
            }
        } else {
            if (rowSelectionData.selected) {
                this.selectedItems.push(rowData);
            } else if (!rowSelectionData.selected) {
                this.selectedItems = this.selectedItems.filter(x => `${x.productIndividualId}|${x.componentTypeId}` !== `${rowData.productIndividualId}|${rowData.componentTypeId}`);
            }
        }
        const selectionChange = rowSelectionData.selected ? 1 : -1;
        this.selectedCount = combineLatest(this.selectedCount, selectedCount => selectedCount + selectionChange);
        this.subscriptions.add(this.selectedCount.subscribe(value => this.selectedCountValue = value));
    }

    onExport() {
        this.componentAllocationService.exportAllocationData(this.allocationStatus);
    }

    async allocate() {
        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.AllocateConfirmation.formatUnicorn(this.selectedCountValue, this.resources.Item_s)
                + this.resources.AllocateComponentConfirmationNotCompletedSelected,
                this.resources.AllocateSelected);
            if (!result) {
                return;
            }

            this.actionMenuEnabled = false;
            const subscription = combineLatest(this.componentSearchForm, this.filterExpression,
                (searchForm, filter) => {
                    this.componentAllocationService.allocate(
                        this.allocationStatus,
                        searchForm.selectedScaniaUnitId,
                        searchForm.fromDate,
                        searchForm.toDate,
                        filter,
                        this.selectAll,
                        this.selectedItems,
                        this.excludedItems)
                        .then((data) => {
                            this.actionMenuEnabled = true;
                            this.onSelectAllChange(false);
                            if (data.errors) {
                                this.errHandler.emitError(this.resources.AllocateError, this.resources.Error, ErrorTypeEnum.Error);
                            } else {
                                if (data.success > 0) {
                                    this.errHandler.emitError(this.resources.AllocatedSuccessfully.formatUnicorn(data.success), this.resources.Success, ErrorTypeEnum.Success);
                                } else {
                                    this.errHandler.emitError(this.resources.NotAllocated, this.resources.Info, ErrorTypeEnum.Info);
                                }
                            }
                        }, (error) => {
                            this.actionMenuEnabled = true;
                            this.errHandler.emitError(this.resources.AllocateError, this.resources.Error, ErrorTypeEnum.Error);
                        });
                }).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(this.resources.SelectComponent, this.resources.NoComponentsSelected, ErrorTypeEnum.Info);
        }
    }

    async moveToStock() {
        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.MoveToStockConfirmation.formatUnicorn(this.selectedCountValue, this.resources.Item_s),
                this.resources.MoveToStock);
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(this.componentSearchForm, this.filterExpression,
                (searchForm, filter) => {
                    this.componentAllocationService.moveToStock(
                        searchForm.selectedScaniaUnitId,
                        searchForm.fromDate,
                        searchForm.toDate,
                        filter,
                        this.selectAll,
                        this.selectedItems,
                        this.excludedItems,
                        this.populateListName)
                        .then((data) => {
                            this.actionMenuEnabled = true;
                            this.onSelectAllChange(false);
                            if (data > 0) {
                                this.errHandler.emitError(this.resources.MovedToStockSuccessfully.formatUnicorn(data), this.resources.Success, ErrorTypeEnum.Success);
                            } else {
                                this.errHandler.emitError(this.resources.NotMovedToStock, this.resources.Info, ErrorTypeEnum.Info);
                            }
                        }, (error) => {
                            this.actionMenuEnabled = true;
                            this.errHandler.emitError(this.resources.MoveToStockError, this.resources.Error, ErrorTypeEnum.Error);
                        });
                }).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(this.resources.SelectComponent, this.resources.NoComponentsSelected, ErrorTypeEnum.Info);
        }
    }

    async recalculate(allocationStatus: string) {
        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.RecalculateConfirmation.formatUnicorn(this.selectedCountValue, this.resources.Item_s),
                this.resources.Recalculate.formatUnicorn(this.resources.component_s));
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(this.componentSearchForm, this.filterExpression,
                (searchForm, filter) => {
                    this.orchestratorService.recalculateProductsByComponentTypes(
                        searchForm.selectedScaniaUnitId,
                        allocationStatus,
                        searchForm.fromDate,
                        searchForm.toDate,
                        filter,
                        this.selectAll,
                        this.selectedItems,
                        this.excludedItems)
                        .then((data) => {
                            this.actionMenuEnabled = true;
                            this.onSelectAllChange(false);
                            if (data > 0) {
                                this.errHandler.emitError(this.resources.RecalculationStarted.formatUnicorn(data || 0, this.resources.component_s), this.resources.Success, ErrorTypeEnum.Success);
                                this.recalculationTriggered.next();
                            } else {
                                this.errHandler.emitError(this.resources.RecalculationNotStarted, this.resources.Info, ErrorTypeEnum.Info);
                            }
                        }, (error) => {
                            this.actionMenuEnabled = true;
                            this.errHandler.emitError(this.resources.RecalculationFailed, this.resources.Error, ErrorTypeEnum.Error);
                        });
                }).subscribe();
            subscription.unsubscribe();
        } else {
            this.errHandler.emitError(this.resources.SelectComponent, this.resources.NoComponentsSelected, ErrorTypeEnum.Info);
        }
    }

    async deallocation() {

        if (this.selectAll || this.selectedItems.length > 0) {
            const result = await this.confirmationDialog.show(
                this.resources.DeallocationConfirmation.formatUnicorn(this.selectedCountValue, this.resources.Item_s),
                this.resources.Deallocate.formatUnicorn(this.resources.component_s));
            if (!result) {
                return;
            }
            this.actionMenuEnabled = false;
            const subscription = combineLatest(this.componentSearchForm, this.filterExpression,
                (searchForm, filter) => {
                    this.orchestratorService.deallocateComponents(
                        searchForm.selectedScaniaUnitId,
                        this.allocationStatus,
                        searchForm.fromDate,
                        searchForm.toDate,
                        filter,
                        this.selectAll,
                        this.selectedItems,
                        this.excludedItems)
                        .then(async (data) => {
                            this.actionMenuEnabled = true;
                            this.onSelectAllChange(false);
                            if (data > 0) {
                                let result = await this.componentAllocationService.deallocateByComponents(this.allocationStatus, searchForm.selectedScaniaUnitId, searchForm.fromDate, searchForm.toDate, data);
                                if (result) {
                                    this.errHandler.emitError(this.resources.DeallocationSuccessfully.formatUnicorn(data || 0, this.resources.component_s), this.resources.Success, ErrorTypeEnum.Success);
                                }
                            } else {
                                this.errHandler.emitError(this.resources.DeallocationNotStarted, this.resources.Info, ErrorTypeEnum.Info);
                            }
                        }, (error) => {
                            this.actionMenuEnabled = true;
                            this.errHandler.emitError(this.resources.DeallocationFailed, this.resources.Error, ErrorTypeEnum.Error);
                        });
                }).subscribe();
            subscription.unsubscribe();
        }
        else {
            this.errHandler.emitError(this.resources.SelectComponent, this.resources.NoComponentsSelected, ErrorTypeEnum.Info);
        }


    };


    isSelected(data: ComponentAllocation): boolean {
        const selected = this.selectAll || this.selectedItems.findIndex(x => `${x.productIndividualId}|${x.componentTypeId}` === `${data.productIndividualId}|${data.componentTypeId}`) >= 0;
        return selected;
    }


}
