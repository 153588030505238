import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProductIndividualSearchFilter } from 'src/app/shared/enums.model'
import { CommonStateService, ProductIndividualStateService } from 'src/state';
import { ComponentIndividualApiService } from 'src/api/services/component-individual-api.service';
import toastConfig from 'src/app/shared/custom-toast-options';

@Component({
    selector: 'cal-product-search',
    templateUrl: './product-search.component.html',
    styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent {

    @ViewChild('inputSearchProduct', { static: true })
    private inputSearchProduct: ElementRef;

    resources: any = this.commonService.getResources();
    searchProductForm: UntypedFormGroup;
    productIndividuals = new Subject();
    productIndividuals$ = this.productIndividuals.asObservable();
    isSearching = new Subject<boolean>();
    isSearching$ = this.isSearching.asObservable();
    searchFilterValues: any = Object.values(ProductIndividualSearchFilter);    
    placeholder: string = this.resources.Search + " " +ProductIndividualSearchFilter.ChassisNumberOrPINumber;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private commonService: CommonStateService,
        private stateService: ProductIndividualStateService,
        private componentStateService: ComponentIndividualApiService
    ) {
        this.searchProductForm = this.formBuilder.group({
            searchProduct: ['', <any>Validators.minLength(1)]
        });
    }

    async onSearch() {
        this.productIndividuals.next([]);
        if (this.searchProductForm.valid) {
            this.toggleSearching(true);
            const selectedFilter = this.inputSearchProduct.nativeElement.placeholder.replace(this.resources.Search, '').trim();
            const productNumber = this.searchProductForm.get('searchProduct').value;
            
            if (productNumber != '' && productNumber != null)
            {
                if (selectedFilter == ProductIndividualSearchFilter.ChassisNumberOrPINumber) {
                    
                        const prodIndividuals = await this.stateService.findProductIndividual(productNumber);
                        
                        if (prodIndividuals.length === 1) {
                            this.searchProductForm.reset();
                            await this.onSelected(prodIndividuals[0].id);

                            this.selectedFilter(selectedFilter);

                        } else if (prodIndividuals.length > 1) {
                            this.productIndividuals.next(prodIndividuals);
                        }
                        else {                            
                            this.toastr.error(this.resources.ProductIndividualOrChassisNumberNotFound, null, toastConfig);
                        }                    
                }
                if(selectedFilter == ProductIndividualSearchFilter.SerialNumber)
                {                    
                        const serialNo = await this.componentStateService.getProductIndividualIdBySerialNo(productNumber);
                        if (serialNo != null && serialNo != '') {
                            this.searchProductForm.reset();
                            await this.onSelected(parseInt(serialNo));
                            this.selectedFilter(selectedFilter);
                        }
                        else {
                            this.toastr.error(this.resources.SerialNumberNotFound, null, toastConfig);
                        }                    
                }
                if(selectedFilter==ProductIndividualSearchFilter.KNRNumber)
                {                    
                    const prodIndividuals = await this.componentStateService.getProductIndividualByKnrNumber(productNumber);
                     
                    if (prodIndividuals.length === 1) {
                        this.searchProductForm.reset();
                        await this.onSelected(prodIndividuals[0].id);

                        this.selectedFilter(selectedFilter);

                    } else if (prodIndividuals.length > 1) {
                        this.productIndividuals.next(prodIndividuals);
                    }
                    else {                            
                        this.toastr.error(this.resources.KnrNumberNotFound, null, toastConfig);
                    }
                }
                if (selectedFilter == ProductIndividualSearchFilter.ArchivedProduct) {
                    
                    const prodIndividuals = await this.stateService.findArchiveProductIndividual(productNumber);
                    
                    if (prodIndividuals.length === 1) {
                        this.searchProductForm.reset();
                        await this.onSelected(prodIndividuals[0].id);

                        this.selectedFilter(selectedFilter);

                    } else if (prodIndividuals.length > 1) {
                        this.productIndividuals.next(prodIndividuals);
                    }
                    else {                            
                        this.toastr.error(this.resources.ProductIndividualOrChassisNumberNotFound, null, toastConfig);
                    }                    
            }
            }
            else {
                this.toastr.error(this.resources.RequiredFieldError, null, toastConfig)
            }            
            this.toggleSearching(false);
        } else {
            this.toastr.warning(this.resources.InvalidPIOrChassisNumberOrSerialNo, null, toastConfig);
        }
    }

    async onSearchButtonClick() {
        await this.onSearch();
        // Have to set the focus on the element, otherwise autocomplete box might not show
        this.inputSearchProduct.nativeElement.focus();
    }

    async onSelected(productIndividualId: number) {
        await this.router.navigate([`/calculation-details/${productIndividualId}/0`]);
        this.productIndividuals.next([]);
    }

    isMatch(productNumber: string) {
        return !!productNumber && !!this.searchProductForm.value.searchProduct 
            && productNumber.toLowerCase() === this.searchProductForm.value.searchProduct.toLowerCase()
            ? 'is-match'
            : '';
    }

    private toggleSearching(isSearching: boolean) {
        this.isSearching.next(isSearching);
        this.searchProductForm.get('searchProduct')[!isSearching ? 'enable' : 'disable']();
    }

    selectedFilter(selected: string)
    {
       this.placeholder = this.resources.Search + " " + selected;
    }
    
}
