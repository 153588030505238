import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map,lastValueFrom } from 'rxjs';
import { VariantFamily, VariantOption } from 'src/state';


@Injectable({
  providedIn: 'root'
})
export class VariantApiService {
  constructor(private apollo: Apollo) { }

  getVariantFamily(variables?): Promise<VariantFamily[]> {
    const result$ = this.apollo
      .query<VariantFamily[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
        query variantFamilyQuery($order: String, $filter: String) {
          admin {
            variantFamily(order: $order, filter: $filter) {
              id
              value
              description
            }
          }
        }`
      })
      .pipe<VariantFamily[]>(
        map((result: any) => {
          return result?.data?.admin?.productClass;
          })

      )
      return lastValueFrom(result$);
  }

  getVariantOptions(variables?): Promise<VariantOption[]> {
    const result$ = this.apollo
      .query<VariantOption[]>({
        variables,
        fetchPolicy: 'no-cache',
        query: gql`
        query variantOptionQuery($order: String, $filter: String, $variantFamilyId:Int) {
          admin {
            variantOption(order: $order, filter: $filter, variantFamilyId: $variantFamilyId) {
              id
              value
              description
              variantFamilyId
            }
            __typename
          }
        }
        `
      })
      .pipe<VariantOption[]>(

        map((result: any) => {
          return result?.data?.admin?.variantOption;
          })

      )
      return lastValueFrom(result$);
  }
}
