import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { ProductionPriceList } from '..';

export enum ProductionPriceListActions {
  loadProductionPriceLists = '[ ProductionPriceList ] Load production price lists',
  selectProductionPriceList = '[ ProductionPriceList ] Select production price list'
}

export class LoadProductionPriceListsAction implements Action {
    readonly type = ProductionPriceListActions.loadProductionPriceLists;
    constructor(public payload: ProductionPriceList[]) { }
}

export class SelectProductionPriceListAction implements Action {
    readonly type = ProductionPriceListActions.selectProductionPriceList;
    constructor(public payload: ProductionPriceList) { }
}

export type ProductionPriceListAction =
  | LoadProductionPriceListsAction
  | SelectProductionPriceListAction;
