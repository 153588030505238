import { ComponentUnit } from './component-unit';
import { ComponentIndividualMaterial } from 'src/state/componentDetails/state-models/component-individual-material';

export interface ComponentIndividual {
    id: number;
    productIndividualId: number;
    productIndividualNumber: string;
    topComponentUnitNumber: string;
    componentUnit: ComponentUnit;
    componentUnitCode: string;
    componentUnitDescription: string;
    componentDescription: string;
    specialOrderNumber: string;
    fitForUseNumber: string;
    multiColorConcept: string;
    extraAttributes: string;
    chassisNumber: string;
    serialNumber: string;
    materialValue: number;
}

export interface FlowControl {
    selectedComponent?: ComponentIndividual;
    TotalCount: number;
}
