import { ProductionPriceListState } from './state';
import { ProductionPriceListAction, ProductionPriceListActions } from './actions';

const initialState = require('./initial-data.json') as ProductionPriceListState;
export function ProductionPriceListReducer(state: ProductionPriceListState = initialState, action: ProductionPriceListAction): ProductionPriceListState {
    switch (action.type) {
        case ProductionPriceListActions.loadProductionPriceLists:
            return {
                ...state,
                productionPriceLists: action.payload
            };
        case ProductionPriceListActions.selectProductionPriceList:
            return {
                ...state,
                selectedProductionPriceList: action.payload
            };
        default:
            return state;
    }
}
