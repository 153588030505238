import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { CalsiumListConfig, CalsiumListColumnItem, ClasiumColumnAction } from '../calsiumlist.component.model';
import { UntypedFormGroup } from '@angular/forms';
import { CalsiumDateFormatterPipe } from '../../shared/calsium-date.pipe';
import { Subscription } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/common/confirmation-dialog/confirmation-dialog.service';
import { CommonStateService } from 'src/state';

@Component({
    selector: '[app-calsiumlist-row]', // tslint:disable-line
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './calsiumlist-row.component.html',
    styleUrls: ['calsiumlist-row.component.css'],
    providers: [CalsiumDateFormatterPipe]
})
export class CalsiumlistRowComponent implements OnInit, OnDestroy {
    @Input() listConfig: CalsiumListConfig;
    @Input() IsAddMode = false;
    @Input() RowData: any;
    @Input() RowNumber: number;
    @Input() AddForm: UntypedFormGroup;
    @Input() EditForm: UntypedFormGroup;
    @Input() resources: any;
    @Input() selected: boolean = false;
    disableEditButton = false;
    subscriptions = new Subscription();
    Form: UntypedFormGroup;

    constructor(private confirmationDialog: ConfirmationDialogService,
        private commonService: CommonStateService) {
        this.resources = this.commonService.getResources();
    }

    ngOnInit() {
        if (this.IsAddMode) {
            this.Form = this.AddForm;
        } else if (this.IsEditMode) {
            this.Form = this.EditForm;
        } else {
            this.Form = null;
        }
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    editRow() {
        this.listConfig.CalsiumListComponent.editRowIndex = this.RowNumber;
        this.Form = this.EditForm;
        this.EditForm.patchValue(this.RowData);
        if (this.listConfig.OnRowEditMethod != null) {
            this.listConfig.OnRowEditMethod.call(this.listConfig.ParentComponent, this.RowData, this.Form);
        }
    }

    cancelEditRow() {
        if (this.IsEditMode) {
            this.listConfig.CalsiumListComponent.editRowIndex = -1;
        }

        if (this.IsAddMode) {
            this.AddForm.reset();
        }
    }

    toggleListSubContent(Rowindex: number) {
        if (Rowindex === this.listConfig.CalsiumListComponent.toggleSubContent) {
            this.listConfig.CalsiumListComponent.toggleSubContent = -1;
            this.listConfig.CalsiumListComponent.expandedItem = null;
        } else {
            this.listConfig.CalsiumListComponent.toggleSubContent = Rowindex;
            this.listConfig.CalsiumListComponent.expandedItem = this.RowData;
        }
    }

    saveEditedRow() {
        this.subscriptions.add(
            this.listConfig.EditItemMethod.call(this.listConfig.ParentComponent, this.EditForm, this.RowData).subscribe((x: boolean) => {
                if (x) {
                    this.listConfig.CalsiumListComponent.editRowIndex = -1;
                }
            })
        );
    }

    AddItem() {
        this.subscriptions.add(
            this.listConfig.AddItemMethod.call(this.listConfig.ParentComponent, this.AddForm)
                .subscribe((x: boolean) => {
                    if (x) {
                        this.AddForm.reset();
                    }
                })
        );
    }

    CloneItem() {
        console.error('Functionality not implemented');
    }

    IsViewMode() {
        return this.listConfig.CalsiumListComponent.editRowIndex !== this.RowNumber && !this.IsAddMode;
    }

    IsEditMode() {
        return this.listConfig.CalsiumListComponent.editRowIndex === this.RowNumber;
    }

    isEditVisible() {
        return this.listConfig.isRowEditable(this.RowData);
    }

    isDeleteVisible(){
        return this.listConfig.IsRowDeletable(this.RowData);
    }

    isSubComponentVisible(){
        return this.listConfig.IsSubComponentVisible(this.RowData);
    }

    GetValue(columnName: string): any {
        const nameArr = columnName.split('.');
        let value = this.RowData;
        for (let i = 0; i < nameArr.length; i++) {
            if (value !== null)
                value = value[nameArr[i]];
        }
        return value;
    }

    async  DeleteRow() {
        const deleteItemConfirmationMessage = this.listConfig.getDeleteItemConfirmationMessage(this.RowData);
        const result = await this.confirmationDialog.show(
            deleteItemConfirmationMessage,
            this.resources.DeleteConfirmation);
        if (!result) {
            return;
        }
        this.listConfig.DeleteItemMethod.call(this.listConfig.ParentComponent, this.RowData);
    }

    GetAnchorLink(column): string {
        if (column.GetAnchorLink !== undefined) {
            return column.GetAnchorLink.call(this.listConfig.ParentComponent, this.RowData);
        }
        return undefined;
    }

    IsAnchor(column): boolean {
        if (column.IsAnchor !== undefined) {
            return column.IsAnchor.call(this.listConfig.ParentComponent, this.RowData);
        }
        return false;
    }

    IsClickable(column): boolean {
        if (column.IsClickable !== undefined) {
            return column.IsClickable.call(this.listConfig.ParentComponent, this.RowData);
        }
        return false;
    }

    OnClick($event: MouseEvent, column: CalsiumListColumnItem): string {
        if (column.OnClick !== undefined) {
            $event.stopImmediatePropagation();
            $event.stopPropagation();
            $event.preventDefault();
            if ($event.ctrlKey) {
                window.open(this.GetAnchorLink(column));
                return null;
            }
            return column.OnClick.call(this.listConfig.ParentComponent, this.RowData);
        }
        return null;
    }

    onChange(column: any) {
        if (column.OnChange != null) {
            column.OnChange.call(this.listConfig.ParentComponent, this.RowData);
        }
    }

    isSelectorEnabled() {
        return this.listConfig.IsSelectorEnabled.call(this.listConfig.ParentComponent, this.RowData);
    }

    onRowSelectionChanged(event) {
        this.listConfig.CalsiumListComponent.rowSelectionChanged.emit({ selected: event.checked, rowdata: this.RowData });
    }

    ActionOnClick(action: ClasiumColumnAction) {
        action.OnClick.call(this.listConfig.ParentComponent, this.RowData);
    }
}
