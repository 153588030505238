import { Component, OnInit, Input } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';

import { CommonStateService } from 'src/state';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { CalsiumSubListInterface } from 'src/app/calsiumlist/calsiumlist-subcomponent/calsiumlist-sublist-interface';
import { CurrencyAdjustmentConditionVariantOption, CurrencyAdjustmentConditionVariantOptionAdd } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition-variant-option';
import { CalsiumListComponent } from 'src/app/calsiumlist/calsiumlist.component';
import { CurrencyAdjustmentCondition } from 'src/state/administraion/currency-adjustment/state-models/currency-adjustment-condition';
import { CurrencyAdjustmentStateService } from 'src/state/administraion/currency-adjustment/service';
import { map } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';


@Component({
    selector: 'cal-currency-adjustment-condition-variant',
    templateUrl: './currency-adjustment-condition-variant.component.html'
})
export class CurrencyAdjustmentConditionVariantComponent implements CalsiumSubListInterface, OnInit {

    @Input() listComponent: CalsiumListComponent;
    condition: CurrencyAdjustmentCondition;
    dataList: Observable<CurrencyAdjustmentConditionVariantOption[]>;
    resources: any = this.commonService.getResources();
    isLoading: Observable<boolean>;
    listConfig: CalsiumListConfig;

    constructor(
        private commonService: CommonStateService,
        private stateService: CurrencyAdjustmentStateService,
        private errHandler: ErrorHandlerService
    ) { }

    public ParentClearFilterAndSortingTriggered(): void { }


    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.condition = this.listComponent.expandedItem;
        this.dataList = this.stateService.getConditionList().pipe(
            map((conditions: CurrencyAdjustmentCondition[]) => conditions.filter(x => x.id === this.condition.id)[0].variantOptions)
        );

        this.buildList();
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ShowListCaption = false;
        this.listConfig.ShowFilterRow = false;
        this.listConfig.ShowExportToExcel = false;
        this.listConfig.ParentComponent = this;
        this.listConfig.AllowEdit = false;
        this.listConfig.AllowDelete = true;
        this.listConfig.IsRowDeletable = (rowdata: CurrencyAdjustmentConditionVariantOption) => rowdata.currencyAdjustmentCondition ? rowdata.currencyAdjustmentCondition.isNew === true : false;

        this.listConfig.DeleteItemMethod = this.onDeleteConditionVariant;
        this.listConfig.getDeleteItemConfirmationMessage = (rowdata: CurrencyAdjustmentConditionVariantOption) => this.resources.CurrencyAdjustmentConditionVariantDeleteConfirm.formatUnicorn(rowdata.variantOption.value, rowdata.variantOption.description);
        this.listConfig.ShowExportToExcel = false;

        this.listConfig.AddListColumn(ColumnDataType.string, 'id', this.resources.Id);
        this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.variantFamily.value', this.resources.VariantFamily);
        this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.variantFamily.description', this.resources.VariantFamilyDescription);
        this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.value', this.resources.VariantOption);
        this.listConfig.AddListColumn(ColumnDataType.string, 'variantOption.description', this.resources.VariantOptionDescription);
    }

    async  onDeleteConditionVariant(rowData: CurrencyAdjustmentConditionVariantOption) {
        await this.stateService.removeConditionVariantOption(this.condition.id, rowData.id);
    }

}
