import * as _ from 'lodash';
import { ReplacementComponentListState } from "./state";
import { ReplacementComponentListAction, ReplacementComponentListActions } from './action';

const initialState = require('./initial-data.json') as ReplacementComponentListState;
export function ReplacementComponentListReducer(
    state: ReplacementComponentListState = initialState, 
    action: ReplacementComponentListAction
): ReplacementComponentListState {
    switch (action.type)
    {
            case ReplacementComponentListActions.LoadReplacementComponentListPageAction:
                return {
                    ...state,
                    replacementComponentList: action.payload
                };                        
            case ReplacementComponentListActions.ChangeReplacementComponentListFilterExpressionAction:
                return {
                    ...state,
                    replacementComponentList: null,
                    replacementComponentListTotalCount: 0,
                    replacementComponentListPaging: { ...state.replacementComponentListPaging, page: 1 },
                    replacementComponentListFilterExpression: action.payload
                };
            case ReplacementComponentListActions.ChangeReplacementComponentListSortExpressionAction:
                return {
                    ...state,
                    replacementComponentList: null,
                    replacementComponentListTotalCount: 0,
                    replacementComponentListPaging: { ...state.replacementComponentListPaging, page: 1 },
                    replacementComponentListSortExpression: action.payload
                };
            case ReplacementComponentListActions.ChangeReplacementComponentListPageAction:
                return {
                    ...state,
                    replacementComponentList: null,
                    replacementComponentListTotalCount: 0,
                    replacementComponentListPaging: { ...state.replacementComponentListPaging, page: action.payload },
                };
            case ReplacementComponentListActions.ChangeReplacementComponentListTotalCountAction:
                return {
                    ...state,
                    replacementComponentListTotalCount: action.payload.totalCount,
                    replacementComponentListErrorsCount: action.payload.errorsCount
                };            
            default:
                return state;
        }
    }
    