import { Action } from '@ngrx/store';
import { SystemNotification } from '..';

export enum NotificationsActions {
    LoadSystemNotifications = '[ Notifications ] Load system notifications'
}

export class LoadSystemNotifications implements Action {
    readonly type = NotificationsActions.LoadSystemNotifications;
    constructor(public payload: SystemNotification[]) { }
}

export type NotificationAction =
    | LoadSystemNotifications
    ;
