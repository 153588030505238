import {
    MarkupConditionMaterialAction,
    MarkupConditionMaterialActionType,
} from "./action";
import { MarkupConditionMaterialStateServiceService } from "./service";
import { MarkupConditionalMaterialState } from "./state";

export const MarkupConditionalInitialState =
    require("./initial-data.json") as MarkupConditionalMaterialState;

export function MarkupConditionMaterialReducer(
    state: MarkupConditionalMaterialState = MarkupConditionalInitialState,
    action: MarkupConditionMaterialAction
): MarkupConditionalMaterialState {
    switch (action.type) {
        case MarkupConditionMaterialActionType.LoadMarkupConditionMaterialListAction:
            return {
                ...state,
                markupConditionMaterialList: action.payload,
            };
        case MarkupConditionMaterialActionType.LoadMarkupConditionMaterialListAction:
            return {
                ...state,
                markupConditionMaterialList: action.payload,
            };
        case MarkupConditionMaterialActionType.AddMarkupConditionMaterialAction:
            return {
                ...state,
                markupConditionMaterialList: [
                    ...state.markupConditionMaterialList,
                    action.payload,
                ],
            };
        case MarkupConditionMaterialActionType.ChangeMarkupConditionMaterialFilterExpressionAction:
            return {
                ...state,
                markupConditionMaterialListFilterExpression: action.payload,
            };
        case MarkupConditionMaterialActionType.ChangeMarkupConditionMaterialSortExpressionAction:
            return {
                ...state,
                markupConditionMaterialListSortExpression: action.payload,
            };

        default:
            return state;
    }
}
