import * as _ from 'lodash';

import { ComponentState } from './state';
import { ComponentAction, ComponentActions } from './actions';
const initialState = require('./initial-data.json') as ComponentState;

export function ComponentReducer(
  state: ComponentState = initialState,
  action: ComponentAction
): ComponentState {
    switch (action.type) {
        case ComponentActions.LoadComponentAction:
            return {
                ...state,
                component: action.payload
            };
        case ComponentActions.LoadProductIndividualComponentsAction:
            return {
                ...state,
                productIndividualComponents: action.payload
            };
        case ComponentActions.ChangeComponentResultsTotalCountAction:
            return {
                ...state,
                componentTotalCount: action.payload
            };
        case ComponentActions.ChangeComponentPageAction:
            return {
                ...state,
                componentPaging: {
                    ...state.componentPaging,
                    page: action.payload
                }
            };
        case ComponentActions.ChangeComponentSortExpressionAction:
            return {
                ...state,
                componentPaging: { ...state.componentPaging, page: 1 },
                componentSortExpression: action.payload
            };
        case ComponentActions.ChangeComponentFilterExpressionAction:
            return {
                ...state,
                componentPaging: { ...state.componentPaging, page: 1 },
                componentFilterExpression: action.payload
            };
        case ComponentActions.ChangeComponentSearchFormAction:
            return {
                ...state,
                componentSearchForm: action.payload,
                componentPaging: { ...state.componentPaging, page: 1 },

            };
        default:
            return state;
    }
}
