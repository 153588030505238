import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { Loadable } from '../common/loadable';
import { LoadProductionPriceListsAction, SelectProductionPriceListAction } from './actions';
import { AppState } from '../app.state';

import { ProductionPriceListApiService } from '../../api/services/production-price-list-api.service';
import { ProductionPriceList } from './state-models/production-price-list';

@Injectable()
export class ProductionPriceListStateService {
    constructor(private appStore: Store<AppState>, private apiService: ProductionPriceListApiService) { }

    @Loadable()
    async loadPriceLists(): Promise<void> {
        const priceLists = await this.apiService.getAllPriceLists();
        this.appStore.dispatch(new LoadProductionPriceListsAction(priceLists));
    }

    @Loadable()
    async loadPriceList(id: number): Promise<void> {
        const priceList = await this.apiService.getPriceList(id);
        this.appStore.dispatch(new SelectProductionPriceListAction(priceList[0]));
    }

    async exportSelectedPriceList(id:number,fileName:string){
        await this.apiService.exportSelectedProductionPriceList(id,fileName);
    }

    getProductionPriceList(): Observable<ProductionPriceList[]> {
        return this.appStore
            .select(state => state.productionPriceList.productionPriceLists).pipe(map(item => _.values(item)));
    }

    getSelectedProductionPriceList(): Observable<ProductionPriceList> {
        return this.appStore
            .select(state => state.productionPriceList.selectedProductionPriceList);
    }
}
