import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import _ from 'lodash';

import { Loadable } from '../common/loadable';
import { AppState } from '../app.state';
import {
    LoadCPAListAction
} from './actions';
import { CPAItem } from './state-models/cpa-item';
import { ConsumingPlanningAreaMappingApiService } from 'src/api/services/consuming-planning-area-mapping-api.service';

@Injectable()
export class CPAReportStateService {
    constructor(
        private apiService: ConsumingPlanningAreaMappingApiService,
        private appStore: Store<AppState>) { }

    @Loadable()
    async loadCPAList() {
        const cpaList = await this.apiService.getConsumingPlanningAreaList();
        this.appStore.dispatch(new LoadCPAListAction(cpaList));
    }

    getList(): Observable<CPAItem[]> {
        return this.appStore.select(state => state.cpaReport.cpaList);
    }

    exportCPAData(cpa: string): Promise<any> {
        const fileName = `CPA_${cpa}_Report`;

        return this.apiService.exportConsumingPlanningAreaReport(fileName, cpa);
    }
}
