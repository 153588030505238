import { Component, OnInit, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import { CalsiumListColumnItem, CalsiumListConfig, ColumnDataType } from './calsiumlist.component.model';
import { SortOrder } from '../shared/enums.model';
import { CalsiumfilterService } from './calsiumlist-filter/calsiumfilter.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CommonStateService } from '../../state';

@Component({
    selector: 'cal-calsiumlist-listing',
    templateUrl: 'calsiumlist.component.html',
    styleUrls: ['calsiumlist.component.css'],
    providers: [CalsiumfilterService]
})
export class CalsiumListComponent implements OnInit, OnChanges {

    @Input() config: CalsiumListConfig;
    @Input() ListBodyData: any[] = [];
    @Input() isLoading = false;
    @Input() dataTotalCount: Number = -1;
    @Input() set filterExpression(filterExpression: string) { this.loadFilterExpression(filterExpression); }
    @Input() name = ''
    @Output() editedItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() exportToExcelTriggered: EventEmitter<any> = new EventEmitter();
    @Output() clearFilterAndSortingTriggered: EventEmitter<any> = new EventEmitter();
    @Output() sortTriggered = new EventEmitter<string>();
    @Output() filterTriggered = new EventEmitter<string>();
    @Output() selectAllChanged = new EventEmitter<boolean>();
    @Output() rowSelectionChanged = new EventEmitter<{ selected: boolean, rowdata: any }>();

    listConfig: CalsiumListConfig = new CalsiumListConfig(); // Internal copy of config defines how the list should appear and word
    listClasses = 'table table-striped table-condensed ';    // bootstrap classes that defines tables
    toggleSubContent = -1;                                   // Toggles row sub setion
    filteredList: any[] = [];                                // Tempoary data store used for filtering
    expandedItem: any = null;                                // The current active pager button
    addForm: UntypedFormGroup; // = new FormGroup({ 'firstName': new FormControl() });
    editForm: UntypedFormGroup;
    editRowIndex = -1;
    resources: any;
    isFiltered = false;
    isSorted = false;
    exportButtonState = 'disabled';

    constructor(private filterService: CalsiumfilterService,
        private commonService: CommonStateService) {
        this.resources = this.commonService.getResources();
    }

    SubComponentRef: any;
    ngOnInit(): void {
        this.buildForm();
        this.listConfig.CalsiumListComponent = this;
        this.listClasses += this.config.ListBordered ? 'table-bordered' : '';
    }

    ngOnChanges(): void {
        this.listConfig = this.config;
        if (this.ListBodyData === null) {
            this.ListBodyData = [];
        }
        this.filteredList = this.ListBodyData;
        this.exportButtonState = !!this.ListBodyData && this.ListBodyData.length > 0 ? '' : 'disabled';
    }

    buildForm() {
        this.addForm = new UntypedFormGroup({});
        this.editForm = new UntypedFormGroup({});
        this.config.ListColumns.forEach((column) => {
            if (this.config.AllowAdd) {
                const control = new UntypedFormControl();
                if (!column.EnabledOnNewItemCreation) {
                    control.disable();
                }
                this.addForm.addControl(column.ColumnName, control);
            }
            if (this.config.AllowEdit) {
                this.editForm.addControl(column.ColumnName, new UntypedFormControl());
            }
        });
    }

    filterItems(filterItem: CalsiumListColumnItem) {
        this.isFiltered = true;
        let filterExpression = null;
        if (typeof (filterItem.FilterValue) === 'string') {
            filterItem.FilterValue = filterItem.FilterValue.trim();
        }
        for (let i = 0; i < this.listConfig.ListColumns.length; i++) {
            if (this.listConfig.ListColumns[i].getFilterExpression() !== null) {
                if (filterExpression !== null && filterExpression.length > 0) {
                    filterExpression += `&${this.listConfig.ListColumns[i].getFilterExpression()}`;
                } else {
                    filterExpression = this.listConfig.ListColumns[i].getFilterExpression();
                }
            }
        }
        if (this.filterTriggered.observers.length) {
            this.filterTriggered.emit(filterExpression);
        } else {
            this.filterService.filterData(this.ListBodyData, filterExpression, this.listConfig.ListColumns)
                .then((data) => {
                    this.filteredList = data;
                });
        }
    }

    clearFilterValues() {
        this.listConfig.ListColumns.forEach((item) => {
            item.FilterValue = '';
        });
    }

    getSum(column: CalsiumListColumnItem): string {
        if (!column.Summable || !this.listConfig.ShowSumRow) {
            return '';
        }

        let sum = 0;
        this.filteredList.forEach((element) => {
            sum += element[column.ColumnName];
        });

        return sum.toString();
    }

    print() {
        // window.print();
        // Or print to excel
        // Or print to pdf
    }

    clearFilterAndSorting() {
        this.clearFilterValues();
        this.resetSortIcons();
        if (this.isFiltered || this.isSorted) {
            this.isFiltered = false;
            this.isSorted = false;
            this.clearFilterAndSortingTriggered.emit();
        }
        this.filteredList = this.ListBodyData;

        if (this.config.HasSubComponent && typeof (this.SubComponentRef) !== 'undefined') {
            this.SubComponentRef.ParentClearFilterAndSortingTriggered();
        }
    }

    exportToExcel() {
        this.exportToExcelTriggered.emit(null);
    }

    resetSortIcons() {
        this.listConfig.ListColumns.forEach((item) => {
            item.SortInOrder = SortOrder.NotDefined;
        });
    }

    sortBy(column: CalsiumListColumnItem, sortOrder: SortOrder) {
        if (column.SortInOrder === sortOrder) {
            return;
        }
        this.isSorted = true;
        this.resetSortIcons();
        column.SortInOrder = sortOrder;
        let columnName = column.ColumnName;
        if (column.DisplayColumnName) {
            columnName = column.DisplayColumnName;
        }
        const sortExpression = `${columnName} ${this.getSortDirection(sortOrder)}`;
        if (this.sortTriggered.observers.length) {
            this.sortTriggered.emit(sortExpression);
        } else {
            if (column.DataType === 'number') {
                this.sortNumeric(column, sortOrder);
            } else {
                this.sortChar(column, sortOrder);
            }
        }
    }

    getSortDirection(sortOrder: SortOrder) {
        if (sortOrder === SortOrder.Asc) {
            return 'asc';
        }
        if (sortOrder === SortOrder.Desc) {
            return 'desc';
        }
        return '';
    }

    sortNumeric(column: CalsiumListColumnItem, sortOrder: SortOrder) {
        const sortOrderNumber = Number(sortOrder);
        this.filteredList.sort((a, b) => {
            let sortOrderResult = 0;
            if (a[column.ColumnName] === null && b[column.ColumnName] === null) {
                sortOrderResult = 0;
            } else if (a[column.ColumnName] === b[column.ColumnName]) {
                sortOrderResult = 0;
            } else if (a[column.ColumnName] === null) {
                sortOrderResult = -1;
            } else if (b[column.ColumnName] === null) {
                sortOrderResult = 1;
            } else {
                const aNum = Number(a[column.ColumnName]);
                const bNum = Number(b[column.ColumnName]);
                if (isNaN(aNum) || isNaN(bNum)) {
                    sortOrderResult = a[column.ColumnName] < b[column.ColumnName] ? -1 : 1;
                } else {
                    sortOrderResult = aNum < bNum ? -1 : 1;
                }
            }
            return sortOrderResult * sortOrderNumber;
        });
    }

    sortChar(column: CalsiumListColumnItem, sortOrder: SortOrder) {
        const sortOrderNumber = Number(sortOrder);
        this.filteredList.sort((a, b) => {
            let sortOrderResult = 0;

            if (a[column.ColumnName] === null && b[column.ColumnName] === null) {
                sortOrderResult = 0;
            } else if (a[column.ColumnName] === null) {
                sortOrderResult = -1;
            } else if (b[column.ColumnName] === null) {
                sortOrderResult = 1;
            } else if (a[column.ColumnName] === b[column.ColumnName]) {
                sortOrderResult = 0;
            } else {
                const aNum = Number(a[column.ColumnName]);
                const bNum = Number(b[column.ColumnName]);
                if (!isNaN(aNum) && !isNaN(bNum)) {
                    sortOrderResult = aNum < bNum ? -1 : 1;
                } else {
                    const aUpperCase = a[column.ColumnName].toUpperCase();
                    const bUpperCase = b[column.ColumnName].toUpperCase();
                    if (aUpperCase === bUpperCase) {
                        sortOrderResult = 0;
                    } else if (aUpperCase < bUpperCase) {
                        sortOrderResult = -1;
                    } else if (aUpperCase > bUpperCase) {
                        sortOrderResult = 1;
                    }
                }
            }
            return sortOrderResult * sortOrderNumber;
        });
    }

    getSortIconClass(column: CalsiumListColumnItem): string {
        if (column.SortInOrder === SortOrder.NotDefined) {
            return 'fa fa-sort';
        }

        if (column.SortInOrder === SortOrder.Asc) {
            return 'fa fa-sort-amount-down-alt';
        }

        if (column.SortInOrder === SortOrder.Desc) {
            return 'fa fa-sort-amount-down';
        }
    }

    rowTrackById(item) {
        return item.id;
    }

    getTotalResultCount() {
        if (+this.dataTotalCount > 0 && this.ListBodyData.length > 0) {
            return this.dataTotalCount;
        }
        return 0;
    }

    loadFilterExpression(filterExpression: string) {
        if (filterExpression !== undefined && filterExpression !== null && filterExpression.length > 0 && filterExpression.indexOf('=') > 0) {
            const filters = filterExpression.split('&');
            for (let i = 0; i < filters.length; i++) {
                const columnName = filters[i].split('=')[0];
                const filterValue = filters[i].split('=')[1];
                for (let i = 0; i < this.config.ListColumns.length; i++) {
                    if (this.config.ListColumns[i].ColumnName === columnName) {
                        this.config.ListColumns[i].FilterValue = filterValue;
                        this.isFiltered = true;
                        break;
                    }
                }
            }
        } else {
            this.clearFilterValues();
            this.isFiltered = false;
        }
    }

    getSafeId(id: string) {
        return id.replace(new RegExp('\\.', 'g'), '-');
    }

    selectAll(event) {
        this.listConfig.GetColumns(ColumnDataType.selector)[0].SelectorValue = true;
        this.selectAllChanged.emit(event.checked);
    }

    isSelected(rowData): boolean {
        if (this.listConfig.IsSelected == null) return null;
        const selected = this.listConfig.IsSelected.call(this.listConfig.ParentComponent, rowData);
        return selected;
    }
}
