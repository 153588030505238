import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { ComponentAllocation } from './state-models/component-allocation';
import { AllocationSummary } from './state-models/allocation-summary';
import { ComponentAllocationSearchForm } from './state-models/component-allocation-search-form';

export enum ComponentAllocationActions {
    ChangeComponentSearchFormAction = '[ Component Allocation ] Change Search Form',

    ChangeProducedComponentListSortExpressionAction = '[ Component Allocation ] Change Produced Sort Expression',
    ChangeProducedComponentListFilterExpressionAction = '[ Component Allocation ] Change Produced Filter Expression',
    ChangeProducedComponentListPageAction = '[ Component Allocation ] Change Produced Page',
    ChangeProducedComponentListTotalCountAction = '[ Component Allocation ] Change Produced Total Count',
    LoadProducedComponentListAction = '[ Component Allocation ] Load produced components',

    ChangeAllocatedComponentListSortExpressionAction = '[ Component Allocation ] Change Allocated Sort Expression',
    ChangeAllocatedComponentListFilterExpressionAction = '[ Component Allocation ] Change Allocated Filter Expression',
    ChangeAllocatedComponentListPageAction = '[ Component Allocation ] Change Allocated Page',
    ChangeAllocatedComponentListTotalCountAction = '[ Component Allocation ] Change allocated Total Count',
    LoadAllocatedComponentListAction = '[ Component Allocation ] Load allocated components',

    ChangeInStockComponentListSortExpressionAction = '[ Component Allocation ] Change in stock Sort Expression',
    ChangeInStockComponentListFilterExpressionAction = '[ Component Allocation ] Change in stock Filter Expression',
    ChangeInStockComponentListPageAction = '[ Component Allocation ] Change in stock Page',
    ChangeInStockComponentListTotalCountAction = '[ Component Allocation ] Change in stock Total Count',
    LoadInStockComponentListAction = '[ Component Allocation ] Load in stock components',

    LoadProducedWidgetAction = '[ Component Allocation ] load produced widget',
    LoadAllocatedWidgetAction = '[ Component Allocation ] load allocated widget',
    LoadInStockWidgetAction = '[ Component Allocation ] load in stock widget',
    AllocateComponents = '[ Component Allocation ] allocate components',
    MoveComponentsToStock = '[ Component Allocation ] move components to stock',
}

export class ChangeComponentSearchFormAction implements Action {
    readonly type = ComponentAllocationActions.ChangeComponentSearchFormAction;
    constructor(public payload: ComponentAllocationSearchForm) { }
}

export class ChangeProducedComponentListSortExpressionAction implements Action {
    readonly type = ComponentAllocationActions.ChangeProducedComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeProducedComponentListFilterExpressionAction implements Action {
    readonly type = ComponentAllocationActions.ChangeProducedComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeProducedComponentListTotalCountAction implements Action {
    readonly type = ComponentAllocationActions.ChangeProducedComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class ChangeProducedComponentListPageAction implements Action {
    readonly type = ComponentAllocationActions.ChangeProducedComponentListPageAction;
    constructor(public payload: number) { }
}

export class LoadProducedComponentListAction implements Action {
    readonly type = ComponentAllocationActions.LoadProducedComponentListAction;
    constructor(public payload: ComponentAllocation[]) { }
}

export class ChangeAllocatedComponentListTotalCountAction implements Action {
    readonly type = ComponentAllocationActions.ChangeAllocatedComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class LoadAllocatedComponentListAction implements Action {
    readonly type = ComponentAllocationActions.LoadAllocatedComponentListAction;
    constructor(public payload: ComponentAllocation[]) { }
}

export class ChangeInStockComponentListTotalCountAction implements Action {
    readonly type = ComponentAllocationActions.ChangeInStockComponentListTotalCountAction;
    payload: { totalCount: number, errorsCount: number };
    constructor(totalCount: number, errorsCount: number) {
        this.payload = { totalCount, errorsCount };
    }
}

export class LoadInStockComponentListAction implements Action {
    readonly type = ComponentAllocationActions.LoadInStockComponentListAction;
    constructor(public payload: ComponentAllocation[]) { }
}

export class LoadProducedWidgetAction implements Action {
    readonly type = ComponentAllocationActions.LoadProducedWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class LoadAllocatedWidgetAction implements Action {
    readonly type = ComponentAllocationActions.LoadAllocatedWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class LoadInStockWidgetAction implements Action {
    readonly type = ComponentAllocationActions.LoadInStockWidgetAction;
    constructor(public payload: AllocationSummary) { }
}

export class ChangeAllocatedComponentListSortExpressionAction implements Action {
    readonly type = ComponentAllocationActions.ChangeAllocatedComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeAllocatedComponentListFilterExpressionAction implements Action {
    readonly type = ComponentAllocationActions.ChangeAllocatedComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeAllocatedComponentListPageAction implements Action {
    readonly type = ComponentAllocationActions.ChangeAllocatedComponentListPageAction;
    constructor(public payload: number) { }
}

export class ChangeInStockComponentListSortExpressionAction implements Action {
    readonly type = ComponentAllocationActions.ChangeInStockComponentListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeInStockComponentListFilterExpressionAction implements Action {
    readonly type = ComponentAllocationActions.ChangeInStockComponentListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeInStockComponentListPageAction implements Action {
    readonly type = ComponentAllocationActions.ChangeInStockComponentListPageAction;
    constructor(public payload: number) { }
}

export class AllocateComponentsAction implements Action {
    readonly type = ComponentAllocationActions.AllocateComponents;
    constructor(public payload: ComponentAllocation[]) { }
}
export class MoveComponentsToStockAction implements Action {
    readonly type = ComponentAllocationActions.MoveComponentsToStock;
    constructor(public payload: ComponentAllocation[]) { }
}

export type ComponentAllocationAction =
    | LoadProducedComponentListAction
    | ChangeComponentSearchFormAction
    | ChangeProducedComponentListSortExpressionAction
    | ChangeProducedComponentListFilterExpressionAction
    | ChangeProducedComponentListTotalCountAction
    | ChangeProducedComponentListPageAction

    | ChangeAllocatedComponentListTotalCountAction
    | LoadAllocatedComponentListAction
    | ChangeAllocatedComponentListSortExpressionAction
    | ChangeAllocatedComponentListFilterExpressionAction
    | ChangeAllocatedComponentListPageAction

    | ChangeInStockComponentListTotalCountAction
    | LoadInStockComponentListAction
    | ChangeInStockComponentListSortExpressionAction
    | ChangeInStockComponentListFilterExpressionAction
    | ChangeInStockComponentListPageAction

    | LoadProducedWidgetAction
    | LoadAllocatedWidgetAction
    | LoadInStockWidgetAction
    | AllocateComponentsAction
    | MoveComponentsToStockAction;
