import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
//import { NgrxCache, NgrxCacheModule } from 'apollo-angular/st';
import { InMemoryCache } from '@apollo/client/core';

export function createApollo(httpLink: HttpLink) {
    return {
        link: httpLink.create({ uri: `${environment.adminUrl}/graphql` }),
        cache: new InMemoryCache(),
        defaultOptions: {
            mutate: {
                errorPolicy: 'all'
            }
        }
    };
}

@NgModule({
    exports: [ApolloModule, HttpClientModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        }
    ],
})
export class GraphQLModule { }
