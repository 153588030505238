import { MaterialPrice } from './material-price';
import { MaterialSupplyType } from './material-supply-type';

export interface Material {
    id: number;
    materialNumber: string;
    materialSupplyType: MaterialSupplyType;
    name: string;
    purchaserCode: string;
    standardPrice: number;
    strategicPrice: number;
    manualPrice: number;
    weightInGrams: number;
    pricedUnitOfMeasurementName: string;
    materialPrices: MaterialPrice[];
}
