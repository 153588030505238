import { ProductClassComponentUnitAction, ProductClassComponentUnitActionType } from "./action";
import { ProductClassComponentUnitState } from "./state";


export const ProductClassComponentUnitInitialState =
    require("./initial-data.json") as ProductClassComponentUnitState;

export function ProductClassComponentUnitReducer(
    state: ProductClassComponentUnitState = ProductClassComponentUnitInitialState,
    action: ProductClassComponentUnitAction
): ProductClassComponentUnitState {
    switch (action.type) {
        case ProductClassComponentUnitActionType.LoadProductClassComponentUnitListAction:
            return {
                ...state,
                productClassComponentUnitList: action.payload,
            };        
        case ProductClassComponentUnitActionType.AddProductClassComponentUnitAction:
            return {
                ...state,
                productClassComponentUnitList: [
                    ...state.productClassComponentUnitList,
                    action.payload,
                ],
            };
        case ProductClassComponentUnitActionType.ChangeProductClassComponentUnitFilterExpressionAction:
            return {
                ...state,
                productClassComponentUnitListFilterExpression: action.payload,
            };
        case ProductClassComponentUnitActionType.ChangeProductClassComponentUnitSortExpressionAction:
            return {
                ...state,
                productClassComponentUnitListSortExpression: action.payload,
            };
        case ProductClassComponentUnitActionType.ChangeProductClassComponentUnitListPageAction:            
            return {
                ...state,
                productClassComponentUnitList: null,
                productClassComponentUnitTotalCount: 0,
                productClassComponentUnitPaging: { ...state.productClassComponentUnitPaging, page: action.payload },
            };
        case ProductClassComponentUnitActionType.ChangeProductClassComponentUnitListTotalCountAction:            
            return {
                ...state,
                productClassComponentUnitTotalCount: action.payload.totalCount,                
            };
        default:
            return state;
    }
}