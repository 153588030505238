import { DeliveryRouteReducer, DeliveryRouteInitialState } from './delivery-route/reducer';
import { AdminState } from './state';
import { ConsumingPlanningAreaMappingInitialState, ConsumingPlanningAreaMappingReducer } from './consuming-planning-area-mapping/reducer';
import { DeliveryRouteAllocationUnitInitialState, DeliveryRouteAllocationUnitReducer } from './delivery-route-allocation-unit/reducer';
import { CurrencyAdjustmentInitialState, CurrencyAdjustmentReducer } from './currency-adjustment/reducer';
import { MarkupConditionalInitialState, MarkupConditionMaterialReducer } from './markup-condition-material/reducer';
import { MarkupPercentageInitialState, MarkupPercentageReducer } from './markup-percentage/reducer';
import { ProductClassComponentUnitInitialState, ProductClassComponentUnitReducer } from './product-class-component-unit/reducer';

const initialState: AdminState = {
    deliveryRoute: DeliveryRouteInitialState,
    consumingPlanningAreaMapping: ConsumingPlanningAreaMappingInitialState,
    deliveryRouteAllocationUnit: DeliveryRouteAllocationUnitInitialState,
    currencyAdjustmentState: CurrencyAdjustmentInitialState,
    markupConditionMaterialState: MarkupConditionalInitialState,
    markupPercentageState: MarkupPercentageInitialState,
    productClassComponentUnitState: ProductClassComponentUnitInitialState
};

export function AdminReducer(
    state: AdminState = initialState,
    action: any
): AdminState {
    const deliveryRouteState = DeliveryRouteReducer(state.deliveryRoute, action);
    const consumingPlanningAreaMappingState = ConsumingPlanningAreaMappingReducer(state.consumingPlanningAreaMapping, action);
    const deliveryRouteAllocationUnitState = DeliveryRouteAllocationUnitReducer(state.deliveryRouteAllocationUnit, action);
    const currencyAdjustmentState = CurrencyAdjustmentReducer(state.currencyAdjustmentState, action);
     const markupConditionMaterialState = MarkupConditionMaterialReducer(
         state.markupConditionMaterialState,
         action
     );
    const markupPercentageState=MarkupPercentageReducer(state.markupPercentageState,action);
    const productClassComponentUnitState = ProductClassComponentUnitReducer(state.productClassComponentUnitState, action);
    return {
        ...state,
        deliveryRoute: deliveryRouteState,
        consumingPlanningAreaMapping: consumingPlanningAreaMappingState,
        deliveryRouteAllocationUnit: deliveryRouteAllocationUnitState,
        currencyAdjustmentState: currencyAdjustmentState,
        markupConditionMaterialState: markupConditionMaterialState,
        markupPercentageState: markupPercentageState,
        productClassComponentUnitState: productClassComponentUnitState
    };
}
