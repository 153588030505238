import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CommonApiService } from '../common.service';
import { ComponentIndividual, ProductIndividual } from '../../state';
import { map,lastValueFrom } from 'rxjs';
import { constant, result } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ComponentIndividualApiService {
    constructor(
        private apollo: Apollo,
        private commonService: CommonApiService
    ) { }

    componentIndividualListQuery = gql`
    query componentIndividualListQuery($from: DateTime, $to: DateTime,$paging: Paging, $order:String, $filter:String, $scaniaUnit: Int) {
        components {
            search(from: $from, to: $to, paging: $paging, order: $order, filter: $filter , scaniaUnit:$scaniaUnit) {
                list {
                    id
                    productIndividualId
                    productIndividualNumber
                    chassisNumber
                    specialOrderNumber
                    fitForUseNumber
                    uniqueDesignNumber
                    countryCode
                    componentUnitCode
                    componentUnitDescription
                    serialNumber
                    componentDescription
                    ipoId
                    popId
                    productionDate
                    materialValue
                    allocationStatus
                    extraAttributes
                }
                totalCount
            }
        }
    }`;

    getComponentIndividuals(variables?): Promise<any> {
        const result$= this.apollo
            .query<any>({
                variables,
                query: this.componentIndividualListQuery
            })
            .pipe(
             map((result: any) => {
              return result?.data?.components?.search;
          })
                
            )
            return lastValueFrom(result$);
    }

    exportComponentIndividuals(variables?, fileName: string = null): Promise<any> {
        return this.commonService.exportQuery(this.componentIndividualListQuery, variables, fileName);
    }

    getComponentIndividualById(id: number): Promise<ComponentIndividual> {
        const result$ = this.apollo
            .query<ComponentIndividual>({
                variables: { id },
                query: gql`
              query($id: Int) {
                components {
                  search(id: $id) {
                    list {
                      id
                      productIndividualId
                      productIndividualNumber
                      chassisNumber
                      specialOrderNumber
                      fitForUseNumber
                      uniqueDesignNumber
                      componentUnitCode
                      componentUnitDescription
                      serialNumber
                      productionDate
                      materialValue
                      extraAttributes
                    }
                  }
                }
              }`
            })
            .pipe<ComponentIndividual>(
                               map((result: any) => {
                  return result?.data?.components?.search?.list;
              })
                    
            )
            
            return lastValueFrom(result$);
    }

    getComponentIndividualMaterial(variables?: any): Promise<any> {
        const result$ =  this.apollo
            .query<any>({
                variables,
                query: gql`
                query componentIndividualMaterialQuery($componentIndividualId: Int, $order: String, $filter: String, $paging: Paging,$showZeroQuantityParts: Boolean) {
                  componentmaterial {
                    search(componentIndividualId: $componentIndividualId, order: $order, filter: $filter, paging: $paging, showZeroQuantityParts:$showZeroQuantityParts) {
                      list {
                        id
                        quantity
                        quantityInBaseUnit
                        price
                        pricePerPart
                        pricePerPartInBaseUnit
                        material{
                          materialNumber
                          name
                        }
                        componentUnit
                        {
                          code
                          description
                        }
                        consumedUnitOfMeasurement
                        {
                          name
                        }
                      }
                      totalCount
                    }
                  }
                }`,
                fetchPolicy: 'no-cache'
            })
            .pipe(
              map((result: any) => {
                return result?.data?.componentmaterial?.search;
            })
            
            )
            
            return lastValueFrom(result$);
    }

    exportComponentIndividualMaterial(variables?, fileName: string = null): Promise<any> {
        const query =
            gql`
            query exportComponentIndividualMaterialQuery(
                $componentIndividualId: Int
                $order: String
                $filter: String
                $paging: Paging
              ) {
                componentmaterial {
                  search(
                    componentIndividualId: $componentIndividualId
                    order: $order
                    filter: $filter
                    paging: $paging
                  ) {
                    list {
                      material {
                        Part_Number: materialNumber
                        Description: name
                      }
                      componentUnit {
                        code
                        description
                      }
                      quantity
                      quantityInBaseUnit
                      consumedUnitOfMeasurement {
                        name
                      }
                      pricePerPart
                      pricePerPartInBaseUnit
                      Total_Price: price
                    }
                  }
                }
              }
              `;
        return this.commonService.exportQuery(query, variables, fileName);
    }

    getComponentsByProductIndividual(variables?): Promise<ComponentIndividual[]> {
        const result$ = this.apollo
            .query<ComponentIndividual[]>({
                variables,
                query: gql`
                query productIndividualComponentsQuery($productIndividualId: Int, $allocationScaniaUnitId: Int) {
                    components {
                        fetch(productIndividualId: $productIndividualId, allocationScaniaUnitId: $allocationScaniaUnitId) {
                            id
                            componentUnitCode
                            componentUnitDescription
                            componentDescription
                        }
                    }
                }
            `})
            .pipe<ComponentIndividual[]>(
           
              map((result: any) => {
                return result?.data?.components?.fetch;
            })
            
            )
            
            return lastValueFrom(result$);
    }

    getProductIndividualIdBySerialNo(serialNumber: string): Promise<string> {
      const result$ = this.apollo
          .query<string>({
            variables: {serialNumber},
              query: gql`
              query fetchProductIndividualIdQuery($serialNumber: String) {
                  components {
                    fetchProductIndividualId(serialNumber: $serialNumber)                       
                  }
              }
          `})
          .pipe<string>(
             
              map((result: any) => {
                return result?.data?.components?.fetchProductIndividualId;
            })
            
          )
          
          return lastValueFrom(result$);
  }

  getProductIndividualByKnrNumber(knrNumber: string): Promise<ProductIndividual[]>{
    const result$ = this.apollo
    .query<ProductIndividual[]>({
        variables:{knrNumber},
        query: gql`
        query($knrNumber: String) {
            products {
                searchUsingKnrNumber(knrNumber: $knrNumber) {
                    list {
                        id
                        chassisNumber
                        productIndividualNumber
                    }
                }
            }
        }
    `})
    .pipe<ProductIndividual[]>(
      
        map((result: any) => {
          return result?.data?.products?.searchUsingKnrNumber?.list;
      })
      
    )
    
    return lastValueFrom(result$);
  }
}
