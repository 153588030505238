
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
import { UnitOfMeasurement } from '../../state/master-data/state-models/unit-of-measurement';
import { map,lastValueFrom } from 'rxjs';
import { ComponentType } from 'src/state/master-data/state-models/component-type';
import { ManualAdjustment } from 'src/app/pva-manual-adjustment/manual-adjustment-model';

@Injectable({
    providedIn: 'root'
})
export class ManualAdjustmentApiService {
    constructor(private apollo: Apollo) { }

    async verify(payload: ManualAdjustment[]): Promise<any> {
        const mutation = `
          mutation ($payload: [ManualAdjustmentInput]!) {
            manualAdjustment {
                verify(payload: $payload) {
                    componentTypeId
                    productIndividualNumber
                    total
              }
            }
          }`;
        return this.apollo.mutate({
            mutation: gql(mutation),
            variables: { payload }
        }).toPromise();
    }

    async confirm(payload: ManualAdjustment[]): Promise<any> {
        const mutation = `
          mutation ($payload: [ManualAdjustmentInput]!) {
            manualAdjustment {
                confirm(payload: $payload)
            }
          }`;
        return this.apollo.mutate({
            mutation: gql(mutation),
            variables: { payload }
        }).toPromise();
    }
}