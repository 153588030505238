import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CalsiumListConfig } from '../../../calsiumlist/calsiumlist.component.model';

import { ProductionPriceList, CommonStateService, MasterDataStateService, ComponentType } from '../../../../state';
import { FormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ManualAdjustmentApiService } from 'src/api/services/manual-adjustment-api.service';
import { ManualAdjustment, ManualAdjustmentTotal } from '../../manual-adjustment-model';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';

@Component({
    selector: 'cal-production-price-list',
    templateUrl: './pva-manual-adjustment.component.html',
    styleUrls: ['./pva-manual-adjustment.component.css']
})
export class PvaManualAdjustmentComponent implements OnInit {

    resources: any = this.commonService.getResources();
    componentTypes: Observable<ComponentType[]>;
    selectedPriceList: Observable<ProductionPriceList>;
    productionPriceListConfig: CalsiumListConfig;
    isPriceListsLoading: boolean = true;
    isResultsLoading: boolean = false;
    showComponentTypeTable: boolean = false;
    csvInput = new UntypedFormControl('', [Validators.required, this.csvFormatValidator(/\d+,"?.+"?,-?\d+,"?(MV|PV|AVC|CREWCAB)"?,"?.+"?/)]);
    totalsLines: ManualAdjustmentTotal[] = [];
    manualAdjustmentList: ManualAdjustment[] = [];
    showConfirm = false;
    isLoading = false;
    constructor(private commonService: CommonStateService,
        private masterDataService: MasterDataStateService,
        private manualAdjustmentApiService: ManualAdjustmentApiService,
        private errHandler: ErrorHandlerService) { }

    ngOnInit() {
        this.masterDataService.loadComponentType();
        this.componentTypes = this.masterDataService.getComponentTypes();
    }

    async validate() {
        this.resetManualAdjustmentList();
        if (!this.csvInput.valid) return;
        this.csvInput.value.split("\n").forEach(line => {
            if (line.trim().length > 0) {
                const lineArr = line.split(',')
                const manualAdjustment: ManualAdjustment = {
                    componentTypeId: +lineArr[0],
                    productIndividualNumber: lineArr[1],
                    adjustment: +lineArr[2],
                    adjustmentType: lineArr[3],
                    comment: lineArr[4]
                }
                this.manualAdjustmentList.push(manualAdjustment);
            }
        });
        this.isLoading = true;
        const result = await this.manualAdjustmentApiService.verify(this.manualAdjustmentList);
        this.isLoading = false;
        if (result.errors) {
            result.errors.forEach((error) => {
                const message = error.message.replace('GraphQL.ExecutionError: ', '');
                this.errHandler.emitError(message, 'invalid format', ErrorTypeEnum.Error);
            });
        } else {
            this.totalsLines = result.data.manualAdjustment.verify;
            this.showConfirm = true;
        }
    }

    async confirm() {
        this.isLoading = true;
        this.showConfirm = false;
        const result = await this.manualAdjustmentApiService.confirm(this.manualAdjustmentList);
        this.isLoading = false;
        this.resetManualAdjustmentList();
        this.totalsLines = [];
        if (result.errors) {
            result.errors.forEach((error) => {
                const message = error.message.replace('GraphQL.ExecutionError: ', '');
                this.errHandler.emitError(message, 'invalid format', ErrorTypeEnum.Error);
            });
        } else if (result.data.manualAdjustment.confirm) {
            this.errHandler.emitError(this.resources.AdjustmentsSavedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
            this.csvInput.reset();
        } else {
            this.errHandler.emitError('error', 'error', ErrorTypeEnum.Error);
        }
    }

    back() {
        this.resetManualAdjustmentList();
        this.totalsLines = [];
        this.isLoading = false;
        this.showConfirm = false;
    }

    resetManualAdjustmentList() {
        this.manualAdjustmentList = [];
    }

    getErrorMessage() {
        return this.csvInput.hasError('required') ? this.resources.RequiredFieldError :
            this.csvInput.hasError('invalidFormat') ? this.resources.InvalidCSV :
            this.csvInput.hasError('exceededMaxEntries') ? "You can only enter a maximum of 500 entries." : '';
    }

    csvFormatValidator(pattern: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value: string = control.value;
            let valid = true;

            if (this.getLineCount(value) > 500) {
                return { 'exceededMaxEntries': { value: control.value } };
            }
            if (value !== null) {
                value.toUpperCase().split("\n").forEach(line => valid = valid && (line.length == 0 || pattern.test(line)));
            }
            return valid ? null : { 'invalidFormat': { value: control.value } };
        };
    }
    csvInputChange(value) {
        const newValue = value.split('\t').join(',');
            if (this.getLineCount(newValue) <= 500) {
                this.csvInput.setValue(newValue, { emitViewToModelChange: false });
            } else {
                this.errHandler.emitError("Maximum of 500 entries allowed.", "Limit Crossed", ErrorTypeEnum.Error);
                this.csvInput.setErrors({ 'exceededMaxEntries': true });
            }
    }

    getLineCount(value: string): number {
        return (value.match(/\n/g) || []).length + 1;
    }
}
