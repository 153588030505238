import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { CommonStateService } from 'src/state';
import { DeliveryRoute } from 'src/state/administraion/delivery-route/state-models/delivery-route';
import { DeliveryRouteStateService } from 'src/state/administraion/delivery-route/service';
import { Validators, UntypedFormControl } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';


@Component({
    selector: 'cal-add-delivery-route',
    templateUrl: './add-delivery-route.component.html',
    styleUrls: ['./add-delivery-route.component.css']
})
export class AddDeliveryRouteComponent implements OnInit, OnDestroy {
    @Input()
    set editItem(value: DeliveryRoute) { this.edit(value); };
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    deliveryRouteCodeInput: UntypedFormControl;
    deliveryRouteDescriptionInput: UntypedFormControl;
    deliveryRouteIsDeliveryValueInput: UntypedFormControl;
    deliveryRouteIsMarkUpValueInput: UntypedFormControl;
    title: string;
    isEdit = false;
    editItemModel:DeliveryRoute;

    constructor(
        private deliveryRouteService: DeliveryRouteStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) { }

    ngOnInit(): void {
        //this.editItem = this.deliveryRouteService.getEditItem();
        this.buildForm();
    }
    buildForm() {
        this.deliveryRouteCodeInput = new UntypedFormControl('', [Validators.required]);
        this.deliveryRouteDescriptionInput = new UntypedFormControl('', [Validators.required]);
        this.deliveryRouteIsDeliveryValueInput = new UntypedFormControl(false);
        this.deliveryRouteIsMarkUpValueInput = new UntypedFormControl(false);
    }

    onSave() {
        if (this.isValidForm()) {         
            const payload: DeliveryRoute = {
                id: this.editItemModel ? this.editItemModel.id : null,
                code: this.deliveryRouteCodeInput.value,
                description: this.deliveryRouteDescriptionInput.value,
                isDeliveryValue: this.deliveryRouteIsDeliveryValueInput.value,
                isMarkUpValue: this.deliveryRouteIsMarkUpValueInput.value
            };

            this.deliveryRouteService.updateDeliveryRoute(payload).then((errors) => {
                if (errors && errors.length) {
                    let errorMessage = '';
                    for (let error of errors) {
                        const errorType = error.split('\n')[0].replace('GraphQL.ExecutionError:', '').trim();
                        switch (errorType) {
                            case 'ALREADY_EXISTING':
                                errorMessage += this.resources.AlreadyExistingDeliveryRoute;
                                break;
                            default:
                                errorMessage += this.resources.ErrorOccured;
                                break;
                        }
                    }
                    this.errHandler.emitError(errorMessage, this.resources.Error, ErrorTypeEnum.Error);
                } else {
                    this.errHandler.emitError(this.resources.DeliveryRouteSavedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
                    this.reset();
                }
            });
        }
    }

    isValidForm(): boolean {
        let valid = true;
        if (this.deliveryRouteCodeInput.errors != null) {
            if (this.deliveryRouteCodeInput.errors.required) {
                this.errHandler.emitError(this.resources.DeliveryRouteCodeRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        if (this.deliveryRouteDescriptionInput.errors != null) {
            if (this.deliveryRouteDescriptionInput.errors.required) {
                this.errHandler.emitError(this.resources.DeliveryRouteDescriptionRequired, this.resources.RequiredFieldError, ErrorTypeEnum.Error);
                valid = false;
            }
        }
        return valid;
    }
    edit(deliveryRoute: DeliveryRoute) {
        this.editItemModel = deliveryRoute;
        if (deliveryRoute) {
            this.deliveryRouteCodeInput.setValue(deliveryRoute.code);
            this.deliveryRouteDescriptionInput.setValue(deliveryRoute.description);
            this.deliveryRouteIsDeliveryValueInput.setValue(deliveryRoute.isDeliveryValue);
            this.deliveryRouteIsMarkUpValueInput.setValue(deliveryRoute.isMarkUpValue);
            this.title = `${this.resources.EditDeliveryRoute} ${deliveryRoute.description}`;
            this.isEdit = true;
            window.scroll(0, 0);
        } else
            this.reset();
    }


    onCancelEdit() {
        this.reset();
    }

    reset() {
        if (this.deliveryRouteCodeInput) {
            this.deliveryRouteCodeInput.reset();
            this.deliveryRouteDescriptionInput.reset();
            this.deliveryRouteIsDeliveryValueInput.setValue(false);
            this.deliveryRouteIsMarkUpValueInput.setValue(false);
        }
        this.isEdit = false;
        this.title = this.resources.AddDeliveryRoute;
        this.editItemModel = null;
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
