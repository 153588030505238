import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { CalsiumListConfig, CalsiumListSelectItem, ColumnDataType } from '../../../calsiumlist/calsiumlist.component.model';
import { Paging, CommonStateService, ScaniaUnit, MasterDataStateService } from '../../../../state';
import { OrchestratorStateService } from '../../../../state/orchestrator/service';
import { StepFaultSearchForm } from '../../../../state/orchestrator/state-models/step-fault-search-form';
import { CalsiumDateFormatterPipe } from '../../../shared/calsium-date.pipe';
import { CalsiumDateFormatter } from '../../../shared/calsium-date-formatter';
import { StepFaultView } from '../../../../state/orchestrator/state-models/step-fault-view';
import { MissingPriceStepFault } from '../../../../state/orchestrator/state-models/step-fault';

@Component({
    selector: 'cal-stepfault-list',
    templateUrl: './stepFault-list.component.html',
    styleUrls: ['./stepFault-list.component.css']
})
export class StepFaultListComponent implements OnInit, OnDestroy {

    resources: any = this.commonService.getResources();
    dataList: Observable<StepFaultView[]>;
    listConfig: CalsiumListConfig;
    paging: Observable<Paging>;
    totalCount: Observable<number>;
    subscriptions = new Subscription();
    isLoading: Observable<boolean>;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    searchForm: UntypedFormGroup;
    stepFaultSearchForm: Observable<StepFaultSearchForm>;
    scaniaUnitList: Observable<ScaniaUnit[]>;

    constructor(
        private apiService: OrchestratorStateService,
        private masterDataService: MasterDataStateService,
        private commonService: CommonStateService,
        private formBuilder: UntypedFormBuilder
    ) { }

    ngOnInit() {
        this.isLoading = this.commonService.getLoading();
        this.paging = this.apiService.getStepFaultPaging();
        this.totalCount = this.apiService.getStepFaultTotalCount();
        this.sortingExpression = this.apiService.getStepFaultSortingExpression();
        this.filterExpression = this.apiService.getStepFaultFilterExpression();
        this.stepFaultSearchForm = this.apiService.getStepFaultSearchForm();
        this.scaniaUnitList = this.masterDataService.getScaniaAllocationUnits();

        let pageInitialLoad = true;
        this.subscriptions.add(
            combineLatest(this.stepFaultSearchForm, this.sortingExpression, this.filterExpression, this.paging).pipe(
                debounceTime(0),
                switchMap(([searchForm, sort, filter, paging]) => {
                    if (searchForm) {
                        this.fillSearchForm(searchForm.fromDate, searchForm.toDate, searchForm.selectedScaniaUnit.toString());
                    }
                    if (!pageInitialLoad) {
                        return this.apiService.loadStepFaultData(searchForm, paging, sort, filter);
                    }
                    pageInitialLoad = false;
                    return new Promise<void>(null);
                })
            ).subscribe()
        );

        this.dataList = this.apiService.getStepFault();
        this.buildList();

        this.fillSearchForm();
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    fillSearchForm(fromDate: Date = null, toDate: Date = null, scaniaUnit: string = '') {
        this.searchForm = this.formBuilder.group({
            startDate: [CalsiumDateFormatter.getDateOrDefault(fromDate, true), []],
            endDate: [CalsiumDateFormatter.getDateOrDefault(toDate, false), []],
            selectedScaniaUnit: [scaniaUnit, <any>Validators.required]
        });
    }

    onPaging(page: number) {
        this.apiService.goToStepFaultPage(page);
    }

    onSort(sortExpression: string) {
        this.apiService.sortStepFault(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.apiService.filterStepFault(filterExpression);
    }

    onClearFilterAndSorting() {
        this.apiService.clearFilterAndSorting();
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ShowListCaption = true;
        this.listConfig.ListCaption = this.resources.FlowErrorPageTitle;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ShowSumRow = false;
        this.listConfig.HasSubComponent = false;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = false;
        this.listConfig.AllowAdd = false;
        this.listConfig.AllowEdit = false;
        this.listConfig.AllowDelete = false;

        // TODO: Add column with data type 'manage' in order to manage all types of flow errors (not only material price missing)
        let column = this.listConfig.AddListColumn(ColumnDataType.list, 'faultType', this.resources.Type);
        column.AllowFilter = true;
        column.Sortable = false;
        column.FilterOptions.FilterListItems = of(CalsiumListSelectItem.getInstanceArr(['Part', 'Component'], [this.resources.Part, this.resources.Component]));
        column.FilterOptions.AddEmptyDropDownListItem = false;

        column = this.listConfig.AddListColumn(ColumnDataType.anchor, 'details', this.resources.ReferenceNumber);
        column.AllowFilter = true;
        column.Sortable = true;
        column.IsAnchor = this.isAnchor;
        column.GetAnchorLink = this.GetAnchorLink;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'stepDescription', this.resources.FlowStep);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'message', this.resources.Message);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'startDate', this.resources.ExecutionDate);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumDateFormatterPipe();
    }

    onSearch() {
        if (this.searchForm.valid) {
            const fromDate = this.searchForm.value.startDate;
            const toDate = this.searchForm.value.endDate;
            const scaniaUnit = this.searchForm.value.selectedScaniaUnit;
            const param: StepFaultSearchForm = {
                fromDate,
                toDate,
                selectedScaniaUnit: scaniaUnit
            };
            this.apiService.changeStepFaultSearchForm(param);
        }
    }

    isAnchor(rowData: StepFaultView): boolean {
        if (rowData.type === MissingPriceStepFault) {
            return true;
        }
        return false;
    }

    GetAnchorLink(rowData: StepFaultView): string {
        if (rowData.type === MissingPriceStepFault) {
            return `/material/${rowData.referenceValue}`;
        }
        // other links in fault screen will be added here
    }
}
