import { Injectable } from '@angular/core';
import { UserManager, User } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccessControlApiService } from '../../../api/services/access-control.service';

@Injectable()
export class AuthService {
    private user: User = null;
    private userLoggedIn = new BehaviorSubject<User>(null);

    get userInfo() {
        return this.userLoggedIn.asObservable();
    }

    get isUserLoggedIn() {
        return this.userInfo.pipe(map(user => user != null && !user.expired));
    }

    get isUserAuthorized$() {
        return this.userInfo.pipe(map(this.isUserAuthorizedInternal));
    }

    constructor(private manager: UserManager, private accessControlService: AccessControlApiService) {
        // if the user is already logged in
        this.manager.getUser().then(async (user) => {
            await this.setUser(user);
        });
    }

    getAuthorizationHeaderValue(): string {
        if (!this.user || this.user.expired) {
            return '';
        }
        return `${this.user.token_type} ${this.user.access_token}`;
    }

    isUserAuthorized(): boolean {
        return this.isUserAuthorizedInternal(this.user);
    }

    private isUserAuthorizedInternal(user: User) {
        return user && user.profile && user.profile.roles && user.profile.roles.length > 0;
    }

    getAuthorizationHeaderName(): string {
        return 'Authorization';
    }

    startAuthentication(): Promise<void> {
        return this.manager.signinRedirect();
    }

    async completeAuthentication(): Promise<void> {
        const user = (await this.manager.signinRedirectCallback()) as User;
        await this.setUser(user);
        this.registerSilentRenewEvents();
    }

    startAuthenticationPopup() {
        window.localStorage.setItem('oidc:calsium:isPopUpCallback', JSON.stringify(true));
        return this.manager.signinPopup();
    }

    async completePopupAuthentication(): Promise<void> {
        window.localStorage.removeItem('oidc:calsium:isPopUpCallback');
        const user = (await this.manager.signinPopupCallback()) as User;
        await this.setUser(user);
    }

    isPopupAuthentication(): boolean {
        return JSON.parse(window.localStorage.getItem('oidc:calsium:isPopUpCallback'));
    }

    private registerSilentRenewEvents() {
        // if the user is logged in.
        this.manager.events.addUserLoaded(async (user) => {
            await this.setUser(user);
        });
        this.manager.events.addSilentRenewError((error) => {
            console.error('Silent renew error', error);
        });
    }

    private async setUser(user: User) {
        this.user = user;
        if (this.user && !this.user.profile.roles) {
            this.user.profile.roles = await this.getUserRoles();
        }
        await this.manager.storeUser(this.user);
        this.userLoggedIn.next(this.user);
    }

    private async getUserRoles(): Promise<string[]> {
        return await this.accessControlService.checkUserAccessRights();
    }
}
